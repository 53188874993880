import React, { useContext, useMemo, useState, useEffect } from 'react'

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import { ReactComponent as BackIcon } from '../../../assets/icons/backArrow.svg';
import { ReactComponent as PrintIcon } from '../../../assets/icons/printIcon.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/editgray.svg';
import { checkIsSomeItemAvailableInList, getPriceFromConfig, getUnitTotalPrice, toINRFormat } from '../../../helpers/helpers';
import moment from 'moment';
import BasicModal from '../../../components/Modals/BasicModal';
import Lightbox from 'yet-another-react-lightbox';
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import pdfimg from '../../../assets/icons/pdfview.jpg'
import CancelBooking from './cancelBooking';
import RejectBooking from './rejectBooking';
import TooltipText from '../../../components/Tooltip';
import Consumer from '../../../helpers/context';
import AddDemand from '../../../components/Collection-Manager/Modal/AddDemand';

const calculatePrice = (basePrice, percent) => {
    return (parseInt(basePrice) * (parseFloat(percent) / 100))
}

const DetailsModal = ({
    show,
    closeModal,
    handleBack,
    unit,
    modalOpenFrom,
    selectedBooking,
    bookingStatus,
    handleChangeApprovalStatus,
    ModalType,
    data,
    handleSubmit,
    updateData,
    operation,
    editBtn,
    handleEditBtn
}) => {
    const [selectdImg, setSelectdImg] = useState();
    const [showLightModal, setShowLightModal] = useState(false);
    const [showCancelBooking, setShowCancelBooking] = useState(false);
    const [showRejectBookingModal, setShowRejectBookingModal] = useState(false);
    const [showRaiseDemand, setShowRaiseDemand] = useState(false);
    const [priceList, setPriceList] = useState({})
    const [documents, setDocuments] = useState([]);
    const [ratePerUnitDiscount, setRatePerUnitDiscount] = useState('');
    const [basePriceDiscount, setBasePriceDiscount] = useState('');
    const [assuredReturnDiscount, setAssuredReturnDiscount] = useState('');
    const [exclusiveDiscount, setExclusiveDiscount] = useState('');

    const { userProfile } = useContext(Consumer);

    const { agreement, bookingUnit, bookingAmeniites, project, bookingPricing, bookingDiscounts, coapplicant, customer, developer, transactions, booking, paymentPlan, customerDocuments } = selectedBooking;

    const aminitiesPrice = useMemo(() => {
        let total = 0;

        bookingAmeniites?.forEach(item => {
            total += parseInt(item.payment_amount)
        })
        return total;
    }, [bookingAmeniites]);

    const aminitiesDiscountPrice = useMemo(() => {
        let total = 0;
        bookingAmeniites?.forEach(item => {
            total += parseInt(item.discount)
        })
        return total;
    }, [bookingAmeniites]);

    useEffect(() => {
        if (bookingDiscounts.length > 0) {
            const ratePerUnit1 = bookingDiscounts.find(dis => dis.type === 'Rate Per Unit')?.payment_amount
            if (ratePerUnit1) {
                setRatePerUnitDiscount(ratePerUnit1)
            }
            const basePriceDiscount1 = bookingDiscounts.find(dis => dis.type === 'Base Price Discount')?.payment_amount
            if (basePriceDiscount1) {
                setBasePriceDiscount(basePriceDiscount1)
            }
            const assuredReturnDiscount1 = bookingDiscounts.find(dis => dis.type === 'Assured Return Discount')?.payment_amount
            if (assuredReturnDiscount1) {
                setAssuredReturnDiscount(assuredReturnDiscount1)
            }
            const exclusiveDiscount1 = bookingDiscounts.find(dis => dis.type === 'Exclusive Discount')?.payment_amount
            if (exclusiveDiscount1) {
                setExclusiveDiscount(exclusiveDiscount1)
            }
        }
    }, [bookingDiscounts]);

    useEffect(() => {
        let transactionDoc = []
        if (customerDocuments || transactions.length > 0) {
            transactions.forEach((transaction) => {
                if (transaction?.documents?.length > 0) {
                    transaction?.documents.forEach((item) => {
                        transactionDoc.push(item);
                    })
                }
            })
            if (customerDocuments && customerDocuments?.length > 0) {
                transactionDoc = [...customerDocuments, ...transactionDoc]
            }
            setDocuments([...transactionDoc])
        }
    }, [transactions, customerDocuments]);

    useEffect(() => {
        const pricingList = {
            ...(bookingUnit?.configuration?.unit_configuration) && { 'Configuration': bookingUnit?.configuration?.unit_configuration },
            ...(bookingUnit?.configuration?.carpet_area) && { 'Carpet Area': bookingUnit?.configuration?.carpet_area && `${bookingUnit?.configuration?.carpet_area} ${bookingUnit?.configuration?.carpet_area_unit}` },
            ...(bookingUnit?.configuration?.plot_area) && { 'Plot Area': bookingUnit?.configuration?.plot_area && `${bookingUnit?.configuration?.plot_area} ${bookingUnit?.configuration?.plot_area_unit}` },
            ...(bookingPricing?.base_price && bookingUnit?.configuration?.plot_area) && { 'Rate/Sqft': bookingPricing?.base_price && `₹ ${toINRFormat(bookingPricing?.base_price / bookingUnit?.configuration?.plot_area)} / sq.yard.` },
            ...(bookingPricing?.base_price) && { 'Base Price': bookingPricing?.base_price && `₹ ${toINRFormat(bookingPricing?.base_price)}` },
            ...(aminitiesPrice > 0) && { 'Amenities + Infra Charges': `₹ ${toINRFormat(aminitiesPrice || 0)}` },
            ...(bookingPricing?.final_price) && { 'Net. Purchase Price': `₹ ${toINRFormat(bookingPricing?.final_price || 0)}` },
            ...(bookingPricing?.stamp_duty || bookingPricing?.stamp_duty_percentage) && { 'Stamp Duty': `₹ ${bookingPricing?.stamp_duty ? toINRFormat(bookingPricing.stamp_duty) : toINRFormat(calculatePrice(bookingPricing?.base_price, bookingPricing?.stamp_duty_percentage) || 0)}` },
            ...(bookingPricing?.idc_charge || bookingPricing?.idc_charge_percentage) && { 'IDC Charge': `₹ ${bookingPricing?.idc_charge ? toINRFormat(bookingPricing.idc_charge) : toINRFormat(calculatePrice(bookingPricing?.base_price, bookingPricing?.idc_charge_percentage) || 0)}` },
            ...(bookingPricing?.edc_charge || bookingPricing?.stamp_duty_percentage) && { 'EDC Charge': `₹ ${bookingPricing?.edc_charge ? toINRFormat(bookingPricing.edc_charge) : toINRFormat(calculatePrice(bookingPricing?.base_price, bookingPricing?.edc_charge_percentage) || 0)}` },
            ...(bookingPricing?.ifdc_charge || bookingPricing?.ifdc_charge_percentage) && { 'IFDC Charge': `₹ ${bookingPricing?.ifdc_charge ? toINRFormat(bookingPricing.ifdc_charge) : toINRFormat(calculatePrice(bookingPricing?.base_price, bookingPricing?.ifdc_charge_percentage) || 0)}` },
            ...(bookingPricing?.registeration_charge || bookingPricing?.registeration_charge_percentage) && { 'Registration': `₹ ${bookingPricing?.registeration_charge ? toINRFormat(bookingPricing.registeration_charge) : toINRFormat(calculatePrice(bookingPricing?.base_price, bookingPricing?.registeration_charge_percentage) || 0)}` },
            ...(bookingPricing?.legal_charge || bookingPricing?.legal_charge_percentage) && { 'Legel Charge': `₹ ${bookingPricing?.legal_charge ? toINRFormat(bookingPricing.legal_charge) : toINRFormat(calculatePrice(bookingPricing?.base_price, bookingPricing?.legal_charge_percentage) || 0)}` },
            ...(bookingPricing?.final_price) && { 'Total Price': `₹ ${toINRFormat(bookingPricing?.final_price || 0)}` },
            ...(aminitiesDiscountPrice > 0) && { 'Amenities Discount': `₹ ${toINRFormat(aminitiesDiscountPrice)}` },
            ...(ratePerUnitDiscount) && { 'Rate Per Unit Discount': `₹ ${toINRFormat(ratePerUnitDiscount)}` },
            ...(basePriceDiscount) && { 'Base Price Discount': `₹ ${toINRFormat(basePriceDiscount)}` },
            ...(assuredReturnDiscount) && { 'Assured Return Discount': `₹ ${toINRFormat(assuredReturnDiscount)}` },
            ...(exclusiveDiscount) && { 'Exclusive Discount': `₹ ${toINRFormat(exclusiveDiscount)}` }
        }

        setPriceList(pricingList);
    }, [bookingUnit, bookingDiscounts]);

    console.log(bookingStatus, 'bookingStatus')
    return (
        <BasicModal show={show} closeModal={closeModal} backdrop={'null'} modalClass='lg:!max-w-[800px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col'>
                <div className='!px-6 !py-6 relative flex items-center justify-between !border-b !border-[#E2E2EA] shadow-bottomshadow'>
                    <div className='flex items-center'>
                        {modalOpenFrom !== 'table' && <button className='mr-2.5' onClick={handleBack}><BackIcon /></button>}
                        <p className='m-0 text-xl font-semibold text-textblack inter capitalize'>{ModalType ? `${ModalType} for ${customer?.customer_name}` : 'Booking'}</p>
                    </div>
                    <div className='flex items-center'>
                        {editBtn && <button
                            className='!border !border-grayLight hover:!border-primary bg-white rounded-lg hover:!bg-blue100 text-base font-medium inline-flex items-center gap-x-2 text-black700 hover:!text-primary w-[150px] h-11 justify-center pr-hover !mr-2'
                            onClick={handleEditBtn}
                        >{editBtn} <EditIcon /></button>}
                        <button onClick={closeModal}><CloseIcon /></button>
                    </div>
                </div>


                <div className='!px-6 !pt-5 pb-8 h-full overflow-y-auto overflow-x-hidden'>
                    <div>
                        <p className='m-0 text-xl font-semibold inter text-textblack'>Customer Details</p>
                        <div className='grid grid-cols-3 gap-y-4 !mt-3 inter'>
                            <div>
                                <p className='!mb-1 text-sm text-black700'>Name</p>
                                <p className='m-0 text-base font-semibold text-black700 inter capitalize'>{customer?.customer_name}</p>
                            </div>
                            <div>
                                <p className='!mb-1 text-sm text-black700'>Mobile</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{customer?.customer_mobile}</p>
                            </div>
                            <div>
                                <p className='!mb-1 text-sm text-black700'>Email</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{customer?.customer_email || '-'}</p>
                            </div>
                            <div>
                                <p className='!mb-1 text-sm text-black700'>
                                    {/* Agreement Amount */}
                                    Purchase Price
                                </p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{(bookingPricing?.final_price || booking?.agreement_price) ? `₹ ${toINRFormat(bookingPricing?.final_price || booking?.agreement_price || '0')}` : '0'}</p>
                            </div>
                            <div>
                                <p className='!mb-1 text-sm text-black700'>
                                    {/* Agreement Amount */}
                                    Booking Price
                                </p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{booking?.amount ? `₹ ${toINRFormat(booking?.amount || '0')}` : '0'}</p>
                            </div>

                            <div>
                                <p className='!mb-1 text-sm text-black700'>{ModalType || 'Booking'} Date</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{moment(new Date(booking?.schedule_date)).format('Do MMM YYYY')} </p>
                            </div>
                            {booking?.token_amount > 0 && <div>
                                <p className='!mb-1 text-sm text-black700'>
                                    {/* Agreement Amount */}
                                    {ModalType || 'Booking'} Amount
                                </p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>₹ {toINRFormat(booking?.token_amount || '0')}</p>
                            </div>}
                            {/* <div>
                                <p className='!mb-1 text-sm text-black700'>Salutation</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>₹ 6254000</p>
                            </div> */}
                        </div>
                    </div>

                    {(customer?.buyer_name || customer?.buyer_mobile || customer?.buyer_email) && <div className='!mt-4'>
                        <p className='m-0 text-xl font-semibold inter text-textblack'>Separate Buyer Details</p>
                        <div className='grid grid-cols-3 gap-y-4 !mt-3 inter'>
                            <div>
                                <p className='!mb-1 text-sm text-black700'>Name</p>
                                <p className='m-0 text-base font-semibold text-black700 inter capitalize'>{customer?.buyer_name || '-'}</p>
                            </div>
                            <div>
                                <p className='!mb-1 text-sm text-black700'>Mobile</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{customer?.buyer_mobile || '-'}</p>
                            </div>
                            <div>
                                <p className='!mb-1 text-sm text-black700'>Email</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{customer?.buyer_email || '-'}</p>
                            </div>
                        </div>

                    </div>}

                    {coapplicant.length > 0 && <div className='!mt-4'>
                        <p className='m-0 text-xl font-semibold inter text-textblack'>Co-applicant Details</p>
                        {
                            coapplicant.map((item, index) => (
                                <div key={`${item.uuid}_${index}`}>
                                    <p className='mb-0 !mt-2 text-base font-semibold inter text-textblack'>Co-applicant {index + 1}</p>
                                    <div className='grid grid-cols-3 gap-y-4 !mt-3 inter'>
                                        <div>
                                            <p className='!mb-1 text-sm text-black700'>Name</p>
                                            <p className='m-0 text-base font-semibold text-black700 inter capitalize'>{item?.name || '-'}</p>
                                        </div>
                                        <div>
                                            <p className='!mb-1 text-sm text-black700'>Mobile</p>
                                            <p className='m-0 text-base font-semibold text-black700 inter'>{item?.mobile || '-'}</p>
                                        </div>
                                        <div>
                                            <p className='!mb-1 text-sm text-black700'>Email</p>
                                            <p className='m-0 text-base font-semibold text-black700 inter'>{item?.email || '-'}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>}
                    <div className='!mt-10'>
                        <p className='m-0 text-xl font-semibold text-textblack inte'>Unit Details</p>
                        {bookingUnit?.configuration?.id && <div className='grid grid-cols-3 gap-y-4 !mt-3 inter'>
                            <div>
                                <p className='!mb-1 text-sm text-black700'>Project</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{project?.name || '-'}</p>
                            </div>
                            {bookingUnit?.configuration?.unit_type !== 'Plot' && <div>
                                <p className='m-0 text-sm text-black700 inter'>Carpet Area</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>{bookingUnit?.configuration?.carpet_area ? `${bookingUnit?.configuration.carpet_area} ${bookingUnit?.configuration.carpet_area_unit}` : '-'}</p>
                            </div>}
                            {bookingUnit?.configuration?.unit_type !== 'Plot' && <div>
                                <p className='m-0 text-sm text-black700 inter'>Balcony Area</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>{bookingUnit?.configuration?.balcony_area ? `${bookingUnit?.configuration.balcony_area} ${bookingUnit?.configuration.balcony_area_unit}` : '-'}</p>
                            </div>}
                            <div>
                                <p className='!mb-1 text-sm text-black700'>{bookingUnit?.configuration?.unit_type === 'Plot' ? 'Block' : 'Tower Number'}</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{bookingUnit?.block ? bookingUnit?.block : '-'}</p>
                            </div>
                            {bookingUnit?.configuration?.unit_type === 'Plot' && <div>
                                <p className='m-0 text-sm text-black700 inter'>Plot Area</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>{bookingUnit?.configuration?.plot_area ? `${bookingUnit?.configuration?.plot_area} ${bookingUnit?.configuration?.plot_area_unit}` : '-'}</p>
                            </div>}
                            {bookingUnit?.configuration?.unit_type !== 'Plot' && <div>
                                <p className='m-0 text-sm text-black700 inter'>Super Built Up Area</p>
                                <p className='m-0 text-base font-semibold text-textblack inter'>{bookingUnit?.configuration?.super_area ? `${bookingUnit?.configuration?.super_area} ${bookingUnit?.configuration?.super_area_unit}` : '-'} </p>
                            </div>}
                            <div>
                                <p className='!mb-1 text-sm text-black700'>Base Price</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'> {bookingPricing?.base_price ? `₹ ${toINRFormat(bookingPricing?.base_price)}` : '-'}</p>
                            </div>
                            {bookingUnit?.configuration?.unit_type !== 'Plot' && <div>
                                <p className='!mb-1 text-sm text-black700'>Floor Number</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{bookingUnit?.floor_no || '-'}</p>
                            </div>}
                            {bookingUnit?.configuration?.unit_type !== 'Plot' && <div>
                                <p className='!mb-1 text-sm text-black700'>Furnishing</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{bookingUnit?.furnishing || '-'}</p>
                            </div>}
                            {bookingUnit?.plc_charge1 && <div>
                                <p className='!mb-1 text-sm text-black700'>PLC Charges 1</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>₹ {toINRFormat(bookingUnit?.plc_charge1 || '-')}</p>
                            </div>}
                            {bookingUnit?.plc_charge2 && <div>
                                <p className='!mb-1 text-sm text-black700'>PLC Charges 2</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>₹ {toINRFormat(bookingUnit?.plc_charge2 || '-')}</p>
                            </div>}
                            {bookingUnit?.plc_charge3 && <div>
                                <p className='!mb-1 text-sm text-black700'>PLC Charges 3</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>₹ {toINRFormat(bookingUnit?.plc_charge3 || '-')}</p>
                            </div>}
                            {bookingUnit?.plc_charge4 && <div>
                                <p className='!mb-1 text-sm text-black700'>PLC Charges 4</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>₹ {toINRFormat(bookingUnit?.plc_charge4 || '-')}</p>
                            </div>}
                            <div>
                                <p className='!mb-1 text-sm text-black700'>Unit Number</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{bookingUnit?.name || '-'}</p>
                            </div>
                            <div>
                                <p className='!mb-1 text-sm text-black700'>Facing</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{bookingUnit?.facing || '-'}</p>
                            </div>
                            <div>
                                <p className='!mb-1 text-sm text-black700'>All Pricing Charges</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{bookingPricing?.final_price ? `₹ ${toINRFormat(bookingPricing?.final_price)}` : '0'}</p>
                            </div>
                        </div>}
                        {booking?.preferred_unit_configuration &&
                            <div className='!mt-4'>
                                <p className='m-0 text-lg font-semibold text-textblack inte'>Preferred Unit</p>
                                <div className='grid grid-cols-3 gap-y-4 !mt-3 inter'>
                                    <div>
                                        <p className='!mb-1 text-sm text-black700'>Project</p>
                                        <p className='m-0 text-base font-semibold text-black700 inter'>{project?.name || '-'}</p>
                                    </div>

                                    <div>
                                        <p className='!mb-1 text-sm text-black700'>Preferred Unit Configuration</p>
                                        <p className='m-0 text-base font-semibold text-black700 inter'> {booking?.preferred_unit_configuration || '-'}</p>
                                    </div>

                                    <div>
                                        <p className='!mb-1 text-sm text-black700'>Preferred Unit Type</p>
                                        <p className='m-0 text-base font-semibold text-black700 inter'>{booking?.preferred_unit_type || '-'}</p>
                                    </div>
                                    <div>
                                        <p className='!mb-1 text-sm text-black700'>Preferred Floor</p>
                                        <p className='m-0 text-base font-semibold text-black700 inter'>{booking?.preferred_floor || '-'}</p>
                                    </div>
                                    <div>
                                        <p className='!mb-1 text-sm text-black700'>Preferred Block</p>
                                        <p className='m-0 text-base font-semibold text-black700 inter'>{booking?.preferred_block || '-'}</p>
                                    </div>
                                    <div>
                                        <p className='!mb-1 text-sm text-black700'>Tentative Pricing</p>
                                        <p className='m-0 text-base font-semibold text-black700 inter'>{booking?.tentative_pricing ? `₹${toINRFormat(booking?.tentative_pricing || '0')}` : '0'}</p>
                                    </div>
                                    <div>
                                        <p className='!mb-1 text-sm text-black700'>Preferred Unit No.</p>
                                        <p className='m-0 text-base font-semibold text-black700 inter'> {booking?.preferred_unit_no || '-'}</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {Object.keys(priceList).length > 0 && <div className={`!mt-10 !mb-3 d-flex flex-fill flex-col`}>
                        <p className='m-0 text-xl font-semibold text-textblack inter'>Pricing</p>
                        <div className='w-100 !mt-5'>
                            <div className={`def-table !h-auto pr-24 pb-0 w-100 !overflow-auto !p-0`}>
                                <table className='table mb-0'>
                                    <thead>
                                        <tr>
                                            <th >S.No</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.keys(priceList).length > 0 && Object.keys(priceList).map((item, index) => {
                                                return ((priceList[item] && !priceList[item]?.includes('undefined') && priceList[item]) && <tr key={item}>
                                                    <td >{index + 1}</td>
                                                    <td>{item}</td>
                                                    <td className='text-black700'>{priceList[item]}</td>
                                                </tr>)
                                            })
                                        }
                                        {/* <tr>
                                            <td>1</td>
                                            <td>Configuration</td>
                                            <td className='text-black700'>{booking?.configuration?.unit_configuration || '-'}</td>
                                        </tr>
                                        {bookingUnit?.configuration?.carpet_area && <tr>
                                            <td >2</td>
                                            <td>Carpet Area</td>
                                            <td className='text-black700'>{bookingUnit?.configuration?.carpet_area ? `${bookingUnit?.configuration?.carpet_area} ${bookingUnit?.configuration?.carpet_area_unit}` : '-'}</td>
                                        </tr>}
                                        {bookingUnit?.configuration?.plot_area && <tr>
                                            <td >2</td>
                                            <td>Plot Area</td>
                                            <td className='text-black700'>{bookingUnit?.configuration?.plot_area ? `${bookingUnit?.configuration?.plot_area} ${bookingUnit?.configuration?.plot_area_unit}` : '-'}</td>
                                        </tr>}
                                        <tr>
                                            <td>3</td>
                                            <td>Rate/Sqft</td>
                                            <td className='text-black700'> {bookingPricing?.base_price ? `₹ ${toINRFormat(bookingPricing?.base_price / bookingUnit?.configuration.plot_area)} / sq.yard.` : '-'} </td>
                                        </tr>
                                        <tr>
                                            <td >4</td>
                                            <td>Base Price</td>
                                            <td className='text-black700'>{bookingPricing?.base_price ? `₹ ${toINRFormat(bookingPricing?.base_price)}` : '-'}</td>
                                        </tr>
                                        <tr>
                                            <td >5</td>
                                            <td>Amenities + Infra Charges</td>
                                            <td className='text-black700'>₹ {toINRFormat(aminitiesPrice || '0')}</td>
                                        </tr>
                                        <tr>
                                            <td >6</td>
                                            <td>Net. Purchase Price</td>
                                            <td className='text-black700'>₹ {toINRFormat(bookingPricing?.final_price || '0')}</td>
                                        </tr>
                                        <tr>
                                            <td >7</td>
                                            <td>Stamp Duty</td>
                                            <td className='text-black700'>₹ {bookingPricing?.stamp_duty ? toINRFormat(bookingPricing.stamp_duty) : toINRFormat(calculatePrice(bookingPricing?.base_price, bookingPricing?.stamp_duty_percentage) || '0')}</td>
                                        </tr>
                                        <tr>
                                            <td >8</td>
                                            <td>Registration</td>
                                            <td className='text-black700'> ₹ {bookingPricing?.registeration_charge ? toINRFormat(bookingPricing.registeration_charge) : toINRFormat(calculatePrice(bookingPricing?.base_price, bookingPricing?.registeration_charge_percentage) || '0')}</td>
                                        </tr>
                                        <tr>
                                            <td >9</td>
                                            <td>GST</td>
                                            <td className='text-black700'>₹ {bookingPricing?.gst ? toINRFormat(bookingPricing.gst) : toINRFormat(calculatePrice(bookingPricing?.base_price, bookingPricing?.gst_percentage) || '0')}</td>
                                        </tr>
                                        <tr>
                                            <td >9</td>
                                            <td>Total Price</td>
                                            <td className='text-black700'>₹ {toINRFormat(bookingPricing?.final_price || '0')}</td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
                    {paymentPlan?.milestones?.length > 0 && <div className="!mt-10 d-flex flex-fill flex-col">
                        <p className='m-0 text-xl font-semibold text-textblack inter'>Payment Plan</p>
                        <div className='w-100 !mt-3'>
                            <div className={`def-table h-auto !overflow-auto !p-0 booking-detail-table w-100`}>
                                <table className='table mb-0'>
                                    <thead>
                                        <tr>
                                            <th className='!w-auto text-left !max-w-fit !min-w-fit'>Milestones</th>
                                            <th>Description</th>
                                            <th>Due Date</th>
                                            <th>%</th>
                                            <th>Net. Purchase Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {
                                            paymentPlan?.milestones?.map((milestone, index) => (
                                                <tr>
                                                    <td className='!w-auto !max-w-fit text-left !min-w-fit'>Milestone {index + 1}</td>
                                                    <td>{milestone.description}</td>
                                                    <td>{milestone.due_date ? moment(new Date(milestone.due_date)).format('Do MMM YYYY') : '-'}</td>
                                                    <td> {milestone.payment_percentage ? `${milestone.payment_percentage}%` : '-'}</td>
                                                    <td> ₹ {milestone.payment_amount ? milestone.payment_amount : toINRFormat(calculatePrice(bookingPricing?.final_price, milestone.payment_percentage) || '0')}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>}
                    {/* <div className='!mt-10'>
                        <p className='m-0 text-xl font-semibold text-textblack inter'>Broker Details</p>
                        <div className='grid grid-cols-3 gap-y-4 !mt-3 text-black700 inter'>
                            <div>
                                <p className='!mb-1 text-sm text-black700'>Name</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{channelPartner?.executive_name}</p>
                            </div>
                            <div>
                                <p className='!mb-1 text-sm text-black700'>Mobile</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{channelPartner?.exective_mobile}</p>
                            </div>
                            <div>
                                <p className='!mb-1 text-sm text-black700'>Email</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>{channelPartner?.exective_email}</p>
                            </div>
                            {/* Need to confirm this agreement amount */}
                    {/* <div>
                        <p className='!mb-1 text-sm text-black700'>Agreement Amount</p>
                        <p className='m-0 text-base font-semibold text-black700 inter'>₹ {toINRFormat(parseInt(channelPartner?.paid_amount) + parseInt(channelPartner?.due_amount) || '0')}</p>
                    </div> */}
                    {/* <div>
                                <p className='!mb-1 text-sm text-black700'>Booking Date</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>12:00 PM, 25 Mar 2023 </p>
                            </div> */}
                    {/* <div>
                                <p className='!mb-1 text-sm text-black700'>Salutation</p>
                                <p className='m-0 text-base font-semibold text-black700 inter'>₹ 6254000</p>
                            </div>
                        </div>
                    </div> */}
                    {documents?.length > 0 && <div className='!mt-10'>
                        <h2 className="inter text-textblack text-xl !mb-4 font-semibold">Media</h2>
                        <div className='grid sm:grid-cols-3 gap-x-6'>
                            {
                                documents.map((item, index) => (
                                    <div >
                                        <div className='!border border-grayLight rounded h-[210px] group  cursor-pointer relative flex items-center justify-center overflow-hidden'
                                        // onClick={() => {
                                        //     if (!['video/mp4', 'application/pdf'].includes(item?.file_type)) {
                                        //         setSelectdImg(index)
                                        //         setShowLightModal(true)
                                        //     } else {
                                        //         window.open(item.url, '_blank')
                                        //     }

                                        // }}
                                        >
                                            {!['pdf', 'pdfx'].includes(item?.name?.split('.').pop()) &&
                                                <img
                                                    onClick={() => {
                                                        if (!['video/mp4'].includes(item?.file_type)) {
                                                            setSelectdImg(index)
                                                            setShowLightModal(true)
                                                        } else {
                                                            window.open(item.url, '_blank')
                                                        }

                                                    }}
                                                    src={item.url} alt="" className='w-full' />}
                                            {['pdf', 'pdfx'].includes(item?.name?.split('.').pop()) &&
                                                <img
                                                    onClick={() => {
                                                        window.open(item.url, '_blank')

                                                    }}
                                                    src={pdfimg} alt="" className='w-full' />}
                                            <div
                                                className='absolute right-4 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-grayLight !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'
                                                onClick={() => {
                                                    const link = document.createElement("a");
                                                    link.href = item.url;
                                                    link.download = `${item.name}`;
                                                    link.click();
                                                }}
                                            >
                                                <DownloadIcon />
                                            </div>
                                        </div>
                                        <TooltipText title={item?.name}>
                                            <p className='text-textblack text-base font-semibold inter !mt-1 w-100 truncate'>
                                                {item?.category}
                                            </p>
                                        </TooltipText>
                                    </div>
                                ))
                            }
                        </div>
                    </div>}
                    {/* <div className='!mt-10'>
                        <h2 className="inter text-textblack text-xl !mb-4 font-semibold">Payment Proof Documents</h2>
                        <div className='grid sm:grid-cols-3 gap-x-6'>
                            {
                                transactions.length > 0 && transactions.map((transaction, index) => {
                                    return transaction?.documents?.length > 0 && transaction?.documents.map((item, index) => (
                                        <div >
                                            <div className='!border border-grayLight rounded h-[210px] group  cursor-pointer relative flex items-center justify-center overflow-hidden'
                                                onClick={() => {
                                                    if (!['video/mp4', 'application/pdf'].includes(item?.file_type)) {
                                                        setSelectdImg(index)
                                                        setShowLightModal(true)
                                                    } else {
                                                        window.open(item.url, '_blank')
                                                    }
                                                }}
                                            >
                                                {!['pdf', 'pdfx'].includes(item?.name?.split('.').pop()) && <img src={item.url} alt="" className='w-full' />}
                                                {['pdf', 'pdfx'].includes(item?.name?.split('.').pop()) && <img src={pdfimg} alt="" className='w-full' />}
                                                <div
                                                    className='absolute right-4 top-4 !border !border-grayLight w-10 h-10 inline-flex items-center justify-center !bg-grayLight !opacity-0 group-hover:!opacity-100 rounded cursor-pointer'
                                                    onClick={() => {
                                                        const link = document.createElement("a");
                                                        link.href = item.url;
                                                        link.download = `${item.name}`;
                                                        link.click();
                                                    }}
                                                >
                                                    <DownloadIcon />
                                                </div>
                                            </div>
                                            <TooltipText title={item?.name}>
                                                <p className='text-textblack text-base font-semibold inter !mt-1 w-100 truncate'>
                                                    {item?.name}
                                                </p>
                                            </TooltipText>
                                        </div>
                                    ))
                                })
                            }

                        </div>
                    </div> */}
                </div>
            </div>

            <div className='!px-6 !py-4 flex sm:items-center justify-between shadow-topshadow sm:flex-row flex-col'>
                {bookingPricing?.final_price > 0 && <div className='w-full'>
                    <p className='m-0 inter sm:text-xl text-lg text-textblack font-semibold'>Total Price : ₹ {toINRFormat(bookingPricing?.final_price || '0')}</p>
                    <p className='m-0 text-sm inter text-black700 !mt-1'>For {bookingUnit?.name}</p>
                </div>}
                {(!operation && bookingStatus !== 'cancelled') && <div className='inline-flex  justify-end gap-x-3 w-full'>
                    {
                        (ModalType !== 'Booking' && bookingStatus === 'approved') &&
                        <button
                            type='button'
                            className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black w-[200px] h-[44px] text-center mr-3'
                            onClick={() => setShowRaiseDemand(true)}
                        >
                            Raise Demand
                        </button>
                    }
                    {bookingStatus === 'pending' ?
                        <>
                            {userProfile?.company_id !== +process.env.REACT_APP_COMPANY_ID && checkIsSomeItemAvailableInList(userProfile?.role?.allowed_permissions, ['change_eoi_approval', 'change_booking_approval']) &&
                                <button
                                    className='border border-grayLight py-2.5 px-3 mr-3 font-medium rounded-lg'
                                    onClick={() => setShowRejectBookingModal(true)}
                                >Reject {ModalType}</button>}
                            {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && checkIsSomeItemAvailableInList(userProfile?.role?.allowed_permissions, ['cancel_eoi', 'cancel_booking']) &&
                                <button
                                    className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[160px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center hover:!bg-black100 !bg-white'
                                    onClick={() => {
                                        if (ModalType) {
                                            setShowCancelBooking(true)
                                        } else {
                                            closeModal()
                                        }
                                    }}
                                >Cancel {ModalType}</button>}
                            {checkIsSomeItemAvailableInList(userProfile?.role?.allowed_permissions, ['change_eoi_approval', 'change_booking_approval']) && <button
                                className='border border-grayLight py-2.5 px-3 mr-3 font-medium rounded-lg !bg-primary text-white flex items-center'
                                onClick={() => handleChangeApprovalStatus({ status: 'approved' })}
                            >
                                Approve {ModalType}
                            </button>}
                        </> :
                        <>
                            <div className='flex  justify-end gap-x-3 w-full'>
                                {/*  */}
                                {checkIsSomeItemAvailableInList(userProfile?.role?.allowed_permissions, ['cancel_approved_booking']) && <button
                                    className={`text-base ${bookingStatus === 'approved' ? '!text-white !bg-red' : '!text-black hover:!bg-black100 !bg-white'} font-medium inter leading-6 rounded-lg !border !border-grayLight  sm:w-[128px] sm:basis-[160px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center `}
                                    onClick={() => {
                                        if (ModalType) {
                                            setShowCancelBooking(true)
                                        } else {
                                            closeModal()
                                        }
                                    }}
                                >Cancel {ModalType}</button>}

                                {(bookingStatus === 'approved' && !userProfile?.role?.allowed_permissions.includes('cancel_approved_booking')) &&
                                    <button
                                        className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[160px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center hover:!bg-black100 !bg-white'
                                        onClick={closeModal}
                                    >Close</button>
                                }
                            </div>
                            {/* <button
                                className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[160px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center hover:!bg-black100 !bg-white items-center inline-flex gap-x-2 justify-center'
                            >
                                Print Details
                                <PrintIcon />
                            </button> */}
                        </>
                    }

                </div>}
                {
                    (bookingStatus === 'cancelled' || operation === 'view only') && <div className='inline-flex  justify-end gap-x-3 w-full'>
                        <button
                            className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black sm:w-[128px] sm:basis-[160px] sm:grow-0 sm:shrink-0 w-full h-[44px] text-center hover:!bg-black100 !bg-white'
                            onClick={closeModal}
                        >Close</button>
                    </div>
                }
                {showLightModal && <Lightbox
                    open={showLightModal}
                    close={() => setShowLightModal(false)}
                    slides={documents?.filter(item => (!['video/mp4', 'application/pdf'].includes(item?.file_type))).map(item => ({ src: item.url }))}
                    carousel={{
                        finite: true
                    }}
                    index={selectdImg}
                    plugins={[Thumbnails]}
                // video={{
                //     controls: true,
                //     playsInline: true,
                //     autoPlay: false,
                //     loop: false,
                //     muted: true,
                //     disablePictureInPicture: false,
                //     disableRemotePlayback: false,
                //     controlsList: ["nodownload" | "nofullscreen" | "noremoteplayback"].join(" "),
                //     crossOrigin: '',
                //     preload: '',
                // }}
                />}

                {showCancelBooking &&
                    <CancelBooking
                        show={showCancelBooking}
                        closeModal={() => setShowCancelBooking(false)}
                        data={selectedBooking}
                        ModalType={ModalType}
                        handleSubmitSuccess={() => {
                            updateData();
                            closeModal();
                            setShowCancelBooking(false);
                        }}
                        updateData={updateData}
                    />
                }
                {showRejectBookingModal &&
                    <RejectBooking
                        show={showRejectBookingModal}
                        closeModal={() => setShowRejectBookingModal(false)}
                        data={data}
                        ModalType={ModalType}
                        handleSubmit={handleChangeApprovalStatus}
                        updateData={updateData}
                    />
                }
            </div>

            {showRaiseDemand && <AddDemand
                show={showRaiseDemand}
                closeModal={() => {
                    setShowRaiseDemand(false)
                }}
                item={{
                    eoi_uuid: data.eoi_uuid,
                    customer_name: data.name,
                    adjusted_price: bookingPricing?.final_price || booking?.agreement_price,
                    lead_uuid: data?.id,
                    unit_name: bookingUnit?.name
                }}
                demandRaiseFrom={'EOI table'}
                paymentMilestones={paymentPlan?.milestones?.filter(item => !item.is_completed).map(milestone => ({ ...milestone, label: `${milestone.count}. ${milestone.description}`, value: milestone.id }))}
                onSuccess={() => {
                    setShowRaiseDemand(false)
                }}
            />}
        </BasicModal>
    )
}

export default DetailsModal