import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import TooltipText from '../../../components/Tooltip'
import { createDatewithTime } from '../../../helpers/date'
import DynamicIntent from '../../../components/Custom Table/TableColumns/DynamicIntent'
import StatusDropdown from './StatusDropdown'
import { getSelectedValue } from '../../../utils/selectedOptionUtils'
import ReengagedSource from '../../../components/Custom Table/TableColumns/ReengagedSource'
import SharedWithUserName from '../../../components/Custom Table/TableColumns/SharedWithUserName'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import moment from 'moment'
import { MAP_CURRENCY_NUMBER_TO_TEXT } from '../../../utils/constants'
import SourceLabel from '../../../components/Custom Table/TableColumns/SourceLabel'
import Projects from '../../../components/Custom Table/TableColumns/Projects';
import { ReactComponent as PhoneIcon } from '../../../assets/new/callSolid.svg'
import { toINRFormat } from '../../../helpers/helpers'
import { Oval } from 'react-loader-spinner'

const SVTYPES = {
    offline: 'Onsite',
    online: 'Video Call'
}

const getStatusClasses = (status) => {
    switch (status) {
        case 'rejected':
            return 'text-red bg-red/10'
        case 'cancelled':
            return 'text-red bg-red/10'
        case 'approved':
            return 'text-green bg-green100'
        default:
            return 'text-orange-dark bg-orange-100'
    }
}

const RenderDashboardTableData = ({
    tHead,
    tData,
    isRedirectOnNameClick,
    intentStatuses,
    statuses, redirectURL, onSuccess, sourceStatuses, redirectBactTo,
    handleOpenVerifyPage,
    selectedItem,
    setSelectedItem,
    setDataLoading,
    dataLoading,
    allowedPermissions,
    selectedLead,
    setLeadStatus,
    setBookingStatus,
    getEOILeadDetails,
    getBookingDetails,
    tab,
    subTab,
    handleClickToCall
}) => {

    const rendering = () => {
        switch (tHead.label) {
            case 'Name':
                return <td class="!px-3 !whitespace-nowrap !py-2 !sticky !left-14 bg-white" scope='col'>
                    {isRedirectOnNameClick && <Link
                        to={`/${redirectURL}/${tData?.uuid || tData?.id}?redirect=${redirectBactTo}`} className='capitalize !text-black !w-[150px]'
                        onClick={() => {
                            localStorage.setItem('tab', tab);
                            localStorage.setItem('subTab', subTab);
                        }}
                    >
                        {tData.name?.length > 15 ? <TooltipText title={tData.name}>
                            <p className='m-0 truncate !w-[150px]'> {tData.name}</p>
                        </TooltipText> :
                            tData.name || '-'
                        }
                    </Link>}
                    {!isRedirectOnNameClick && <p className='capitalize !text-black !w-[150px] !mb-0'>
                        {tData.name?.length > 15 ? <TooltipText title={tData.name}>
                            <p className='m-0 truncate !w-[150px]'> {tData.name}</p>
                        </TooltipText> :
                            tData.name || '-'
                        }
                    </p>}
                </td>
            case 'Opportunities':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    <Link to={`/${redirectURL}/${tData?.uuid || tData?.id}`} className='capitalize !text-black !w-[150px]'>
                        {tData.project?.length > 15 ? <TooltipText title={tData.project}>
                            <p className='m-0 truncate !w-[150px]'> {tData.project}</p>
                        </TooltipText> :
                            tData.project || '-'
                        }
                    </Link>

                </td>
            case 'Mobile No.':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    <div className='flex items-center'>
                        <span className='!mr-3 w-[109px]'>
                            {tData?.display_number || tData?.mobile || '-'}
                        </span>
                        <button
                            className='!border hover:!border-primary-blue-dark !p-1.5 !rounded  hover:bg-primary-blue-dark/10 call-svg'
                            onClick={() => handleClickToCall(tData)}
                        >
                            <PhoneIcon className='fill-black-t hover:!fill-primary-blue-dark' />
                        </button>
                    </div>
                </td>
            case 'Email':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    {tData?.email || '-'}
                </td>
            case 'Created Date':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    {(tData?.created_on || tData?.created_date) ? createDatewithTime(tData?.created_on || tData?.created_date) : '-'}
                </td>
            case 'Created date':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    {(tData?.created_on || tData?.created_date) ? createDatewithTime(tData?.created_on || tData?.created_date) : '-'}
                </td>
            case 'Status':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'> <StatusDropdown
                    value={getSelectedValue(tData.status, statuses)}
                    options={statuses}
                    id={tData.id}
                    reloadData={() => { }}
                    leadName={tData.name}
                    intentOptions={intentStatuses}
                    data={tData}
                    onSuccess={onSuccess}
                    handleOpenVerifyPage={handleOpenVerifyPage}
                />
                </td>
            case 'Intent':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    <DynamicIntent
                        selectedIntent={tData.intent}
                        intentOptions={intentStatuses}
                        reloadData={onSuccess}
                        id={tData.id}
                        data={tData}
                        status={getSelectedValue(tData.status, statuses)}
                        statusName={tData.status_name}
                        rowLength={tData.length}
                        tableName='AllLead'
                    />
                </td>
            case 'Source':
                return (
                    <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                        <SourceLabel
                            allSources={sourceStatuses}
                            sources={tData.source}
                        />
                    </td>)

            case 'City':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.city ? tData.city : '-'}</td>

            case 'Assigned User':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    {tData.assigned_to?.length > 20 ?
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="button-tooltip" className='leadmanager-tooltip'>
                                    <div className='info-msg'>
                                        {tData.assigned_to || '-'}
                                    </div>
                                </Tooltip>
                            }
                        >

                            <span className='three-dots'>{tData.assigned_to || '-'}</span>
                        </OverlayTrigger>
                        :
                        <span className=''>{tData.assigned_to || '-'}</span>
                    }

                </td>

            case 'Marketing Agency':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'> -</td>
            // return agencyList.length > 0 && <td className='text-capitalize'>
            //     {(tData.landing_page_agency_name || tData.fb_agency_name) ? <OverlayTrigger
            //         placement="bottom"
            //         overlay={
            //             <Tooltip id="button-tooltip" >
            //                 <div className='info-msg'>
            //                     {/* <h4>Total Leads</h4>*/}
            //                     <p className=''>{tData.landing_page_agency_name || tData.fb_agency_name || '-'}</p>
            //                 </div>
            //             </Tooltip >
            //         }
            //     >
            //         <span className='three-dots cursor-pointer'>{tData.landing_page_agency_name || tData.fb_agency_name || '-'}</span>
            //     </OverlayTrigger > : '-'}
            // </td>

            case 'Date':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.Date ? createDatewithTime(new Date(tData.date)) : '-'}</td>

            case 'Purpose':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.purpose || '-'}</td>

            case 'Preferred Location':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    {tData.preferred_location ? <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="button-tooltip" >
                                <div className='info-msg'>
                                    {/* <h4>Total Leads</h4>*/}
                                    <p className='text-capitalize'>{tData.preferred_location}</p>
                                </div>
                            </Tooltip >
                        }
                    >
                        <span className='three-dots cursor-pointer'>{tData.preferred_location}</span>
                    </OverlayTrigger > : '-'}
                </td>
            case 'Budget':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    {`${tData.minimum_budget ? `₹ ${MAP_CURRENCY_NUMBER_TO_TEXT[tData.minimum_budget]} ${tData.maximum_budget ? `- ${MAP_CURRENCY_NUMBER_TO_TEXT[tData.maximum_budget]}` : ''}` : '-'}`}

                    {/* {`${tData.minimum_budget ? `₹ ${MAP_CURRENCY_NUMBER_TO_TEXT[tData.minimum_budget]}` : ''} - ${tData.maximum_budget ? `₹ ${MAP_CURRENCY_NUMBER_TO_TEXT[tData.maximum_budget]}` : ''}`} */}
                </td>
            case 'Property Type':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.property_category || '-'}</td>
            case 'Configuration Type':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>

                    {tData.configuration ? <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="button-tooltip" >
                                <div className='info-msg'>
                                    {/* <h4>Total Leads</h4>*/}
                                    <p className=''>{tData.configuration}</p>
                                </div>
                            </Tooltip >
                        }
                    >
                        <span className='three-dots cursor-pointer'>{tData.configuration}</span>
                    </OverlayTrigger > : '-'}
                </td>
            case 'Size':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    {tData.carpet_area ? <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="button-tooltip" >
                                <div className='info-msg'>
                                    {/* <h4>Total Leads</h4>*/}
                                    <p className=''>{`${tData.carpet_area}  ${tData.carpet_area_unit}`}</p>
                                </div>
                            </Tooltip >
                        }
                    >
                        <span className='three-dots cursor-pointer'>{tData.carpet_area ? `${tData.carpet_area}  ${tData.carpet_area_unit}` : '-'}</span>
                    </OverlayTrigger > : '-'}
                </td>
            case 'Requirement Type':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.property_type || '-'}</td>
            case 'Funding Source':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.funding_source || '-'}</td>
            case 'Employment Type':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.employement_type || '-'}</td>
            case 'Facing':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.facing || '-'}</td>
            case 'Profession':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.profession || '-'}</td>
            case 'Address':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.address || '-'}</td>
            case 'Preferred Unit Configuration':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.preferred_unit_configuration || '-'}</td>

            case 'Designation':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.designation || '-'}</td>
            case 'Purpose':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.purpose || '-'}</td>
            case 'Possession':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.possession || '-'}</td>
            case 'Minimum Budget':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.minimum_budget ? `₹ ${toINRFormat(tData.minimum_budget)}` : '-'}</td>
            case 'Maximum Budget':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.maximum_budget ? `₹  ${toINRFormat(tData.maximum_budget)}` : '-'}</td>
            case 'Customer Address':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    {tData.current_residence ? <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="button-tooltip" >
                                <div className='info-msg'>
                                    {/* <h4>Total Leads</h4>*/}
                                    <p className='text-capitalize'>{tData.current_residence || '-'}</p>
                                </div>
                            </Tooltip >
                        }
                    >
                        <span className='three-dots cursor-pointer'>{tData.current_residence || '-'}</span>
                    </OverlayTrigger> : '-'}

                </td>
            case 'Assigned Date':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{createDatewithTime(tData.system_modified_time) || '-'}</td>
            case 'Possession':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.possession || '-'}</td>
            case 'Alt. No.':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    <a href={`tel:${tData.alternate_mobile}`} style={{ color: '#171725' }}>{tData.alternate_mobile || '-'}</a>
                </td>
            case 'Alt. Mobile':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    <a href={`tel:${tData.alternate_mobile}`} style={{ color: '#171725' }}>{tData.alternate_mobile || '-'}</a>
                </td>
            case 'Referred By':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.referred_by || '-'}</td>
            case 'Referred By Number':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.referred_by_number || '-'}</td>
            case 'Referred By Society':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.referred_by_society || '-'}</td>
            case 'Referred By Flat No':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.referred_by_flat_no || '-'}</td>
            case 'Customer ID':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>#{tData.customer_id || '-'}</td>
            case 'App User':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.is_app_customer ? 'Yes' : 'No'}</td>
            case 'Primary Project':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    {
                        tData.project_name?.length > 30 ? <TooltipText>
                            <p className='m-0 w-[150px] truncate'>{tData.project_name}</p>
                        </TooltipText> :
                            tData.project_name || '-'
                    }
                </td>
            case 'Primary Source':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.source || '-'}</td>
            case 'Industry':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col' >{tData.industry || '-'}</td>
            case 'View Lead Share':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    {
                        tData.shared_with_count > 0 ?
                            <div className='view-notes'>
                                <button className="btn btn-secondary dropdown-toggle" type="button" id=""
                                // onClick={() => {
                                //     setSelectedItem(tData)
                                //     setShowShareLeadAccessModal(true)
                                // }}
                                >
                                    View
                                </button>
                            </div>
                            : '-'
                    }
                </td>
            case 'Marked By':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    {tData?.status_changed_by_name || '-'}
                </td>
            case 'Marked Date':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    {
                        tData?.status_changed_on ? createDatewithTime(new Date(tData?.status_changed_on)) : '-'
                    }
                </td>
            case 'Lead Access Validtiy':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData.validity ? `${tData?.validity} ${tData.validity_unit}` : '-'}</td>
            case 'Access':
                return <td class="!px-3 !whitespace-nowrap !py-2 capitalize" scope='col' >{tData?.access ? tData?.access === 'edit' ? 'Edit/View' : 'View' : '-'}</td>
            case 'Last Note':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    {tData?.last_note ? <TooltipText title={tData?.last_note}>
                        <div className='w-[150px] truncate'>{tData?.last_note}</div>
                    </TooltipText> : '-'}
                </td>
            case 'Note':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    {tData?.last_note ? <TooltipText title={tData?.last_note}>
                        <div className='w-[150px] truncate'>{tData?.last_note}</div>
                    </TooltipText> : '-'}
                </td>

            case 'Shared With User':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    {tData?.shared_with_names ? <SharedWithUserName shareWithNameArr={tData.shared_with_names} /> : '-'}
                </td>
            case 'Reasons':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData?.followup_info || '-'}</td>
            case 'SV Duration':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    <button onClick={() => {
                        // setSelectedLead(tData);
                        // setSVCompletedModal(true);
                    }}>
                        {tData?.sv_duration ? `${tData.sv_duration} ${tData.sv_duration_unit}` : '-'}
                    </button>
                </td>
            case 'Type of Site Visit':
                return <td class="!px-3 !whitespace-nowrap !py-2 capitalize" scope='col' >
                    <button onClick={() => {
                        // setSelectedLead(tData);
                        // setSiteVisitModal(true);
                    }}>
                        {tData?.sv_type ? SVTYPES[tData?.sv_type] : 'Onsite'}
                    </button>
                </td>
            case 'Projects':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    <div className='d-flex flex-row flex-nowrap align-itmes-center project-td'>
                        <Projects
                            projectsArr={tData?.project_names?.filter((project) => project !== null)}
                        />
                    </div>
                </td>
            case 'Project':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    <div className='d-flex flex-row flex-nowrap align-itmes-center project-td'>
                        {/* <Projects
                                projectsArr={tData?.project_names?.filter((project) => project !== null)}
                            /> */}
                        {
                            tData.project?.length > 30 ? <TooltipText title={tData.project}>
                                <p className='m-0 w-[150px] truncate'>{tData.project}</p>
                            </TooltipText> :
                                tData.project || '-'
                        }
                    </div>
                </td>
            case 'Re-Engaged Sources':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    {(tData?.is_re_engaged || tData?.re_engaged_sources?.length) ? <ReengagedSource uuid={tData.id} sources={tData?.re_engaged_sources || []} /> : '-'}
                </td>
            case 'RM':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData?.rm_name || '-'}</td>
            case 'CP Firm':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData?.broker_company_name || '-'}</td>
            case 'CP User':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData?.broker_user_name || '-'}</td>
            case 'Last Activity Date':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{
                    tData?.last_activity_date ? createDatewithTime(new Date(tData?.last_activity_date)) : '-'
                }</td>
            case 'Tag Expiry':
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0); // Set current time to midnight

                const expiryDate = new Date(tData?.tag_expiry);
                expiryDate.setHours(0, 0, 0, 0); // Set expiry time to midnight

                // Older moment method 
                // return <td>{moment(tData?.tag_expiry).diff(moment(), 'days') > 0 ? moment(tData?.tag_expiry).diff(moment(), 'days') + ' days' : tData?.last_tag_cp_user ? 'Expired' : '-'}</td>
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>
                    {moment(tData?.tag_expiry).diff(moment(), 'days') > 0 ?
                        Math.floor(
                            (expiryDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24),) + ' days' :
                        tData?.last_tag_cp_user ? 'Expired' : '-'}
                </td>
            case 'Last Activity':
                return <td class="!px-3 !whitespace-nowrap !py-2 capitalize" scope='col' >
                    {
                        tData?.last_activity_type ? `${tData?.last_activity_type} ${tData?.last_activity_value}` : '-'
                    }
                </td>
            case 'Last Event':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData?.last_activity_value || '-'}</td>
            case 'Presales':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData?.presales_manager || '-'}</td>
            case 'Sourcing':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData?.sourcing_manager || '-'}</td>
            case 'Closing':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{tData?.closing_manager || '-'}</td>
            case 'Followup Date':
                return <td class={` ${(moment(tData.followup_date) < moment()) ? "text-red" : ''} !px-3 !whitespace-nowrap !py-2`} scope='col'>{
                    tData?.followup_date ? moment(tData?.followup_date).add('5:30', 'hours').format('hh:mm a, Do MMM YYYY') : '-'
                }</td>
            case 'Booking Date':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{
                    tData?.booking_date ? createDatewithTime(new Date(tData?.booking_date)) : '-'
                }</td>
            case 'Token Date':
                return <td class="!px-3 !whitespace-nowrap !py-2" scope='col'>{
                    tData?.eoi_date ? createDatewithTime(new Date(tData?.eoi_date)) : '-'
                }</td>
            case 'Site Visit Date':
                return <td>
                    <a bsstyle="default" className={`${(moment(tData.sv_schedule_date) < moment() && !tData.completed_time) ? "red-color" : "text-black "}`}
                        onClick={() => {
                            // selectCurrentLead(tData)
                            // const statusVal = getSelectedValue(tData.status, statuses)
                            // if (statusVal.label === 'SV Completed') {
                            //   showSvCompleteModal(true)
                            // } else {
                            //   showSiteVisitModal(true)
                            // }
                        }}>
                        {tData.completed_time ? createDatewithTime(new Date(tData.completed_time)) : createDatewithTime(new Date(tData.sv_schedule_date))}
                    </a>
                    {/* <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="button-tooltip" className='' >
                            <div className='info-msg'>
                              <p className='d-flex fz18'>Edit Date</p>
                            </div>
                          </Tooltip>
                        }
                      >
                        <a bsstyle="default" className={`${(moment(data.sv_schedule_date) < moment() && !data.completed_time) ? "red-color" : "text-black "} cursor-pointer`}
                          onClick={() => {
                            selectCurrentLead(data)
                            const statusVal = getSelectedValue(data.status, statuses)
                            if (statusVal.label === 'SV Completed') {
                              showSvCompleteModal(true)
                            } else {
                              showSiteVisitModal(true)
                            }
                          }}>
                          {data.completed_time ? createDatewithTime(new Date(data.completed_time)) : createDatewithTime(new Date(data.sv_schedule_date))}
                        </a>
                      </OverlayTrigger> */}
                </td>
            case 'Approval Status':
                return <td class="!px-3 !whitespace-nowrap !py-2"> <p className={`capitalize m-0 !py-1.5 rounded-lg text-center ${tData.approval_status && getStatusClasses(tData.approval_status)}`}>
                    {tData.approval_status || '-'}
                </p></td>

            case 'Token Details':
                return <td>
                    {(!dataLoading || selectedItem?.eoi_uuid !== tData?.eoi_uuid) && <button
                        className={`border ${tData.approval_status === 'pending' ? 'border-primary text-primary' : ''} py-1 px-3 rounded-lg`}
                        onClick={() => {
                            setLeadStatus('eoi');
                            setSelectedItem(tData)
                            setBookingStatus(tData.approval_status);
                            setDataLoading(true)
                            getEOILeadDetails(tData.eoi_uuid)
                        }}>
                        {(allowedPermissions.includes('verify_bookings_eoi') ? (tData.approval_status === 'pending' ? `Verify Token ` : `View Token `) : `View Token`)}
                    </button>}
                    {
                        dataLoading && selectedItem?.eoi_uuid === tData?.eoi_uuid &&
                        <button
                            className={`border ${tData.approval_status === 'pending' ? 'border-primary text-primary' : ''} py-1 px-3 rounded-lg w-[100px] flex items-center justify-center`}
                        >
                            <Oval
                                height={22}
                                width={22}
                                color="#0062FF"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#0062FF"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                        </button>
                    }
                </td>
            case 'Booking Details':
                return <td>
                    {(!dataLoading || selectedItem?.booking_uuid !== tData?.booking_uuid) && <button
                        className={`border ${tData.approval_status === 'pending' ? 'border-primary text-primary' : ''} py-1 px-3 rounded-lg`}
                        onClick={() => {
                            setLeadStatus('booking')
                            setSelectedItem(tData)
                            setBookingStatus(tData.approval_status);
                            setDataLoading(true);
                            getBookingDetails(tData.booking_uuid)

                        }}>
                        {(allowedPermissions.includes('verify_bookings_eoi') ? (tData.approval_status === 'pending' ? 'Verify Booking' : 'View Booking') : 'View Booking')}
                    </button>}
                    {
                        dataLoading && selectedItem?.booking_uuid === tData?.booking_uuid &&
                        <button
                            className={`border ${tData.approval_status === 'pending' ? 'border-primary text-primary' : ''} py-1 px-3 rounded-lg w-[130px] flex items-center justify-center`}
                        >
                            <Oval
                                height={22}
                                width={22}
                                color="#0062FF"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#0062FF"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                            />
                        </button>
                    }
                </td>
            case 'Re-engage source':
                return <td>
                    {tData?.last_re_engaged_source || '-'}
                </td>
            case 'Re-engage Date':
                return <td>
                    {tData?.last_engagement_date ? createDatewithTime(tData?.last_engagement_date) : '-'}
                </td>
        }

    }
    return (
        <>{rendering()}</>
    )
}

export default RenderDashboardTableData