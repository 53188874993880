import React, { useEffect, useState, useRef } from 'react';
import { Image } from 'react-bootstrap';
import './CustomTable.css';
import { createDatewithTime } from '../../helpers/date';
import { getCompanyAgency, getMasterEnumsCall, getTimelineCall, knowlarityClickToCall, knowlarityClickToCallAdvanced, updateleadByKeyValue } from '../../services/private/company.service';
import { toast } from 'react-toastify';
import dotMenuIcon from '../../assets/icons/dotmenu.svg';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import NoResultFound from '../NotFound/NoResultFound';
import ConfirmDeletion from '../Modals/ConfirmDeletion';
import { getSelectedValue } from '../../utils/selectedOptionUtils';
import LeadName from './TableColumns/LeadName';
import Projects from './TableColumns/Projects';
import SourceLabel from './TableColumns/SourceLabel';
import StatusDropDown from './TableColumns/StatusDropDown';
import DynamicIntent from './TableColumns/DynamicIntent';
import AssignedTo from './TableColumns/AssignedTo';
import checkAssignedTo from '../../utils/checkAssignedTo';
import MobileNo from './TableColumns/MobileNo';
import BookingsDoneModal from '../Modals/BookingsDoneModal';
import SortIconDisplay from './sortIconDisplay';
import UpdateFollowupModal from '../Modals/FollowupModal/UpdateFollowupModal';
import { useMemo } from 'react';
import { useCallback } from 'react';
import NotesDropDown from './TableColumns/NotesDropDown';
import RecentActivities from '../../modules/Lead Details/components/Recent Activities/RecentActivities';
import { Oval } from 'react-loader-spinner';
import MiniTimline from '../Modals/MiniTimline';
import RenderTableData from './RenderTableData';
import CustomColumnModal from '../Modals/CustomColumnModal';
import { getBookingDoneDetails, getEOIDetails, updateBookingApprovalStatus, updateEOIApprovalStatus } from '../../services/private/inventory.service';
import DetailsModal from '../../modules/Inventory/Modal/details';
import ShareLeadAccessModal from '../Modals/ShareLeadAccessModal';
import { checkIsSomeItemAvailableInList, mergeUniqueByKey } from '../../helpers/helpers';
import SharedWithUserName from './TableColumns/SharedWithUserName';
import AdvanceCallModal from '../Modals/AdvanceCallModal';
import EoiDoneModal from '../Modals/EoiDoneModal';


const getStatusClasses = (status) => {
    switch (status) {
        case 'pending':
            return 'text-yellow-dark bg-yellow-100'
        case 'rejected':
            return 'text-red bg-red-light'
        case 'approved':
            return 'text-green bg-green100'
    }
}

export default function BookingsTable({
    leadData,
    filters,
    startIndex,
    showCheck,
    updateData,
    selectedTableValues,
    setSelectedTableValues,
    setSelectedTableData,
    selectedTableData,
    handleUserDeletion,
    handleUserReassignLead,
    columns,
    col,
    count,
    searchText,
    sort,
    reAssignRole,
    intentStatuses,
    statuses,
    sourceStatuses,
    setLeadName,
    selectedLeadName,
    agencyList,
    showCreateLeadModal,
    bookingLeadsColumn,
    saveCurrentUserColumnPrefrence,
    handleChangeSelection,
    updateColumnList,
    dashboardColumns,
    allowedPermissions,
    isRolesModule,
    userProfile,
    callerList
}) {
    const [deadUuid, setDeadUuid] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedSingleUserIdFromTable, setSelectedSingleUserIdFromTable] = useState();
    const [showBookingModal, setShowBookingModal] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [showFollowupModal, setShowFollowupModal] = useState(false);
    const [timeline, setTimeline] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState('');
    const [showTimeline, setShowTimeline] = useState(false);
    const [timelineLeadName, setTimelineLeadName] = useState('');
    const [showCustomColumn, setShowCustomColumn] = useState(false);
    const [columnsList, setColumnsList] = useState(bookingLeadsColumn);
    const [tableColumn, setTableColumn] = useState([]);
    const [isCustomColumnAvailable, setIsCustomColumnAvailable] = useState(false);
    const [reAssignAvailable, setReAssignAvailable] = useState(false);
    const [deleteAvailable, setDeleteAvailable] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState({});
    const [bookingStatus, setBookingStatus] = useState('pending');
    const [bookingUUId, setBookingUUID] = useState('');
    const [leadStatus, setLeadStatus] = useState('');
    const [showShareLeadAccessModal, setShowShareLeadAccessModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [showAdvanceCallmodal, setShowAdvanceCallModal] = useState(false);
    const [showEoiDoneModal, setShowEoiDoneModal] = useState(false);

    const ROLE_NAME = localStorage.getItem('role');
    const tableRef = useRef();

    useEffect(() => {
        if (!isRolesModule) {
            if (ROLE_NAME === 'ADMIN') {
                setIsCustomColumnAvailable(true);
                setReAssignAvailable(true);
                setDeleteAvailable(true);
            }
            if (reAssignRole?.includes('re_assign_leads')) {
                setReAssignAvailable(true);
            }
        } else {
            if (allowedPermissions.includes('customise_column_self')) {
                setIsCustomColumnAvailable(true);
            }
            if (allowedPermissions.includes('re_assign_leads_single')) {
                setReAssignAvailable(true);
            }
            if (allowedPermissions.includes('delete_leads')) {
                setDeleteAvailable(true);
            }
        }
    }, [isRolesModule]);

    const handelChange = (e, data) => {
        if (e.target.checked) {
            setLeadName(data.name);
            setSelectedTableValues([...selectedTableValues, data.id]);
            setSelectedTableData([...selectedTableData, data]);
        } else {
            const filteredValues = selectedTableValues.filter((value) => value != data.id);
            const filteredData = selectedTableData.filter((value) => value.id != data.id);
            setSelectedTableValues(filteredValues);
            setSelectedTableData(filteredData);
            setLeadName('');
        }
    }

    const getBookingDetails = async (uuid) => {
        setBookingUUID(uuid)
        const res = await getBookingDoneDetails(uuid);
        if (res.data.status === 200) {
            if (res.data.data?.customer?.uuid) {
                setSelectedBooking(res.data.data);
                setShowDetailsModal(true);
            }
        }
    }

    const getEOILeadDetails = async (uuid) => {
        setBookingUUID(uuid)
        const res = await getEOIDetails(uuid);
        if (res.data.status === 200) {
            if (res.data.data?.customer?.uuid) {
                setSelectedBooking(res.data.data);
                setShowDetailsModal(true);
            }
        }
    }

    useEffect(() => {
        if (!selectedLeadName && selectedTableValues?.length === 1) {
            const leadVal = leadData.find((lead) => lead.id === selectedTableValues[0]);
            setLeadName(leadVal.name)
        }
    }, [selectedLeadName, selectedTableValues])

    useEffect(() => {
        if (selectedTableValues?.length >= leadData?.length) {
            const allSelected = leadData.every(lead => selectedTableValues.includes(lead.id))
            setSelectAll(allSelected)
        }
    }, [selectedTableValues, selectedTableData, leadData]);

    const onHandleSelectAllLeads = () => {
        if (!selectAll) {
            const selectedVal = leadData.map((lead) => lead.id)
            // setSelectedTableValues([...selectedTableValues, ...selectedVal]);
            // setSelectedTableData([...selectedTableData, ...leadData]);
            setSelectedTableValues([...new Set([...selectedTableValues, ...selectedVal])]);
            const mergedLeads = mergeUniqueByKey(selectedTableData, leadData, 'id')
            setSelectedTableData([...mergedLeads]);
        } else {
            if (selectedTableValues?.length > leadData?.length) {
                const leadIds = leadData.map(lead => lead.id);
                const filtered = selectedTableValues.filter(item => !leadIds.includes(item))
                const filteredData = selectedTableData.filter(item => !leadIds.includes(item.id))
                setSelectedTableValues(filtered)
                setSelectedTableData(filteredData)
            } else {

                setSelectedTableValues([])
                setSelectedTableData([]);
            }
        }
        setSelectAll(!selectAll)
    }

    const handleRowPosition = (e, index) => {
        localStorage.setItem('rowIndex', index);
    }

    useEffect(() => {
        tableRef.current.scrollTop = parseInt(localStorage.getItem('rowIndex')) * 50;
    }, [leadData])

    useEffect(() => {
        const sortedList = bookingLeadsColumn.sort((a, b) => a.placement - b.placement)
        setTableColumn(sortedList);
    }, [bookingLeadsColumn]);

    useEffect(() => {
        const handleCopy = (e) => {
            // Optionally prevent default copy behavior
            if (userProfile?.company_id !== 29) {
                e.preventDefault();
            }
        };
        const handleContextMenu = (e) => {
            e.preventDefault();
        };

        document.addEventListener('copy', handleCopy);
        // document.addEventListener('contextmenu', handleContextMenu);
        return () => {
            document.removeEventListener('copy', handleCopy);
            // document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    const isEOIDone = useCallback((status) => {
        const statusVal = getSelectedValue(status, statuses)
        return (statusVal?.label === 'EOI Completed' || statusVal?.label === 'Token Done')
    }, [statuses])

    const handleLeadTimeline = useCallback((data, index) => {
        setSelectedIndex(index)
        if (data.id) {
            setTimelineLeadName(data.name)
            getTimelineCall(data.id).then((res) => {
                if (res.data.status === 200) {
                    setTimeline(res.data.data)
                    setShowTimeline(true)
                }
            }).catch((err) => {
                console.log({ err })
            })
        }
    }, []);

    const closeTimelineDropdown = useCallback(() => {
        setSelectedIndex('');
        setTimeline([]);
        setShowTimeline(false);
    }, []);

    const selectSingleUserId = useCallback((data) => {
        setSelectedSingleUserIdFromTable(data);
    }, []);

    const setFollowupModal = useCallback((val) => {
        setShowFollowupModal(val);
    }, []);

    const closeCustomColumnModal = useCallback(() => {
        setShowCustomColumn(false)
    }, []);

    const handleChangeBookingStatus = async (status) => {
        const res = await updateBookingApprovalStatus(bookingUUId, { status });
        if (res.data.status === 200) {
            console.log(res.data.data);
            toast.success(res.data.message);
            updateData();
            setShowDetailsModal(false)
        }
    }

    const handleChangeEOIStatus = async (status) => {
        const res = await updateEOIApprovalStatus(bookingUUId, { status });
        if (res.data.status === 200) {
            console.log(res.data.data);
            toast.success(res.data.message);
            updateData();
            setShowDetailsModal(false)
        }
    }

    const handleClickToCall = async (lead) => {
        const res = await knowlarityClickToCall(lead.id, {});
        if (res.data.status === 200) {
            toast.success(res.data.message)
        }
    }

    const handleAdvanceClickToCall = async (useAlternate, callerId) => {
        const payload = {
            use_alternate: useAlternate,
            caller_id: callerId
        }

        const res = await knowlarityClickToCall(selectedItem.id, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message)
            setSelectedItem({})
            setShowAdvanceCallModal(false)
        }
    }

    return (
        <>
            {showDeleteModal && selectedSingleUserIdFromTable.id && <ConfirmDeletion
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                title={`Delete ${selectedSingleUserIdFromTable.name}?`}
                deleteHandler={() => handleUserDeletion(selectedSingleUserIdFromTable.id)}
            />}

            {showBookingModal && selectedSingleUserIdFromTable.id && <BookingsDoneModal
                setShow={setShowBookingModal}
                show={showBookingModal}
                leadId={selectedSingleUserIdFromTable.id}
                onSuccess={updateData}
                isEdit={showBookingModal}
                bookingsData={selectedSingleUserIdFromTable}
            />}

            {showEoiDoneModal && selectedSingleUserIdFromTable.id && <EoiDoneModal
                show={showEoiDoneModal}
                setShow={setShowEoiDoneModal}
                leadId={selectedSingleUserIdFromTable.id}
                onSuccess={updateData}
                intentOptions={intentStatuses}
                selectedIntent={selectedSingleUserIdFromTable.intent}
                EOIData={selectedSingleUserIdFromTable}
                isEdit={true}
            />}
            {showFollowupModal && selectedSingleUserIdFromTable.id && <UpdateFollowupModal
                setShow={setShowFollowupModal}
                show={showFollowupModal}
                leadId={selectedSingleUserIdFromTable.id}
                isEdit={showFollowupModal}
                onSuccess={updateData}
                intentOptions={intentStatuses}
                selectedIntent={selectedSingleUserIdFromTable.intent}
            />}
            <div className="all-leads !pt-1 md:!pl-5 !pl-4">
                <div className={`lead-table h-full  ${userProfile?.company_id !== 29 ? 'no-select' : ''} ${leadData?.length === 0 ? 'overflow-hidden shownofound' : ''}`} ref={tableRef}>

                    <table className="table booking-table">
                        <thead>
                            <tr>
                                <th
                                    className='cursor-pointer'
                                >
                                    {showCheck ?
                                        <>
                                            <input
                                                type="checkbox"
                                                onChange={onHandleSelectAllLeads}
                                                checked={selectAll}
                                                id='selectAll_leads'
                                                title='Select all'
                                                className='resasign-input'
                                            />

                                        </>
                                        :
                                        'S.No.'
                                    }
                                </th>

                                {
                                    tableColumn?.map((item, index) => {
                                        if (item.label === 'Marketing Agency' && agencyList?.length === 0) {
                                            return null
                                        }

                                        if (item.label === 'View Lead Share' && !userProfile?.allowed_modules?.includes('lead_sharing')) {
                                            return null
                                        }
                                        return (
                                            <th key={`${item.db_value}_${index}`} className={item.label === 'Name' ? 'sticky-th' : ''}>
                                                <span className='flex items-center gap-1'>
                                                    {item.label}
                                                    <SortIconDisplay sortCol={col} sort={sort} colName={item.label} />
                                                </span>
                                            </th>
                                        )
                                    })
                                }
                                {isCustomColumnAvailable ? <th>
                                    <OverlayTrigger
                                        placement="left"
                                        overlay={
                                            <Tooltip id="button-tooltip" className='leadmanager-tooltip'>
                                                <div className='info-msg'>
                                                    Customise Columns
                                                </div>
                                            </Tooltip>
                                        }
                                    >

                                        <button className='cust-col-btn' onClick={() => setShowCustomColumn(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <line x1="5.81445" y1="10.1094" x2="14.4082" y2="10.1094" stroke="#44444F" strokeWidth="1.71875" strokeLinecap="round" />
                                                <line x1="10.1113" y1="5.8125" x2="10.1113" y2="14.4062" stroke="#44444F" strokeWidth="1.71875" strokeLinecap="round" />
                                            </svg>

                                        </button>
                                    </OverlayTrigger>
                                </th> : <th></th>}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                leadData && leadData?.length > 0 &&
                                leadData.map((data, index) => (
                                    <tr
                                        key={data.id + data.intent + data.status}
                                        id={`leads_table_${index}`}
                                        onClick={(e) => handleRowPosition(e, index)}
                                    >
                                        <td>
                                            {showCheck ?
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => handelChange(e, data)}
                                                    checked={selectedTableValues.includes(data.id)}
                                                /> :
                                                index + startIndex
                                            }
                                        </td>
                                        {
                                            tableColumn?.map((item, index) => {
                                                return (
                                                    <RenderTableData
                                                        item={item}
                                                        data={data}
                                                        updateData={updateData}
                                                        intentStatuses={intentStatuses}
                                                        statuses={statuses}
                                                        leadData={leadData}
                                                        agencyList={agencyList}
                                                        sourceStatuses={sourceStatuses}
                                                        selectSingleUserId={selectSingleUserId}
                                                        setFollowupModal={setFollowupModal}
                                                        tab={'BOOKINGS'}
                                                        getBookingDetails={getBookingDetails}
                                                        userProfile={userProfile}
                                                        key={`${item.db_value}_${index}`}
                                                    />
                                                )
                                            })
                                        }

                                        <td className="dropdown view-more">
                                            {(isEOIDone(data.status) || reAssignAvailable || deleteAvailable) &&
                                                < >
                                                    <button
                                                        className={`bg-tr border-0 ${(userProfile?.allowed_modules?.includes('lead_sharing') && data?.access === 'view') ? 'cursor-not-allowed' : ''} `}
                                                        type="button"
                                                        id="dropdownMenuButton"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                        disabled={(userProfile?.allowed_modules?.includes('lead_sharing') && data?.access === 'view')}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#B5B5BE"></path></svg>
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                        {isEOIDone(data.status) && <a className="dropdown-item" onClick={() => {
                                                            setShowFollowupModal(true)
                                                            setSelectedSingleUserIdFromTable(data)
                                                        }
                                                        }>
                                                            Edit Followup
                                                        </a>}
                                                        {isEOIDone(data.status) && <a className="dropdown-item" onClick={() => {
                                                            setShowEoiDoneModal(true)
                                                            setSelectedSingleUserIdFromTable(data)
                                                        }
                                                        }>
                                                            Edit {userProfile.company_id === 581 ? 'Token' : 'EOI'}
                                                        </a>}
                                                        {!isEOIDone(data.status) && <a className="dropdown-item" onClick={() => {
                                                            setShowBookingModal(true)
                                                            setSelectedSingleUserIdFromTable(data)
                                                        }
                                                        }>
                                                            Edit Booking
                                                        </a>}

                                                        {reAssignAvailable &&
                                                            <a className="dropdown-item"
                                                                onClick={() => {
                                                                    setLeadName(data.name);
                                                                    handleUserReassignLead(data.id)
                                                                }}
                                                            >
                                                                Re-assign Lead
                                                            </a>}

                                                        <a className="dropdown-item" onClick={() => {
                                                            setTimeline([]);
                                                            handleLeadTimeline(data, index);
                                                        }}>
                                                            View Recent Activity
                                                        </a>
                                                        {userProfile?.allowed_modules?.includes('lead_sharing') &&
                                                            checkIsSomeItemAvailableInList(allowedPermissions, ['can_share_leads']) &&
                                                            <a className="dropdown-item" onClick={() => {
                                                                setSelectedItem(data)
                                                                setShowShareLeadAccessModal(true)
                                                            }}>

                                                                Share Lead Access
                                                            </a>}
                                                        {callerList.length > 0 &&
                                                            (ROLE_NAME === 'Super Admin' ||
                                                                (userProfile?.allowed_modules?.includes('ivr_call') &&
                                                                    allowedPermissions.includes('use_ivr_outbound_call'))) && <a
                                                                        className="dropdown-item" onClick={() => handleClickToCall(data)}
                                                                    >
                                                                Quick Call
                                                            </a>}
                                                        {callerList.length > 0 &&
                                                            (ROLE_NAME === 'Super Admin' ||
                                                                (userProfile?.allowed_modules?.includes('ivr_call') &&
                                                                    allowedPermissions.includes('use_ivr_outbound_call'))) && <a
                                                                        className="dropdown-item" onClick={() => {
                                                                            setSelectedItem(data);
                                                                            setShowAdvanceCallModal(true);
                                                                        }}>
                                                                Advanced Call
                                                            </a>}
                                                        {deleteAvailable &&
                                                            <a className="dropdown-item del-menu-itme" onClick={() => {
                                                                setSelectedSingleUserIdFromTable(data)
                                                                setShowDeleteModal(true)
                                                            }}>Delete Lead
                                                            </a>
                                                        }

                                                    </div>
                                                </>}
                                            {/* {selectedIndex === index && <div className="dropdown view-more">
                                                <div className="dropdown-menu mini-timeline show">
                                                    {timeline?.length > 0 && <RecentActivities ActivityTimeline={timeline} leadName={data.name} closeTimelineDropdown={closeTimelineDropdown} />}
                                                    {timeline?.length === 0 && <div className='d-flex align-items-center justify-content-center' style={{ height: '100%' }}>
                                                        <Oval
                                                            height={30}
                                                            width={30}
                                                            color="#0062FF"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={true}
                                                            ariaLabel='oval-loading'
                                                            secondaryColor="#0062FF"
                                                            strokeWidth={2}
                                                            strokeWidthSecondary={2}
                                                        />
                                                    </div>}
                                                </div>
                                            </div>} */}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    {leadData?.length === 0 && <NoResultFound count={count} filters={filters} searchText={searchText} showCreateLeadModal={showCreateLeadModal} />}
                    {showTimeline && <MiniTimline setShow={setShowTimeline}
                        show={showTimeline} ActivityTimeline={timeline} leadName={timelineLeadName} closeTimelineDropdown={closeTimelineDropdown} />}

                    {showCustomColumn &&
                        <CustomColumnModal
                            subHeading={'Booking Leads'}
                            activeColumnTab='Booking'
                            show={showCustomColumn}
                            setShow={closeCustomColumnModal}
                            updateColumnList={updateColumnList}
                            dashboardColumns={dashboardColumns}
                            tab={'Booking'}
                            handleChangeSelection={handleChangeSelection}
                            saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
                        />
                    }
                    {showDetailsModal && <DetailsModal
                        show={showDetailsModal}
                        unit={{}}
                        selectedBooking={selectedBooking}
                        closeModal={() => setShowDetailsModal(false)}
                        modalOpenFrom={'table'}
                        bookingStatus={bookingStatus}
                        handleChangeApprovalStatus={leadStatus === 'booking' ? handleChangeBookingStatus : handleChangeEOIStatus
                        }
                    />}
                    {showShareLeadAccessModal &&
                        <ShareLeadAccessModal
                            show={showShareLeadAccessModal}
                            selectedItem={selectedItem}
                            closeModal={() => setShowShareLeadAccessModal(false)}
                            onSuccess={updateData}
                        />
                    }
                    {
                        showAdvanceCallmodal &&
                        <AdvanceCallModal
                            show={showAdvanceCallmodal}
                            closeModal={() => {
                                setSelectedItem({})
                                setShowAdvanceCallModal(false)
                            }}
                            handleSubmit={handleAdvanceClickToCall}
                            callerList={callerList}
                        />
                    }
                </div>
            </div>
        </>
    )
}