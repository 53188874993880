import React from 'react'
import SmallCenterModal from '../../../../../components/Modals/small-center-modal'
import { ReactComponent as WhatsAppIcon } from '../../../../../assets/icons/Whatsapp_Logo.svg';
import PreviewIcon from '../../../../../assets/icons/preview_card_bg.png';
import PreviewCard from '../Cards/PreviewCard'
import CenterModal from '../../../../../components/Modals/center-modal';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/close.svg';
import { ReactComponent as Vthreedots } from '../../../../../assets/icons/threedots.svg';
import { useHistory } from 'react-router-dom';

const styles = {
    backgroundImage: `url(${PreviewIcon})`,
    backgroundSize: 'cover',
    backgroundPositon: 'left center'
};

const TemplatePreviewModal = ({ show, closeModal, selectedItem }) => {

    const history = useHistory();

    return (
        <SmallCenterModal show={show} size='md-down' closeModal={closeModal} dialogClassName={'template-preview !rounded-xl'}>
            <div className='h-[600px]'>
                <div className='w-full h-100 !p-4 relative' style={styles}>
                    <div className='flex items-center justify-between font-semibold preview-linear-gradient !pt-4 !px-4 absolute top-0 w-100 left-0'>
                        <div className='flex items-center'>
                            <span className='!mr-2'><WhatsAppIcon /></span>
                            Template Preview
                        </div>
                        <div className='flex items-center'>
                            <div className='dropdown view-more !mr-2'>
                                <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <Vthreedots />
                                </button>
                                <div className="dropdown-menu dropdown-menu-right !p-2" aria-labelledby="dropdownMenuButton">
                                    <a className="dropdown-item text-capitalize" onClick={
                                        () => {
                                            history.push(`/settings/whatsapp/template/duplicate-template/${selectedItem.uuid}`)
                                        }
                                    }>Duplicate</a>
                                </div>
                            </div>
                            <button onClick={closeModal}>
                                <CloseIcon />
                            </button>
                        </div>
                    </div>
                    <div className='!mt-12'>
                        <PreviewCard
                            type={selectedItem?.type}
                            title={selectedItem?.template_name}
                            message={selectedItem?.body_text}
                            footer={selectedItem?.footer_text}
                            header={selectedItem?.header_text}
                            ctaList={selectedItem?.buttons}
                        />
                    </div>
                    {selectedItem.status !== 'REJECTED' && <div className='bg-white !py-3 !px-4 absolute bottom-0 w-100 left-0'>
                        <button
                            className='bg-primary text-white w-100 !py-2.5 text-xs font-semibold rounded-lg'
                            onClick={() => {
                                history.push(`/settings/whatsapp/campaign/create-campaign?template=${selectedItem.uuid}`)
                            }}
                        >
                            Use Template
                        </button>
                    </div>}
                </div>
            </div>
        </SmallCenterModal>
    )
}

export default TemplatePreviewModal