import React, { useEffect, useState } from 'react'
import RightModal from '../../../../components/Modals/RightModal';
import { ReactComponent as LeadIcon } from "../../../../assets/icons/LeadIcon.svg";
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { assignClosingAndTagging } from '../../services/dashboard.service';
import moment from 'moment';
import InputSelect from '../../../../components/InputGroup/InputSelect';
import { toast } from 'react-toastify';

const TaggingConflictModal = ({ show, closeModal, conflictData, opportunityData, onSuccess, allUsers, selectedClosing, selectedSV, setConflictData }) => {
    const [selectedItem, setSelectedItem] = useState({});
    const [closingManager, setSelectClosingManager] = useState(selectedClosing)
    const [remarks, setRemarks] = useState('');

    useEffect(() => {
        setSelectClosingManager(selectedClosing)
    }, [selectedClosing]);

    useEffect(() => {
        if (conflictData.length > 0) {
            setSelectedItem(conflictData?.find(i => i.is_tagged))
        }
    }, [conflictData]);

    console.log(selectedItem, 'selected item')
    const handleSubmit = async () => {
        const payload = {
            pre_sale_user_id: selectedItem?.pre_sale_user_id || null,
            closing_user_id: closingManager.value,
            broker_user_id: selectedItem?.broker_user_id || null,
            broker_company_id: selectedItem?.broker_company_id || null,
            remarks: remarks
        }
        try {
            const res = await assignClosingAndTagging(selectedSV.lead_uuid, payload)
            if (res.data.status === 200) {
                toast.success(res.data.message)
                onSuccess();
                closeModal();
            }
        } catch (err) {
            toast.error(err.response.data.message)
        }
    }
    return (
        <RightModal closeModal={closeModal} show={show} modalClass={'!max-w-[600px]'}>
            {/* Header Section */}
            <div className='d-flex justify-content-between !pt-5 !px-5 !mb-1'>
                <h1 className='mb-0 black inter text-2xl font-semibold text-black'>
                    Assign Tagging Issue
                </h1>
                <button className='' onClick={closeModal}><CloseIcon /></button>
            </div>

            <div className='!p-5 !h-5/6 overflow-y-auto'>
                {/* Lead Information */}
                <div className="flex justify-between items-start !p-2.5">
                    <div>
                        <p className="text-sm font-medium text-textblack !mb-1">{opportunityData?.customer_name}</p>
                        <p className="text-xs font-medium text-grey-500 !mb-0">{opportunityData?.customer_mobile}</p>
                    </div>
                    <div className="">
                        <p className="text-sm font-medium text-textblack !mb-1">{opportunityData?.project_name}</p>
                        <p className="text-xs font-medium text-grey-500 !mb-0">Project</p>
                    </div>
                    <div className="flex justify-end items-start">
                        <a
                            href={`/presale/opportunity-details/${opportunityData?.lead_uuid}?redirect=site-visit-tracke`}
                            className="text-sm font-medium !text-primary underline mr-2 flex items-center"
                            target='_blank'
                        >
                            View Lead
                            <LeadIcon className="w-3 h-3 !ml-1 !stroke-1.5" />
                        </a>
                    </div>
                </div>

                {/* Opportunity Section */}
                <div className="!mt-5">
                    <label className="text-sm text-[#647087] !mb-3">
                        Assign Opportunity tagging to
                        <sup>*</sup>
                    </label>
                    <div className='space-y-5'>
                        {conflictData.map((conflict, index) => (
                            <div
                                key={index}
                                className={`!border !rounded-lg !p-3 cursor-pointer 
                                ${(conflict.is_selected)
                                        ? "!border-primary !border-2 bg-blue-50"
                                        : "border-gray-300 bg-white"
                                    }`}
                                onClick={() => {
                                    const conf = conflictData?.map(i => ({ ...i, is_selected: false }))
                                    conf[index].is_selected = true
                                    setConflictData(conf);
                                    setSelectedItem(conflict)
                                }}
                            >
                                <div className="flex items-center border-b-2 !pb-3">
                                    <input
                                        type="radio"
                                        id={`tag-${index}`}
                                        name="tagging"
                                        className="w-4 h-4 mb-0 text-blue-500 border-gray-300 focus:ring-blue-500"
                                        checked={(conflict.is_selected)}
                                    />
                                    {conflict.pre_sale_user_name && <label
                                        htmlFor={`tag-${index}`}
                                        className="ml-3 mb-0 text-sm font-medium text-gray-900"
                                    >
                                        Presale({conflict.pre_sale_user_name})
                                    </label>}
                                    {conflict.broker_user_name && <label
                                        htmlFor={`tag-${index}`}
                                        className="ml-3 mb-0 text-sm font-medium text-gray-900"
                                    >
                                        CP({conflict.broker_user_name})
                                    </label>}
                                    {(!conflict.broker_user_name && !conflict.pre_sale_user_name) && <label
                                        htmlFor={`tag-${index}`}
                                        className="ml-3 mt-1 mb-0 text-sm font-medium text-gray-900"
                                    >
                                        Direct(Walk-in)
                                    </label>}
                                    {conflict.tag_expiry && (
                                        <span className="ml-auto text-sm text-[#D39E36]">
                                            Tagging Validity: {moment(conflict?.tag_expiry).format('Do MMM YYYY')}
                                        </span>
                                    )}
                                    {conflict.is_tagged && (
                                        <span className="ml-auto text-sm text-grey-500 ">
                                            Currently Tagged
                                        </span>
                                    )}
                                </div>
                                {conflict?.is_booking_done && <p className="text-sm text-black-t mt-2">Booking Done</p>}
                                {conflict?.is_booking_done && <p className="text-sm text-grey-500">{moment(conflict?.booking_done_time).format('Do MMM YYYY')}</p>}

                                {conflict?.is_eoi_done && <p className="text-sm text-black-t mt-2">Token Done</p>}
                                {conflict?.is_eoi_done && <p className="text-sm text-grey-500">{moment(conflict?.eoi_done_time).format('Do MMM YYYY')}</p>}

                                {conflict?.is_sv_completed && <p className="text-sm text-black-t mt-2">Site Visit Completed</p>}
                                {conflict?.is_sv_completed && <p className="text-sm text-grey-500">{moment(conflict?.sv_completed_time).format('Do MMM YYYY')}</p>}
                            </div>
                        ))}
                    </div>
                </div>

                {/* Assign Closing Section */}
                <div className="!mt-6 ">
                    {/* <label className="block text-sm font-semibold text-gray-900 mb-2">
                        Assign Closing <span className="text-red-500">*</span>
                    </label> */}
                    <InputSelect
                        placeholder={'Select Closing Manager'}
                        name='assignTo'
                        label={'Assign Closing'}
                        required={true}
                        options={allUsers}
                        value={closingManager}
                        onChange={(e) => {
                            setSelectClosingManager(e)
                        }}
                        isMulti={false}
                    />
                </div>

                {/* Comments Section */}
                <div className="!mt-6">
                    <label className="text-sm text-[#647087]">
                        Comments
                    </label>
                    <textarea
                        rows="3"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                        className="w-full border border-gray-300 rounded-md p-2 text-sm  outline-none"
                        placeholder={'Add your comments here...'}
                    />
                </div>
            </div>

            {/* Footer Buttons */}
            <div className="mt-3 !px-5 flex justify-between">
                <button
                    className="w-1/2 !border !border-primary !mr-4 !text-primary rounded-md py-2 text-base font-medium hover:bg-blue-50"
                    onClick={closeModal}
                >
                    Cancel
                </button>
                <button
                    className="w-1/2 !bg-primary text-white rounded-md py-2 text-base font-medium hover:bg-blue-600"
                    onClick={handleSubmit}
                >
                    Assign Tagging
                </button>
            </div>
        </RightModal>
    )
}

export default TaggingConflictModal