import React, { useEffect, useState } from 'react';

import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
// import RightModal from '../../Modals/RightModal';
// import InputRupees from '../../InputGroup/InputRupees';
// import InputText from '../../InputGroup/InputText';
// import { validityUnit } from '../../../helpers/enums';
// import { insertOffer, updateOfferByUUID, uploadFile } from '../../../services/public/public.service';
// import InputTextarea from '../../InputGroup/InputTextarea';
// import InputSelect from '../../InputGroup/InputSelect';
// import DateTimePicker from '../../InputGroup/DateTime';
import { toast } from 'react-toastify';
import { ReactComponent as PluswhiteIcon } from '../../../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trash.svg';
import InputTextarea from '../../../../components/InputGroup/InputTextarea';
import DateTimePicker from '../../../../components/InputGroup/DateTime';
import InputSelect from '../../../../components/InputGroup/InputSelect';
import InputRupees from '../../../../components/InputGroup/InputRupees';
import InputText from '../../../../components/InputGroup/InputText';
import RightModal from '../../../../components/Modals/RightModal';
import style from '../../../../modules/Settings/Projects/style.module.css';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { getCities } from '../../../../services/private/csv.service';
import TextEditor from '../../../ListingProjects/Editor';
import { addReferralIncentiveCreative, insertNews, updateNews, updateReferralIncentiveCreativeByUUID } from '../../../../services/private/customerApp.service';
import { getProjects } from '../../../../services/private/company.service';
import { Oval } from 'react-loader-spinner';
import { uploadFile } from '../../../../services/public/public.service';

const OfferType = [
    {
        label: 'Booking',
        value: 'Booking'
    },
    {
        label: 'Site visit',
        value: 'Site visit'
    }
]

const customStyles = {
    option: (styles, state) => ({
        ...styles,
        zIndex: 99,
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: (base, state) => ({
        ...base,
        zIndex: 99,
        // You can also use state.isFocused to conditionally style based on the focus state
    }),
};

const IncentiveCreationModal = ({ show, closeModal, uuid, getAllIncentivs, selectedItem }) => {

    const [title, setTitle] = useState('');
    const [error, setError] = useState(false);
    const [mediaUrl, setMediaUrl] = useState('');
    const [project, setProject] = useState([])
    const [projectList, setProjectList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log(selectedItem, 'selectedItem')
        if (selectedItem && Object.keys(selectedItem).length > 0) {
            setTitle(selectedItem?.title);
            setMediaUrl(selectedItem?.image_url)
            setProject(selectedItem?.project_id)
        }
    }, [selectedItem]);

    const handleOnChangeImage = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file)
        const response = await uploadFile(formData);

        if (response.data.status === 200) {
            setMediaUrl(response.data.data.s3_url)
        }
    }

    const getAllProjects = async () => {
        const res = await getProjects('');
        if (res.data.status === 200) {
            let mappedProjects = res.data.data.projects.map(item => ({ ...item, label: item.name, value: item.id }))

            setProjectList(mappedProjects);
        }
    }

    useEffect(() => {
        getAllProjects();
    }, []);

    const handleSubmit = async () => {
        if (!title || !mediaUrl || !project) {
            setError(true);
            return
        } else {
            setError(false);
        }

        const payload = {
            title: title,
            image_url: mediaUrl,
            project_id: project
        }
        let res = {}
        if (selectedItem?.uuid) {
            res = await updateReferralIncentiveCreativeByUUID(selectedItem?.uuid, payload);
        } else {
            res = await addReferralIncentiveCreative(payload);
        }

        if (res.data.status === 200) {
            toast.success(res.data.message)
            getAllIncentivs();
            closeModal();
        }
    }

    return (
        <RightModal show={show} closeModal={closeModal}>

            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    {/* {selectedBankDetail?.uuid ? 'Edit' : 'Add'} */}
                    Add Referral Incentive</h1>
                <button className='border-0 bg-tr ' onClick={closeModal}><CloseIcon /></button>
            </div>
            <form className={`${style.modalForm}  modalForm d-flex h-100 overflow-auto flex-column px-20`}>
                <div className={`form-group ${error && !mediaUrl ? 'invalid' : ''}  position-relative !mt-3`}>
                    <div className='fz14 black-dark-700 mb-8 inter d-flex justify-content-between'>
                        <span>Image <sup>*</sup></span>
                        {/* <span>PNG, 2MB</span> */}
                    </div>
                    <div className={`${style.upload}`}>
                        {mediaUrl && <img src={mediaUrl} alt="" className='w-100 h-100' style={{ objectFit: 'contain' }} />}
                        <input type="file" className='d-none' name='offer-img' id='offer-img' accept='image/*' onChange={(e) => handleOnChangeImage(e)} />
                        {!mediaUrl && <div className={`${style.uplaodbtngrp}`}>
                            <button
                                type='button'
                                className={`${style.uploadbtn} pr-btn inter flex items-center`}
                                onClick={() => {
                                    document.getElementById('offer-img').click()
                                }}
                            ><PluswhiteIcon />Upload</button>
                        </div>}
                        {mediaUrl && <div className={`${style.hoverbox}`}>
                            <button
                                type='button'
                                className={`${style.replacebtn} pr-btn inter flex items-center`}
                                onClick={() => {
                                    document.getElementById('offer-img').click()
                                }}
                            ><PluswhiteIcon />Replace</button>
                            <button
                                type='button'
                                className={`${style.delbtn} inter border-0 bg-white`}
                                onClick={() => {
                                    setMediaUrl('')
                                }}
                            ><TrashIcon /></button>
                        </div>}
                    </div>
                    {/* {logoFileError && <p className='color-red fz10 mb-0 mt4 inter'>Invalid resolution. Please upload an image with dimensions of 900x400.</p>} */}
                    {/* {logoDeveloperFileError && <p className='color-red fz10 mb-0 mt4 inter'>{logoDeveloperFileError}</p>} */}
                    {error && !mediaUrl && (
                        <div className="input-feedback mt-8">
                            Please upload media
                        </div>
                    )}
                </div>
                <InputText
                    inputclass='!mt-0'
                    placeholder='Enter Title'
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value)
                    }}
                    label={'Title'}
                    required={true}
                    error={error && !title ? 'Please enter title' : ''}
                />
                <InputSelect
                    inputclass='!mt-0'
                    placeholder='Select Project'
                    value={project ? projectList.find(i => i.value === project) : ''}
                    onChange={(value) => {
                        setProject(value.value)
                    }}
                    options={projectList}
                    label={'Project'}
                    required={true}
                    error={error && project.length === 0 ? 'Please select project' : ''}
                />

                {/* <InputTextarea
                    label='Description'
                    placeholder='Enter Description'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                /> */}

            </form>
            <div className={`${style.righmodalfooter} d-flex flex-row align-items-center justify-content-between border-top border-color px-20 righmodalfooter`}>
                <button className='outline-btn w-100' onClick={closeModal}>Cancel</button>
                {!loading && <button className='pr-btn w-100' onClick={handleSubmit} disabled={loading}>
                    {uuid ? 'Update' : 'Save'}
                </button>}
                {loading && <button className='pr-btn w-100'>
                    <Oval
                        height={22}
                        width={22}
                        color="#FFFFFF"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#FFFFFF"
                        strokeWidth={4}
                        strokeWidthSecondary={4}
                    />
                </button>}
            </div>
        </RightModal >
    )
}

export default IncentiveCreationModal
