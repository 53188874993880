import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/PhonIcon.svg";
import PostCallModal from './Modal/PostCallModal';
import StatusDropDown from '../../../components/Custom Table/TableColumns/StatusDropDown';
import { getSelectedValue } from '../../../utils/selectedOptionUtils';
import Consumer from '../../../helpers/context';
import StatusDropdown from '../Components/StatusDropdown';
import TooltipText from '../../../components/Tooltip';
import { createDatewithTime } from '../../../helpers/date';
import DynamicIntent from '../../../components/Custom Table/TableColumns/DynamicIntent';
import RightModal from "./Modal/RightModal";
import RenderDashboardTableData from "../Components/RenderDashboardTableData";
import { ReactComponent as TableIcon } from '../../../assets/new/table.svg';
import PresaleColumnCustomisation from "./Modal/PresaleColumnCustomisation";
import { saveColumnPreferenceForOpportunityDashbaord } from "../services/dashboard.service";
import { toast } from "react-toastify";
import { checkIsSomeItemAvailableInList, mergeUniqueByKey } from '../../../helpers/helpers';
import { ReactComponent as ThreedotsIcon } from '../../../assets/icons/threedots.svg';
import { knowlarityClickToCall } from "../../../services/private/company.service";
import ShareLeadAccessModal from "../../../components/Modals/ShareLeadAccessModal";
import AdvanceCallModal from "../../../components/Modals/AdvanceCallModal";
import LeadTaggingModal from "../../../components/Modals/LeadTaggingModal";
import SortIconDisplay from "../../../components/Custom Table/sortIconDisplay";
import { useHistory } from "react-router-dom";
import { Oval } from "react-loader-spinner";

const SiteVisitLeads = ({
  opportunityData,
  tableColumn,
  getOpportunities,
  sourceStatuses,
  statuses,
  intentStatuses,
  dashboardColumns,
  getColumns,
  updateColumnList,
  handleChangeSelection,
  selectedTab,
  col,
  sort,
  selectedTableValues,
  setSelectedTableValues,
  setSelectedTableData,
  selectedTableData,
  setLeadName,
  showCheck,
  callerList,
  setSelectedLead,
  setShowCreateOppotunityModal,
  setShowNewOppReassigned,
  filters,
  searchText,
  isLoading,
  selectedInternalTab
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedManager, setSelectedManager] = useState("Gautam Singh");
  const [showPostCallModal, setShowPostCallModal] = useState(false);
  const [showCustomisedColumnModal, setShowCustomisedColumnModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [showLeadTaggingModal, setShowLeadTaggingModal] = useState(false);
  const [tagTitle, setTagTitle] = useState('');
  const [showShareLeadAccessModal, setShowShareLeadAccessModal] = useState(false);
  const [showAdvanceCallModal, setShowAdvanceCallModal] = useState(false);

  let { companyConfig, allowedPermissions, isRolesModule, userProfile } = useContext(Consumer);
  const history = useHistory();

  const ROLE_NAME = localStorage.getItem('role');

  const onHandleSelectAllLeads = () => {
    if (!selectAll) {
      const selectedVal = opportunityData.map((lead) => lead.id)
      // setSelectedTableValues([...selectedTableValues, ...selectedVal]);
      setSelectedTableValues([...new Set([...selectedTableValues, ...selectedVal])]);
      const mergedLeads = mergeUniqueByKey(selectedTableData, opportunityData, 'id')
      // setSelectedTableData([...selectedTableData, ...opportunityData]);
      setSelectedTableData([...mergedLeads]);
    } else {
      if (selectedTableValues?.length > opportunityData?.length) {
        const leadIds = opportunityData.map(lead => lead.id);
        const filtered = selectedTableValues.filter(item => !leadIds.includes(item))
        const filteredData = selectedTableData.filter(item => !leadIds.includes(item.id))
        setSelectedTableValues(filtered)
        setSelectedTableData(filteredData)
      } else {

        setSelectedTableValues([])
        setSelectedTableData([]);
      }
    }
    setSelectAll(!selectAll)
  }

  useEffect(() => {
    if (selectedTableValues?.length >= opportunityData?.length) {
      const allSelected = opportunityData.every(lead => selectedTableValues.includes(lead.id))
      setSelectAll(allSelected && selectedTableValues?.length > 0)
    }
  }, [selectedTableValues, selectedTableData, opportunityData]);

  const handelChange = (e, data) => {
    if (e.target.checked) {
      setLeadName(data.name)
      setSelectedTableValues([...selectedTableValues, data.id])
      setSelectedTableData([...selectedTableData, data])
    }
    else {
      const filteredValues = selectedTableValues.filter((value) => value != data.id);
      const filteredData = selectedTableData.filter((value) => value.id != data.id);
      setSelectedTableValues(filteredValues);
      setSelectedTableData(filteredData);
      setLeadName('')
    }
  }

  const handleAdvanceClickToCall = async (useAlternate, callerId) => {
    const payload = {
      use_alternate: useAlternate,
      caller_id: callerId
    }

    const res = await knowlarityClickToCall(selectedItem.id, payload);
    if (res.data.status === 200) {
      toast.success(res.data.message)
      setSelectedItem({})
      setShowAdvanceCallModal(false)
    }
  }

  const handleClickToCall = async (lead) => {
    const res = await knowlarityClickToCall((lead?.id || lead?.uuid), {});
    if (res.data.status === 200) {
      toast.success(res.data.message)
    }
  }

  const opportunityTags = [
    {
      name: "Presales ($Name)",
      isActive: true,
      status: "Site Visit Scheduled",
      date: "12:30pm, 21 Sept'22",
    },
    {
      name: "CP ($Name)",
      isActive: false,
      status: "Site Visit Scheduled",
      date: "12:30pm, 21 Sept'22",
      taggingValidity: "Tagging Validity: 23rd Oct'23",
    },
  ];

  const managers = ["Gautam Singh", "Rahul Sharma", "Priya Kapoor"];
  const closingOptions = ["Gautam Singh", "Other Person"];

  const saveCurrentUserColumnPrefrence = () => {
    const preference = dashboardColumns.map(items => {
      items.columns.selected.forEach((item, index) => {
        item.placement = index + 1;
      })
      return {
        tab: items.tab,
        columns: [...items.columns.selected, ...items.columns.notSelected]
      }
    })
    saveColumnPreferenceForOpportunityDashbaord({ preference }).then(res => {
      if (res.data.status === 200) {
        toast.success(res.data.message);
        getColumns();
        setShowCustomisedColumnModal(false);
        // getLeadsData();
      }
    }).catch(err => {
      console.log({ err })
    })
  }

  return (
    <>
      {/* <div className="flex items-center justify-center h-screen">
      <button
        onClick={() => setModalOpen(true)}
        className="px-4 py-2 text-white bg-blue-500 rounded-md"
      >
        Open Modal
      </button>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onAssign={() => alert("Tagging Assigned!")}
        data={{
          opportunities,
          selectedManager,
          managers,
          onManagerChange: setSelectedManager,
        }}
      />
    </div> */}

      <div className={`relative ${isLoading ? '' : 'overflow-auto'} !w-100 !border !rounded-lg !max-h-[calc(100vh-240px)] !min-h-[calc(100vh-240px)]`}>
        <table class="min-w-full text-sm text-left ">
          <thead class="text-sm  text-[#344054]">
            <tr className="border-b-2">
              <th scope="col" class="!pl-3 !py-2 !sticky left-0 text-sm !text-black700 !font-normal inter ">
                {showCheck ?

                  <input
                    type="checkbox"
                    onChange={onHandleSelectAllLeads}
                    checked={selectAll}
                    id='selectAll_leads'
                    className='select-all-check resasign-input'
                    title='Select all'

                  />

                  :
                  'S.No.'
                }
              </th>
              {
                tableColumn?.map((item, index) => {
                  return (
                    <th
                      key={`${item.label}_${index}`}
                      className={`${item.label === 'Name' ? '!sticky !left-14' : ''} !pl-3 !py-2`}
                      scope="col"
                    >
                      <span className='flex items-center gap-1 text-sm !text-black700 !font-normal inter '>
                        {item.label}
                        <SortIconDisplay sortCol={col} sort={sort} colName={item.label} />
                      </span>
                    </th>
                  )
                })
              }
              <th scope="col" class="!pl-3 !py-2 !sticky !right-0">
                <TooltipText id="button-tooltip" title='Customise Columns' className='leadmanager-tooltip'>
                  <button onClick={() => setShowCustomisedColumnModal(true)}>
                    <TableIcon />
                  </button>
                </TooltipText>
              </th>
            </tr>
          </thead>
          <tbody>
            {
              !isLoading && opportunityData?.length > 0 && opportunityData.map((lead, index) => (
                <tr class=" border-b !px-3">
                  <td class="!pl-3 !py-2 !sticky bg-white left-0 text-[#44444F]">
                    {showCheck ?
                      <input
                        type="checkbox"
                        onChange={(e) => handelChange(e, lead)}
                        checked={selectedTableValues.includes(lead.id)}
                      /> :
                      index + 1
                    }
                  </td>
                  {
                    tableColumn?.map((item, index) => {
                      return (
                        <RenderDashboardTableData
                          tHead={item}
                          tData={lead}
                          isRedirectOnNameClick={true}
                          intentStatuses={intentStatuses}
                          statuses={statuses}
                          sourceStatuses={sourceStatuses}
                          redirectURL={'presale/opportunity-details'}
                          onSuccess={getOpportunities}
                          redirectBactTo={'presale'}
                          tab={selectedTab?.name}
                          subTab={selectedInternalTab?.name}
                          handleClickToCall={handleClickToCall}
                        />
                      )
                    })}
                  <td className='dropdown view-more bg-white !sticky right-0'>
                    <button className="!w-[50px] hover:bg-black100 flex items-center justify-center !h-7 rounded-lg" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <ThreedotsIcon />
                    </button>
                    <div className="dropdown-menu dropdown-menu-right p-2" aria-labelledby="dropdownMenuButton">
                      <a className="dropdown-item"
                        onClick={() => {
                          setSelectedLead(lead);
                          setShowNewOppReassigned(true)
                        }}
                      >
                        Re-assign Lead
                      </a>

                      <a className="dropdown-item"
                        onClick={() => {
                          history.push(`/presale/opportunity-details/${lead.id || lead.uuid}?redirect=presale`)
                        }}
                      >
                        View Timeline
                      </a>

                      <a className="dropdown-item"
                        onClick={() => {
                          history.push(`/presale/opportunity-details/${lead.id || lead.uuid}?redirect=presale`)
                        }}
                      >
                        View Details
                      </a>
                      {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && allowedPermissions?.includes('edit_broker_tag') && <a className="dropdown-item" onClick={() => {
                        setSelectedItem(lead)
                        setShowLeadTaggingModal(true);
                      }}>
                        Edit Lead Tagging
                      </a>}
                      {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && allowedPermissions?.includes('edit_broker_tag') && <a className="dropdown-item" onClick={() => {
                        setSelectedItem(lead)
                        setShowLeadTaggingModal(true);
                      }}>
                        Edit CP User
                      </a>}
                      {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && allowedPermissions?.includes('edit_broker_tag') && <a className="dropdown-item" onClick={() => {
                        setSelectedItem(lead)
                        setShowLeadTaggingModal(true);
                        setTagTitle('Remove Lead Title')
                      }}>
                        Remove Lead Tagging
                      </a>}
                      {userProfile?.allowed_modules?.includes('lead_sharing') &&
                        checkIsSomeItemAvailableInList(allowedPermissions, ['can_share_leads']) &&
                        <a className="dropdown-item" onClick={() => {
                          setSelectedItem(lead)
                          setShowShareLeadAccessModal(true)
                        }}>

                          Share Lead Access
                        </a>}
                      {callerList.length > 0 &&
                        (ROLE_NAME === 'Super Admin' ||
                          (userProfile?.allowed_modules?.includes('ivr_call') &&
                            allowedPermissions.includes('use_ivr_outbound_call'))) && <a
                              className="dropdown-item" onClick={() => handleClickToCall(lead)}
                            >
                          Quick Call
                        </a>}
                      {callerList.length > 0 &&
                        (ROLE_NAME === 'Super Admin' ||
                          (userProfile?.allowed_modules?.includes('ivr_call') &&
                            allowedPermissions.includes('use_ivr_outbound_call'))) && <a
                              className="dropdown-item" onClick={() => {
                                setSelectedItem(lead);
                                setShowAdvanceCallModal(true);
                              }}>
                          Advanced Call
                        </a>}
                      <a className="dropdown-item del-menu-itme" onClick={() => {

                      }}>Delete Opportunity</a>
                    </div>
                  </td>
                </tr>
              ))
            }

          </tbody>
        </table>
        {isLoading && <div className='d-flex w-full justify-content-center flex-column items-center !max-h-[calc(100vh-240px)] !min-h-[calc(100vh-240px)]'>
          <Oval
            height={50}
            width={50}
            color="#0062FF"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#0062FF"
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        </div>}
        {!isLoading && opportunityData?.length === 0 && <div className='d-flex justify-content-center flex-column text-center !max-h-[calc(100vh-240px)] !min-h-[calc(100vh-240px)]'>
          <div className='flex items-center flex-col'>

            <figure><svg xmlns="http://www.w3.org/2000/svg" width="63" height="65" viewBox="0 0 63 65" fill="none"><circle cx="33.1727" cy="35.6068" r="29.3934" fill="#F0F6FF"></circle><path d="M39.6259 38.4741C39.1482 38.2353 30.7839 43.2533 30.545 44.2094C30.3062 45.1654 37.3318 58.8344 39.6259 63.8049C46.5082 62.2755 51.5744 58.229 53.2472 56.3969C53.2472 56.3969 40.2674 38.7946 39.6259 38.4741Z" fill="#0062FF"></path><circle opacity="0.1" cx="21.9407" cy="22.4632" r="20.0735" fill="#696974"></circle><circle cx="20.5071" cy="20.0735" r="20.0735" fill="#B5B5BE"></circle><circle cx="20.5078" cy="20.0732" r="16.25" fill="#D5D5DC"></circle><path fillRule="evenodd" clipRule="evenodd" d="M14.2217 35.0627C9.40488 32.2428 6.16973 27.0138 6.16973 21.0292C6.16973 12.0546 13.4451 4.77918 22.4197 4.77918C24.6483 4.77918 26.7722 5.22781 28.7058 6.03969C26.2992 4.63079 23.4978 3.82324 20.5078 3.82324C11.5332 3.82324 4.25781 11.0986 4.25781 20.0732C4.25781 26.8193 8.36853 32.6052 14.2217 35.0627Z" fill="#696974"></path><path d="M33.1716 35.3677L30.543 37.2794L33.1716 42.5368L36.7562 40.3861L33.1716 35.3677Z" fill="#B5B5BE"></path></svg></figure>
            {(filters?.length > 0 || searchText) &&
              <>
                <h2 className="po black fz16 fw-po-medium mb-8">Sorry, we couldn’t find any results</h2>
                <p className='text-center black-dark-600 fz14 mb-0'> Please try again</p>
              </>
            }
            {
              !searchText && !filters?.length &&
              <>
                <h2 className="po black fz16 fw-po-medium mb-8"> No Opportunity Found</h2>
              </>
            }
          </div>
        </div>}
        {showPostCallModal && (
          <PostCallModal
            show={showPostCallModal}
            closeModal={() => setShowPostCallModal(false)}
          />
        )}
      </div>
      {isModalOpen && (
        <RightModal
          leadName="Sumit Singh"
          leadContact="+91-8797616890"
          projectName="Haridwar One"
          viewLeadLink="#"
          opportunityTags={opportunityTags}
          closingOptions={closingOptions}
          commentsPlaceholder="Add your comments here..."
          defaultComment="She’s interested in our new product line and wants our very best price please include a detailed breakdown of costs."
          onCancel={() => setModalOpen(false)}
          onAssignTagging={() => alert("Assign Tagging clicked")}
          onClose={() => setModalOpen(false)} // Close modal
        />
      )}
      {showCustomisedColumnModal &&
        <PresaleColumnCustomisation
          dashboardColumns={dashboardColumns}
          activeColumnTab={selectedTab.name}
          show={showCustomisedColumnModal}
          setShow={setShowCustomisedColumnModal}
          updateColumnList={updateColumnList}
          handleChangeSelection={handleChangeSelection}
          saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
        />
      }
      {showShareLeadAccessModal &&
        <ShareLeadAccessModal
          show={showShareLeadAccessModal}
          selectedItem={selectedItem}
          closeModal={() => {
            setShowShareLeadAccessModal(false)
            setSelectedItem({})
          }}
          onSuccess={getOpportunities}
          allowedPermissions={allowedPermissions}
        />
      }
      {
        showAdvanceCallModal &&
        <AdvanceCallModal
          show={showAdvanceCallModal}
          closeModal={() => {
            setSelectedItem({})
            setShowAdvanceCallModal(false)
          }}
          handleSubmit={handleAdvanceClickToCall}
          callerList={callerList}
        />
      }

      {
        showLeadTaggingModal &&
        <LeadTaggingModal
          show={showLeadTaggingModal}
          uuid={selectedItem.id}
          selectedItem={selectedItem}
          updateData={getOpportunities}
          title={tagTitle}
          closeModal={() => {
            setSelectedItem({})
            setShowLeadTaggingModal(false)
          }}
        />
      }
    </>
  );
};

export default SiteVisitLeads;
