import React, { useContext, useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as LeftIcon } from '../../assets/icons/left.svg';
import { ReactComponent as PluswhiteIcon } from '../../assets/icons/Pluswhite.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { Controller, useForm } from 'react-hook-form';
import { ReactComponent as ThreedotsIcon } from '../../assets/icons/threedots.svg';
import { ReactComponent as PlushIcon } from '../../assets/icons/Pluswhite.svg';
import { ReactComponent as AnCloseIcon } from '../../assets/icons/closeicon.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as LockIcon } from '../../assets/icons/lock.svg';
import ProjectHeader from './project-header';
import { deleteAdditionalCostByUUID, deleteBankDetailByUUID, deletePaymentPlanByUUID, deleteProjectLatestUpdates, getProjectBankDetails, getProjectBasicDetailsByUUID, getProjectLatestUpdates, getProjectPaymentPlans, getProjectProjectAdditionalCosts, updateDeveloperDetailsByUUID, updateProjectAdditionalDetailsByUUID, uploadFile } from '../../services/public/public.service';
import style from '../../modules/Settings/Projects/style.module.css';
import '../../modules/Settings/Projects/style.css';
import ReactDatePicker from 'react-datepicker';
import { maintenance_payee, towerHeight } from '../../utils/project-constant';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendar.svg';
import Dropdown from '../InputGroup/dropdown';
import InputSelect from '../InputGroup/InputSelect';
import AmenitiesModal from './Modal/AmenitiesModal';
import NearbyLandmarksModal from './Modal/NearbyLandmarksModal';
import ProjectOfferModal from './Modal/ProjectOfferModal';
import AdditionalCost from './Modal/AdditionalCost';
import PaymentPlanModal from './Modal/PaymentPlanModal';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { checkIsSomeItemAvailableInList, convertToTitleCase, numDifferentiation } from '../../helpers/helpers';
import moment from 'moment';
import { toast } from 'react-toastify';
import AddBankModal from './Modal/AddBankModal';
import Consumer from '../../helpers/context';
import OfferModal from './Modal/OfferModal';
import AddLatestUpdateModal from './Modal/AddLatestUpdate';
import TooltipText from '../Tooltip';
import TextEditor from '../../modules/ListingProjects/Editor';
import InputTextarea from '../InputGroup/InputTextarea';
import { getUsersForSettings } from '../../services/private/company.service';


const AdditionalInfo = () => {

    const [logo, setLogo] = useState('');
    const [logoFileError, setLogoFileError] = useState('');
    const [isLogoMissing, setIsLogoMissing] = useState(false);
    const [developerlogo, setDeveloperLogo] = useState('');
    const [logoDeveloperFileError, setDeveloperLogoFileError] = useState('');
    const [isDeveloperLogoMissing, setIsDeveloperLogoMissing] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showLandMarkModal, setShowLandMarkModal] = useState(false);
    const [showOfferModal, setShowOfferModal] = useState(false);
    const [showAdditionalModal, setShowAdditionalModal] = useState(false);
    const [selectedPaymentPlan, setSelectedPaymentPlan] = useState({});
    const [paymentPlans, setPaymentPlans] = useState([]);
    const [additionalCost, setAdditionalCost] = useState([]);
    const [offers, setOffers] = useState([]);
    const [landMarks, setLandMarks] = useState([]);
    const [bankDetails, setBankDetails] = useState([]);
    const [showBankDetailModal, setShowBankDetailModal] = useState(false);
    const [selectedBankDetail, setSelectedBankDetail] = useState({});
    const [selectedAdditionalDetail, setSelectedAdditionalDetail] = useState({});
    const [inventoryType, setInventoryType] = useState('');
    const [heightError, setHeightError] = useState(false);
    const [showLatestUpdate, setShowLatestUpdate] = useState(false);
    const [latestUpdateList, setLatestUpdateList] = useState([]);
    const [selectedUpdate, setSelectedUpdate] = useState({});
    const [whatsappMessage, setWhatsappMessage] = useState('');
    const [allUser, setAllUsers] = useState([]);
    const [salesPOC, setSalesPOC] = useState('')

    const error = '';

    const { uuid } = useParams();
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const propertyType = searchParams.get('pt');
    const transactionType = searchParams.get('tt');
    const ROLE = localStorage.getItem('role');
    const projectStatus = localStorage.getItem('projectStatus');
    let { userProfile, allowedPermissions, isRolesModule } = useContext(Consumer);

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        resetField,
        reset,
        setError,
        formState: { errors, isSubmitting, isDirty }
    } = useForm({
        defaultValues: {
            poc_name: '',
            poc_mobile: '',
            developer_description: '',
            allotment_date: '',
            tower_count: '',
            tower_height_unit: '',
            tower_height: '',
            brokerage_payout: '',
            owner_name: '',
            owner_mobile: '',
            brokerage_payout_is_private: false
        },
        // resolver: yupResolver(validationSchema)
    });

    const brokeragePayoutIsPrivate = watch('brokerage_payout_is_private');

    const getBasicDetails = async () => {
        const res = await getProjectBasicDetailsByUUID(uuid)
        if (res.status === 200) {
            const data = res.data.data;
            reset({
                developer_description: data?.developer_description || '',
                owner_mobile: data.owner_mobile || '',
                owner_name: data.owner_name || '',
                brokerage_payout: data.brokerage_payout || '',
                brokerage_payout_is_private: data.brokerage_payout_is_private || true,
                poc_name: data.poc_name || '',
                poc_mobile: data.poc_mobile || '',
                tower_count: data.tower_count || '',
                allotment_date: data.allotment_date ? new Date(data.allotment_date) : '',
                tower_height: data.tower_height || '',
                tower_height_unit: data.tower_height_unit ? towerHeight.find(item => item.value === data.tower_height_unit) : ''
            },
                { keepDefaultValues: true })
            setLogo(data?.logo);
            setWhatsappMessage(res.data.data?.whatsapp_message || '')
            setDeveloperLogo(data?.developer_logo);
            setLandMarks(data.nearby || []);
            setOffers(data.offers || [])
            setInventoryType(data.inventory_type);
            setSalesPOC(data?.sales_poc || '')
        }
    }

    const getBankDetails = async () => {
        const res = await getProjectBankDetails(uuid);
        if (res.status === 200) {
            setBankDetails(res.data.data);

        }
    }
    const getPaymentPlans = async () => {
        const res = await getProjectPaymentPlans(uuid);
        if (res.status === 200) {
            setPaymentPlans(res.data.data)
        }
    }

    const getAdditionCost = async () => {
        const res = await getProjectProjectAdditionalCosts(uuid);
        if (res.data.status === 200) {
            setAdditionalCost(res.data.data)
        }
    }

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        value: data.id
                    }
                })
                setAllUsers(userData)
            }
        })
    }
    useEffect(() => {
        if (uuid) {
            getPaymentPlans();
            getBasicDetails();
            getAdditionCost();
            getBankDetails();
            handleGetUpdates();
            getAllUsers();
        }
    }, [uuid]);

    useEffect(() => {
        if (!isDirty) {
            localStorage.setItem('project_state', 'create')
        }
    }, [isDirty])

    const handleUpdateLandMarks = (landmark) => {
        const filtered = landmark.filter(item => item)
        setLandMarks(filtered)
    }

    const handleUpdateOffers = (offer) => {
        const filtered = offer.filter(item => item);
        setOffers(filtered);
    }

    const handleDeleteBankDetails = async (bankuuid) => {
        const res = await deleteBankDetailByUUID(bankuuid);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getBankDetails();
        }
    }

    const handleDeletPaymentPlans = async (planuuid) => {
        const res = await deletePaymentPlanByUUID(planuuid);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getPaymentPlans();
        }
    }

    const handleDeleteAdditionalDetails = async (costuuid) => {
        const res = await deleteAdditionalCostByUUID(costuuid);
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getAdditionCost();
        }
    }

    const handleGetUpdates = async () => {
        const res = await getProjectLatestUpdates(uuid);
        if (res.data.status === 200) {
            setLatestUpdateList(res.data.data)
        }
    }

    const handleDeleteLatestUpdate = async (updateUUID) => {
        const res = await deleteProjectLatestUpdates(updateUUID);
        if (res.data.status === 200) {
            handleGetUpdates();
            toast.success(res.data.message);
        }
    }

    const handleOnChangeLogo = async (e) => {
        const file = e.target.files[0];

        if (file.size / 1048576 > 2) {
            setLogoFileError('File too large. Please upload an image under 2MB in size');
            return;
        }
        if (file.type != 'image/jpeg' && file.type != 'image/png' && file.type != 'image/jpg') {
            setLogoFileError('Wrong File format Uploaded');
            return;
        }

        const formData = new FormData();
        formData.append('file', file)
        const response = await uploadFile(formData);

        if (response.data.status === 200) {
            setLogo(response.data.data.s3_url);
            setIsLogoMissing(false);
            setLogoFileError('');
        }
    }

    const handleOnChangeDeveloperLogo = async (e) => {
        const file = e.target.files[0];

        if (file.size / 1048576 > 2) {
            setLogoFileError('File too large. Please upload an image under 2MB in size');
            return;
        }
        if (file.type != 'image/jpeg' && file.type != 'image/png' && file.type != 'image/jpg') {
            setLogoFileError('Wrong File format Uploaded');
            return;
        }

        const formData = new FormData();
        formData.append('file', file)
        const response = await uploadFile(formData);

        if (response.data.status === 200) {
            setDeveloperLogo(response.data.data.s3_url);
            setIsDeveloperLogoMissing(false);
            setDeveloperLogoFileError('');
        }
    }

    const onFormSubmit = async (values) => {
        if (values.tower_height && !values.tower_height_unit) {
            setHeightError(true);
            return
        } else {
            setHeightError(false)
        }
        const payload = {
            logo: logo || '',
            allotment_date: values.allotment_date || null,
            tower_count: values.tower_count || null,
            tower_height: values.tower_height || null,
            tower_height_unit: values.tower_height_unit.value || null,
            nearby: landMarks,
            poc_name: values.poc_name || null,
            poc_mobile: values.poc_mobile,
            developer_description: values.developer_description,
            developer_logo: developerlogo || null,
            offers: offers,
            brokerage_payout: values.brokerage_payout,
            brokerage_payout_is_private: values.brokerage_payout_is_private,
            owner_name: values.owner_name,
            owner_mobile: values.owner_mobile,
            whatsapp_message: whatsappMessage,
            sales_poc: salesPOC
        }

        const res = await updateProjectAdditionalDetailsByUUID(uuid, payload)
        if (res.status === 200) {
            toast.success(res.data.message);
            if (checkIsSomeItemAvailableInList(allowedPermissions, ['view_payment_plans', 'create_payment_plan'])) {
                history.push(`/projects/create-projects/payment-plan/${uuid}?pt=${propertyType}&tt=${transactionType}`);
            } else if (checkIsSomeItemAvailableInList(allowedPermissions, ['view_pricing_module', 'edit_pricing_module'])) {
                history.push(`/projects/create-projects/pricing/${uuid}?pt=${propertyType}&tt=${transactionType}`);
            } else if (checkIsSomeItemAvailableInList(allowedPermissions, ['edit_project_routing', 'select_project_lead'])) {
                history.push(`/projects/create-projects/team-info/${uuid}?pt=${propertyType}&tt=${transactionType}`);
            } else {
                history.push(`/projects`);
            }

        }
    }

    return (
        <div className={`${style.creatproject}`}>
            <ProjectHeader style={style} />
            <form onSubmit={handleSubmit(onFormSubmit)} className={`${style.creatproject} overflow-auto border-top border-color`} id='additioninfo'>
                <div className={`${style.Location} d-flex flex-fill flex-col mb-1 pl-24 pt-20`}>
                    <div className='w-100'>
                        <div className={`${style.basic_form} basic_form`}>
                            <div className={`${style.col_span_2} border-bottom border-color`}>
                                <h1 className='fz18 black fw-po-semi inter mb4'>Project Details</h1>
                            </div>

                            <div className='relative form-group'>
                                <Controller
                                    control={control}
                                    name='allotment_date'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <div className='position-relative'>
                                            <label className=''>Allotment Date</label>
                                            <ReactDatePicker
                                                placeholderText='Select Allotment Date'
                                                onChange={onChange}
                                                dateFormat="dd MMM yyyy"
                                                selected={value}
                                                className={`form-control text-input ${error ? 'error' : ''}`}
                                            // error={errors?.state?.message ? 'Please select State' : ''}
                                            />
                                            <CalendarIcon className={`${style.calicon}`} />
                                        </div>
                                    )}
                                />
                            </div>

                            <div className={`${style.groupofinput}`}>
                                <div className='form-group'>
                                    <label>No. of Tower(s)</label>
                                    <input
                                        type='text'
                                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                                        placeholder='e.g. 10'
                                        {...register('tower_count', { required: false })}
                                    />
                                    {/* {errorMessage && <div className={`${errorClass} text-red mt-2 text-xs font-medium`}>{errorMessage}</div>} */}

                                </div>

                            </div>
                            <div className='form-group position-relative'>
                                <label>Tower Height </label>
                                <Controller
                                    control={control}
                                    name='tower_height_unit'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <Dropdown
                                                list={towerHeight}
                                                onChange={onChange}
                                                value={value}
                                                style={style}
                                            />
                                        </>
                                    )}
                                />

                                <input
                                    type='number'
                                    className={heightError ? 'form-control text-input error' : 'form-control text-input'}
                                    placeholder='e.g. 100'
                                    {...register('tower_height')}
                                />

                                {heightError && (
                                    <div className='invalid'>
                                        <div className="input-feedback mt-8">
                                            Please select Tower Height Unit
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div>
                                <div className='fz14 black-dark-700 mb-8 inter d-flex justify-content-between'>
                                    <span>Project Logo</span>
                                    <span>PNG, 2MB</span>
                                </div>
                                <div className={`${style.upload}`}>
                                    {logo && <img src={logo} alt="" className='w-100 h-100' style={{ objectFit: 'contain' }} />}
                                    <input type="file" className='d-none' name='developer-logo' id='project-logo' accept='image/*' onChange={handleOnChangeLogo} />
                                    {!logo && <div className={`${style.uplaodbtngrp}`}>
                                        <button
                                            type='button'
                                            className={`${style.uploadbtn} pr-btn inter flex items-center`}
                                            onClick={() => {
                                                document.getElementById('project-logo').click()
                                            }}
                                        ><PluswhiteIcon />Upload</button>
                                    </div>}
                                    {logo && <div className={`${style.hoverbox}`}>
                                        <button
                                            type='button'
                                            className={`${style.replacebtn} pr-btn inter flex items-center`}
                                            onClick={() => {
                                                document.getElementById('project-logo').click()
                                            }}
                                        ><PluswhiteIcon />Replace</button>
                                        <button
                                            type='button'
                                            className={`${style.delbtn} inter border-0 bg-white`}
                                            onClick={() => setLogo('')}
                                        ><TrashIcon /></button>
                                    </div>}
                                </div>
                                {/* {logoFileError && <p className='color-red fz10 mb-0 mt4 inter'>Invalid resolution. Please upload an image with dimensions of 900x400.</p>} */}
                                {logoFileError && <p className='color-red fz10 mb-0 mt4 inter'>{logoFileError}</p>}
                            </div>
                        </div>
                        {paymentPlans?.length === 0 && <div className={`${style.basic_form} basic_form mt-16`}>
                            <div className={`${style.col_span_2}`}>
                                <button type='button' className='pr-outline-btn d-block w-100' onClick={() => setShowPaymentModal(true)}>+ Add Payment Plan</button>
                            </div>
                        </div>}
                        {paymentPlans?.length > 0 && <div className='max-w-[915px]'>
                            <div className={`${style.projecttitle} border-bottom border-color pt-30 pb-10 pr-6 d-flex align-items-center justify-content-between`}>
                                <h1 className='fz18 black fw-po-semi inter mb-0'>Payment Plan</h1>
                                <button type='button' className='pr-btn inter  ' onClick={() => setShowPaymentModal(true)}><PlushIcon />Add</button>
                            </div>

                            <div className='max-w-[915px]'>
                                <div className={`${style.bankDetailsmain} d-flex flex-fill flex-col mb-1 pl-sm0 pt-20`}>
                                    <div className='w-100'>
                                        <div className={`${style.bankDetailtable} def-table pr-6 pb-0 w-100`}>
                                            <table className='table mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th >S.No</th>
                                                        <th>Payment Plan Type</th>
                                                        <th>Description</th>
                                                        <th>Offers</th>
                                                        <th>Offer Validity</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        paymentPlans?.map((item, index) => (
                                                            <tr key={`${item.uuid}_${index}`}>
                                                                <td >{index + 1}</td>
                                                                <td>{convertToTitleCase(item.name || '-')}</td>
                                                                <td>
                                                                    {item.description?.length > 20 ?
                                                                        <OverlayTrigger
                                                                            placement="bottom"
                                                                            overlay={
                                                                                <Tooltip id="button-tooltip" >
                                                                                    <div className='info-msg'>
                                                                                        {/* <h4>Total Leads</h4> */}
                                                                                        <p className=''>{item.description}</p>
                                                                                    </div>
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span className='three-dots'>{item.description}</span>
                                                                        </OverlayTrigger>
                                                                        :
                                                                        <div className=''> {item.description || '-'}</div>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {item.offers?.length > 20 ?
                                                                        <OverlayTrigger
                                                                            placement="bottom"
                                                                            overlay={
                                                                                <Tooltip id="button-tooltip" >
                                                                                    <div className='info-msg'>
                                                                                        {/* <h4>Total Leads</h4> */}
                                                                                        <p className=''>{convertToTitleCase(item.offers || '-')}</p>
                                                                                    </div>
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span className='three-dots'>{convertToTitleCase(item.offers || '-')}</span>
                                                                        </OverlayTrigger>
                                                                        :
                                                                        <div className=''>{convertToTitleCase(item.offers || '-')}</div>
                                                                    }


                                                                </td>
                                                                <td>{item.validity ? moment(item.validity).format('D MMMM YYYY') : '-'}</td>
                                                                <td className='dropdown view-more'>
                                                                    <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <ThreedotsIcon />
                                                                    </button>
                                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                        <a className="dropdown-item text-capitalize"
                                                                            onClick={() => {
                                                                                setSelectedPaymentPlan(item);
                                                                                setShowPaymentModal(true);
                                                                            }
                                                                            }>
                                                                            Edit Entry
                                                                        </a>
                                                                        <a className="dropdown-item del-menu-itme text-capitalize"
                                                                            onClick={() => {
                                                                                handleDeletPaymentPlans(item.uuid)
                                                                            }}
                                                                        >
                                                                            Delete Entry
                                                                        </a>

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {bankDetails?.length === 0 && <div className={`${style.basic_form} basic_form mt-16`}>
                            <div className={`${style.col_span_2}`}>
                                <button type='button' className='pr-outline-btn d-block w-100' onClick={() => setShowBankDetailModal(true)}>+ Add Bank Details</button>
                            </div>
                        </div>}
                        {bankDetails?.length > 0 && <div >
                            <div className={`${style.projecttitle} border-bottom border-color pt-30 pb-10 pr-6 d-flex align-items-center justify-content-between `}>
                                <h1 className='fz18 black fw-po-semi inter mb-0'>Bank Details</h1>
                                <button type='button' className='pr-btn inter ' onClick={() => setShowBankDetailModal(true)}><PlushIcon />Add</button>
                            </div>

                            <div>
                                <div className={`${style.bankDetailsmain} d-flex flex-fill flex-col mb-1 pl-sm-12 pt-20`}>
                                    <div className='w-100'>
                                        <div className={`${style.bankDetailtable} def-table pr-6 pb-0 w-100`}>
                                            <table className='table mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Bank Name</th>
                                                        <th>Bank Branch</th>
                                                        <th>Account No.</th>
                                                        <th>IFSC Code</th>
                                                        <th>Payment Link</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        bankDetails?.map((bank, index) => (
                                                            <tr key={`${bank.uuid}_${index}`}>
                                                                <td>{index + 1}</td>
                                                                <td>{convertToTitleCase(bank.bank_name)}</td>
                                                                <td>{convertToTitleCase(bank.bank_branch)}</td>
                                                                <td>{bank.account_no}</td>
                                                                <td>{convertToTitleCase(bank.ifsc)}</td>
                                                                <td><a href={bank.payment_link} target='_blank'>Link</a></td>
                                                                <td className='dropdown view-more'>
                                                                    <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <ThreedotsIcon />
                                                                    </button>
                                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                        <a className="dropdown-item text-capitalize"
                                                                            onClick={() => {
                                                                                setSelectedBankDetail(bank);
                                                                                setShowBankDetailModal(true);
                                                                            }}
                                                                        >
                                                                            Edit Entry
                                                                        </a>
                                                                        <a className="dropdown-item del-menu-itme text-capitalize"
                                                                            onClick={() => {
                                                                                handleDeleteBankDetails(bank.uuid)
                                                                            }}
                                                                        >
                                                                            Delete Entry
                                                                        </a>

                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {landMarks?.length === 0 && <div className={`${style.basic_form} basic_form mt-16`}>
                            <div className={`${style.col_span_2}`}>
                                <button type='button' className='pr-outline-btn d-block w-100' onClick={() => setShowLandMarkModal(true)}>+ Add Nearby Landmarks</button>
                            </div>
                        </div>}
                        {landMarks?.length > 0 && <div>
                            <div className={`${style.projecttitle} border-bottom border-color pt-30 pb-10 pr-6 d-flex align-items-center justify-content-between pl-sm-12 pr-sm0 pl-sm0`}>
                                <h1 className='fz18 black fw-po-semi inter mb-0'>Nearby Landmarks</h1>
                                <button type='button' className='pr-btn inter' onClick={() => setShowLandMarkModal(true)}><PlushIcon />Add</button>
                            </div>
                            <div className='d-flex flex-wrap mt-20 gap-y-14 gap-x-8 mb-4'>
                                {landMarks.map((item, index) => (
                                    <div
                                        className={`${style.animitestags}`}
                                        key={`${item}_${index}`}

                                    >
                                        {item}
                                        <button className={`${style.closetagbtn}`} type='button' onClick={() => {
                                            const filteredList = landMarks.filter((landmark, landIndex) => landIndex !== index);
                                            setLandMarks(filteredList)
                                        }}>
                                            <AnCloseIcon />
                                        </button>
                                    </div>
                                ))
                                }

                            </div>
                        </div>}
                        {/* {(propertyType !== 'commercial' && transactionType !== 'sale') && additionalCost?.length === 0 && <></>
                        } */}
                        <div className={`${style.projecttitle} border-bottom border-color !mb-4 pt-30 pb-10 pr-6 d-flex align-items-center justify-content-between pl-sm-12 pr-sm0 pl-sm0`}>
                            <h1 className='fz18 black fw-po-semi inter mb-0'>Whatsapp Message</h1>

                        </div>
                        <InputTextarea
                            value={whatsappMessage}
                            placeholder={'Enter Whatsapp Message'}
                            row={7}
                            onChange={(e) => {
                                setWhatsappMessage(e.target.value)
                            }} />
                        {additionalCost?.length === 0 && <div className={`${style.basic_form} basic_form mt-16`}>
                            <div className={`${style.col_span_2}`}>
                                <button type='button' className='pr-outline-btn w-100' onClick={() => setShowAdditionalModal(true)}>+ Add Any Additional Costs</button>
                            </div>
                        </div>}
                        {additionalCost?.length > 0 && <div >
                            <div className={`${style.projecttitle} border-bottom border-color d-flex align-items-center justify-content-between pb-10 mt-24 pr-6`}>
                                <h1 className='fz18 black fw-po-semi inter mb-0'>Additional Costs</h1>
                                <button type='button' className='pr-btn inter' onClick={() => setShowAdditionalModal(true)}><PlushIcon />Add</button>
                            </div>
                            <div>
                                <div className={`${style.bankDetailsmain} d-flex flex-fill flex-col mb-1 pt-20`}>
                                    <div className='w-100'>
                                        <div className={`${style.bankDetailtable} def-table pr-6 pb-0 w-100`}>
                                            <table className='table mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th >S.No</th>
                                                        <th>Cost Name</th>
                                                        <th>Amount</th>
                                                        <th>Comments</th>
                                                        {/*<th>Offer Validity</th>*/}
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        additionalCost.map((item, index) => (
                                                            <tr key={`${item.uuid}_${index}`}>
                                                                <td >{index + 1}</td>
                                                                <td>{convertToTitleCase(item.name || '-')}</td>
                                                                <td>{item?.amount ? '₹ ' + numDifferentiation(item?.amount) : '-'}</td>
                                                                <td>
                                                                    {item?.comment?.length > 30 ?
                                                                        <OverlayTrigger
                                                                            placement="bottom"
                                                                            overlay={
                                                                                <Tooltip id="button-tooltip" >
                                                                                    <div className='info-msg w-auto'>
                                                                                        {/* <h4>Total Leads</h4> */}
                                                                                        <p className=''>{item.comment}</p>
                                                                                    </div>
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span className=' three-dots'>{item.comment}</span>
                                                                        </OverlayTrigger>
                                                                        :
                                                                        <div className=''> {item.comment || '-'}</div>
                                                                    }
                                                                </td>
                                                                <td className='dropdown view-more'>
                                                                    <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <ThreedotsIcon />
                                                                    </button>
                                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                        <a className="dropdown-item text-capitalize"
                                                                            onClick={() => {
                                                                                setSelectedAdditionalDetail(item);
                                                                                setShowAdditionalModal(true);
                                                                            }}
                                                                        >
                                                                            Edit Entry
                                                                        </a>
                                                                        <a className="dropdown-item del-menu-itme text-capitalize"
                                                                            onClick={() => {
                                                                                handleDeleteAdditionalDetails(item.uuid)
                                                                            }}
                                                                        >
                                                                            Delete Entry
                                                                        </a>

                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {latestUpdateList?.length === 0 && <div className={`${style.basic_form} basic_form mt-16`}>
                            <div className={`${style.col_span_2}`}>
                                <button type='button' className='pr-outline-btn w-100' onClick={() => setShowLatestUpdate(true)}>+ Add Latest Update</button>
                            </div>
                        </div>}
                        {latestUpdateList?.length > 0 && <div className='w-[915px] !mt-9' >
                            <div className={`${style.projecttitle} border-bottom border-color d-flex align-items-center justify-content-between pb-10 mt-24 pr-6`}>
                                <h1 className='fz18 black fw-po-semi inter mb-0'>Latest Update</h1>
                                <button type='button' className='pr-btn inter' onClick={() => setShowLatestUpdate(true)}><PlushIcon />Add</button>
                            </div>
                            <div>
                                <div className={`${style.bankDetailsmain} d-flex flex-fill flex-col mb-1 pt-20`}>
                                    <div className='w-100'>
                                        <div className={`${style.bankDetailtable} def-table pr-6 pb-0 w-100`}>
                                            <table className='table mb-0'>
                                                <thead>
                                                    <tr>
                                                        <th >S.No</th>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        latestUpdateList.map((item, index) => (
                                                            <tr key={`${item.uuid}_${index}`}>
                                                                <td >{index + 1}</td>
                                                                <td className='w-[300px]'>
                                                                    {
                                                                        item.title ? <TooltipText title={item.title}>
                                                                            <p className='truncate w-[300px] m-0'>{item.title}</p>
                                                                        </TooltipText>
                                                                            : '-'
                                                                    }
                                                                </td>
                                                                <td className='w-[300px]'>
                                                                    {
                                                                        item.description ? <TooltipText title={item.description}>
                                                                            <p className='truncate w-[300px] m-0'>{item.description}</p>
                                                                        </TooltipText>
                                                                            : '-'
                                                                    }
                                                                </td>
                                                                <td className='dropdown view-more'>
                                                                    <button className="bg-tr border-0 d-table ml-auto" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <ThreedotsIcon />
                                                                    </button>
                                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                        <a className="dropdown-item text-capitalize"
                                                                            onClick={() => {
                                                                                setSelectedUpdate(item);
                                                                                setShowLatestUpdate(true);
                                                                            }}
                                                                        >
                                                                            Edit
                                                                        </a>
                                                                        <a className="dropdown-item del-menu-itme text-capitalize"
                                                                            onClick={() => {
                                                                                handleDeleteLatestUpdate(item.uuid)
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </a>

                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
                <div className={`${style.Location} d-flex flex-fill flex-col pl-24  mt-36`}>
                    <div className='w-100'>
                        <div className={`${style.basic_form} basic_form`}>
                            <div className={`${style.col_span_2} border-bottom border-color`}>
                                <h1 className='fz18 black fw-po-semi inter mb4'>Sales POC</h1>
                            </div>
                            <InputSelect
                                label="Sales POC"
                                placeholder="Select Sales POC"
                                name="name"
                                inputclass='m-0 inter'
                                value={salesPOC ? allUser.find(i => i.value === salesPOC) : ''}
                                onChange={(value) => setSalesPOC(value.value)}
                                options={allUser}
                            />
                        </div>
                    </div>
                </div>
                <div className={`${style.Location} d-flex flex-fill flex-col pl-24  mt-36`}>
                    <div className='w-100'>
                        <div className={`${style.basic_form} basic_form`}>
                            <div className={`${style.col_span_2} border-bottom border-color`}>
                                <h1 className='fz18 black fw-po-semi inter mb4'>Developer Details</h1>
                            </div>

                            <div className={`form-group ${error ? 'invalid' : ''} position-relative`}>
                                <label>POC Name</label>
                                <input
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    type={"text"}
                                    placeholder='Enter POC Name'
                                    {...register('poc_name')}
                                />
                            </div>
                            <div className={`form-group ${error ? 'invalid' : ''} position-relative`}>
                                <label>POC Mobile</label>
                                <Controller
                                    control={control}
                                    name='poc_mobile'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <input
                                            className={error ? 'form-control text-input error' : 'form-control text-input'}
                                            type={"text"}
                                            placeholder='Enter POC Mobile'
                                            value={value}
                                            onChange={(e) => {
                                                onChange(
                                                    e.target.value
                                                        .replace(/^0+/g, '')
                                                        .replace(/[^0-9]/g, '')
                                                        .substring(0, 10)
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </div>

                            <div className={`form-group ${style.col_span_2}`}>
                                <label>Developer Description</label>
                                <textarea
                                    label='Developer Description'
                                    placeholder='Add short description'
                                    {...register('developer_description')}
                                    className='form-control text-input'
                                    rows="4"
                                    style={{
                                        minHeight: 'unset!important',
                                        important: true

                                    }}
                                ></textarea>
                            </div>

                            <div>
                                <div className='fz14 black-dark-700 mb-8 inter d-flex justify-content-between'>
                                    <span>Developer Logo</span>
                                    <span>PNG, 2MB</span>
                                </div>
                                <div className={`${style.upload}`}>
                                    {developerlogo && <img src={developerlogo} alt="" className='w-100 h-100' style={{ objectFit: 'contain' }} />}
                                    <input type="file" className='d-none' name='developer-logo' id='developer-logo' accept='image/*' onChange={handleOnChangeDeveloperLogo} />
                                    {!developerlogo && <div className={`${style.uplaodbtngrp}`}>
                                        <button
                                            type='button'
                                            className={`${style.uploadbtn} pr-btn inter flex items-center`}
                                            onClick={() => {
                                                document.getElementById('developer-logo').click()
                                            }}
                                        ><PluswhiteIcon />Upload</button>
                                    </div>}
                                    {developerlogo && <div className={`${style.hoverbox}`}>
                                        <button
                                            type='button'
                                            className={`${style.replacebtn} pr-btn inter flex items-center`}
                                            onClick={() => {
                                                document.getElementById('developer-logo').click()
                                            }}
                                        ><PluswhiteIcon />Replace</button>
                                        <button
                                            type='button'
                                            className={`${style.delbtn} inter border-0 bg-white`}
                                            onClick={() => setDeveloperLogo('')}
                                        ><TrashIcon /></button>
                                    </div>}
                                </div>
                                {/* {logoFileError && <p className='color-red fz10 mb-0 mt4 inter'>Invalid resolution. Please upload an image with dimensions of 900x400.</p>} */}
                                {logoDeveloperFileError && <p className='color-red fz10 mb-0 mt4 inter'>{logoDeveloperFileError}</p>}
                            </div>
                            <div></div>
                            {/* <div className={`form-group ${error ? 'invalid' : ''} position-relative`}>
                                <label>Bank Name</label>
                                <input
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    type={"text"}
                                    placeholder='e.g. State Bank Of India'
                                    {...register('bank_name')}
                                />
                            </div>
                            <div className={`form-group ${error ? 'invalid' : ''} position-relative`}>
                                <label>Bank Branch</label>
                                <input
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    type={"text"}
                                    placeholder='e.g. Mountroad, Chennai'
                                    {...register('bank_branch')}
                                />
                            </div>
                            <div className={`form-group ${error ? 'invalid' : ''} position-relative`}>
                                <label>Account No.</label>
                                <input
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    type={"text"}
                                    placeholder='Enter Bank Account Number'
                                    {...register('account_no')}
                                />
                            </div>
                            <div className={`form-group ${error ? 'invalid' : ''} position-relative`}>
                                <label>IFSC Code</label>
                                <input
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    type={"text"}
                                    placeholder='Enter Bank IFSC Code'
                                    {...register('ifsc')}
                                />
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className={`${style.Location} d-flex flex-fill flex-col pl-24 mt-24`}>
                    <div className='w-100'>
                        {offers?.length === 0 && <div className={`${style.basic_form} basic_form `}>

                            <div className={`${style.col_span_2} border-color d-flex align-items-center justify-content-between border-bottom `}>
                                <h1 className="fz18 black fw-po-semi inter mb-2">Project Offers</h1>
                            </div>

                            <div className={`${style.col_span_2} mb-2`}>
                                <button type='button' className='pr-outline-btn w-100' onClick={() => setShowOfferModal(true)}>+ Offers</button>
                            </div>
                        </div>}
                        {offers?.length > 0 && <div>
                            <div className={`${style.projecttitle} border-bottom border-color pt-30 pb-10 pr-6 d-flex align-items-center justify-content-between`}>
                                <h1 className='fz18 black fw-po-semi inter mb4'>Project Offers</h1>
                                <button type='button' className='pr-btn inter' onClick={() => setShowOfferModal(true)}><PlushIcon />Add</button>
                            </div>
                            <div className='d-flex flex-wrap mt-20 gap-y-14 gap-x-8 mb-4'>
                                {offers?.map((item, index) => (

                                    <div

                                        className={`${style.animitestags}`}
                                        key={`${item}_${index}`}

                                    >
                                        {item}
                                        <button className={`${style.closetagbtn}`} type='button' onClick={() => {
                                            const filteredList = offers.filter((offer, offerIndex) => offerIndex !== index);
                                            setOffers(filteredList)
                                        }}> <AnCloseIcon />
                                        </button>
                                    </div>
                                ))
                                }

                            </div>
                        </div>}
                    </div>
                </div>
                {(transactionType !== 'sale' || inventoryType === 'resale') &&
                    <div className={`${style.Location} ${style.basic_form} basic_form d-flex flex-fill flex-column pl-24 mt-24`}>
                        <div className='d-flex align-items-center justify-content-between border-bottom border-color'>
                            <h1 className='fz18 black fw-po-semi inter mb4'>Owner Details</h1>
                        </div>
                        <div className='w-100 mt-20'>
                            <div className={`${style.basic_form} basic_form`}>
                                <div className={`form-group ${error ? 'invalid' : ''} position-relative`}>
                                    <label>Owner Name</label>
                                    <input
                                        className={error ? 'form-control text-input error' : 'form-control text-input'}
                                        type={"text"}
                                        placeholder='Enter Owner Name'
                                        {...register('owner_name')}
                                    />
                                </div>
                                <div className={`form-group ${error ? 'invalid' : ''} position-relative`}>
                                    <label>Owner Mobile</label>
                                    <Controller
                                        control={control}
                                        name='owner_mobile'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <input
                                                className={error ? 'form-control text-input error' : 'form-control text-input'}
                                                type={"text"}
                                                placeholder='eg. 99999 99999'
                                                value={value}
                                                onChange={(e) => {
                                                    onChange(
                                                        e.target.value
                                                            .replace(/^0+/g, '')
                                                            .replace(/[^0-9]/g, '')
                                                            .substring(0, 10)
                                                    )
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                <div className={`${style.Location} d-flex flex-fill flex-col mb-5 pl-24 mt-36`}>
                    <div className='w-100'>
                        <div className={`${style.basic_form} basic_form `}>
                            <div className={`${style.col_span_2} border-bottom border-color`}>
                                <h1 className='fz18 black fw-po-semi inter mb4'>Brokerage Payout</h1>
                            </div>
                            <div className={`form-group ${error ? 'invalid' : ''} position-relative ${style.col_span_2}`}>

                                <div className='d-flex justify-content-between align-items-center mb-8'>
                                    <label className='mb-0'>Brokerage</label>
                                    {/* <div className='d-inline-flex align-items-center gap-8'>
                                        <input type="checkbox" {...register('brokerage_payout_is_private')} style={{ width: '16px', height: '16px' }} />
                                        <div className={`${style.privateinfo} border border-color radius-6 gap-4 d-inline-flex align-items-center`}>
                                            <LockIcon />
                                            <span>Private</span>
                                            <OverlayTrigger
                                                placement="left"
                                                overlay={
                                                    <Tooltip id="button-tooltip" >
                                                        <div className='info-msg'>                                                            
                                                            <p className=''>If checked, This field will only be visible to you.</p>
                                                        </div>
                                                    </Tooltip>
                                                }
                                            >
                                                <InfoIcon className='cursor-pointer' />
                                            </OverlayTrigger>

                                        </div>
                                    </div> */}
                                </div>
                                <textarea
                                    className={error ? 'form-control text-input error' : 'form-control text-input'}
                                    type={"text"}
                                    placeholder='e.g 5%'
                                    {...register('brokerage_payout')}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className={`${style.createprojectfooter} border-top border-color d-flex justify-content-between align-items-center`}>
                <button
                    type='button'
                    className={`${style.cancelbtn}`}
                    onClick={() => {
                        history.push(`/projects/create-projects/mediaupload/${uuid}?pt=${propertyType}&tt=${transactionType}`);
                    }}
                ><LeftIcon />Previous </button>
                <button type='submit' form='additioninfo' disabled={isSubmitting} className={`${style.savebtn}`}>
                    {projectStatus === 'new' ? 'Create New Project' : 'Update'}
                </button>
            </div>
            {showPaymentModal &&
                <PaymentPlanModal
                    getPaymentPlans={getPaymentPlans}
                    style={style}
                    show={showPaymentModal}
                    closeModal={() => {
                        setSelectedPaymentPlan({})
                        setShowPaymentModal(false)
                    }}
                    uuid={uuid}
                    selectedPaymentPlan={selectedPaymentPlan}
                />
            }
            {showLandMarkModal &&
                <NearbyLandmarksModal
                    show={showLandMarkModal}
                    closeModal={() => setShowLandMarkModal(false)}
                    style={style}
                    handleUpdateLandMarks={handleUpdateLandMarks}
                    previousLandmark={landMarks}
                />}
            {showOfferModal &&
                <OfferModal
                    show={showOfferModal}
                    closeModal={() => setShowOfferModal(false)}
                    style={style}
                    handleUpdateOffers={handleUpdateOffers}
                    previousOffer={offers}
                />
            }
            {showAdditionalModal &&
                <AdditionalCost
                    show={showAdditionalModal}
                    closeModal={() => {
                        setSelectedAdditionalDetail({});
                        setShowAdditionalModal(false)
                    }}
                    style={style}
                    uuid={uuid}
                    getAdditionCost={getAdditionCost}
                    selectedAdditionalDetail={selectedAdditionalDetail}
                />
            }
            {
                showBankDetailModal && <AddBankModal
                    getBankDetails={getBankDetails}
                    style={style}
                    show={showBankDetailModal}
                    closeModal={() => {
                        setSelectedBankDetail({})
                        setShowBankDetailModal(false)
                    }}
                    uuid={uuid}
                    selectedBankDetail={selectedBankDetail}
                />
            }
            {
                showLatestUpdate && <AddLatestUpdateModal
                    show={showLatestUpdate}
                    closeModal={() => {
                        setShowLatestUpdate(false);
                        setSelectedUpdate({});
                    }}
                    style={style}
                    projectUuid={uuid}
                    getUpdates={handleGetUpdates}
                    selectedUpdate={selectedUpdate}
                />
            }

        </div>
    )
}

export default AdditionalInfo;