import React, { useEffect } from 'react';
import { numberFormat } from '../../../helpers/helpers';

const InternalTabs = ({ selectedTab, setSelectedTab, tabList }) => {

    return (
        <div className='w-100 tabs-bottom-active-border'>
            <div class=" border-0 !mb-5 gap-x-6" id="nav-tab" role="tablist">
                <ul className="nav gap-x-6 nav-payment-milestone flex-nowrap" >
                    {tabList.map(item => (
                        <>
                            <li className="" key={item.name}>
                                <a
                                    className={`nav-link !py-1.5 !px-3 text-sm rounded-full relative font-medium hover:!text-primary bg-[#F1F1F6] hover:!bg-primary/10 hover:!border hover:!border-primary  ${selectedTab.name === item.name ? 'text-[#2064FA] hover:text-primary !bg-primary/10 !border !border-primary' : 'text-[#667085] hover:text-[#667085]'}`}
                                    onClick={() => setSelectedTab(item)}
                                >
                                    {item.name + ` - ${numberFormat(item.count)}`}
                                </a>
                            </li>
                            {/* {item.name === 'Site Visits' && <div className={`dropdown view-more !px-3 !py-1.5 flex items-center text-sm rounded-full relative font-medium hover:!text-primary bg-[#F1F1F6] hover:!bg-primary/10 hover:!border hover:!border-primary  ${selectedTab.name === item.name ? 'text-[#2064FA] hover:text-primary !bg-primary/10 !border !border-primary' : 'text-[#667085] hover:text-[#667085]'}`}>
                                <button
                                    className="inline-flex items-center justify-center "
                                    type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                >
                                    {item.name + ` - ${numberFormat(item.count)}`}
                                    <svg
                                        className="w-4 h-4 ml-2"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 01.02-1.06z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                                <div className="dropdown-menu dropdown-menu-right !p-2" aria-labelledby="dropdownMenuButton">
                                    {
                                        item?.childern.map((ch, index) => (
                                            <a className="dropdown-item" key={ch.value} onClick={() => setSelectedTab(item)}>
                                                {ch.name}
                                            </a>
                                        ))
                                    }

                                </div>
                            </div>} */}
                        </>
                    ))}
                </ul>
            </div>
        </div>
    )
}
export default InternalTabs;