import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import TooltipText from '../../components/Tooltip'
import { useHistory } from 'react-router-dom'
import InputSelect from '../../components/InputGroup/InputSelect';
import Consumer from '../../helpers/context';
import { ReactComponent as ExpiryInfoIcon } from '../../assets/icons/expiry_info.svg';
import { ReactComponent as RedInfoIcon } from '../../assets/icons/red_info.svg';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DynamicIntent from '../../components/Custom Table/TableColumns/DynamicIntent';
import SourceLabel from '../../components/Custom Table/TableColumns/SourceLabel';
import StatusDropdown from '../New Dashboard/Components/StatusDropdown';
import { getSelectedValue } from '../../utils/selectedOptionUtils';
import { createDatewithTime } from '../../helpers/date';
import NotesDropDown from '../../components/Custom Table/TableColumns/NotesDropDown';
import ReengagedSource from '../../components/Custom Table/TableColumns/ReengagedSource';
import { MAP_CURRENCY_NUMBER_TO_TEXT } from '../../utils/constants';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';

const SVTYPES = {
    offline: 'Onsite',
    online: 'Video Call'
}

const getClassName = (exist) => {
    switch (exist) {
        case 'yes':
            return 'hot-btn'
        case 'no':
            return 'dead-btn'
    }
}

const RenderTableData = ({
    item,
    lead,
    setSelectedImg,
    setShowLightModal,
    allUsers,
    handleCheckLeadExist,
    handleSendInvite,
    redirectURL,
    redirectBactTo,
    statuses,
    intentStatuses,
    sourceStatuses,
    onSuccess,
    agencyList,
    getConflict,
    handleAssignTagging,
    tab,
    subTab,
    handleUpdateAssignedTO,
}) => {
    const [show, setShow] = useState(false);

    const target = useRef(null);
    const history = useHistory();

    let { allowedPermissions, userProfile } = useContext(Consumer);

    const handleOutsideClick = (e) => {
        if (target.current && !target.current.contains(e.target)) {
            setShow(false);

        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick)
        return (() => document.removeEventListener('mousedown', handleOutsideClick))
    }, []);

    const rendering = () => {
        switch (item.label) {
            case 'Name':
                return <td className='!py-2 sticky-td'>
                    <div className="text-capitalize z-10 flex items-center">
                        <Link to={`/${redirectURL}/${lead?.lead_uuid}?redirect=${redirectBactTo}`} className='text-black-t'
                            onClick={() => {
                                localStorage.setItem('tab', tab);
                                localStorage.setItem('subTab', subTab);
                            }}
                        >
                            {lead?.name?.length > 50 ? <div className='w-[100px] truncate'>
                                <TooltipText title={lead?.name}>
                                    {lead?.name || '-'}
                                </TooltipText>
                            </div> : lead?.name}
                        </Link>
                        {/* {(!lead.broker_company_id && lead.company_owner_mobile && lead.company_owner_name) && <div className='view-notes sv-info !ml-2'>
                        {lead.customer_name?.length > 50 ? <div className='w-[100px] truncate'>
                            <TooltipText title={lead.customer_name}>
                                {lead.customer_name || '-'}
                            </TooltipText>
                        </div> : lead.customer_name} */}
                        {(!lead.broker_company_id && lead.company_owner_mobile && lead.company_owner_name) && <Button className='bg-transparent border-0' ref={target} onClick={() => {
                            setShow(!show)
                        }}>
                            <ExpiryInfoIcon />
                        </Button>}
                        <Overlay target={target.current} show={show} placement="right">
                            {({
                                placement: _placement,
                                arrowProps: _arrowProps,
                                show: _show,
                                popper: _popper,
                                hasDoneInitialMeasure: _hasDoneInitialMeasure,
                                ...props
                            }) => (
                                <div
                                    {...props}
                                    style={{
                                        position: 'absolute',
                                        top: '54px',
                                        left: '-12px',
                                        backgroundColor: 'rgba(255, 100, 100, 0.85)',
                                        color: 'white',
                                        borderRadius: 3,
                                        ...props.style,
                                    }}
                                    className="info_box !border !border-red !rounded-lg !px-2.5 !py-1.5 !top-[54px] !-left-3"
                                >
                                    <div className="notes drop-notes flex items-center ">
                                        <div className="left-icon active:!bg-transparent">
                                            <RedInfoIcon />
                                        </div>
                                        <div className="right-text !ml-2">
                                            <p className='text-[#344054] text-xs mb4 ro'>CP of this customer is not registered with us</p>
                                        </div>
                                    </div>
                                    <div className='w-full flex justify-end !mt-2'>
                                        <button
                                            className='text-white !bg-[#2064FA] focus:!bg-[#2064FA] focus:!text-white rounded-full !px-2.5 !py-1.5'
                                            onClick={() => handleSendInvite(lead)}
                                        >
                                            Invite CP
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Overlay>
                        {/* {(!lead.broker_company_id && lead.company_owner_mobile && lead.company_owner_name) && <div className='view-notes sv-info !ml-2'>
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <ExpiryInfoIcon />
                            </button>
                            <div className="dropdown-menu info_box !border !border-red" aria-labelledby="dropdownMenuButton">

                                <div className="notes drop-notes items-center ">
                                    <div className="left-icon active:!bg-transparent">
                                        <RedInfoIcon />
                                    </div>
                                    <div className="right-text">
                                        <p className='text-[#344054] text-xs mb4 ro'>CP of this customer is not registered with us</p>
                                    </div>
                                </div>
                                <div className='w-full flex justify-end !mt-2'>
                                    <button
                                        className='text-white !bg-[#2064FA] focus:!bg-[#2064FA] focus:!text-white rounded-full !px-2.5 !py-1.5'
                                        onClick={() => handleSendInvite(lead)}
                                    >
                                        Invite CP
                                    </button>
                                </div>
                            </div>
                        </div>} */}
                    </div>
                </td>
            case 'Mobile No.':
                return <td className='!py-2'>{lead?.customer_mobile || '-'}</td>
            case 'Email':
                return <td className='!py-2'>{lead?.customer_email || '-'}</td>
            case 'Scheduled Date':
                return <td className='!py-2'>{lead?.schedule_date ? moment(lead?.schedule_date).format("h:mm a, DD MMM YY") : '-'}</td>
            case 'SV Type':
                return <td className='!py-2 capitalize'>{lead?.sv_type || '-'}</td>
            case 'CP Firm':
                return <td className='!py-2'>
                    {/* <button onClick={() => {
                    history.push(`/cp-manager/users/${lead?.cp_firm_uuid}?company=${lead.company_name}&id=${lead.cp_firm_id}`)
                }}>
                    {lead.company_name || '-'}
                </button> */}
                    {+userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID &&
                        userProfile?.allowed_modules?.includes('plus_app_management') &&
                        allowedPermissions?.includes('plus_app_manage_users') && lead?.company_name ? <>
                        <button onClick={() => {
                            if (lead.company_name) {
                                window.open(`/cp-manager/users/${lead?.cp_firm_uuid}?company=${lead.company_name}&id=${lead.cp_firm_id}`)
                            }
                        }}>
                            {lead?.company_name?.length > 20 ?
                                <TooltipText title={lead?.company_name}>
                                    <div className='w-[100px] truncate'>
                                        {lead?.company_name}
                                    </div>
                                </TooltipText>
                                : lead?.company_name
                            }
                        </button>
                    </> :
                        (lead?.broker_company_name || lead?.company_name) && <div className='flex'>
                            {(lead?.broker_company_name || lead?.company_name)?.length > 20 ?
                                <TooltipText title={lead?.broker_company_name || lead?.company_name}>
                                    <div className='w-[100px] truncate'>
                                        {lead?.broker_company_name || lead?.company_name}
                                    </div>
                                </TooltipText>
                                : lead?.broker_company_name || lead?.company_name}

                            {(!lead.broker_company_id && lead.company_owner_mobile && lead.company_owner_name) && <div className='view-notes sv-info !ml-2'>
                                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <ExpiryInfoIcon />
                                </button>
                                <div className="dropdown-menu info_box !border !border-red" aria-labelledby="dropdownMenuButton">

                                    <div className="notes drop-notes items-center ">
                                        <div className="left-icon active:!bg-transparent">
                                            <RedInfoIcon />
                                        </div>
                                        <div className="right-text">
                                            <p className='text-[#344054] text-xs mb4 ro'>CP of this customer is not registered with us</p>
                                        </div>
                                    </div>
                                    <div className='w-full flex justify-end !mt-2'>
                                        <button
                                            className='text-white !bg-[#2064FA] focus:!bg-[#2064FA] focus:!text-white rounded-full !px-2.5 !py-1.5'
                                            onClick={() => handleSendInvite(lead)}
                                        >
                                            Invite CP
                                        </button>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    }
                    {
                        (!lead?.company_name && !lead?.broker_company_name && '-')
                    }
                </td>
            case 'CP User':
                return <td className='!py-2'>{lead?.broker_name || '-'}</td>
            case 'CP Owner Mobile':
                return <td className='!py-2'>{lead.company_owner_mobile || '-'}</td>
            case 'CP User Mobile':
                return <td className='!py-2'>{lead.broker_mobile || '-'}</td>
            case 'CP Email':
                return <td className='!py-2'>
                    {(lead?.company_owner_email && lead?.company_owner_email?.length > 20) ?
                        <TooltipText title={lead?.company_owner_email}>
                            <div className='w-[100px] truncate'>
                                {lead?.company_owner_email}
                            </div>
                        </TooltipText>
                        : (lead?.company_owner_email || '-')}
                    {/* {lead?.company_owner_email || '-'} */}
                </td>
            case 'CP User Registered':
                return <td className='!py-2'>{lead.broker_company_id ? 'Yes' : 'No'}</td>
            case 'Project':
                return <td className='!py-2'>{lead?.project_name || '-'}</td>
            case 'Remarks':
                return <td className='!py-2'>
                    {lead?.remarks ? <TooltipText title={lead?.remarks}>
                        <div className='w-[200px] truncate'>
                            {
                                lead?.remarks
                            }
                        </div>
                    </TooltipText> : '-'}
                </td>
            case 'Lead Creation Date':
                return <td className='!py-2'>{lead?.created_lead_time ? moment(lead?.created_lead_time).format("h:mm a, DD MMM YY") : '-'}</td>
            case 'Lead Creation':
                return <td className='!py-2'>
                    {lead?.create_lead_by ? <a className={`border text-textblack block !w-[117px] !py-2 px-3 rounded-lg hover:bg-black700/10  `} onClick={() => {
                        window.open(`/leads/${lead.lead_uuid}`)
                    }}>
                        View Lead
                    </a> :
                        <a className={`border border-primary text-primary !py-2 px-3 rounded-lg hover:bg-primary/10`} onClick={() => {
                            handleCheckLeadExist(lead, 'triple')
                        }}>
                            Create Lead
                        </a>
                    }
                </td>
            case 'Lead Created By':
                return <td className='!py-2'>{lead?.create_lead_by || '-'}</td>
            case 'Address':
                return <td className='!py-2'>
                    {lead?.customer_address ? <TooltipText title={lead?.customer_address}>
                        <div className='w-[200px] truncate'>
                            {
                                lead?.customer_address
                            }
                        </div>
                    </TooltipText> : '-'}
                </td>
            case 'Profession':
                return <td className='!py-2'>{lead?.customer_profession || '-'}</td>
            case 'Company':
                return <td className='!py-2'>{lead?.customer_company || '-'}</td>
            case 'Designation':
                return <td className='!py-2'>{lead?.customer_designation || '-'}</td>
            case 'Budget':
                return <td className='!py-2'>
                    {`${lead.min_budget ? `₹ ${MAP_CURRENCY_NUMBER_TO_TEXT[lead.min_budget]} ${lead.max_budget ? `- ${MAP_CURRENCY_NUMBER_TO_TEXT[lead.max_budget]}` : ''}` : '-'}`}
                </td>
            case 'View Photo':
                return <td className='!py-2'>
                    {lead.image ? <a className={`border text-textblack block w-max !py-2 px-3 rounded-lg hover:bg-black700/10  `}
                        onClick={() => {
                            setSelectedImg(lead);
                            setShowLightModal(true)
                        }}
                    >
                        View
                    </a> : '-'}
                </td>
            case 'Purpose':
                return <td className='!py-2 capitalize' >{lead?.purpose || '-'}</td>
            case 'Possession Preference':
                return <td className='!py-2'>{lead?.possession_pref || '-'}</td>
            case 'Reference Name':
                return <td className='!py-2'>{lead.reference_name || '-'}</td>
            case 'Reference Mobile':
                return <td className='!py-2'>{lead.reference_mobile || '-'}</td>
            case 'Reference Society':
                return <td className='!py-2'>{lead.reference_society || '-'}</td>
            case 'Reference Flat No':
                return <td className='!py-2'>{lead.reference_flat_no || '-'}</td>
            case 'Alt. Mobile':
                return <td className='!py-2'>{lead?.customer_alternate_number || '-'}</td>
            case 'Closing Manager':
                return <td className='!py-2'>
                    <div className='w-[220px]'>
                        <InputSelect
                            placeholder={'Select Closing Manager'}
                            name='assignTo'
                            options={allUsers}
                            value={lead?.closing_user_id ? {
                                label: lead?.closing_user_name,
                                value: lead?.closing_user_id
                            } : ''}
                            onChange={(e) => {
                                // const leadList = [...leads]
                                // leadList[index]['assigned_to'] = e.value
                                // setEvents(leadList)
                                if (!lead?.is_conflict) {
                                    handleAssignTagging(e, lead)
                                } else {
                                    getConflict(e, lead)
                                }
                                // handleCheckLeadExist(lead, 'table', e.value)
                            }}
                            isMulti={false}
                        />
                    </div>
                </td>
            case 'Sourcing Manager':
                return <td className='!py-2'>
                    {lead?.sourcing_manager || '-'}
                </td>
            case 'Lead Exist':
                return <td className='!py-2'>
                    <span className={`${(lead?.is_lead_existed || lead?.lead_uuid) ? getClassName('yes') : getClassName('no')} `} >
                        {(lead?.is_lead_existed || lead?.lead_uuid) ? 'Yes' : 'No'}
                    </span>
                </td>
            case 'Created lead time':
                return <td className='!py-2'>{lead?.created_lead_time ? moment(lead?.created_lead_time).format("h:mm a, DD MMM YY") : '-'}</td>
            case 'SV Complete Source':
                return <td className='!py-2'>{lead?.about_project || '-'}</td>
            case 'Completed Time':
                return <td className='!py-2'>{lead?.completed_time ? moment(lead?.completed_time).format("h:mm a, DD MMM YY") : '-'}</td>
            case 'Completed By':
                return <td className='!py-2'>{lead?.completed_by || '-'}</td>
            case 'Preference':
                return <td className='!py-2'>{lead?.preference || '-'}</td>
            case 'Property Type':
                return <td className='!py-2' >{lead.property_type || '-'}</td>
            // return <td className='!py-2' >{lead.property_category || '-'}</td>
            case 'Configuration Type':
                return <td className='!py-2' >
                    {lead.configuration ? <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="button-tooltip" >
                                <div className='info-msg'>
                                    {/* <h4>Total Leads</h4>*/}
                                    <p className=''>{lead.configuration}</p>
                                </div>
                            </Tooltip >
                        }
                    >
                        <span className='three-dots cursor-pointer'>{lead.configuration}</span>
                    </OverlayTrigger > : '-'}
                </td>
            case 'Size':
                return <td className='!py-2' >
                    {lead.carpet_area ? <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="button-tooltip" >
                                <div className='info-msg'>
                                    {/* <h4>Total Leads</h4>*/}
                                    <p className=''>{`${lead.carpet_area}  ${lead.carpet_area_unit}`}</p>
                                </div>
                            </Tooltip >
                        }
                    >
                        <span className='three-dots cursor-pointer'>{lead.carpet_area ? `${lead.carpet_area}  ${lead.carpet_area_unit}` : '-'}</span>
                    </OverlayTrigger > : '-'}
                </td>
            case 'Requirement Type':
                return <td className='!py-2' >{lead.property_category || '-'}</td>
            case 'Funding Source':
                return <td className='!py-2' >{lead.funding_source || '-'}</td>
            case 'Employment Type':
                return <td className='!py-2' >{lead.employement_type || '-'}</td>
            case 'Facing':
                return <td className='!py-2' >{lead.facing || '-'}</td>
            case 'Profession':
                return <td className='!py-2' >{lead.profession || '-'}</td>
            case 'Referred By':
                return <td className='!py-2' >{lead.referred_by || '-'}</td>
            case 'Referred By Number':
                return <td className='!py-2' >{lead.referred_by_number || '-'}</td>
            case 'Referred By Society':
                return <td className='!py-2' >{lead.referred_by_society || '-'}</td>
            case 'Referred By Flat No':
                return <td className='!py-2' >{lead.referred_by_flat_no || '-'}</td>
            case 'Site Visit Date':
                return <td className='!py-2'>
                    <a bsstyle="default" className={`${(moment(lead.schedule_date) < moment()) ? "red-color" : "text-black "}`}
                        onClick={() => {
                            // selectCurrentLead(lead)
                            // const statusVal = getSelectedValue(lead.status, statuses)
                            // if (statusVal.label === 'SV Completed') {
                            //   showSvCompleteModal(true)
                            // } else {
                            //   showSiteVisitModal(true)
                            // }
                        }}>
                        {lead?.schedule_date ? createDatewithTime(new Date(lead.schedule_date)) : '-'}
                    </a>
                    {/* <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="button-tooltip" className='' >
                                <div className='info-msg'>
                                  <p className='d-flex fz18'>Edit Date</p>
                                </div>
                              </Tooltip>
                            }
                          >
                            <a bsstyle="default" className={`${(moment(lead.sv_schedule_date) < moment() && !data.completed_time) ? "red-color" : "text-black "} cursor-pointer`}
                              onClick={() => {
                                selectCurrentLead(data)
                                const statusVal = getSelectedValue(data.status, statuses)
                                if (statusVal.label === 'SV Completed') {
                                  showSvCompleteModal(true)
                                } else {
                                  showSiteVisitModal(true)
                                }
                              }}>
                              {data.completed_time ? createDatewithTime(new Date(data.completed_time)) : createDatewithTime(new Date(data.sv_schedule_date))}
                            </a>
                          </OverlayTrigger> */}
                </td>
            case 'Status':
                return <td className='!py-2' > <StatusDropdown
                    value={getSelectedValue(lead.status, statuses)}
                    options={statuses}
                    id={lead.id}
                    reloadData={() => { }}
                    leadName={lead.name}
                    intentOptions={intentStatuses}
                    data={lead}
                    onSuccess={onSuccess}
                    handleOpenVerifyPage={() => { }}
                />
                </td>
            case 'Intent':
                return <td className='!py-2' >
                    <DynamicIntent
                        selectedIntent={lead.intent_id}
                        intentOptions={intentStatuses}
                        reloadData={onSuccess}
                        id={lead.id}
                        data={lead}
                        status={getSelectedValue(lead.status_id, statuses)}
                        statusName={lead.status}
                        rowLength={lead.length}
                        tableName='AllLead'
                    />
                </td>
            case 'Source':
                return (
                    <td className='!py-2'>
                        <SourceLabel
                            allSources={sourceStatuses}
                            sources={lead.source}
                        />
                    </td>)

            case 'City':
                return <td className='!py-2' >{lead.city ? lead.city : '-'}</td>
            case 'Last Activity Date':
                return <td className='!py-2' >{
                    lead?.last_activity_date ? createDatewithTime(new Date(lead?.last_activity_date)) : '-'
                }</td>
            case 'Tag Expiry':
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0); // Set current time to midnight

                const expiryDate = new Date(lead?.tag_expiry);
                expiryDate.setHours(0, 0, 0, 0); // Set expiry time to midnight

                // Older moment method 
                // return <td className='!py-2'>{moment(lead?.tag_expiry).diff(moment(), 'days') > 0 ? moment(lead?.tag_expiry).diff(moment(), 'days') + ' days' : lead?.last_tag_cp_user ? 'Expired' : '-'}</td>
                return <td className='!py-2' >
                    {moment(lead?.tag_expiry).diff(moment(), 'days') > 0 ?
                        Math.floor(
                            (expiryDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24),) + ' days' :
                        lead?.last_tag_cp_user ? 'Expired' : '-'}
                </td>
            case 'Last Activity':
                return <td className='!py-2' class="!px-3 !whitespace-nowrap !py-2 capitalize" scope='col' >
                    {
                        lead?.last_activity_type ? `${lead?.last_activity_type} ${lead?.last_activity_value}` : '-'
                    }
                </td>
            case 'Last Event':
                return <td className='!py-2' >{lead?.last_activity_value || '-'}</td>
            case 'Presales Manager':
                return <td className='!py-2' >{lead?.presales_manager || '-'}</td>
            case 'Sourcing Manager':
                return <td className='!py-2' >{lead?.sourcing_manager || '-'}</td>
            // case 'Closing Manager':
            //     return <td className='!py-2' >{lead?.closing_manager || '-'}</td>
            case 'Followup Date':
                return <td className='!py-2' class={` ${(moment(lead.followup_date) < moment()) ? "text-red" : ''} !px-3 !whitespace-nowrap !py-2`} scope='col'>{
                    lead?.followup_date ? createDatewithTime(new Date(lead?.followup_date)) : '-'
                }</td>
            case 'Preferred Location':
                return <td className='!py-2'>
                    {lead.preferred_location ? <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="button-tooltip" >
                                <div className='info-msg'>
                                    {/* <h4>Total Leads</h4>*/}
                                    <p className='text-capitalize'>{lead.preferred_location}</p>
                                </div>
                            </Tooltip >
                        }
                    >
                        <span className='three-dots cursor-pointer'>{lead.preferred_location}</span>
                    </OverlayTrigger > : '-'}
                </td>
            case 'RM':
                return <td className='!py-2'>{lead?.rm_name || '-'}</td>
            case 'Note':
                return (
                    <td className='!py-2'>
                        <div className='view-notes'>
                            <NotesDropDown
                                note={lead.remarks || lead.completed_notes || lead.sv_remarks || lead.followup_remarks}
                                date={lead['Event Created Date']}
                            />
                        </div>
                    </td>)
            case 'Assigned User':
                return <td className='!py-2'>
                    {lead.assigned_to?.length > 20 ?
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="button-tooltip" className='leadmanager-tooltip'>
                                    <div className='info-msg'>
                                        {lead.assigned_to || '-'}
                                    </div>
                                </Tooltip>
                            }
                        >

                            <span className='three-dots'>{lead.assigned_to || '-'}</span>
                        </OverlayTrigger>
                        :
                        <span className=''>{lead.assigned_to || '-'}</span>
                    }

                </td>
            case 'Marketing Agency':
                return agencyList?.length > 0 && <td className='!py-2 text-capitalize'>
                    {(lead?.landing_page_agency_name || lead?.fb_agency_name) ? <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="button-tooltip" >
                                <div className='info-msg'>
                                    {/* <h4>Total Leads</h4>*/}
                                    <p className=''>{lead?.landing_page_agency_name || lead?.fb_agency_name || '-'}</p>
                                </div>
                            </Tooltip >
                        }
                    >
                        <span className='three-dots cursor-pointer'>{lead?.landing_page_agency_name || lead?.fb_agency_name || '-'}</span>
                    </OverlayTrigger > : '-'}
                </td>
            case 'Created Date':
                return <td className='!py-2'>{lead.created_date ? createDatewithTime(new Date(lead.created_date)) : '-'}</td>
            case 'Possession':
                return <td className='!py-2'>{lead.possession || '-'}</td>
            case 'Customer Address':
                return <td className='!py-2'>
                    {lead.current_residence ? <OverlayTrigger
                        placement="bottom"
                        overlay={
                            <Tooltip id="button-tooltip" >
                                <div className='info-msg'>
                                    {/* <h4>Total Leads</h4>*/}
                                    <p className='text-capitalize'>{lead.current_residence || '-'}</p>
                                </div>
                            </Tooltip >
                        }
                    >
                        <span className='three-dots cursor-pointer'>{lead.current_residence || '-'}</span>
                    </OverlayTrigger> : '-'}
                </td>
            case 'Industry':
                return <td className='!py-2'>{lead?.industry || '-'}</td>
            case 'Re-Engaged Sources':
                return <td className='!py-2'>
                    {(lead?.is_re_engaged || lead?.re_engaged_sources?.length) ? <ReengagedSource uuid={lead.id} sources={lead?.re_engaged_sources || []} /> : '-'}
                </td>
            case 'Presale User':
                return <td className='!py-2' >{lead?.presales_manager || '-'}</td>
            case 'Cancel Reason':
                return <td className='!py-2'>
                    {lead?.cancel_reason?.length > 50 ? <div className='w-[100px] truncate'>
                        <TooltipText title={lead?.cancel_reason}>
                            {lead?.cancel_reason || '-'}
                        </TooltipText>
                    </div> : lead?.cancel_reason || '-'}
                </td>
        }
    }

    return <>{rendering()}</>;
}

export default RenderTableData;
