import React, { Component } from 'react';
import Select from 'react-select';
import TooltipText from '../../../../../components/Tooltip';

export default class AreaInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode: false,
            areaVal: '',
            key: this.props.inputKey,
            key2: this.props.inputKey2,
            unit: '',
            options: [
                { label: 'Sqft', value: 'sqft' },
                { label: 'Sq yard', value: 'sq yard' },
                { label: 'Sqm', value: 'sqm' },
                { label: 'Yards', value: 'yards' },
                { label: 'Acres', value: 'acres' },
                { label: 'Hectares', value: 'hectares' },
                { label: 'Gaj', value: 'gaj' },
                { label: 'Biga', value: 'biga' },
            ]
        }
    }

    saveHandler = (e) => {
        e.preventDefault();
        // if (this.props.carpetArea && this.props.carpetAreaUnit) {
        const val = {
            key1: 'carpet_area',
            value1: this.props.carpetArea,
            key2: 'carpet_area_unit',
            value2: this.props.carpetAreaUnit
        }
        this.props.onSave(val)
        // this.props.editDeactive();
        // }

    }

    inputChangeHandler = (value) => {
        this.setState({
            inputValue: value
        });
    }

    resetForm = (e) => {
        e.preventDefault();
        this.setState({ editMode: false })
        this.props.setEditActive(false);

    }

    getEditButton = () => {
        if (this.props.isEditable) {
            return <button onClick={() => { this.setState({ editMode: true, inputValue: this.props.carpetArea || '' }); this.props.onEditClick() }}
                className={`ml-8 border-0 bg-tr pr-hover ${this.props.editActive ? 'd-none' : ''}`}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <rect x="0.5" y="0.5" width="27" height="27" rx="3.5" fill="white" stroke="#E2E2EA" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M18.3957 10.6971L19.2318 9.86101L17.389 8.01821L16.5529 8.85428L18.3957 10.6971ZM17.3406 11.7522L15.4978 9.90936L8.60424 16.803L8.1801 19.0699L10.447 18.6458L17.3406 11.7522ZM20.3314 8.85049C20.8895 9.40859 20.8895 10.3134 20.3314 10.8715L11.3388 19.8642C11.2324 19.9705 11.0964 20.0423 10.9485 20.0699L7.38362 20.7369C6.86812 20.8334 6.41664 20.3819 6.51308 19.8664L7.18006 16.3015C7.20772 16.1536 7.27946 16.0176 7.38584 15.9112L16.3785 6.91857C16.9366 6.36048 17.8414 6.36048 18.3995 6.91857L20.3314 8.85049ZM20.0074 20.75H13.9926C13.0025 20.75 13.0025 19.25 13.9926 19.25H20.0074C20.9975 19.25 20.9975 20.75 20.0074 20.75Z" fill="#414346" />
                </svg>
            </button>
        }
    }
    render() {
        return <>
            {!this.props.editActive && <div className='main-box'>
                <div className="name-box">
                    <div className="row">
                        <div className="col-md-10 col-10 ">
                            <h3 className={` ${this.props.isNewVersion ? 'text-sm' : 'fz16'} mb-6`}>{this.props.heading}</h3>
                            <h4 className={`${this.props.isNewVersion ? 'text-sm' : 'fz16'} mb-6`}>
                                {this.props.carpetArea && this.props.carpetAreaUnit ? this.props.carpetArea + ' ' + this.props.carpetAreaUnit : '-'}
                            </h4>
                        </div>
                        <div className="col-md-2 col-2 pl-0 d-flex align-items-center justify-content-center">
                            {(this.props.userProfile?.allowed_modules?.includes('lead_sharing') && this.props.leadAccess === 'view') ?
                                <TooltipText title={"You don't have permission"}>
                                    <button
                                        className={`border-0 bg-tr pr-hover ${this.props.editActive ? 'd-none' : ''} ${(this.props.userProfile?.allowed_modules?.includes('lead_sharing') && this.props.leadAccess === 'view') ? 'cursor-not-allowed' : ''}`}
                                        disabled={(this.props.userProfile?.allowed_modules?.includes('lead_sharing') && this.props.leadAccess === 'view')}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                            <rect x="0.5" y="0.5" width="27" height="27" rx="3.5" fill="white" stroke="#E2E2EA" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M18.3957 10.6971L19.2318 9.86101L17.389 8.01821L16.5529 8.85428L18.3957 10.6971ZM17.3406 11.7522L15.4978 9.90936L8.60424 16.803L8.1801 19.0699L10.447 18.6458L17.3406 11.7522ZM20.3314 8.85049C20.8895 9.40859 20.8895 10.3134 20.3314 10.8715L11.3388 19.8642C11.2324 19.9705 11.0964 20.0423 10.9485 20.0699L7.38362 20.7369C6.86812 20.8334 6.41664 20.3819 6.51308 19.8664L7.18006 16.3015C7.20772 16.1536 7.27946 16.0176 7.38584 15.9112L16.3785 6.91857C16.9366 6.36048 17.8414 6.36048 18.3995 6.91857L20.3314 8.85049ZM20.0074 20.75H13.9926C13.0025 20.75 13.0025 19.25 13.9926 19.25H20.0074C20.9975 19.25 20.9975 20.75 20.0074 20.75Z" fill="#414346" />
                                        </svg>
                                    </button>
                                </TooltipText> :
                                this.getEditButton()}
                        </div>
                    </div>
                </div>

            </div>
            }

            {/* edit input */}
            {this.props.editActive && <div className={`edit-input-modal active`}>
                <form className='form-group mb-0'>
                    <div className="row ">
                        <div className="col-8 pr-0">
                            <h3 className="fz16 mb-6">{this.props.heading}</h3>
                        </div>
                        <div className="col-4 d-flex align-itmes-center">
                            <button onClick={(e) => this.saveHandler(e)} className='border-0 ml4 bg-tr px-2'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <rect width="20" height="20" rx="4" fill="#0062FF" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M5.58613 9.97668C5.25907 9.65288 4.73144 9.65552 4.40764 9.98259C4.08383 10.3097 4.08648 10.8373 4.41354 11.1611L7.60133 14.3005C7.94405 14.6398 8.50236 14.6182 8.81787 14.2535L15.6301 6.37854C15.9312 6.03047 15.8931 5.5042 15.545 5.2031C15.197 4.902 14.6707 4.94008 14.3696 5.28815L8.14051 12.489L5.58613 9.97668Z" fill="white" />
                            </svg></button>
                            <button onClick={(e) => this.resetForm(e)} className='border-0 ml4 bg-tr'><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z" fill="#B5B5BE" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.1761 10.0006L15.5737 14.4021C15.899 14.7277 15.8987 15.2553 15.5731 15.5806C15.2476 15.9059 14.7199 15.9057 14.3946 15.5801L9.99674 11.1782L5.58865 15.5798C5.26298 15.905 4.73534 15.9046 4.41014 15.5789C4.08495 15.2532 4.08534 14.7256 4.41102 14.4004L8.81876 9.99919L4.41288 5.58931C4.08759 5.26373 4.08783 4.73609 4.41341 4.4108C4.739 4.08551 5.26663 4.08575 5.59192 4.41134L9.99815 8.82155L14.3948 4.43145C14.7205 4.10625 15.2481 4.10664 15.5733 4.43232C15.8985 4.758 15.8981 5.28564 15.5724 5.61083L11.1761 10.0006Z" fill="white" />
                            </svg></button>
                        </div>
                    </div>
                    <div className='form-group position-relative search-input'>
                        <div className='position-relative d-flex'>
                            <input
                                className=' px-2 w-50 mr-2'
                                type='text'
                                min="0"
                                placeholder={this.props.placeholder}
                                value={this.props.carpetArea || ''}
                                pattern="[0-9]+"
                                onChange={(e) => {
                                    this.props.onChangeCarpetArea(
                                        e.target.value
                                            .replace(/^0+/g, '')
                                            .replace(/[^0-9]/g, ''))
                                }}
                            />
                            {/* <div className="close-icon"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M10.4189 4.43106L11.0692 3.78078L9.63587 2.3475L8.9856 2.99777L10.4189 4.43106ZM9.59827 5.25168L8.16498 3.81839L2.80329 9.18008L2.4734 10.9432L4.23658 10.6134L9.59827 5.25168ZM11.9244 2.99482C12.3585 3.4289 12.3585 4.13267 11.9244 4.56674L4.93017 11.561C4.84743 11.6438 4.7416 11.6995 4.62658 11.7211L1.85391 12.2398C1.45298 12.3148 1.10182 11.9637 1.17683 11.5627L1.69559 8.79008C1.71711 8.67506 1.7729 8.56923 1.85565 8.48648L8.84991 1.49222C9.28398 1.05815 9.98776 1.05815 10.4218 1.49222L11.9244 2.99482ZM11.6724 12.25H6.99421C6.22414 12.25 6.22414 11.0833 6.99421 11.0833H11.6724C12.4425 11.0833 12.4425 12.25 11.6724 12.25Z" fill="#92929D"></path></svg></div> */}
                            <Select
                                maxMenuHeight={'150px'}
                                menuPosition={'fixed'}
                                menuPlacement="top"
                                className='w-50'
                                options={this.state.options}
                                value={this.state.options.find(op => op.value === this.props.carpetAreaUnit)}
                                onChange={value => this.props.onChangeCarpetAreaUnit(value.value)}
                            // defaultValue={{ label: this.state.options[0].label, value: this.state.options[0].value }}
                            />
                        </div>
                    </div>
                </form>
            </div>}
        </>
    }
}