import { API_URLS, API_METHOD } from '../../config/apiUrl';
import request from '../../helpers/requests';

export async function insertNews(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customer.insertNews}`, null, payload, null);
}

export async function updateNews(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customer.updateNews}/${uuid}`, null, payload, null);
}

export async function getAllNewsCRM() {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customer.getAllNewsCRM}`, null, null, null);
}

export async function getNewsCRM(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.customer.getNewsCRM}/${uuid}`, null, null, null);
}

export async function deleteNews(uuid) {
    return await request(API_METHOD.DELETE, `${API_URLS.baseurl}${API_URLS.customer.deleteNews}/${uuid}`, null, null, null);
}

export async function getAppCustomers(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customer.getAppCustomers}`, null, payload, null);
}

export async function getNewsTags() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.customer.getNewsTags}`, null, null, null);
}

export async function addReferralIncentiveCreative(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customer.addReferralIncentiveCreative}`, null, payload, null);
}

export async function updateReferralIncentiveCreativeByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customer.updateReferralIncentiveCreativeByUUID}/${uuid}`, null, payload, null);
}

export async function getAllCustomerReferrals(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customer.getAllCustomerReferrals}`, null, payload, null);
}
export async function getReferralIncentiveCreative(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customer.getReferralIncentiveCreative}`, null, payload, null);
}
export async function toggleReferralIncentiveCreativeStatusByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customer.toggleReferralIncentiveCreativeStatusByUUID}/${uuid}`, null, payload, null);
}

export async function getResaleRequests(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customer.getResaleRequests}`, null, payload, null);
}

export async function updateResaleRequestVisibilityByUUID(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customer.updateResaleRequestVisibilityByUUID}/${uuid}`, null, payload, null);
}

export async function submitResaleRequestByPropacity(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customer.submitResaleRequestByPropacity}`, null, payload, null);
}

export async function insertNoteByResaleRequestUUID(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customer.insertNoteByResaleRequestUUID}/${uuid}`, null, payload, null);
}

export async function getNotesByResaleRequestUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.customer.getNotesByResaleRequestUUID}/${uuid}`, null, null, null);
}

export async function insertNoteByReferralUUID(uuid, payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customer.insertNoteByReferralUUID}/${uuid}`, null, payload, null);
}

export async function getNotesByReferralUUID(uuid) {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.customer.getNotesByReferralUUID}/${uuid}`, null, null, null);
}

export async function insertCustomerAppFaq(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customer.insertCustomerAppFaq}`, null, payload, null);
}

export async function updateFaqById(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customer.updateFaqById}/${uuid}`, null, payload, null);
}

export async function getCustomerAppFaqCrm() {
    return await request(API_METHOD.GET, `${API_URLS.baseurl}${API_URLS.customer.getCustomerAppFaqCrm}`, null, null, null);
}

export async function deleteFaqById(uuid) {
    return await request(API_METHOD.DELETE, `${API_URLS.baseurl}${API_URLS.customer.deleteFaqById}/${uuid}`, null, null, null);
}

export async function getAllCustomerCallRequested(payload) {
    return await request(API_METHOD.POST, `${API_URLS.baseurl}${API_URLS.customer.getCustomerCallRequested}`, null, payload, null);
}

export async function insertCallbackRemarks(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customer.insertCallbackRemarks}/${uuid}`, null, payload, null);
}

export async function updateCustomerCallRequested(uuid, payload) {
    return await request(API_METHOD.PUT, `${API_URLS.baseurl}${API_URLS.customer.updateCustomerCallRequested}/${uuid}`, null, payload, null);
}