import React, { useEffect, useState } from 'react';
import { ReactComponent as ResultSearchIcon } from '../../../assets/icons/searchicon.svg';
import IncentiveCreationModal from './Modal/IncentiveCreationModal';
import { getReferralIncentiveCreative, toggleReferralIncentiveCreativeStatusByUUID } from '../../../services/private/customerApp.service';
import TooltipText from '../../../components/Tooltip';
import { toast } from 'react-toastify';
import LeadsFooterBar from '../../LeadsManager/LeadsFooterBar';
import Lightbox from 'yet-another-react-lightbox';
import Captions from "yet-another-react-lightbox/plugins/captions";

const ReferralIncentive = () => {
    const [allIncentive, setAllIncentive] = useState([]);
    const [showIncentiveModal, setShowIncentiveModal] = useState(false);
    const [showingResults, setShowingResults] = useState(10);
    const [currentPage, setCurrentPage] = useState(1)
    const [leadsCount, setLeadsCount] = useState('');
    const [maxPage, setMaxPage] = useState(1);
    const [selectedItem, setSelectedItem] = useState({});
    const [showLightModal, setShowLightModal] = useState(false);


    const getAllIncentivs = async () => {
        const payload = {
            pageNumber: currentPage,
            perPage: showingResults
        }
        const res = await getReferralIncentiveCreative(payload);
        if (res.data.status === 200) {
            if (res.data.data?.totalCount) {
                const totalPages = Math.ceil(
                    Number(res.data.data?.totalCount) / showingResults
                );
                setMaxPage(totalPages);
            }
            setLeadsCount(res.data.data?.totalCount)
            setAllIncentive(res.data.data.incentiveCreative);
        }
    }

    useEffect(() => {
        getAllIncentivs();
    }, [showingResults, currentPage]);

    const handleChangeStatus = async (uuid, value) => {
        const res = await toggleReferralIncentiveCreativeStatusByUUID(uuid, { is_enabled: value })
        if (res.data.status === 200) {
            toast.success(res.data.message);
            getAllIncentivs();
        }
    }
    return (
        <div className="users w-100 h-full flex flex-col">
            <div className="settings-title">
                <div className="row d-flex d-flex flex-md-fill flex-wrap">
                    <div className="col-md-7 col-6 d-flex flex-md-fill flex-column">
                        <h1 className="fz24 mb-16 black fw-po-medium">Referral Incentive</h1>
                        {/* <p className="fz16 black-dark-700 mb-0 d-md-block d-none">Manage all users for {companyDetails.name}</p> */}
                    </div>
                    <div className="col-md-5 col-6 d-flex justify-content-end align-items-md-end aling-items-center d-flex flex-md-fill">

                        {/* <div className={`border rounded px-2 py-1 flex mr-3 items-center ${searchText ? 'border-primary' : ''} `}>
                                    <SearchIcon className='mr-2' />
                                    <input
                                        type="text"
                                        placeholder='Search For Users'
                                        className=' w-100 focus:outline-none text-sm'
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                    />
                                    {searchText &&
                                        <div className="cursor-pointer" onClick={() => setSearchText('')}>
                                            <SearchCloseIcon />
                                        </div>}
                                </div> */}

                        <div className="">
                            <button onClick={() => {
                                // history.push('/settings/customer-news/news/create')
                                setShowIncentiveModal(true)
                            }
                            } className="pr-btn flex">
                                <svg className="mr4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <mask id="path-1-inside-1_1117_451" fill="white">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" />
                                    </mask>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" fill="white" />
                                    <path d="M12.6665 11.3335H9.66653V14.3335H12.6665V11.3335ZM12.6665 12.6668V9.66678H9.66653V12.6668H12.6665ZM11.3332 12.6668H14.3332V9.66678H11.3332V12.6668ZM11.3332 11.3335V14.3335H14.3332V11.3335H11.3332ZM12.6665 14.3335H17.3331V8.33347H12.6665V14.3335ZM17.3331 14.3335C16.0444 14.3335 14.9998 13.2888 14.9998 12.0001H20.9998C20.9998 9.97509 19.3581 8.33347 17.3331 8.33347V14.3335ZM14.9998 12.0001C14.9998 10.7115 16.0444 9.66678 17.3331 9.66678V15.6668C19.3581 15.6668 20.9998 14.0252 20.9998 12.0001H14.9998ZM17.3331 9.66678H12.6665V15.6668H17.3331V9.66678ZM9.66653 12.6668V17.3333H15.6665V12.6668H9.66653ZM9.66653 17.3333C9.66653 16.0447 10.7112 15 11.9999 15V21C14.0249 21 15.6665 19.3584 15.6665 17.3333H9.66653ZM11.9999 15C13.2885 15 14.3332 16.0447 14.3332 17.3333H8.33322C8.33322 19.3584 9.97484 21 11.9999 21V15ZM14.3332 17.3333V12.6668H8.33322V17.3333H14.3332ZM11.3332 9.66678H6.66665V15.6668H11.3332V9.66678ZM6.66665 9.66678C7.95533 9.66678 9 10.7115 9 12.0001H3C3 14.0252 4.64162 15.6668 6.66665 15.6668V9.66678ZM9 12.0001C9 13.2888 7.95533 14.3335 6.66665 14.3335V8.33347C4.64162 8.33347 3 9.97509 3 12.0001H9ZM6.66665 14.3335H11.3332V8.33347H6.66665V14.3335ZM14.3332 11.3335V6.6669H8.33322V11.3335H14.3332ZM14.3332 6.6669C14.3332 7.95557 13.2885 9.00024 11.9999 9.00024V3.00024C9.97484 3.00024 8.33322 4.64186 8.33322 6.6669H14.3332ZM11.9999 9.00024C10.7112 9.00024 9.66653 7.95557 9.66653 6.6669H15.6665C15.6665 4.64186 14.0249 3.00024 11.9999 3.00024V9.00024ZM9.66653 6.6669V11.3335H15.6665V6.6669H9.66653Z" fill="white" mask="url(#path-1-inside-1_1117_451)" />
                                </svg>
                                Add Incentive</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="def-table pr-24 !mt-4 !pl-2.5">
                <table className='table'>
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Title</th>
                            {/* <th>City</th> */}
                            <th>Project</th>
                            <th>Status</th>
                            <th>View Image</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {allIncentive.length > 0 ? allIncentive.map((item, index) => {

                            return (
                                <tr key={`${item.uuid}_${index}`}>
                                    <td>{index + 1}</td>
                                    <td>
                                        {
                                            item.title ?
                                                item.title.length > 30 ?
                                                    <TooltipText title={item.title}>
                                                        <div className='w-[200px] truncate'>
                                                            {item.title}
                                                        </div>
                                                    </TooltipText>
                                                    : item.title : '-'
                                        }
                                    </td>
                                    <td>
                                        {
                                            item.project_name ?
                                                item.project_name.length > 30 ?
                                                    <TooltipText title={item.project_name}>
                                                        <div className='w-[200px] truncate'>
                                                            {item.project_name}
                                                        </div>
                                                    </TooltipText>
                                                    : item.project_name : '-'
                                        }
                                    </td>
                                    <td>
                                        {item.is_enabled ? 'Active' : 'Deactive'}
                                    </td>
                                    <td>
                                        {item.image_url ? <a className={`border text-textblack block w-max !py-2 px-3 rounded-lg hover:bg-black700/10  `}
                                            onClick={() => {
                                                setSelectedItem(item);
                                                setShowLightModal(true)
                                            }}
                                        >
                                            View
                                        </a> : '-'}
                                    </td>
                                    <td className="dropdown view-more">
                                        <button
                                            className="bg-tr border-0"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z"
                                                    fill="#B5B5BE"
                                                />
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z"
                                                    fill="#B5B5BE"
                                                />
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z"
                                                    fill="#B5B5BE"
                                                />
                                            </svg>
                                        </button>
                                        <div
                                            className="dropdown-menu dropdown-menu-right w-auto"
                                            aria-labelledby="dropdownMenuButton"
                                        >
                                            <a
                                                className="dropdown-item"
                                                data-toggle="modal"
                                                data-target="#exampleModal"
                                                onClick={
                                                    () => {
                                                        handleChangeStatus(item.uuid, !item?.is_enabled)
                                                    }
                                                }
                                            >
                                                {!item?.is_enabled ? 'Activate' : 'Deactivate'}
                                            </a>
                                            <a
                                                className="dropdown-item"
                                                data-toggle="modal"
                                                data-target="#exampleModal"
                                                onClick={
                                                    () => {
                                                        setSelectedItem(item);
                                                        setShowIncentiveModal(true);
                                                    }
                                                }
                                            >
                                                Edit
                                            </a>

                                        </div>
                                    </td>
                                </tr>
                            )
                        }) :
                            null
                        }
                    </tbody>
                </table>
                {allIncentive?.length === 0 && <div className='flex justify-center flex-column items-center w-full h-[50vh] '>
                    <figure><ResultSearchIcon /></figure>
                    <h2 className="po black fz16 fw-po-medium mb-8 text-capitalize">No Referral Incentive Added </h2>
                    {/* <p className='text-center black-dark-600 fz14 mb-4 text-capitalize'>We're sorry, but we couldn't find any results for your search.
                          <sapn className='d-md-block d-none'>Please try again with different keywords.</sapn></p>                        */}
                    <div className="">
                        <button onClick={() => {
                            // history.push('/settings/customer-news/news/create')
                            setShowIncentiveModal(true)
                        }
                        } className="pr-btn flex">
                            <svg className="mr4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <mask id="path-1-inside-1_1117_451" fill="white">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" />
                                </mask>
                                <path fillRule="evenodd" clipRule="evenodd" d="M12.6665 11.3335H17.3331C17.7013 11.3335 17.9998 11.6319 17.9998 12.0001C17.9998 12.3683 17.7013 12.6668 17.3331 12.6668H12.6665V17.3333C12.6665 17.7015 12.3681 18 11.9999 18C11.6317 18 11.3332 17.7015 11.3332 17.3333V12.6668H6.66665C6.29847 12.6668 6 12.3683 6 12.0001C6 11.6319 6.29847 11.3335 6.66665 11.3335H11.3332V6.6669C11.3332 6.29871 11.6317 6.00024 11.9999 6.00024C12.3681 6.00024 12.6665 6.29871 12.6665 6.6669V11.3335Z" fill="white" />
                                <path d="M12.6665 11.3335H9.66653V14.3335H12.6665V11.3335ZM12.6665 12.6668V9.66678H9.66653V12.6668H12.6665ZM11.3332 12.6668H14.3332V9.66678H11.3332V12.6668ZM11.3332 11.3335V14.3335H14.3332V11.3335H11.3332ZM12.6665 14.3335H17.3331V8.33347H12.6665V14.3335ZM17.3331 14.3335C16.0444 14.3335 14.9998 13.2888 14.9998 12.0001H20.9998C20.9998 9.97509 19.3581 8.33347 17.3331 8.33347V14.3335ZM14.9998 12.0001C14.9998 10.7115 16.0444 9.66678 17.3331 9.66678V15.6668C19.3581 15.6668 20.9998 14.0252 20.9998 12.0001H14.9998ZM17.3331 9.66678H12.6665V15.6668H17.3331V9.66678ZM9.66653 12.6668V17.3333H15.6665V12.6668H9.66653ZM9.66653 17.3333C9.66653 16.0447 10.7112 15 11.9999 15V21C14.0249 21 15.6665 19.3584 15.6665 17.3333H9.66653ZM11.9999 15C13.2885 15 14.3332 16.0447 14.3332 17.3333H8.33322C8.33322 19.3584 9.97484 21 11.9999 21V15ZM14.3332 17.3333V12.6668H8.33322V17.3333H14.3332ZM11.3332 9.66678H6.66665V15.6668H11.3332V9.66678ZM6.66665 9.66678C7.95533 9.66678 9 10.7115 9 12.0001H3C3 14.0252 4.64162 15.6668 6.66665 15.6668V9.66678ZM9 12.0001C9 13.2888 7.95533 14.3335 6.66665 14.3335V8.33347C4.64162 8.33347 3 9.97509 3 12.0001H9ZM6.66665 14.3335H11.3332V8.33347H6.66665V14.3335ZM14.3332 11.3335V6.6669H8.33322V11.3335H14.3332ZM14.3332 6.6669C14.3332 7.95557 13.2885 9.00024 11.9999 9.00024V3.00024C9.97484 3.00024 8.33322 4.64186 8.33322 6.6669H14.3332ZM11.9999 9.00024C10.7112 9.00024 9.66653 7.95557 9.66653 6.6669H15.6665C15.6665 4.64186 14.0249 3.00024 11.9999 3.00024V9.00024ZM9.66653 6.6669V11.3335H15.6665V6.6669H9.66653Z" fill="white" mask="url(#path-1-inside-1_1117_451)" />
                            </svg>
                            Add Incentive</button>
                    </div>
                </div>}
            </div>
            <LeadsFooterBar
                showingResults={showingResults}
                setShowingResults={setShowingResults}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                maxPage={maxPage}
                leadsCount={leadsCount}
                classname='!basis-0 !w-[calc(100%-288px)]'
            />
            {showIncentiveModal && <IncentiveCreationModal
                show={showIncentiveModal}
                closeModal={() => setShowIncentiveModal(false)}
                getAllIncentivs={getAllIncentivs}
                selectedItem={selectedItem}
            />}
            {showLightModal && <Lightbox
                styles={{ navigationNext: { "yarl__navigation_next": "display: none !important" } }}
                className={'broker-lightbox'}
                open={showLightModal}
                close={() => {
                    setShowLightModal(false)
                    setSelectedItem('')
                }}
                slides={[{ src: selectedItem.image_url, title: `${selectedItem.title}` }]}
                plugins={[Captions]}
            />}
        </div>
    )
}

export default ReferralIncentive