import React, { useEffect, useState, useRef } from 'react';
import { Image } from 'react-bootstrap';
import './CustomTable.css';
import { createDatewithTime } from '../../helpers/date';
import { getCompanyAgency, getMasterEnumsCall, getTimelineCall, knowlarityClickToCall, knowlarityClickToCallAdvanced, updateleadByKeyValue } from '../../services/private/company.service';
import { toast } from 'react-toastify';
import dotMenuIcon from '../../assets/icons/dotmenu.svg';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import NoResultFound from '../NotFound/NoResultFound';
import ConfirmDeletion from '../Modals/ConfirmDeletion';
import { getSelectedValue } from '../../utils/selectedOptionUtils';
import LeadName from './TableColumns/LeadName';
import Projects from './TableColumns/Projects';
import SourceLabel from './TableColumns/SourceLabel';
import StatusDropDown from './TableColumns/StatusDropDown';
import DynamicIntent from './TableColumns/DynamicIntent';
import AssignedTo from './TableColumns/AssignedTo';
import checkAssignedTo from '../../utils/checkAssignedTo';
import MobileNo from './TableColumns/MobileNo';
import BookingsDoneModal from '../Modals/BookingsDoneModal';
import SortIconDisplay from './sortIconDisplay';
import UpdateFollowupModal from '../Modals/FollowupModal/UpdateFollowupModal';
import { useMemo } from 'react';
import { useCallback } from 'react';
import NotesDropDown from './TableColumns/NotesDropDown';
import RecentActivities from '../../modules/Lead Details/components/Recent Activities/RecentActivities';
import { Oval } from 'react-loader-spinner';
import MiniTimline from '../Modals/MiniTimline';
import RenderTableData from './RenderTableData';
import CustomColumnModal from '../Modals/CustomColumnModal';
import { getBookingDoneDetails, getBookingPaymentMilestonesByBookingId, getBookingPaymentMilestonesByEOIId, getDemandDetailByEOI, getEOIBookingPricing, getEOIDetails, updateBookingApprovalStatus, updateEOIApprovalStatus } from '../../services/private/inventory.service';
import DetailsModal from '../../modules/Inventory/Modal/details';
import AddDemand from '../Collection-Manager/Modal/AddDemand';
import moment from 'moment';
import { checkIsSomeItemAvailableInList, mergeUniqueByKey, toINRFormat } from '../../helpers/helpers';
import EoiDoneModal from '../Modals/EoiDoneModal';
import EOIModal from '../Modals/EOIModal';
import ShareLeadAccessModal from '../Modals/ShareLeadAccessModal';
import CancelBooking from '../../modules/Inventory/Modal/cancelBooking';
import AreYouSureModal from '../Modals/AreYouSureModal';
import SharedWithUserName from './TableColumns/SharedWithUserName';
import AdvanceCallModal from '../Modals/AdvanceCallModal';
import LeadTaggingModal from '../Modals/LeadTaggingModal';
import NewEOIModal from '../Modals/NewEOIModal';
import TooltipText from '../Tooltip';

const getStatusClasses = (status) => {
    switch (status) {
        case 'rejected':
            return 'text-red bg-red/10'
        case 'cancelled':
            return 'text-red bg-red/10'
        case 'approved':
            return 'text-green bg-green100'
        default:
            return 'text-orange-dark bg-orange-100'
    }
}

const getAgreementClasses = (status) => {
    switch (status) {
        case 'Pending':
            return 'text-orange-dark bg-orange-100'
        case 'Approved':
            return 'text-green bg-green100'
    }
}

export default function NewEoiTable({
    leadData,
    filters,
    startIndex,
    showCheck,
    updateData,
    selectedTableValues,
    setSelectedTableValues,
    setSelectedTableData,
    selectedTableData,
    handleUserDeletion,
    handleUserReassignLead,
    columns,
    col,
    count,
    searchText,
    sort,
    reAssignRole,
    intentStatuses,
    statuses,
    sourceStatuses,
    setLeadName,
    selectedLeadName,
    agencyList,
    showCreateLeadModal,
    bookingLeadsColumn,
    saveCurrentUserColumnPrefrence,
    handleChangeSelection,
    updateColumnList,
    dashboardColumns,
    allowedPermissions,
    isRolesModule,
    userProfile,
    callerList
}) {
    const [deadUuid, setDeadUuid] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [selectedSingleUserIdFromTable, setSelectedSingleUserIdFromTable] = useState();
    const [showBookingModal, setShowBookingModal] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [showFollowupModal, setShowFollowupModal] = useState(false);
    const [timeline, setTimeline] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState('');
    const [showTimeline, setShowTimeline] = useState(false);
    const [timelineLeadName, setTimelineLeadName] = useState('');
    const [showCustomColumn, setShowCustomColumn] = useState(false);
    const [columnsList, setColumnsList] = useState(bookingLeadsColumn);
    const [tableColumn, setTableColumn] = useState([]);
    const [isCustomColumnAvailable, setIsCustomColumnAvailable] = useState(false);
    const [reAssignAvailable, setReAssignAvailable] = useState(false);
    const [deleteAvailable, setDeleteAvailable] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState({});
    const [bookingStatus, setBookingStatus] = useState('pending');
    const [bookingUUId, setBookingUUID] = useState('');
    const [leadStatus, setLeadStatus] = useState('');
    const [selectedItem, setSelectedItem] = useState({});
    const [paymentMilestones, setPaymentMilestones] = useState([]);
    const [showRaiseDemand, setShowRaiseDemand] = useState(false);
    const [editDemandData, setEditDemandData] = useState({});
    const [showEOIDoneModal, setShowEOIDoneModal] = useState(false);
    const [showShareLeadAccessModal, setShowShareLeadAccessModal] = useState(false);
    const [selectedLead, setSelectedLead] = useState({});
    const [showCancelEOI, setShowCancelEOI] = useState(false);
    const [showVerifyToken, setShowVerifyToken] = useState(false);
    const [showAdvanceCallmodal, setShowAdvanceCallModal] = useState(false);
    const [showLeadTaggingModal, setShowLeadTaggingModal] = useState(false);
    const [showTab, setShowTab] = useState('');
    const [dataLoading, setDataLoading] = useState(false);

    const ROLE_NAME = localStorage.getItem('role');
    const tableRef = useRef();

    useEffect(() => {
        if (!isRolesModule) {
            if (ROLE_NAME === 'ADMIN') {
                setIsCustomColumnAvailable(true);
                setReAssignAvailable(true);
                setDeleteAvailable(true);
            }
            if (reAssignRole?.includes('re_assign_leads')) {
                setReAssignAvailable(true);
            }
        } else {
            if (allowedPermissions.includes('customise_column_self')) {
                setIsCustomColumnAvailable(true);
            }
            if (allowedPermissions.includes('re_assign_leads_single')) {
                setReAssignAvailable(true);
            }
            if (allowedPermissions.includes('delete_leads')) {
                setDeleteAvailable(true);
            }
        }
    }, [isRolesModule]);

    const handelChange = (e, data) => {
        if (e.target.checked) {
            setLeadName(data.name);
            setSelectedTableValues([...selectedTableValues, data.id]);
            setSelectedTableData([...selectedTableData, data]);
        } else {
            const filteredValues = selectedTableValues.filter((value) => value != data.id);
            const filteredData = selectedTableData.filter((value) => value.id != data.id);
            setSelectedTableValues(filteredValues);
            setSelectedTableData(filteredData);
            setLeadName('');
        }
    }

    const getBookingDetails = async (uuid) => {
        setBookingUUID(uuid)
        const res = await getBookingDoneDetails(uuid);
        if (res.data.status === 200) {
            if (res.data.data?.customer?.uuid) {
                setSelectedBooking(res.data.data);
                setShowDetailsModal(true);
            }
        }
    }

    const getEOILeadDetails = async (uuid) => {
        setBookingUUID(uuid)
        const res = await getEOIDetails(uuid);
        setDataLoading(false);
        if (res.data.status === 200) {
            if (userProfile?.company_id === 581 || res.data.data?.customer?.uuid) {
                setSelectedBooking(res.data.data);
                setShowDetailsModal(true);
                setShowVerifyToken(false);
            }
        }
    }

    useEffect(() => {
        if (!selectedLeadName && selectedTableValues?.length === 1) {
            const leadVal = leadData.find((lead) => lead.id === selectedTableValues[0]);
            setLeadName(leadVal.name)
        }
    }, [selectedLeadName, selectedTableValues])

    useEffect(() => {
        const handleCopy = (e) => {
            // Optionally prevent default copy behavior
            if (userProfile?.company_id !== 29) {
                e.preventDefault();
            }
        };
        const handleContextMenu = (e) => {
            e.preventDefault();
        };

        document.addEventListener('copy', handleCopy);
        // document.addEventListener('contextmenu', handleContextMenu);
        return () => {
            document.removeEventListener('copy', handleCopy);
            // document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    useEffect(() => {
        if (selectedTableValues?.length >= leadData?.length) {
            const allSelected = leadData.every(lead => selectedTableValues.includes(lead.id))
            setSelectAll(allSelected)
        }
    }, [selectedTableValues, selectedTableData, leadData]);

    const onHandleSelectAllLeads = () => {
        if (!selectAll) {
            const selectedVal = leadData.map((lead) => lead.id)
            // setSelectedTableValues([...selectedTableValues, ...selectedVal]);
            // setSelectedTableData([...selectedTableData, ...leadData]);
            setSelectedTableValues([...new Set([...selectedTableValues, ...selectedVal])]);
            const mergedLeads = mergeUniqueByKey(selectedTableData, leadData, 'id')
            setSelectedTableData([...mergedLeads]);
        } else {
            if (selectedTableValues?.length > leadData?.length) {
                const leadIds = leadData.map(lead => lead.id);
                const filtered = selectedTableValues.filter(item => !leadIds.includes(item))
                const filteredData = selectedTableData.filter(item => !leadIds.includes(item.id))
                setSelectedTableValues(filtered)
                setSelectedTableData(filteredData)
            } else {

                setSelectedTableValues([])
                setSelectedTableData([]);
            }
        }
        setSelectAll(!selectAll)
    }

    const handleRowPosition = (e, index) => {
        localStorage.setItem('rowIndex', index);
    }

    useEffect(() => {
        tableRef.current.scrollTop = parseInt(localStorage.getItem('rowIndex')) * 50;
    }, [leadData])

    useEffect(() => {
        const sortedList = bookingLeadsColumn.sort((a, b) => a.placement - b.placement)
        setTableColumn(sortedList);
    }, [bookingLeadsColumn]);

    const isEOIDone = useCallback((status) => {
        const statusVal = getSelectedValue(status, statuses)
        return (statusVal?.label === 'EOI Completed' || statusVal?.label === 'Token Done')
    }, [statuses])

    const handleLeadTimeline = useCallback((data, index) => {
        setSelectedIndex(index)
        if (data.id) {
            setTimelineLeadName(data.name)
            getTimelineCall(data.id).then((res) => {
                if (res.data.status === 200) {
                    setTimeline(res.data.data)
                    setShowTimeline(true)
                }
            }).catch((err) => {
                console.log({ err })
            })
        }
    }, []);

    const closeTimelineDropdown = useCallback(() => {
        setSelectedIndex('');
        setTimeline([]);
        setShowTimeline(false);
    }, []);

    const selectSingleUserId = useCallback((data) => {
        setSelectedSingleUserIdFromTable(data);
    }, []);

    const setFollowupModal = useCallback((val) => {
        setShowFollowupModal(val);
    }, []);

    const closeCustomColumnModal = useCallback(() => {
        setShowCustomColumn(false)
    }, []);

    const handleChangeBookingStatus = async (payload) => {
        const res = await updateBookingApprovalStatus(bookingUUId, payload);
        if (res.data.status === 200) {
            console.log(res.data.data);
            toast.success(res.data.message);
            updateData();
            setShowDetailsModal(false)
        }
    }

    const handleChangeEOIStatus = async (payload) => {
        const res = await updateEOIApprovalStatus(bookingUUId, payload);
        if (res.data.status === 200) {
            console.log(res.data.data);
            toast.success(res.data.message);
            updateData();
            setShowDetailsModal(false)
        }
    }

    const getPaymentMilestones = async (uuid) => {
        const res = await getBookingPaymentMilestonesByEOIId(uuid);
        if (res.data.status === 200) {
            const milestones = res.data.data.map(milestone => ({ ...milestone, label: `${milestone.count}. ${milestone.description}`, value: milestone.id }));
            setPaymentMilestones(milestones);
        }
    }

    const getBookingPricing = async (data) => {
        const res = await getEOIBookingPricing(data.eoi_uuid);
        if (res.data.status) {
            setSelectedItem({
                ...data,
                customer_name: data.name,
                adjusted_price: res.data.data?.bookingPricing?.adjusted_price
            })
            setShowRaiseDemand(true);
        }
    }

    const getEoiDemand = async (data) => {
        const res = await getDemandDetailByEOI(data.eoi_uuid);
        if (res.data.status === 200) {
            setEditDemandData(res.data.data.demand);
        }
    }

    const handleUpdateEOi = async (payload) => {
        const res = await updateEOIApprovalStatus(bookingUUId, payload);
        if (res.data.status === 200) {
            updateData();
            setShowCancelEOI(false);
            setSelectedLead({});
        }
    }

    const handleOpenVerifyPage = (data) => {
        setLeadStatus('eoi')
        setSelectedLead(data);
        // setShowDetailsModal(true)
        setShowVerifyToken(true)
    }

    const handleClickToCall = async (lead) => {
        const res = await knowlarityClickToCall(lead.id, {});
        if (res.data.status === 200) {
            toast.success(res.data.message)
        }
    }

    const handleAdvanceClickToCall = async (useAlternate, callerId) => {
        const payload = {
            use_alternate: useAlternate,
            caller_id: callerId
        }

        const res = await knowlarityClickToCall(selectedItem.id, payload);
        if (res.data.status === 200) {
            toast.success(res.data.message)
            setSelectedItem({})
            setShowAdvanceCallModal(false)
        }
    }

    console.log(selectedItem, 'selectedItem')
    return (
        <>
            {showDeleteModal && selectedSingleUserIdFromTable.id && <ConfirmDeletion
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                title={`Delete ${selectedSingleUserIdFromTable.name}?`}
                deleteHandler={() => handleUserDeletion(selectedSingleUserIdFromTable.id)}
                subText={`After removal, unit ${selectedSingleUserIdFromTable?.unit_name}, unit ${selectedSingleUserIdFromTable?.unit_name} will be unblocked.`}
            />}

            {showBookingModal && selectedSingleUserIdFromTable.id && <BookingsDoneModal
                setShow={setShowBookingModal}
                show={showBookingModal}
                leadId={selectedSingleUserIdFromTable.id}
                onSuccess={updateData}
                isEdit={showBookingModal}
                bookingsData={selectedSingleUserIdFromTable}
            />}

            {showFollowupModal && selectedSingleUserIdFromTable.id && <UpdateFollowupModal
                setShow={setShowFollowupModal}
                show={showFollowupModal}
                leadId={selectedSingleUserIdFromTable.id}
                isEdit={showFollowupModal}
                onSuccess={updateData}
                intentOptions={intentStatuses}
                selectedIntent={selectedSingleUserIdFromTable.intent}
            />}
            <div className="all-leads !pt-1 md:!pl-5 !pl-4">
                <div className={`lead-table EOITable  h-full  ${userProfile?.company_id !== 29 ? 'no-select' : ''} ${leadData?.length === 0 ? 'overflow-hidden shownofound' : ''}`} ref={tableRef}>

                    <table className="table booking-table">
                        <thead>
                            <tr>
                                <th
                                    className='cursor-pointer'
                                >
                                    {showCheck ?
                                        <>
                                            <input
                                                type="checkbox"
                                                onChange={onHandleSelectAllLeads}
                                                checked={selectAll}
                                                id='selectAll_leads'
                                                title='Select all'
                                                className='resasign-input'
                                            />

                                        </>
                                        :
                                        'S.No.'
                                    }
                                </th>

                                <th className='sticky-th'>
                                    Name
                                    <SortIconDisplay sortCol={col} sort={sort} colName='Name' />
                                </th>

                                <th className={`${userProfile?.company_id === 581 ? 'sticky left-[252px]' : ''}`}>
                                    Mobile No.
                                    <SortIconDisplay sortCol={col} sort={sort} colName='Mobile No.' />
                                </th>
                                <th>Source</th>
                                <th>
                                    Email
                                    <SortIconDisplay sortCol={col} sort={sort} colName='Email' />
                                </th>
                                <th>Status</th>
                                <th>Approval Status</th>
                                <th>Token Details</th>
                                <th>Followup Date</th>
                                <th>Token Amt.</th>
                                <th>Token Validity</th>
                                <th>Token Date</th>
                                <th>Token Project</th>
                                <th>Demand Amount</th>
                                <th>Note</th>
                                {/* <th>Preferred Unit</th>
                                <th>Preferred Unit Type</th>
                                <th>Preferred Unit Configuration</th>
                                <th>Preferred Tower</th>
                                <th>Preferred Block</th>
                                <th>Preferred Floor</th> */}
                                <th>Block/Tower</th>
                                {/* <th>Block</th> */}
                                <th>Floor</th>
                                <th>Configuration</th>
                                <th>Unit Type</th>
                                <th>Unit</th>
                                <th>Agreement Amt.</th>
                                <th>Final Price (i)</th>
                                <th>{userProfile?.company_id === 581 ? 'Token Id' : 'EOI ID'}</th>
                                <th>{userProfile?.company_id === 581 ? 'Token Id' : 'EOI By'}</th>
                                {/* <th>Broker/CP Name</th> */}
                                <th>Assigned User</th>
                                <th>Loan Status</th>
                                <th>Communication Address</th>
                                <th>Alternate Mobile No.</th>

                                <th>Added By</th>
                                <th>Approved By</th>
                                <th>Approval Time</th>
                                {userProfile?.allowed_modules?.includes('lead_sharing') && <th>View lead share</th>}
                                <th>Shared With User</th>
                                <th>Marked By</th>
                                <th>Marked Date</th>
                                <th>Lead Access Validity</th>
                                <th>Access</th>
                                {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && <th>Lead Tagged</th>}
                                {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && <th>Last CP tagged</th>}
                                <th>Agreement Status</th>
                                <th>Proposed Agreement Date</th>
                                <th>Agreement Date</th>
                                <th>Preferred Block</th>
                                <th>Preferred Floor</th>
                                <th>Preferred Location</th>
                                <th>Preferred Unit Config</th>
                                <th>Preferred Unit No</th>
                                <th>Preferred Unit Type</th>
                                <th>Referred Name</th>
                                <th> Referred Mobile</th>
                                <th> Referred Society</th>
                                <th> Referred Flat</th>
                                <th>Cancel Reason</th>
                                <th>Last Activity</th>
                                <th>Last Activity Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                leadData && leadData?.length > 0 &&
                                leadData.map((data, index) => (
                                    <tr
                                        key={data.id + data.intent + data.status}
                                        id={`leads_table_${index}`}
                                        onClick={(e) => handleRowPosition(e, index)}
                                    >
                                        <td>
                                            {showCheck ?
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => handelChange(e, data)}
                                                    checked={selectedTableValues.includes(data.id)}
                                                /> :
                                                index + startIndex
                                            }
                                        </td>
                                        <td className='sticky-td'><LeadName
                                            data={data}
                                            intent={data.intent}
                                            intentStatuses={intentStatuses}
                                        /></td>
                                        <td className={`pr4 ${userProfile?.company_id === 581 ? 'sticky left-[252px]' : ''}`} style={{ maxWidth: '200px', overflow: 'hidden', }}>
                                            {<MobileNo mobileNo={data.mobile} />}
                                        </td>
                                        <td>
                                            <SourceLabel
                                                allSources={sourceStatuses}
                                                sources={data.source}
                                            />
                                        </td>
                                        <td>
                                            {data.email ? <OverlayTrigger
                                                placement="bottom"
                                                overlay={
                                                    <Tooltip id="button-tooltip" >
                                                        <div className='info-msg'>
                                                            {/* <h4>Total Leads</h4>*/}
                                                            <p className=''>{data.email}</p>
                                                        </div>
                                                    </Tooltip >
                                                }
                                            >
                                                <span className='three-dots cursor-pointer'>{data.email}</span>
                                            </OverlayTrigger > : '-'}
                                        </td>
                                        <td>
                                            <StatusDropDown
                                                value={getSelectedValue(data.status, statuses)}
                                                options={statuses}
                                                id={data.id}
                                                reloadData={updateData}
                                                leadName={data.name}
                                                intentOptions={intentStatuses}
                                                data={data}
                                                handleOpenVerifyPage={handleOpenVerifyPage}
                                            />
                                        </td>
                                        <td>
                                            <p className={`capitalize m-0 py-1 px-3 rounded-lg text-center ${data.approval_status && getStatusClasses(data.approval_status)}`}>
                                                {data.approval_status || '-'}
                                            </p>
                                        </td>
                                        <td>
                                            {(!dataLoading || selectedLead?.id !== data?.id) && <button
                                                className={`border ${data.approval_status === 'pending' ? 'border-primary text-primary' : ''} py-1 px-3 rounded-lg`}
                                                onClick={() => {
                                                    setLeadStatus('eoi');
                                                    setSelectedItem(data)
                                                    setBookingStatus(data.approval_status);
                                                    setSelectedLead(data)
                                                    setDataLoading(true)
                                                    getEOILeadDetails(data.eoi_uuid)
                                                }}>
                                                {(allowedPermissions.includes('verify_bookings_eoi') ? (data.approval_status === 'pending' ? `Verify ${userProfile?.company_id === 581 ? 'Token' : 'EOI'}` : `View ${userProfile?.company_id === 581 ? 'Token' : 'EOI'}`) : `View ${userProfile?.company_id === 581 ? 'Token' : 'EOI'}`)}
                                            </button>}
                                            {
                                                dataLoading && selectedItem.id === data.id &&
                                                <button
                                                    className={`border ${data.approval_status === 'pending' ? 'border-primary text-primary' : ''} py-1 px-3 rounded-lg w-[100px] flex items-center justify-center`}
                                                >
                                                    <Oval
                                                        height={22}
                                                        width={22}
                                                        color="#0062FF"
                                                        wrapperStyle={{}}
                                                        wrapperClass=""
                                                        visible={true}
                                                        ariaLabel='oval-loading'
                                                        secondaryColor="#0062FF"
                                                        strokeWidth={2}
                                                        strokeWidthSecondary={2}
                                                    />
                                                </button>
                                            }
                                        </td>
                                        <td>
                                            <a bsstyle="default" className={`${(moment(data.followup_date) < moment()) ? "red-color" : "text-black "} cursor-pointer`}
                                            >
                                                {data.followup_date ? createDatewithTime(new Date(data.followup_date)) : '-'}
                                            </a>
                                        </td>
                                        <td>{data?.token_amount > 0 ? `₹ ${toINRFormat(data?.token_amount)}` : '-'}</td>
                                        <td className={`${(data.eoi_validity && moment(data.eoi_validity) < moment()) ? "red-color" : "text-black "}`}>{data?.eoi_validity ? moment(data?.eoi_validity).format('Do MMM YYYY') : '-'}</td>
                                        <td>{data.eoi_date ? moment(data.eoi_date).format('Do MMM YYYY') : '-'}</td>
                                        <td>
                                            {/* <Projects
                                                projectsArr={data.project?.filter((project) => project !== null)}
                                            /> */}
                                            <p className='text-capitalize m-0'>{data.eoi_project_name}</p>
                                        </td>
                                        <td>{data.demand_amount ? `₹ ${toINRFormat(data.demand_amount)}` : '-'}</td>
                                        <td>
                                            <div className='view-notes'>
                                                <NotesDropDown
                                                    note={data.eoi_remarks}
                                                    date={data.followup_date ? data.booking_created_time : data['Booking Created Date']}
                                                />
                                            </div>
                                        </td>
                                        {/* <td>{data?.preferred_unit || '-'}</td>
                                        <td>{data?.preferred_unit_type || '-'}</td>
                                        <td>{data?.preferred_unit_configuration || '-'}</td>
                                        <td>{data?.preferred_block || '-'}</td>
                                        <td>{data?.preferred_block || '-'}</td>
                                        <td>{data?.preferred_floor || '-'}</td> */}
                                        <td>{data?.unit_block || '-'}</td>
                                        {/* <td>{data?.unit_block || '-'}</td> */}
                                        <td>{data?.unit_floor || '-'}</td>
                                        <td>{data?.unit_configurtion || '-'}</td>
                                        <td>{data?.unit_type || '-'}</td>
                                        <td>{data?.unit_name || '-'}</td>
                                        <td>{data?.booking_agreement_price ? toINRFormat(data?.booking_agreement_price) : '-'}</td>
                                        <td>{data?.booking_agreement_price ? toINRFormat(data?.booking_agreement_price) : '-'}</td>
                                        <td>{data.eoi_unique_id || '-'}</td>
                                        <td>{data?.created_by || '-'}</td>
                                        {/* <td>{data?.channel_partner_firm_name || '-'}</td> */}
                                        <td>{data?.assigned_to || '-'}</td>
                                        <td>{data?.loan_status || '-'}</td>
                                        <td>{data?.customer_communication_address || '-'}</td>
                                        <td>{data?.customer_alternate_mobile || '-'}</td>

                                        <td>{data?.created_by || '-'}</td>
                                        <td> {data?.approval_status_changed_by || '-'}</td>
                                        <td>{data?.approval_time ? createDatewithTime(new Date(data.approval_time)) : '-'}</td>
                                        {userProfile?.allowed_modules?.includes('lead_sharing') && <td>
                                            {
                                                data.shared_with_count > 0 ?
                                                    <div className='view-notes'>
                                                        <button className="btn btn-secondary dropdown-toggle" type="button" id=""
                                                            onClick={() => {
                                                                setSelectedItem(data)
                                                                setShowShareLeadAccessModal(true)
                                                            }}
                                                        >
                                                            View
                                                        </button>
                                                    </div>
                                                    : '-'
                                            }
                                        </td>}
                                        <td>
                                            {data?.shared_with_names ? <SharedWithUserName shareWithNameArr={data.shared_with_names} /> : '-'}
                                        </td>
                                        <td>
                                            {data?.status_changed_by_name || '-'}
                                        </td>
                                        <td>
                                            {
                                                data?.status_changed_on ? createDatewithTime(new Date(data?.status_changed_on)) : '-'
                                            }
                                        </td>
                                        <td>{data.validity ? `${data?.validity} ${data.validity_unit}` : '-'}</td>
                                        <td className='capitalize'>{data?.access ? data?.access === 'edit' ? 'Edit/View' : 'View' : '-'}</td>
                                        {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && <td>
                                            <div className={`${data?.broker_user_name ? 'text-[#2064FA] bg-[#2064FA]/10' : 'text-[#667085] bg-[#F1F1F5]'}  text-xs rounded-lg text-center font-semibold !py-1.5 `}>
                                                {data?.broker_user_name ? 'Tagged' : 'Untagged'}
                                            </div>
                                        </td>}
                                        {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && <td>{data?.last_tag_cp_user || '-'}</td>}
                                        <td>
                                            <p className={`capitalize m-0 py-1 px-3 rounded-lg text-center ${data?.agreement_status && getAgreementClasses(data?.agreement_status)}`}>
                                                {data?.agreement_status || '-'}
                                            </p>
                                            {/* {data?.agreement_status || '-'} */}
                                        </td>
                                        <td
                                            className='cursor-pointer'
                                            onClick={() => {
                                                setShowEOIDoneModal(true);
                                                setSelectedItem(data)
                                                setShowTab('EOIOther')
                                            }}
                                        >{data?.agreement_propose_date ? createDatewithTime(new Date(data?.agreement_propose_date)) : '-'}</td>
                                        <td className='cursor-pointer ' onClick={() => {
                                            setShowEOIDoneModal(true);
                                            setSelectedItem(data)
                                            setShowTab('EOIOther')
                                        }}>{data?.agreement_date ? createDatewithTime(new Date(data?.agreement_date)) : '-'}</td>
                                        <td>{data?.preferred_block || '-'}</td>
                                        <td>{data?.preferred_floor || '-'}</td>
                                        <td>
                                            {
                                                (data?.preferred_location && data?.preferred_location?.length > 50) ?
                                                    <TooltipText title={data?.preferred_location}>
                                                        <div className='w-[100px] truncate'>{data?.preferred_location}</div>
                                                    </TooltipText> :
                                                    data?.preferred_location || '-'
                                            }
                                        </td>
                                        <td>{data?.preferred_unit_configuration || '-'}</td>
                                        <td>{data?.preferred_unit_no || '-'}</td>
                                        <td>{data?.preferred_unit_type || '-'}</td>
                                        <td>{data?.referred_by_name || '-'}</td>
                                        <td>{data?.referred_by_mobile || '-'}</td>
                                        <td>{data?.referred_by_society || '-'}</td>
                                        <td>{data?.referred_by_flat_no || '-'}</td>
                                        <td>
                                            <div className='view-notes'>
                                                {
                                                    data?.reason ? <>
                                                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            View Reason
                                                        </button>
                                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <div className="notes drop-notes">
                                                                <div className="right-text">
                                                                    {/* <h2 className='black fz14'>Note</h2> */}
                                                                    <p className='black-dark-700 fz16 mb4 ro'>{data?.reason}</p>

                                                                </div>

                                                            </div>
                                                            {/* <p className='black-dark-700 fz12 mb-0 text-right mt-1'>{date ? moment(date).format('h:mm a, Do MMM YYYY') : ''}</p> */}
                                                        </div>
                                                    </>
                                                        : '-'

                                                }
                                            </div>
                                        </td>
                                        <td className='capitalize'>
                                            {
                                                data?.last_activity_type ? `${data?.last_activity_type} ${data?.last_activity_value}` : '-'
                                            }
                                        </td>
                                        <td>{
                                            data?.last_activity_date ? createDatewithTime(new Date(data?.last_activity_date)) : '-'
                                        }</td>
                                        <td className="dropdown view-more">
                                            {(isEOIDone(data.status) || reAssignAvailable || deleteAvailable) &&
                                                < >
                                                    <button
                                                        className={`bg-tr border-0 ${(userProfile?.allowed_modules?.includes('lead_sharing') && data?.access === 'view') ? 'cursor-not-allowed' : ''} `}
                                                        type="button"
                                                        id="dropdownMenuButton"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                        disabled={(userProfile?.allowed_modules?.includes('lead_sharing') && data?.access === 'view')}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#B5B5BE"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#B5B5BE"></path></svg>
                                                    </button>
                                                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                        <div className="head-itme pb-2 m-0">Actions <span></span></div>
                                                        {/* <a className="dropdown-item" >
                                                            Send Quotation
                                                        </a>
                                                        <a className="dropdown-item" >
                                                            Cancel EOI
                                                        </a> */}
                                                        <a className="dropdown-item" onClick={() => getEOILeadDetails(data.eoi_uuid)} >
                                                            View Details
                                                        </a>
                                                        <a className="dropdown-item" onClick={() => {
                                                            setTimeline([]);
                                                            handleLeadTimeline(data, index);
                                                        }}>
                                                            View Recent Activity
                                                        </a>
                                                        {/* {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && allowedPermissions?.includes('edit_broker_tag') && <a className="dropdown-item" onClick={() => {
                                                            setSelectedItem(data)
                                                            setShowLeadTaggingModal(true);
                                                        }}>
                                                            Edit Lead Tagging
                                                        </a>}
                                                        {userProfile?.company_id === +process.env.REACT_APP_COMPANY_ID && allowedPermissions?.includes('edit_broker_tag') && <a className="dropdown-item" onClick={() => {
                                                            setSelectedItem(data)
                                                            setShowLeadTaggingModal(true);
                                                        }}>
                                                            Edit CP User
                                                        </a>} */}
                                                        {userProfile?.allowed_modules?.includes('lead_sharing') &&
                                                            checkIsSomeItemAvailableInList(allowedPermissions, ['can_share_leads']) &&
                                                            <a className="dropdown-item" onClick={() => {
                                                                setSelectedItem(data)
                                                                setShowShareLeadAccessModal(true)
                                                            }}>

                                                                Share Lead Access
                                                            </a>}
                                                        {!data?.eoi_latest_demand_id && < a className="dropdown-item" onClick={() => {
                                                            // setSelectedItem(data);
                                                            getPaymentMilestones(data.eoi_uuid);
                                                            getBookingPricing(data);
                                                        }} >
                                                            Raise Demand
                                                        </a>}
                                                        {data?.eoi_latest_demand_id > 0 && <a className="dropdown-item" onClick={() => {
                                                            getEoiDemand(data);
                                                            getPaymentMilestones(data.eoi_uuid);
                                                            getBookingPricing(data);
                                                        }} >
                                                            Edit Demand
                                                        </a>}
                                                        {/* {data?.eoi_latest_demand_id > 0 && <a className="dropdown-item" onClick={() => {
                                                            // setSelectedItem(data);
                                                            // getPaymentMilestones(data.eoi_uuid);
                                                            // getBookingPricing(data);
                                                        }} >
                                                            View Demand
                                                        </a>} */}
                                                        <div className="head-itme">Edit <span style={{ width: '80px' }}></span></div>
                                                        <a className="dropdown-item"
                                                            onClick={() => {
                                                                setShowEOIDoneModal(true);
                                                                setSelectedItem(data)
                                                            }}
                                                        >
                                                            Edit {userProfile?.company_id === 581 ? 'Token' : 'EOI'}
                                                        </a>
                                                        {isEOIDone(data.status) && <a className="dropdown-item" onClick={() => {
                                                            setShowFollowupModal(true)
                                                            setSelectedSingleUserIdFromTable(data)
                                                        }
                                                        }>
                                                            Edit Followup
                                                        </a>}

                                                        {reAssignAvailable &&
                                                            <a className="dropdown-item"
                                                                onClick={() => {
                                                                    setLeadName(data.name);
                                                                    handleUserReassignLead(data.id)
                                                                }}
                                                            >
                                                                Re-assign Lead
                                                            </a>}

                                                        {(data.approval_status === 'pending' || (data.approval_status === 'approved' && allowedPermissions.includes('cancel_approved_booking'))) &&
                                                            <a className="dropdown-item"
                                                                onClick={() => {
                                                                    setShowCancelEOI(true);
                                                                    setBookingUUID(data.eoi_uuid);
                                                                    setSelectedLead(data);
                                                                }}
                                                            >
                                                                {data?.approval_status === 'cancelled' ? 'Edit' : ''} Cancel Token
                                                            </a>}
                                                        {data.approval_status === 'cancelled' &&
                                                            <a className="dropdown-item"
                                                                onClick={() => {
                                                                    setShowCancelEOI(true);
                                                                    setBookingUUID(data.eoi_uuid);
                                                                    setSelectedLead(data);
                                                                }}
                                                            >
                                                                Edit Cancel Token
                                                            </a>}

                                                        {callerList.length > 0 &&
                                                            (ROLE_NAME === 'Super Admin' ||
                                                                (userProfile?.allowed_modules?.includes('ivr_call') &&
                                                                    allowedPermissions.includes('use_ivr_outbound_call'))) && <a
                                                                        className="dropdown-item" onClick={() => handleClickToCall(data)}
                                                                    >
                                                                Quick Call
                                                            </a>}
                                                        {callerList.length > 0 &&
                                                            (ROLE_NAME === 'Super Admin' ||
                                                                (userProfile?.allowed_modules?.includes('ivr_call') &&
                                                                    allowedPermissions.includes('use_ivr_outbound_call'))) && <a
                                                                        className="dropdown-item" onClick={() => {
                                                                            setSelectedItem(data);
                                                                            setShowAdvanceCallModal(true);
                                                                        }}>
                                                                Advanced Call
                                                            </a>}
                                                        {deleteAvailable &&
                                                            <a className="dropdown-item del-menu-itme" onClick={() => {
                                                                setSelectedSingleUserIdFromTable(data)
                                                                setShowDeleteModal(true)
                                                            }}>Delete Lead
                                                            </a>
                                                        }

                                                    </div>
                                                </>}
                                            {/* {selectedIndex === index && <div className="dropdown view-more">
                                                <div className="dropdown-menu mini-timeline show">
                                                    {timeline?.length > 0 && <RecentActivities ActivityTimeline={timeline} leadName={data.name} closeTimelineDropdown={closeTimelineDropdown} />}
                                                    {timeline?.length === 0 && <div className='d-flex align-items-center justify-content-center' style={{ height: '100%' }}>
                                                        <Oval
                                                            height={30}
                                                            width={30}
                                                            color="#0062FF"
                                                            wrapperStyle={{}}
                                                            wrapperClass=""
                                                            visible={true}
                                                            ariaLabel='oval-loading'
                                                            secondaryColor="#0062FF"
                                                            strokeWidth={2}
                                                            strokeWidthSecondary={2}
                                                        />
                                                    </div>}
                                                </div>
                                            </div>} */}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    {leadData?.length === 0 && <NoResultFound
                        count={count}
                        filters={filters}
                        searchText={searchText}
                        showCreateLeadModal={showCreateLeadModal}
                    />}
                    {showTimeline && <MiniTimline
                        setShow={setShowTimeline}
                        show={showTimeline}
                        ActivityTimeline={timeline}
                        leadName={timelineLeadName}
                        closeTimelineDropdown={closeTimelineDropdown}
                    />}

                    {showCustomColumn &&
                        <CustomColumnModal
                            subHeading={'Booking Leads'}
                            activeColumnTab='Booking'
                            show={showCustomColumn}
                            setShow={closeCustomColumnModal}
                            updateColumnList={updateColumnList}
                            dashboardColumns={dashboardColumns}
                            tab={'Booking'}
                            handleChangeSelection={handleChangeSelection}
                            saveCurrentUserColumnPrefrence={saveCurrentUserColumnPrefrence}
                        />
                    }
                    {showDetailsModal && <DetailsModal
                        show={showDetailsModal}
                        unit={{}}
                        selectedBooking={selectedBooking}
                        closeModal={() => setShowDetailsModal(false)}
                        modalOpenFrom={'table'}
                        bookingStatus={bookingStatus}
                        ModalType='Token'
                        handleChangeApprovalStatus={handleChangeEOIStatus}
                        data={selectedLead}
                        handleSubmit={handleUpdateEOi}
                        updateData={updateData}
                        editBtn={`Edit ${userProfile?.company_id === 581 ? 'Token' : 'EOI'}`}
                        handleEditBtn={() => {
                            setShowEOIDoneModal(true)
                            setShowDetailsModal(false)
                        }}
                        operation={!allowedPermissions.includes('verify_bookings_eoi') ? 'view only' : ''}
                    />}
                    {showRaiseDemand && selectedItem.eoi_uuid && <AddDemand
                        show={showRaiseDemand}
                        closeModal={() => {
                            setSelectedItem({})
                            setShowRaiseDemand(false)
                        }}
                        item={selectedItem}
                        paymentMilestones={paymentMilestones}
                        onSuccess={updateData}
                        demandRaiseFrom='EOI table'
                        editDemandData={editDemandData}
                    />}
                    {
                        showEOIDoneModal &&
                        // <
                        <NewEOIModal
                            show={showEOIDoneModal}
                            closeModal={() => setShowEOIDoneModal(false)}
                            lead={selectedItem}
                            data={selectedItem}
                            onSuccess={() => {
                                setSelectedItem({});
                                setShowEOIDoneModal(false);
                                updateData();
                            }}
                            isEdit={showEOIDoneModal}
                            showTab={showTab}
                        />
                    }
                    {showShareLeadAccessModal &&
                        <ShareLeadAccessModal
                            show={showShareLeadAccessModal}
                            selectedItem={selectedItem}
                            closeModal={() => setShowShareLeadAccessModal(false)}
                            onSuccess={updateData}
                            allowedPermissions={allowedPermissions}
                        />
                    }
                    {showCancelEOI &&
                        <CancelBooking
                            show={showCancelEOI}
                            closeModal={() => {
                                setShowCancelEOI(false)
                            }}
                            handleSubmitSuccess={() => {
                                updateData();
                                setSelectedLead({});
                            }}
                            data={selectedLead}
                            ModalType={'Token'}
                            handleSubmit={handleUpdateEOi}
                        // updateData={updateData}
                        />
                    }
                    {showVerifyToken && <AreYouSureModal
                        show={showVerifyToken}
                        title='Verify Token before booking'
                        subText={'Kindly verify the received token amount before marking the booking status for this lead.'}
                        closeModal={() => {
                            setShowVerifyToken(false)
                        }}
                        confirmClose={() => {
                            setBookingStatus(selectedLead.approval_status);
                            getEOILeadDetails(selectedLead.eoi_uuid);
                        }}
                        buttonTitle={'Verify Token'}
                    />}
                    {
                        showAdvanceCallmodal &&
                        <AdvanceCallModal
                            show={showAdvanceCallmodal}
                            closeModal={() => {
                                setSelectedItem({})
                                setShowAdvanceCallModal(false)
                            }}
                            handleSubmit={handleAdvanceClickToCall}
                            callerList={callerList}
                        />
                    }
                    {
                        showLeadTaggingModal &&
                        <LeadTaggingModal
                            show={showLeadTaggingModal}
                            uuid={selectedItem.id}
                            updateData={updateData}
                            selectedItem={selectedItem}
                            closeModal={() => {
                                setSelectedItem({})
                                setShowLeadTaggingModal(false)
                            }}
                        />
                    }
                </div>
            </div >
        </>
    )
}