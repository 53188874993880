import React from 'react';
import TextInput from '../../modules/Lead Details/components/Info Components/Text Input/TextInput';
import InputModal from '../Modals/InputModal';
import MobileInputModal from '../Modals/MobileInputModal';


export default function DynamicMobileInput({
    editActive,
    setEditActive,
    heading,
    name,
    placeholder,
    isEditable,
    value,
    type,
    onValueChange,
    onSave,
    countryCode,
    handleBlur,
    leadAccess,
    userProfile,
    isNewVersion
}) {
    return (
        <>
            {
                !editActive ?
                    <TextInput
                        inputKey={name}
                        heading={heading}
                        placeholder={placeholder}
                        isEditable={isEditable}
                        enableEditing={editActive}
                        value={value}
                        countryCode={countryCode}
                        //  onClickHandler={() => { }}
                        editActive={editActive}
                        onEditClick={() => setEditActive(true)}
                        editDeactive={() => setEditActive(false)}
                        leadAccess={leadAccess}
                        userProfile={userProfile}
                        isNewVersion={isNewVersion}
                    /> :
                    <MobileInputModal
                        show={editActive}
                        setShow={() => setEditActive(!editActive)}
                        name={heading}
                        type={type || "text"}
                        placeholder={placeholder}
                        countryCode={countryCode}
                        value={value}
                        onChange={onValueChange}
                        editSave={onSave}
                        handleBlur={handleBlur}
                    />
            }
        </>
    )
}