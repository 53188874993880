import React, { useContext, useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { Image, Dropdown } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import downArrow from "../../assets/icons/downarrow.svg";
import { intentEnum } from '../../config/enums';
import "../../global.css";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { createDatewithTime } from '../../helpers/date';
import Consumer from '../../helpers/context';

// selectedIntent => number
// options [] => [{value: "",label: ""}]

export default function IntentSelect({
    selectedIntent,
    intentOptions,
    onChange,
    data,
    rowLength,
    tableName,
    statusName
}) {

    const [selectedIntentText, setSelectedIntentText] = useState({})

    let { userProfile } = useContext(Consumer);

    const getIntentClassName = (intent) => {
        switch (intent) {
            case intentEnum.New_Lead:
                return 'pr-btn';
            case intentEnum.Hot:
                return 'hot-btn';
            case intentEnum.Dead:
                return 'dead-btn';
            case intentEnum.Warm:
                return 'warm-btn';
            case intentEnum.Cold:
                return 'cold-btn';
        }
    }

    const deadText = useMemo(() => {
        if (data)
            return `${data['Dead Reason'] || data?.reason} : ${data['Dead Comment'] || ''}`
        else return ''
    }, [data])

    useEffect(() => {
        const selectedText = intentOptions && intentOptions.length > 0 ? intentOptions.find(data => selectedIntent == data.value) : ''
        if (selectedText) {
            setSelectedIntentText(selectedText)
        }

    }, [intentOptions, selectedIntent]);

    return (
        <>
            <div className="intent-drop">
                {['Dead Lead', 'Dump'].includes(statusName) && <>

                    {(tableName === 'AllLead' && data['Dead Reason'] || data?.reason) ||
                        (data['Dead Comment'] || data.comment) ? <OverlayTrigger
                            placement="left"

                            overlay={
                                <Tooltip id="button-tooltip" className='leadmanager-tooltip'>
                                    <div className='info-msg'>
                                        {tableName === 'DeadLead' ? 'View comment' : 'View Reason'}
                                    </div>
                                </Tooltip>
                            }
                        >

                        <button
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className={`dead-btn dead-reason ${rowLength === 1 ? 'single-dead-lead' : ''} ${(userProfile?.allowed_modules?.includes('lead_sharing') && data?.access === 'view') ? 'cursor-not-allowed' : ''}`}
                            disabled={(userProfile?.allowed_modules?.includes('lead_sharing') && data?.access === 'view')}
                        >
                            {statusName}
                        </button>
                    </OverlayTrigger> :
                        <button
                            type="button"
                            className={`dead-btn dead-reason ${rowLength === 1 ? 'single-dead-lead' : ''} ${(userProfile?.allowed_modules?.includes('lead_sharing') && data?.access === 'view') ? 'cursor-not-allowed' : ''}`}
                            disabled={(userProfile?.allowed_modules?.includes('lead_sharing') && data?.access === 'view')}
                        >
                            {statusName}
                        </button>
                    }
                    <div className="dropdown-menu dead-lead-dropdown " aria-labelledby="dropdownMenuButton">
                        <a className="close"><svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13.4115 12.0003L18.6886 17.2822C19.079 17.6729 19.0787 18.306 18.688 18.6964C18.2973 19.0867 17.6641 19.0864 17.2738 18.6957L11.9963 13.4135L6.70658 18.6953C6.31577 19.0856 5.6826 19.0851 5.29237 18.6943C4.90213 18.3035 4.9026 17.6703 5.29342 17.2801L10.5827 11.9986L5.29565 6.70679C4.9053 6.31609 4.90559 5.68292 5.29629 5.29257C5.68699 4.90223 6.32016 4.90251 6.7105 5.29321L11.998 10.5855L17.2739 5.31734C17.6648 4.92711 18.2979 4.92758 18.6881 5.31839C19.0784 5.70921 19.0779 6.34237 18.6871 6.73261L13.4115 12.0003Z" fill="#171725"></path></svg></a>
                        <div className="d-flex flex-column text-break dead-lead-dropdown-content">
                            {(data['Dead Reason'] || data?.reason) && tableName === 'AllLead' &&
                                <p className='black-dark-800 fz14 d-flex  mb-8 pr-4'>
                                    <svg width="18" height="18" className="mr-2 mt-1" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z" stroke="#FC5A5A
                                        " strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 13.3333V10" stroke="#FC5A5A
                                        " strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path><path d="M10 6.6665H10.0088" stroke="#FC5A5A
                                        " strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                    {data['Dead Reason'] || data?.reason}
                                </p>
                            }

                            {(data['Dead Comment'] || data?.comment) &&
                                <div>
                                    <p className='black-dark-700 fz14 mb-0 d-flex pr-4'>
                                        <svg className='mr-2 mt-1' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M7 1H15.1482C15.7514 1 16.3224 1.27227 16.7022 1.74098L20.554 6.49524C20.8425 6.8514 21 7.29587 21 7.75425V19C21 21.2091 19.2091 23 17 23H7C4.79086 23 3 21.2091 3 19V5C3 2.79086 4.79086 1 7 1ZM15.625 3.58854V6.4H17.9028L15.625 3.58854ZM7 3C5.89543 3 5 3.89543 5 5V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V8.4H15.625C14.5204 8.4 13.625 7.50457 13.625 6.4V3H7Z" fill="#92929D"></path></svg>
                                        {data['Dead Comment'] || data?.comment || '-'}
                                    </p>

                                </div>
                            }
                            {data['Dead Lead Time'] && <p className='black-dark-700 fz12 mb-0 text-right mt-1'>{createDatewithTime(new Date(data['Dead Lead Time']))}</p>}
                        </div>
                    </div>
                </>}

                {!statusName && <button className='pr-btn'>New Lead</button>}
                {
                    statusName && selectedIntent && selectedIntentText.label &&
                    <>
                        <button
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className={`${getIntentClassName(selectedIntentText && selectedIntentText.label ? selectedIntentText.label : "pr-btn")} ${(userProfile?.allowed_modules?.includes('lead_sharing') && data?.access === 'view') ? 'cursor-not-allowed' : ''}`}
                            disabled={(userProfile?.allowed_modules?.includes('lead_sharing') && data?.access === 'view')}
                        >
                            {selectedIntentText.label ? selectedIntentText.label : 'Select Intent'}
                            <Image src={downArrow} />
                        </button>

                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                            {
                                intentOptions && intentOptions.length > 0 ?
                                    intentOptions.map((option, index) => {
                                        return (
                                            selectedIntent !== option.value ?
                                                <a tabIndex={''}
                                                    key={index}
                                                    className={`dropdown-item ${getIntentClassName(option.label)} text-capitalize`}
                                                    onClick={() => onChange(option)}
                                                >{option.label}
                                                </a>
                                                :
                                                null)
                                    }) : " "
                            }
                        </div>
                    </>
                }
            </div >
        </>
    )
}