import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap'
import { getKnowlarityIvrCallerIdByCompanyId } from '../../services/private/company.service';
import SliderModal from './SliderModal';
import InputSelect from '../InputGroup/InputSelect';
import InputRupees from '../InputGroup/InputRupees';
import InputTextarea from '../InputGroup/InputTextarea';
import { toast } from 'react-toastify';
import { submitResaleRequestByPropacity } from '../../services/private/customerApp.service';
import { Oval } from 'react-loader-spinner';

const ResaleRequestModal = ({ show, closeModal, updateData, selectedItem }) => {
    const [amount, setAmount] = useState('');
    const [remarks, setRemarks] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        if (!amount) {
            setError(true);
            return;
        } else {
            setError(false);
        }
        const payload = {
            booking_uuid: selectedItem?.booking_uuid,
            desired_amount: amount,
            comments: remarks
        }
        setLoading(true);
        const res = await submitResaleRequestByPropacity(payload)
        setLoading(false)
        if (res.data.status === 200) {
            toast.success(res.data.message);
            updateData()
        }
    }
    return (
        <SliderModal
            setShow={closeModal}
            show={show}
            title={`Resale Request`}
            body={<>
                <div className="modal-conents mt-4">
                    {/* <div className="d-flex justify-content-start align-items-start flex-column filter-sticky px-20 pb-3">
                        <h1 className='fz24 h-font fw-regular mb-0'>Advance call</h1>
                    </div> */}
                    <div className='px-3 h-[75vh]'>
                        <div className='!mb-4'>
                            <label htmlFor="">Name</label>
                            <p className='m-0 capitalize'>{selectedItem?.name || '-'}</p>
                        </div>
                        <InputRupees
                            label={'Desire Amount'}
                            placeholder={'Enter Desire Amount'}
                            value={amount}
                            onChange={(value) => setAmount(value)}
                            handleBlur={() => { }}
                            error={error && !amount ? 'Please Enter Desire Amount' : ''}
                            required={true}
                        />
                        <InputTextarea
                            label='Remarks'
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                        />
                    </div>
                    <div className="form-inline justify-content-between px-20 filter-ok-cancel">
                        <button className="cancel outline-btn" onClick={closeModal}>
                            Cancel
                        </button>
                        {
                            !loading && <button className="pr-btn" onClick={() => handleSubmit()} >Confirm</button>

                        }
                        {
                            loading && <button className="pr-btn flex items-center justify-center" >
                                <Oval
                                    height={24}
                                    width={24}
                                    color="#FFFFFF"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#FFFFFF"
                                    strokeWidth={4}
                                    strokeWidthSecondary={4}
                                />
                            </button>
                        }
                    </div>
                </div>
            </>
            }
        />
    )
}

export default ResaleRequestModal;