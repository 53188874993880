import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg'
import RightModal from '../../../components/Modals/RightModal';
import { getDisplayDateTime } from '../../../helpers/helpers';
import moment from 'moment';
import { ReactComponent as EditBlackIcon } from '../../../assets/icons/editgray.svg';
import InputTextarea from '../../../components/InputGroup/InputTextarea';

const AddRemarksModal = ({ show, closeModal, handleSubmit, item, remarksList, setRemarksList, handleUpdateRemark, showEditIcon = true }) => {
    const [remarks, setRemarks] = useState({});
    const [selectedIndex, setSelectedIndex] = useState('');
    const [error, setError] = useState(false)

    return (
        <RightModal show={show} closeModal={closeModal} modalClass={`configurationModal`}>
            <div className='d-flex justify-content-between border-bottom border-color px-20 py-16'>
                <h1 className='mb-0 black inter fz20 fw-po-medium'>
                    Notes</h1>
                <button className='border-0 bg-tr ' onClick={() => {
                    closeModal();
                }}><CloseIcon /></button>
            </div>
            {remarksList.length > 0 && <div className="resent-notes px-4 !pt-4 !max-h-[63vh] overflow-auto">
                {
                    remarksList?.map((remark, index) => (
                        <>
                            <div className='!pt-3 !pb-3 !pr-5 flex flex-row items-center gap-x-6 !border-b border-grayLight' key={remark.updated_on}>
                                <div className='w-100'>
                                    <div className='flex items-center justify-between '>
                                        <p className='!mb-1 inter text-base text-black800 font-medium !break-all'>{remark.note}</p>
                                        {showEditIcon && <div className='w-6 h-6 basis-6 shrink-0 grow-0 edit-note-pencil  '>
                                            <button className='w-6 h-6 flex items-center justify-center rounded-full bg-blue-light' onClick={() => {
                                                setSelectedIndex(index)
                                                setRemarks(remark);
                                                const remarkCopy = [...remarksList];
                                                remarkCopy.splice(index, 1);
                                                setRemarksList([...remarkCopy]);
                                            }}>
                                                <EditBlackIcon className='fill-primary' />
                                            </button>
                                        </div>}
                                    </div>
                                    <p className='!mb-0 text-xs flex justify-end inter text-black700 font-medium'>{remark.updated_by_name || remark.created_by_name} • {moment(remark.updated_on).format("hh:mm a, Do MMM YYYY")}</p>
                                </div>

                            </div>
                            {/* {notesList.length > 0 && <div className=' !pt-3 border-b !pb-3'></div>} */}
                        </>
                    ))
                }
                {/* {(Object.keys(selectedRemark).length > 0) && <div className='!border-t border-grayLight !pt-3 border-b !pb-3'>
                    <InputTextarea
                        value={selectedRemark.note}
                        onChange={(e) => setSelectedRemarks({ ...selectedRemark, note: e.target.value })}
                    />
                    <div className='flex justify-end !mt-3 gap-x-3'>
                        <button
                            className='text-base inter font-medium w-[90px] text-center rounded-lg !py-2 leading-6 bg-[#F1F1F5] text-textblack'
                            onClick={() => {
                                if (selectedRemark) {
                                    let remakrs = [...remarksList];
                                    remakrs.push(selectedRemark)
                                    setRemarksList([...remakrs]);

                                }
                                // setSelectedIndex('')
                                setSelectedRemarks({});
                            }}
                        >Cancel</button>
                        <button
                            className='text-base inter font-medium w-[90px] text-center rounded-lg !py-2 leading-6 bg-blueHighlight !text-primary'
                            onClick={() => handleUpdateRemark(selectedRemark)}
                        >Save</button>
                    </div>
                </div>} */}
            </div>}
            <div className={`flex flex-col p-4 w-full absolute bottom-16 bg-white form-group ${error ? 'invalid' : ''}`}>
                <label htmlFor="">New Remarks <sup>*</sup></label>
                <textarea rows="2"
                    className='border rounded-lg'
                    value={remarks?.note}
                    onChange={(e) => setRemarks({ ...remarks, note: e.target.value })}
                ></textarea>
                {error && !remarks?.note && (
                    <>
                        <div className="input-feedback mt-8">Please Enter Remarks
                        </div>
                    </>

                )}
            </div>
            <div className={`absolute bottom-0 flex flex-row items-center justify-between bg-white border-top border-color w-100 space-x-3 p-3`}>
                <button className='w-1/2 outline-btn !py-1.5 !text-lg !font-medim hover:bg-black100 !rounded-lg' onClick={() => {
                    closeModal();
                }}>Cancel</button>
                <button className='pr-btn w-1/2 !py-1.5 !text-lg !font-medim !rounded-lg' onClick={() => {
                    if (!remarks.note) {
                        setError(true);
                        return
                    } else {
                        setError(false)
                    }
                    handleSubmit(remarks.note)
                }
                }>Save</button>
            </div>
        </RightModal>
    )
}

export default AddRemarksModal