export const statesDropDown = [
    {
        label: "Andhra Pradesh",
        value: "Andhra Pradesh"
    },
    {
        label: "Andaman and Nicobar Islands",
        value: "Andaman and Nicobar Islands"
    },
    {
        label: "Arunachal Pradesh",
        value: "Arunachal Pradesh"
    },
    {
        label: "Assam",
        value: "Assam"
    },
    {
        label: "Bihar",
        value: "Bihar"
    },
    {
        label: "Chandigarh",
        value: "Chandigarh"
    },
    {
        label: "Chhattisgarh",
        value: "Chhattisgarh"
    },
    {
        label: "Dadar and Nagar Haveli",
        value: "Dadar and Nagar Haveli"
    },
    {
        label: "Daman and Diu",
        value: "Daman and Diu"
    },
    {
        label: "Delhi",
        value: "Delhi"
    },
    {
        label: "Lakshadweep",
        value: "Lakshadweep"
    },
    {
        label: "Puducherry",
        value: "Puducherry"
    },
    {
        label: "Goa",
        value: "Goa"
    },
    {
        label: "Gujarat",
        value: "Gujarat"
    },
    {
        label: "Haryana",
        value: "Haryana"
    },
    {
        label: "Himachal Pradesh",
        value: "Himachal Pradesh"
    },
    {
        label: "Jammu and Kashmir",
        value: "Jammu and Kashmir"
    },
    {
        label: "Jharkhand",
        value: "Jharkhand"
    },
    {
        label: "Karnataka",
        value: "Karnataka"
    },
    {
        label: "Kerala",
        value: "Kerala"
    },
    {
        label: "Madhya Pradesh",
        value: "Madhya Pradesh"
    },
    {
        label: "Maharashtra",
        value: "Maharashtra"
    },
    {
        label: "Manipur",
        value: "Manipur"
    },
    {
        label: "Meghalaya",
        value: "Meghalaya"
    },
    {
        label: "Mizoram",
        value: "Mizoram"
    },
    {
        label: "Nagaland",
        value: "Nagaland"
    },
    {
        label: "Odisha",
        value: "Odisha"
    },
    {
        label: "Punjab",
        value: "Punjab"
    },
    {
        label: "Rajasthan",
        value: "Rajasthan"
    },
    {
        label: "Sikkim",
        value: "Sikkim"
    },
    {
        label: "Tamil Nadu",
        value: "Tamil Nadu"
    },
    {
        label: "Telangana",
        value: "Telangana"
    },
    {
        label: "Tripura",
        value: "Tripura"
    },
    {
        label: "Uttar Pradesh",
        value: "Uttar Pradesh"
    },
    {
        label: "Uttarakhand",
        value: "Uttarakhand"
    },
    {
        label: "West Bengal",
        value: "West Bengal"
    }
]

export const countriesDropdown = [
    {
        label: "Afghanistan",
        value: "Afghanistan"
    },
    {
        label: "Albania",
        value: "Albania"
    },
    {
        label: "Algeria",
        value: "Algeria"
    },
    {
        label: "Andorra",
        value: "Andorra"
    },
    {
        label: "Angola",
        value: "Angola"
    },
    {
        label: "Antigua & Deps",
        value: "Antigua & Deps"
    },
    {
        label: "Argentina",
        value: "Argentina"
    },
    {
        label: "Armenia",
        value: "Armenia"
    },
    {
        label: "Australia",
        value: "Australia"
    },
    {
        label: "Austria",
        value: "Austria"
    },
    {
        label: "Azerbaijan",
        value: "Azerbaijan"
    },
    {
        label: "Bahamas",
        value: "Bahamas"
    },
    {
        label: "Bahrain",
        value: "Bahrain"
    },
    {
        label: "Bangladesh",
        value: "Bangladesh"
    },
    {
        label: "Barbados",
        value: "Barbados"
    },
    {
        label: "Belarus",
        value: "Belarus"
    },
    {
        label: "Belgium",
        value: "Belgium"
    },
    {
        label: "Belize",
        value: "Belize"
    },
    {
        label: "Benin",
        value: "Benin"
    },
    {
        label: "Bhutan",
        value: "Bhutan"
    },
    {
        label: "Bolivia",
        value: "Bolivia"
    },
    {
        label: "Bosnia Herzegovina",
        value: "Bosnia Herzegovina"
    },
    {
        label: "Botswana",
        value: "Botswana"
    },
    {
        label: "Brazil",
        value: "Brazil"
    },
    {
        label: "Brunei",
        value: "Brunei"
    },
    {
        label: "Bulgaria",
        value: "Bulgaria"
    },
    {
        label: "Burkina",
        value: "Burkina"
    },
    {
        label: "Burundi",
        value: "Burundi"
    },
    {
        label: "Cambodia",
        value: "Cambodia"
    },
    {
        label: "Cameroon",
        value: "Cameroon"
    },
    {
        label: "Canada",
        value: "Canada"
    },
    {
        label: "Cape Verde",
        value: "Cape Verde"
    },
    {
        label: "Central African Rep",
        value: "Central African Rep"
    },
    {
        label: "Chad",
        value: "Chad"
    },
    {
        label: "Chile",
        value: "Chile"
    },
    {
        label: "China",
        value: "China"
    },
    {
        label: "Colombia",
        value: "Colombia"
    },
    {
        label: "Comoros",
        value: "Comoros"
    },
    {
        label: "Congo",
        value: "Congo"
    },
    {
        label: "Congo {Democratic Rep}",
        value: "Congo {Democratic Rep}"
    },
    {
        label: "Costa Rica",
        value: "Costa Rica"
    },
    {
        label: "Croatia",
        value: "Croatia"
    },
    {
        label: "Cuba",
        value: "Cuba"
    },
    {
        label: "Cyprus",
        value: "Cyprus"
    },
    {
        label: "Czech Republic",
        value: "Czech Republic"
    },
    {
        label: "Denmark",
        value: "Denmark"
    },
    {
        label: "Djibouti",
        value: "Djibouti"
    },
    {
        label: "Dominica",
        value: "Dominica"
    },
    {
        label: "Dominican Republic",
        value: "Dominican Republic"
    },
    {
        label: "East Timor",
        value: "East Timor"
    },
    {
        label: "Ecuador",
        value: "Ecuador"
    },
    {
        label: "Egypt",
        value: "Egypt"
    },
    {
        label: "El Salvador",
        value: "El Salvador"
    },
    {
        label: "Equatorial Guinea",
        value: "Equatorial Guinea"
    },
    {
        label: "Eritrea",
        value: "Eritrea"
    },
    {
        label: "Estonia",
        value: "Estonia"
    },
    {
        label: "Ethiopia",
        value: "Ethiopia"
    },
    {
        label: "Fiji",
        value: "Fiji"
    },
    {
        label: "Finland",
        value: "Finland"
    },
    {
        label: "France",
        value: "France"
    },
    {
        label: "Gabon",
        value: "Gabon"
    },
    {
        label: "Gambia",
        value: "Gambia"
    },
    {
        label: "Georgia",
        value: "Georgia"
    },
    {
        label: "Germany",
        value: "Germany"
    },
    {
        label: "Ghana",
        value: "Ghana"
    },
    {
        label: "Greece",
        value: "Greece"
    },
    {
        label: "Grenada",
        value: "Grenada"
    },
    {
        label: "Guatemala",
        value: "Guatemala"
    },
    {
        label: "Guinea",
        value: "Guinea"
    },
    {
        label: "Guinea-Bissau",
        value: "Guinea-Bissau"
    },
    {
        label: "Guyana",
        value: "Guyana"
    },
    {
        label: "Haiti",
        value: "Haiti"
    },
    {
        label: "Honduras",
        value: "Honduras"
    },
    {
        label: "Hungary",
        value: "Hungary"
    },
    {
        label: "Iceland",
        value: "Iceland"
    },
    {
        label: "India",
        value: "India"
    },
    {
        label: "Indonesia",
        value: "Indonesia"
    },
    {
        label: "Iran",
        value: "Iran"
    },
    {
        label: "Iraq",
        value: "Iraq"
    },
    {
        label: "Ireland {Republic}",
        value: "Ireland {Republic}"
    },
    {
        label: "Israel",
        value: "Israel"
    },
    {
        label: "Italy",
        value: "Italy"
    },
    {
        label: "Ivory Coast",
        value: "Ivory Coast"
    },
    {
        label: "Jamaica",
        value: "Jamaica"
    },
    {
        label: "Japan",
        value: "Japan"
    },
    {
        label: "Jordan",
        value: "Jordan"
    },
    {
        label: "Kazakhstan",
        value: "Kazakhstan"
    },
    {
        label: "Kenya",
        value: "Kenya"
    },
    {
        label: "Kiribati",
        value: "Kiribati"
    },
    {
        label: "Korea North",
        value: "Korea North"
    },
    {
        label: "Korea South",
        value: "Korea South"
    },
    {
        label: "Kosovo",
        value: "Kosovo"
    },
    {
        label: "Kuwait",
        value: "Kuwait"
    },
    {
        label: "Kyrgyzstan",
        value: "Kyrgyzstan"
    },
    {
        label: "Laos",
        value: "Laos"
    },
    {
        label: "Latvia",
        value: "Latvia"
    },
    {
        label: "Lebanon",
        value: "Lebanon"
    },
    {
        label: "Lesotho",
        value: "Lesotho"
    },
    {
        label: "Liberia",
        value: "Liberia"
    },
    {
        label: "Libya",
        value: "Libya"
    },
    {
        label: "Liechtenstein",
        value: "Liechtenstein"
    },
    {
        label: "Lithuania",
        value: "Lithuania"
    },
    {
        label: "Luxembourg",
        value: "Luxembourg"
    },
    {
        label: "Macedonia",
        value: "Macedonia"
    },
    {
        label: "Madagascar",
        value: "Madagascar"
    },
    {
        label: "Malawi",
        value: "Malawi"
    },
    {
        label: "Malaysia",
        value: "Malaysia"
    },
    {
        label: "Maldives",
        value: "Maldives"
    },
    {
        label: "Mali",
        value: "Mali"
    },
    {
        label: "Malta",
        value: "Malta"
    },
    {
        label: "Marshall Islands",
        value: "Marshall Islands"
    },
    {
        label: "Mauritania",
        value: "Mauritania"
    },
    {
        label: "Mauritius",
        value: "Mauritius"
    },
    {
        label: "Mexico",
        value: "Mexico"
    },
    {
        label: "Micronesia",
        value: "Micronesia"
    },
    {
        label: "Moldova",
        value: "Moldova"
    },
    {
        label: "Monaco",
        value: "Monaco"
    },
    {
        label: "Mongolia",
        value: "Mongolia"
    },
    {
        label: "Montenegro",
        value: "Montenegro"
    },
    {
        label: "Morocco",
        value: "Morocco"
    },
    {
        label: "Mozambique",
        value: "Mozambique"
    },
    {
        label: "Myanmar, {Burma}",
        value: "Myanmar, {Burma}"
    },
    {
        label: "Namibia",
        value: "Namibia"
    },
    {
        label: "Nauru",
        value: "Nauru"
    },
    {
        label: "Nepal",
        value: "Nepal"
    },
    {
        label: "Netherlands",
        value: "Netherlands"
    },
    {
        label: "New Zealand",
        value: "New Zealand"
    },
    {
        label: "Nicaragua",
        value: "Nicaragua"
    },
    {
        label: "Niger",
        value: "Niger"
    },
    {
        label: "Nigeria",
        value: "Nigeria"
    },
    {
        label: "Norway",
        value: "Norway"
    },
    {
        label: "Oman",
        value: "Oman"
    },
    {
        label: "Pakistan",
        value: "Pakistan"
    },
    {
        label: "Palau",
        value: "Palau"
    },
    {
        label: "Panama",
        value: "Panama"
    },
    {
        label: "Papua New Guinea",
        value: "Papua New Guinea"
    },
    {
        label: "Paraguay",
        value: "Paraguay"
    },
    {
        label: "Peru",
        value: "Peru"
    },
    {
        label: "Philippines",
        value: "Philippines"
    },
    {
        label: "Poland",
        value: "Poland"
    },
    {
        label: "Portugal",
        value: "Portugal"
    },
    {
        label: "Qatar",
        value: "Qatar"
    },
    {
        label: "Romania",
        value: "Romania"
    },
    {
        label: "Russian Federation",
        value: "Russian Federation"
    },
    {
        label: "Rwanda",
        value: "Rwanda"
    },
    {
        label: "St Kitts & Nevis",
        value: "St Kitts & Nevis"
    },
    {
        label: "St Lucia",
        value: "St Lucia"
    },
    {
        label: "Saint Vincent & the Grenadines",
        value: "Saint Vincent & the Grenadines"
    },
    {
        label: "Samoa",
        value: "Samoa"
    },
    {
        label: "San Marino",
        value: "San Marino"
    },
    {
        label: "Sao Tome & Principe",
        value: "Sao Tome & Principe"
    },
    {
        label: "Saudi Arabia",
        value: "Saudi Arabia"
    },
    {
        label: "Senegal",
        value: "Senegal"
    },
    {
        label: "Serbia",
        value: "Serbia"
    },
    {
        label: "Seychelles",
        value: "Seychelles"
    },
    {
        label: "Sierra Leone",
        value: "Sierra Leone"
    },
    {
        label: "Singapore",
        value: "Singapore"
    },
    {
        label: "Slovakia",
        value: "Slovakia"
    },
    {
        label: "Slovenia",
        value: "Slovenia"
    },
    {
        label: "Solomon Islands",
        value: "Solomon Islands"
    },
    {
        label: "Somalia",
        value: "Somalia"
    },
    {
        label: "South Africa",
        value: "South Africa"
    },
    {
        label: "South Sudan",
        value: "South Sudan"
    },
    {
        label: "Spain",
        value: "Spain"
    },
    {
        label: "Sri Lanka",
        value: "Sri Lanka"
    },
    {
        label: "Sudan",
        value: "Sudan"
    },
    {
        label: "Suriname",
        value: "Suriname"
    },
    {
        label: "Swaziland",
        value: "Swaziland"
    },
    {
        label: "Sweden",
        value: "Sweden"
    },
    {
        label: "Switzerland",
        value: "Switzerland"
    },
    {
        label: "Syria",
        value: "Syria"
    },
    {
        label: "Taiwan",
        value: "Taiwan"
    },
    {
        label: "Tajikistan",
        value: "Tajikistan"
    },
    {
        label: "Tanzania",
        value: "Tanzania"
    },
    {
        label: "Thailand",
        value: "Thailand"
    },
    {
        label: "Togo",
        value: "Togo"
    },
    {
        label: "Tonga",
        value: "Tonga"
    },
    {
        label: "Trinidad & Tobago",
        value: "Trinidad & Tobago"
    },
    {
        label: "Tunisia",
        value: "Tunisia"
    },
    {
        label: "Turkey",
        value: "Turkey"
    },
    {
        label: "Turkmenistan",
        value: "Turkmenistan"
    },
    {
        label: "Tuvalu",
        value: "Tuvalu"
    },
    {
        label: "Uganda",
        value: "Uganda"
    },
    {
        label: "Ukraine",
        value: "Ukraine"
    },
    {
        label: "United Arab Emirates",
        value: "United Arab Emirates"
    },
    {
        label: "United Kingdom",
        value: "United Kingdom"
    },
    {
        label: "United States",
        value: "United States"
    },
    {
        label: "Uruguay",
        value: "Uruguay"
    },
    {
        label: "Uzbekistan",
        value: "Uzbekistan"
    },
    {
        label: "Vanuatu",
        value: "Vanuatu"
    },
    {
        label: "Vatican City",
        value: "Vatican City"
    },
    {
        label: "Venezuela",
        value: "Venezuela"
    },
    {
        label: "Vietnam",
        value: "Vietnam"
    },
    {
        label: "Yemen",
        value: "Yemen"
    },
    {
        label: "Zambia",
        value: "Zambia"
    },
    {
        label: "Zimbabwe",
        value: "Zimbabwe"
    }

]

export const ReligionList = [
    {
        label: 'Hinduism ',
        value: 'Hinduism '
    },
    {
        label: 'Islam',
        value: 'Islam'
    },
    {
        label: 'Christianity',
        value: 'Christianity'
    },
    {
        label: 'Sikhism',
        value: 'Sikhism'
    },
    {
        label: 'Buddhism',
        value: 'Buddhism'
    },
    {
        label: 'Jainism',
        value: 'Jainism'
    },
    {
        label: 'Other',
        value: 'Other'
    }
]

export const SalutationList = [
    {
        label: 'MR',
        value: 'MR'
    },
    {
        label: 'MS',
        value: 'MS'
    },
    {
        label: 'MRS',
        value: 'MRS'
    }
]

export const PaymentModeList = [
    {
        label: 'NEFT',
        value: 'neft'
    },
    {
        label: 'RTGS',
        value: 'rtgs'
    },
    {
        label: 'IMPS',
        value: 'imps'
    },
    {
        label: 'DEBIT CARD',
        value: 'debit_card'
    },
    {
        label: 'CREDIT CARD',
        value: 'credit_card'
    },
    {
        label: 'CHEQUE',
        value: 'cheque'
    },
    {
        label: 'UPI',
        value: 'upi'
    },
    {
        label: 'Other',
        value: 'other'
    }
]

export const BankList = [
    {
        label: 'Andhra Pradesh Grameena Vikas Bank',
        value: 'Andhra Pradesh Grameena Vikas Bank'
    },
    {
        label: 'Andhra Pragathi Grameena Bank',
        value: 'Andhra Pragathi Grameena Bank'
    },
    {
        label: 'Arunachal Pradesh Rural Bank',
        value: 'Arunachal Pradesh Rural Bank'
    },
    {
        label: 'Aryavart Bank',
        value: 'Aryavart Bank'
    },
    {
        label: 'Assam Gramin Vikash Bank',
        value: 'Assam Gramin Vikash Bank'
    },
    {
        label: 'Au Small Finance Bank',
        value: 'Au Small Finance Bank'
    },
    {
        label: 'Axis Bank',
        value: 'Axis Bank'
    },
    {
        label: 'Bandhan Bank',
        value: 'Bandhan Bank'
    },
    {
        label: 'Bangiya Gramin Vikas Bank',
        value: 'Bangiya Gramin Vikas Bank'
    },
    {
        label: 'Bank of Baroda',
        value: 'Bank of Baroda'
    },
    {
        label: 'Bank of India',
        value: 'Bank of India'
    },
    {
        label: 'Bank of Maharashtra',
        value: 'Bank of Maharashtra'
    },
    {
        label: 'Baroda Gujarat Gramin Bank',
        value: 'Baroda Gujarat Gramin Bank'
    },
    {
        label: 'Baroda Rajasthan Kshetriya Gramin Bank',
        value: 'Baroda Rajasthan Kshetriya Gramin Bank'
    },
    {
        label: 'Baroda UP Bank',
        value: 'Baroda UP Bank'
    },
    {
        label: 'CSB Bank',
        value: 'CSB Bank'
    },
    {
        label: 'Canara Bank',
        value: 'Canara Bank'
    },
    {
        label: 'Capital Small Finance Bank',
        value: 'Capital Small Finance Bank'
    },
    {
        label: 'Central Bank of India',
        value: 'Central Bank of India'
    },
    {
        label: 'Chaitanya Godavari Grameena Bank',
        value: 'Chaitanya Godavari Grameena Bank'
    },
    {
        label: 'Chhattisgarh Rajya Gramin Bank',
        value: 'Chhattisgarh Rajya Gramin Bank'
    },
    {
        label: 'City Union Bank',
        value: 'City Union Bank'
    },
    {
        label: 'DCB Bank',
        value: 'DCB Bank'
    },
    {
        label: 'Dakshin Bihar Gramin Bank',
        value: 'Dakshin Bihar Gramin Bank'
    },
    {
        label: 'Dhanlaxmi Bank',
        value: 'Dhanlaxmi Bank'
    },
    {
        label: 'ESAF Small Finance Bank',
        value: 'ESAF Small Finance Bank'
    },
    {
        label: 'Ellaquai Dehati Bank',
        value: 'Ellaquai Dehati Bank'
    },
    {
        label: 'Equitas Small Finance Bank',
        value: 'Equitas Small Finance Bank'
    },
    {
        label: 'Federal Bank',
        value: 'Federal Bank'
    },
    {
        label: 'Fincare Small Finance Bank',
        value: 'Fincare Small Finance Bank'
    },
    {
        label: 'HDFC Bank',
        value: 'HDFC Bank'
    },
    {
        label: 'Himachal Pradesh Gramin Bank',
        value: 'Himachal Pradesh Gramin Bank'
    },
    {
        label: 'ICICI Bank',
        value: 'ICICI Bank'
    },
    {
        label: 'IDBI Bank',
        value: 'IDBI Bank'
    },
    {
        label: 'IDFC First Bank',
        value: 'IDFC First Bank'
    },
    {
        label: 'Indian Bank',
        value: 'Indian Bank'
    },
    {
        label: 'Indian Overseas Bank',
        value: 'Indian Overseas Bank'
    },
    {
        label: 'Induslnd Bank',
        value: 'Induslnd Bank'
    },
    {
        label: 'J&K Grameen Bank',
        value: 'J&K Grameen Bank'
    },
    {
        label: 'Jammu & Kashmir Bank',
        value: 'Jammu & Kashmir Bank'
    },
    {
        label: 'Jana Small Finance Bank',
        value: 'Jana Small Finance Bank'
    },
    {
        label: 'Jharkhand Rajya Gramin Bank',
        value: 'Jharkhand Rajya Gramin Bank'
    },
    {
        label: 'Karnataka Bank',
        value: 'Karnataka Bank'
    },
    {
        label: 'Karnataka Gramin Bank',
        value: 'Karnataka Gramin Bank'
    },
    {
        label: 'Karnataka Vikas Grameena Bank',
        value: 'Karnataka Vikas Grameena Bank'
    },
    {
        label: 'Karur Vysya Bank',
        value: 'Karur Vysya Bank'
    },
    {
        label: 'Kerala Gramin Bank',
        value: 'Kerala Gramin Bank'
    },
    {
        label: 'Kotak Mahindra Bank',
        value: 'Kotak Mahindra Bank'
    },
    {
        label: 'Madhya Pradesh Gramin Bank',
        value: 'Madhya Pradesh Gramin Bank'
    },
    {
        label: 'Madhyanchal Gramin Bank',
        value: 'Madhyanchal Gramin Bank'
    },
    {
        label: 'Maharashtra Gramin Bank',
        value: 'Maharashtra Gramin Bank'
    },
    {
        label: 'Manipur Rural Bank',
        value: 'Manipur Rural Bank'
    },
    {
        label: 'Meghalaya Rural Bank',
        value: 'Meghalaya Rural Bank'
    },
    {
        label: 'Mizoram Rural Bank',
        value: 'Mizoram Rural Bank'
    },
    {
        label: 'Nagaland Rural Bank',
        value: 'Nagaland Rural Bank'
    },
    {
        label: 'Nainital Bank',
        value: 'Nainital Bank'
    },
    {
        label: 'North East Small Finance Bank',
        value: 'North East Small Finance Bank'
    },
    {
        label: 'Odisha Gramya Bank',
        value: 'Odisha Gramya Bank'
    },
    {
        label: 'Paschim Banga Gramin Bank',
        value: 'Paschim Banga Gramin Bank'
    },
    {
        label: 'Prathama UP Gramin Bank',
        value: 'Prathama UP Gramin Bank'
    },
    {
        label: 'Puduvai Bharathiar Grama Bank',
        value: 'Puduvai Bharathiar Grama Bank'
    },
    {
        label: 'Punjab & Sind Bank',
        value: 'Punjab & Sind Bank'
    },
    {
        label: 'Punjab Gramin Bank',
        value: 'Punjab Gramin Bank'
    },
    {
        label: 'Punjab National Bank',
        value: 'Punjab National Bank'
    },
    {
        label: 'RBL Bank',
        value: 'RBL Bank'
    },
    {
        label: 'Rajasthan Marudhara Gramin Bank',
        value: 'Rajasthan Marudhara Gramin Bank'
    },
    {
        label: 'Saptagiri Grameena Bank',
        value: 'Saptagiri Grameena Bank'
    },
    {
        label: 'Sarva Haryana Gramin Bank',
        value: 'Sarva Haryana Gramin Bank'
    },
    {
        label: 'Saurashtra Gramin Bank',
        value: 'Saurashtra Gramin Bank'
    },
    {
        label: 'Shivalik Small Finance Bank',
        value: 'Shivalik Small Finance Bank'
    },
    {
        label: 'South Indian Bank',
        value: 'South Indian Bank'
    },
    {
        label: 'State Bank of India',
        value: 'State Bank of India'
    },
    {
        label: 'Suryoday Small Finance Bank',
        value: 'Suryoday Small Finance Bank'
    },
    {
        label: 'Tamil Nadu Grama Bank',
        value: 'Tamil Nadu Grama Bank'
    },
    {
        label: 'Tamilnad Mercantile Bank',
        value: 'Tamilnad Mercantile Bank'
    },
    {
        label: 'Telangana Grameena Bank',
        value: 'Telangana Grameena Bank'
    },
    {
        label: 'Tripura Gramin Bank',
        value: 'Tripura Gramin Bank'
    },
    {
        label: 'UCO Bank',
        value: 'UCO Bank'
    },
    {
        label: 'Ujjivan Small Finance Bank',
        value: 'Ujjivan Small Finance Bank'
    },
    {
        label: 'Union Bank of India',
        value: 'Union Bank of India'
    },
    {
        label: 'Unity Small Finance Bank',
        value: 'Unity Small Finance Bank'
    },
    {
        label: 'Utkal Grameen bank',
        value: 'Utkal Grameen bank'
    },
    {
        label: 'Utkarsh Small Finance Bank',
        value: 'Utkarsh Small Finance Bank'
    },
    {
        label: 'Uttar Bihar Gramin Bank',
        value: 'Uttar Bihar Gramin Bank'
    },
    {
        label: 'Uttarakhand Gramin Bank',
        value: 'Uttarakhand Gramin Bank'
    },
    {
        label: 'Uttarbanga Kshetriya Gramin Bank',
        value: 'Uttarbanga Kshetriya Gramin Bank'
    },
    {
        label: 'Vidharbha Konkan Gramin Bank',
        value: 'Vidharbha Konkan Gramin Bank'
    },
    {
        label: 'Yes Bank',
        value: 'Yes Bank'
    }
]

export const CountryList = [

    {
        label: "Afghan",
        value: "afghan"
    },
    {
        label: "Albanian",
        value: "albanian"
    },
    {
        label: "Algerian",
        value: "algerian"
    },
    {
        label: "American",
        value: "american"
    },
    {
        label: "Andorran",
        value: "andorran"
    },
    {
        label: "Angolan",
        value: "angolan"
    },
    {
        label: "Antiguans",
        value: "antiguans"
    },
    {
        label: "Argentinean",
        value: "argentinean"
    },
    {
        label: "Armenian",
        value: "armenian"
    },
    {
        label: "Australian",
        value: "australian"
    },
    {
        label: "Austrian",
        value: "austrian"
    },
    {
        label: "Azerbaijani",
        value: "azerbaijani"
    },
    {
        label: "Bahamian",
        value: "bahamian"
    },
    {
        label: "Bahraini",
        value: "bahraini"
    },
    {
        label: "Bangladeshi",
        value: "bangladeshi"
    },
    {
        label: "Barbadian",
        value: "barbadian"
    },
    {
        label: "Barbudans",
        value: "barbudans"
    },
    {
        label: "Batswana",
        value: "batswana"
    },
    {
        label: "Belarusian",
        value: "belarusian"
    },
    {
        label: "Belgian",
        value: "belgian"
    },
    {
        label: "Belizean",
        value: "belizean"
    },
    {
        label: "Beninese",
        value: "beninese"
    },
    {
        label: "Bhutanese",
        value: "bhutanese"
    },
    {
        label: "Bolivian",
        value: "bolivian"
    },
    {
        label: "Bosnian",
        value: "bosnian"
    },
    {
        label: "Brazilian",
        value: "brazilian"
    },
    {
        label: "British",
        value: "british"
    },
    {
        label: "Bruneian",
        value: "bruneian"
    },
    {
        label: "Bulgarian",
        value: "bulgarian"
    },
    {
        label: "Burkinabe",
        value: "burkinabe"
    },
    {
        label: "Burmese",
        value: "burmese"
    },
    {
        label: "Burundian",
        value: "burundian"
    },
    {
        label: "Cambodian",
        value: "cambodian"
    },
    {
        label: "Cameroonian",
        value: "cameroonian"
    },
    {
        label: "Canadian",
        value: "canadian"
    },
    {
        label: "Cape Verdean",
        value: "cape verdean"
    },
    {
        label: "Central African",
        value: "central african"
    },
    {
        label: "Chadian",
        value: "chadian"
    },
    {
        label: "Chilean",
        value: "chilean"
    },
    {
        label: "Chinese",
        value: "chinese"
    },
    {
        label: "Colombian",
        value: "colombian"
    },
    {
        label: "Comoran",
        value: "comoran"
    },
    {
        label: "Congolese",
        value: "congolese"
    },
    {
        label: "Costa Rican",
        value: "costa rican"
    },
    {
        label: "Croatian",
        value: "croatian"
    },
    {
        label: "Cuban",
        value: "cuban"
    },
    {
        label: "Cypriot",
        value: "cypriot"
    },
    {
        label: "Czech",
        value: "czech"
    },
    {
        label: "Danish",
        value: "danish"
    },
    {
        label: "Djibouti",
        value: "djibouti"
    },
    {
        label: "Dominican",
        value: "dominican"
    },
    {
        label: "Dutch",
        value: "dutch"
    },
    {
        label: "East Timorese",
        value: "east timorese"
    },
    {
        label: "Ecuadorean",
        value: "ecuadorean"
    },
    {
        label: "Egyptian",
        value: "egyptian"
    },
    {
        label: "Emirian",
        value: "emirian"
    },
    {
        label: "Equatorial Guinean",
        value: "equatorial guinean"
    },
    {
        label: "Eritrean",
        value: "eritrean"
    },
    {
        label: "Estonian",
        value: "estonian"
    },
    {
        label: "Ethiopian",
        value: "ethiopian"
    },
    {
        label: "Fijian",
        value: "fijian"
    },
    {
        label: "Filipino",
        value: "filipino"
    },
    {
        label: "Finnish",
        value: "finnish"
    },
    {
        label: "French",
        value: "french"
    },
    {
        label: "Gabonese",
        value: "gabonese"
    },
    {
        label: "Gambian",
        value: "gambian"
    },
    {
        label: "Georgian",
        value: "georgian"
    },
    {
        label: "German",
        value: "german"
    },
    {
        label: "Ghanaian",
        value: "ghanaian"
    },
    {
        label: "Greek",
        value: "greek"
    },
    {
        label: "Grenadian",
        value: "grenadian"
    },
    {
        label: "Guatemalan",
        value: "guatemalan"
    },
    {
        label: "Guinea-Bissauan",
        value: "guinea-bissauan"
    },
    {
        label: "Guinean",
        value: "guinean"
    },
    {
        label: "Guyanese",
        value: "guyanese"
    },
    {
        label: "Haitian",
        value: "haitian"
    },
    {
        label: "Herzegovinian",
        value: "herzegovinian"
    },
    {
        label: "Honduran",
        value: "honduran"
    },
    {
        label: "Hungarian",
        value: "hungarian"
    },
    {
        label: "Icelander",
        value: "icelander"
    },
    {
        label: "Indian",
        value: "indian"
    },
    {
        label: "Indonesian",
        value: "indonesian"
    },
    {
        label: "Iranian",
        value: "iranian"
    },
    {
        label: "Iraqi",
        value: "iraqi"
    },
    {
        label: "Irish",
        value: "irish"
    },
    {
        label: "Israeli",
        value: "israeli"
    },
    {
        label: "Italian",
        value: "italian"
    },
    {
        label: "Ivorian",
        value: "ivorian"
    },
    {
        label: "Jamaican",
        value: "jamaican"
    },
    {
        label: "Japanese",
        value: "japanese"
    },
    {
        label: "Jordanian",
        value: "jordanian"
    },
    {
        label: "Kazakhstani",
        value: "kazakhstani"
    },
    {
        label: "Kenyan",
        value: "kenyan"
    },
    {
        label: "Kittian and Nevisian",
        value: "kittian and nevisian"
    },
    {
        label: "Kuwaiti",
        value: "kuwaiti"
    },
    {
        label: "Kyrgyz",
        value: "kyrgyz"
    },
    {
        label: "Laotian",
        value: "laotian"
    },
    {
        label: "Latvian",
        value: "latvian"
    },
    {
        label: "Lebanese",
        value: "lebanese"
    },
    {
        label: "Liberian",
        value: "liberian"
    },
    {
        label: "Libyan",
        value: "libyan"
    },
    {
        label: "Liechtensteiner",
        value: "liechtensteiner"
    },
    {
        label: "Lithuanian",
        value: "lithuanian"
    },
    {
        label: "Luxembourger",
        value: "luxembourger"
    },
    {
        label: "Macedonian",
        value: "macedonian"
    },
    {
        label: "Malagasy",
        value: "malagasy"
    },
    {
        label: "Malawian",
        value: "malawian"
    },
    {
        label: "Malaysian",
        value: "malaysian"
    },
    {
        label: "Maldivan",
        value: "maldivan"
    },
    {
        label: "Malian",
        value: "malian"
    },
    {
        label: "Maltese",
        value: "maltese"
    },
    {
        label: "Marshallese",
        value: "marshallese"
    },
    {
        label: "Mauritanian",
        value: "mauritanian"
    },
    {
        label: "Mauritian",
        value: "mauritian"
    },
    {
        label: "Mexican",
        value: "mexican"
    },
    {
        label: "Micronesian",
        value: "micronesian"
    },
    {
        label: "Moldovan",
        value: "moldovan"
    },
    {
        label: "Monacan",
        value: "monacan"
    },
    {
        label: "Mongolian",
        value: "mongolian"
    },
    {
        label: "Moroccan",
        value: "moroccan"
    },
    {
        label: "Mosotho",
        value: "mosotho"
    },
    {
        label: "Motswana",
        value: "motswana"
    },
    {
        label: "Mozambican",
        value: "mozambican"
    },
    {
        label: "Namibian",
        value: "namibian"
    },
    {
        label: "Nauruan",
        value: "nauruan"
    },
    {
        label: "Nepalese",
        value: "nepalese"
    },
    {
        label: "New Zealander",
        value: "new zealander"
    },
    {
        label: "Ni-Vanuatu",
        value: "ni-vanuatu"
    },
    {
        label: "Nicaraguan",
        value: "nicaraguan"
    },
    {
        label: "Nigerien",
        value: "nigerien"
    },
    {
        label: "North Korean",
        value: "north korean"
    },
    {
        label: "Northern Irish",
        value: "northern irish"
    },
    {
        label: "Norwegian",
        value: "norwegian"
    },
    {
        label: "Omani",
        value: "omani"
    },
    {
        label: "Pakistani",
        value: "pakistani"
    },
    {
        label: "Palauan",
        value: "palauan"
    },
    {
        label: "Panamanian",
        value: "panamanian"
    },
    {
        label: "Papua New Guinean",
        value: "papua new guinean"
    },
    {
        label: "Paraguayan",
        value: "paraguayan"
    },
    {
        label: "Peruvian",
        value: "peruvian"
    },
    {
        label: "Polish",
        value: "polish"
    },
    {
        label: "Portuguese",
        value: "portuguese"
    },
    {
        label: "Qatari",
        value: "qatari"
    },
    {
        label: "Romanian",
        value: "romanian"
    },
    {
        label: "Russian",
        value: "russian"
    },
    {
        label: "Rwandan",
        value: "rwandan"
    },
    {
        label: "Saint Lucian",
        value: "saint lucian"
    },
    {
        label: "Salvadoran",
        value: "salvadoran"
    },
    {
        label: "Samoan",
        value: "samoan"
    },
    {
        label: "San Marinese",
        value: "san marinese"
    },
    {
        label: "Sao Tomean",
        value: "sao tomean"
    },
    {
        label: "Saudi",
        value: "saudi"
    },
    {
        label: "Scottish",
        value: "scottish"
    },
    {
        label: "Senegalese",
        value: "senegalese"
    },
    {
        label: "Serbian",
        value: "serbian"
    },
    {
        label: "Seychellois",
        value: "seychellois"
    },
    {
        label: "Sierra Leonean",
        value: "sierra leonean"
    },
    {
        label: "Singaporean",
        value: "singaporean"
    },
    {
        label: "Slovakian",
        value: "slovakian"
    },
    {
        label: "Slovenian",
        value: "slovenian"
    },
    {
        label: "Solomon Islander",
        value: "solomon islander"
    },
    {
        label: "Somali",
        value: "somali"
    },
    {
        label: "South African",
        value: "south african"
    },
    {
        label: "South Korean",
        value: "south korean"
    },
    {
        label: "Spanish",
        value: "spanish"
    },
    {
        label: "Sri Lankan",
        value: "sri lankan"
    },
    {
        label: "Sudanese",
        value: "sudanese"
    },
    {
        label: "Surinamer",
        value: "surinamer"
    },
    {
        label: "Swazi",
        value: "swazi"
    },
    {
        label: "Swedish",
        value: "swedish"
    },
    {
        label: "Swiss",
        value: "swiss"
    },
    {
        label: "Syrian",
        value: "syrian"
    },
    {
        label: "Taiwanese",
        value: "taiwanese"
    },
    {
        label: "Tajik",
        value: "tajik"
    },
    {
        label: "Tanzanian",
        value: "tanzanian"
    },
    {
        label: "Thai",
        value: "thai"
    },
    {
        label: "Togolese",
        value: "togolese"
    },
    {
        label: "Tongan",
        value: "tongan"
    },
    {
        label: "Trinidadian or Tobagonian",
        value: "trinidadian or tobagonian"
    },
    {
        label: "Tunisian",
        value: "tunisian"
    },
    {
        label: "Turkish",
        value: "turkish"
    },
    {
        label: "Tuvaluan",
        value: "tuvaluan"
    },
    {
        label: "Ugandan",
        value: "ugandan"
    },
    {
        label: "Ukrainian",
        value: "ukrainian"
    },
    {
        label: "Uruguayan",
        value: "uruguayan"
    },
    {
        label: "Uzbekistani",
        value: "uzbekistani"
    },
    {
        label: "Venezuelan",
        value: "venezuelan"
    },
    {
        label: "Vietnamese",
        value: "vietnamese"
    },
    {
        label: "Welsh",
        value: "welsh"
    },
    {
        label: "Yemenite",
        value: "yemenite"
    },
    {
        label: "Zambian",
        value: "zambian"
    },
    {
        label: "Zimbabwean",
        value: "zimbabwean"
    }
]

export const RelationList = [
    {
        label: 'Mother',
        value: 'Mother'
    },
    {
        label: 'Father',
        value: 'Father'
    },
    {
        label: 'Sister',
        value: 'Sister'
    },
    {
        label: 'Brother',
        value: 'Brother'
    },
    {
        label: 'Son',
        value: 'Son'
    },
    {
        label: 'Daughter',
        value: 'Daughter'
    },
    {
        label: 'Grandparent',
        value: 'Grandparent'
    },

    {
        label: 'Other',
        value: 'Other'
    }
]

export const CustomerDocumentList = [
    {
        label: 'Booking Form',
        value: 'Booking Form'
    },
    {
        label: 'Aadhaar',
        value: 'Aadhaar'
    },

    {
        label: 'PAN',
        value: 'PAN'
    },
    {
        label: 'Transactions (Customer)',
        value: 'Transactions (Customer)'
    },
    {
        label: 'Cheque',
        value: 'Cheque'
    },
    {
        label: 'Post Dated Cheque',
        value: 'Post Dated Cheque'
    },
    {
        label: 'Allotment Letter',
        value: 'Allotment Letter'
    },
    {
        label: 'Quotation',
        value: 'Quotation'
    },
    {
        label: 'Agreement',
        value: 'Agreement'
    },
    {
        label: 'Demand Letter',
        value: 'Demand Letter'
    },
    {
        label: 'Payments',
        value: 'Payments'
    },
    {
        label: 'Passport',
        value: 'Passport'
    },
    {
        label: 'Photo',
        value: 'Photo'
    },
    {
        label: 'Cancelled Cheque',
        value: 'Cancelled Cheque'
    },
    {
        label: 'Other',
        value: 'Other'
    }
]

export const BrokerageDocumentList = [
    {
        label: 'Aadhaar Front',
        value: 'Aadhaar Front'
    },
    {
        label: 'Aadhaar Back',
        value: ' Aadhaar Back'
    },
    {
        label: 'PAN',
        value: 'PAN'
    },
    {
        label: 'Company Registration - Certificate',
        value: 'Registration Certificate'
    },
    {
        label: 'GST Certificate',
        value: 'GST Certificate'
    },
    {
        label: 'RERA Certificate(s)',
        value: 'RERA Certificate(s)'
    },
    {
        label: 'TAN Certificate',
        value: 'TAN Certificate'
    },
    {
        label: 'Invoice',
        value: 'Invoice'
    }
]

export const PaidBy = [
    {
        label: 'Customer',
        value: 'Customer'
    },
    {
        label: 'Bank',
        value: 'Bank'
    }
]

export const customerDocList = [
    {
        fileName: 'Aadhar Front',
        type: '',
        url: '',
        size: '',
        extension: '',
        file_type: '',
        is_private: false,
        category: 'Aadhaar',
        showCategory: false
    },
    {
        fileName: 'Aadhar Back',
        type: '',
        url: '',
        size: '',
        is_private: false,
        extension: '',
        file_type: '',
        category: 'Aadhaar',
        showCategory: false
    },
    {
        fileName: 'PAN',
        type: '',
        url: '',
        size: '',
        is_private: false,
        extension: '',
        file_type: '',
        category: 'PAN',
        showCategory: false
    },
    {
        fileName: 'Cutomer Photo',
        type: '',
        url: '',
        size: '',
        is_private: false,
        extension: '',
        file_type: '',
        category: 'Photo',
        showCategory: false
    },
    {
        fileName: 'Cancelled Cheque',
        type: '',
        url: '',
        size: '',
        is_private: false,
        extension: '',
        file_type: '',
        category: 'Cancelled Cheque',
        showCategory: false
    },
    {
        fileName: 'Office Id',
        type: '',
        url: '',
        size: '',
        is_private: false,
        extension: '',
        file_type: '',
        category: 'Office Id',
        showCategory: false
    },
    {
        fileName: 'Passport',
        type: '',
        url: '',
        size: '',
        is_private: false,
        extension: '',
        file_type: '',
        category: 'Passport',
        showCategory: false
    },
    {
        fileName: 'Salary Slips',
        type: '',
        url: '',
        size: '',
        is_private: false,
        extension: '',
        file_type: '',
        category: 'Salary Slip',
        showCategory: false
    },
    {
        fileName: 'Bank Statement',
        type: '',
        url: '',
        size: '',
        is_private: false,
        extension: '',
        file_type: '',
        category: 'Bank Statement',
        showCategory: false
    },
]

export const coapplicantDocs = [
    {
        fileName: 'Aadhar Front',
        name: '',
        type: '',
        url: '',
        size: '',
        is_private: false,
        extension: '',
        file_type: '',
        category: 'Aadhar',
        showCategory: false
    },
    {
        fileName: 'Aadhar Back',
        name: '',
        type: '',
        url: '',
        size: '',
        is_private: false,
        extension: '',
        file_type: '',
        category: 'Aadhar',
        showCategory: false
    },
    {
        fileName: 'PAN',
        name: '',
        type: '',
        url: '',
        size: '',
        is_private: false,
        extension: '',
        file_type: '',
        category: 'PAN',
        showCategory: false
    },
    {
        fileName: 'Cutomer Photo',
        name: '',
        type: '',
        url: '',
        size: '',
        is_private: false,
        extension: '',
        file_type: '',
        category: 'Photo',
        showCategory: false
    }
]

export const validityUnit = [
    {
        label: 'Days',
        value: 'days'
    },
    {
        label: 'Weeks',
        value: 'weeks'
    },
    {
        label: 'Month',
        value: 'months'
    },
    {
        label: 'Years',
        value: 'years'
    }
];

export const documentCategory = [
    {
        label: 'Aadhaar',
        value: 'Aadhaar'
    },
    {
        label: 'PAN',
        value: 'PAN'
    },
    {
        label: 'Photo',
        value: 'Photo'
    },
    {
        label: 'Cancelled Cheque',
        value: 'Cancelled Cheque'
    },
    {
        label: 'Office Id',
        value: 'Office Id'
    },
    {
        label: 'Passport',
        value: 'Passport'
    },
    {
        label: 'Salary Slip',
        value: 'Salary Slip'
    },
    {
        label: 'Bank Statement',
        value: 'Bank Statement'
    },
    {
        label: 'Other',
        value: 'Other'
    }
]

export const SVTrackerLeadSource = [
    {
        label: 'Channel Partner',
        value: 'with_cp'
    },
    {
        label: 'Direct',
        value: 'self'
    },
    {
        label: 'Reference',
        value: 'Reference'
    },
    {
        label: 'Event',
        value: 'Event'
    },
    {
        label: 'Website',
        value: 'Website'
    },
    {
        label: 'SMS',
        value: 'SMS'
    },
    {
        label: 'WhatsApp',
        value: 'WhatsApp'
    },
    {
        label: 'Facebook/Instagram',
        value: 'Facebook/Instagram'
    },
    {
        label: 'Newspaper',
        value: 'Newspaper'
    },
    {
        label: 'Hoarding',
        value: 'Hoarding'
    },
    {
        label: 'PreSales',
        value: 'PreSales'
    },

    {
        label: 'Other',
        value: 'Other'
    }
]