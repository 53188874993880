import CompanyInfo from "../modules/Settings/Company info/CompanyInfo";
import PasswordChange from "../modules/Settings/My Profile/Password/PasswordChange";
import PersonalInfo from "../modules/Settings/My Profile/Personal Info/PersonalInfo";
import Projects from "../modules/Settings/Projects/Projects";
import CompanyIntegrations from "../modules/Settings/User&Teams/Integrations/CompanyIntegrations";
import FacebookLeadAds from "../modules/Settings/User&Teams/Integrations/FacebookLeadAds/FacebookLeadAds"
import GoogleAds from "../modules/Settings/User&Teams/Integrations/GoogleAds/GoogleAds"
import Roles from "../modules/Settings/User&Teams/Roles/Roles";
import SettingsUser from "../modules/Settings/User&Teams/User/User";
import LeadIntegrations from "../modules/Settings/User&Teams/Lead Integration/LeadIntegrations";
import Agency from "../modules/Settings/Company info/Agency";
import DataImport from "../modules/Settings/Data-import";
import Export from '../modules/Settings/Export';
import HousingAds from '../modules/Settings/User&Teams/Integrations/Housing/HousingAds';
import AcresAds from "../modules/Settings/User&Teams/Integrations/Acres/AcresAds";
import MagicbricksAds from "../modules/Settings/User&Teams/Integrations/Magicbricks/MagicbricksAds";
import Forms from "../modules/Settings/User&Teams/Integrations/FacebookLeadAds/Forms";
import Integration from "../modules/Settings/User&Teams/Integrations/FacebookLeadAds/Integration";
import PageLinkForms from "../modules/Settings/User&Teams/Integrations/FacebookLeadAds/PageLinkForms";
import ManageAccounts from "../modules/Settings/User&Teams/Integrations/FacebookLeadAds/ManageAccounts";
import UserAccount from "../modules/Settings/User&Teams/Integrations/FacebookLeadAds/userAccount";
import Manage99AcresAccounts from "../modules/Settings/User&Teams/Integrations/Acres/Manage99AcresAccounts";
import ManageHousingAccounts from "../modules/Settings/User&Teams/Integrations/Housing/ManageHousingAccounts";
import ManageMagicbricksAccounts from "../modules/Settings/User&Teams/Integrations/Magicbricks/ManageMagicbricksAccounts";
import LeadDuplication from "../modules/Settings/User&Teams/Lead Integration/LeadDuplication";
import BasicDetails from "../components/Projects/BasicDetails";
import MediaUpload from "../components/Projects/MediaUpload";
import AdditionalInfo from "../components/Projects/AdditionalInfo";
import ImportPreviousLeads from "../modules/Settings/User&Teams/Integrations/FacebookLeadAds/ImportPreviousLeads";
import RolesDetail from "../modules/Settings/User&Teams/Roles/RolesDetail";
import InviteUsers from "../modules/Settings/User&Teams/Roles/InviteUsers";
import NewRole from "../modules/Settings/User&Teams/Roles/NewRole";
import Permissions from "../modules/Settings/User&Teams/Roles/Permissions";
import TeamInfo from "../components/Projects/TeamInfo";
import RolesPrevious from "../modules/Settings/User&Teams/Roles/OldRole";
import PaymentPlan from "../components/Projects/PaymentPlan";
import PricingModule from "../components/Projects/PricingModule";
// import IVR from "../modules/Settings/User&Teams/Integrations/IVR/IVR";
import StatusPermissions from "../modules/Settings/User&Teams/StatusPermission";
import Teams from "../modules/Settings/User&Teams/Teams/Teams";
import TeamDetails from "../modules/Settings/User&Teams/Teams/TeamDetails";
import Team from "../components/Projects/Team";
import Workshops from "../components/workshops";
import EditWorkshop from "../components/workshops/EditWorkshop";
import CreateWorkshop from "../components/workshops/CreateWorkshop";
import EnrolledUsers from "../components/workshops/enrolled-users";
import Workshop from "../components/workshops/Workshop/Workshop";
import LeadAutomation from "../modules/Settings/Automation/LeadAutomation";
import UnAssignedLeads from "../modules/Settings/Automation/UnAssignedLeads";
import FollowupUsers from "../modules/Settings/Automation/FollowupUsers";
import ReassignEoiCancelled from "../modules/Settings/Automation/ReassignEoiCancelled";
import Events from "../modules/Settings/Listing/Events";
import ListingProjects from "../modules/Settings/Listing/Projects";
import CreateEvent from "../modules/Events/CreateEvent";
import CreateProject from "../modules/ListingProjects/CreateProject";
import ConstructionUpdate from "../components/Projects/ConstructionUpdate";
import MyOperator from "../modules/Settings/User&Teams/Integrations/IVR/MyOperator";
import ListingMediaUpload from "../modules/ListingProjects/ListingMediaUpload";
import SeoInfo from "../modules/ListingProjects/SeoInfo";
import CreateCourses from "../components/courses/create";
import Courses from "../components/courses";
import Category from "../components/category";
import Guides from "../components/guides/Guides";
import CreateMentor from "../components/mentors/CreateMentor";
import EditMentor from "../components/mentors/EditMentor";
import Mentors from "../components/mentors/index"
import LeadSource from "../modules/Settings/User&Teams/Lead Integration/LeadSource";
import BuilderInfo from "../modules/Settings/Listing/BuilderInfo";
import TeamFollowupUsers from "../modules/Settings/Automation/TeamsFollowupUser.jsx";
import CompanyAnalytics from "../modules/PropacityPlus/Company.jsx";
import Knowlarity from "../modules/Settings/User&Teams/Integrations/IVR/Knowlarity";
import FinalNegotiation from "../modules/Settings/Automation/FinalNegotiation/index.jsx";
import ProjectWiseLeads from "../modules/Settings/Automation/ProjectWiseLeads/index.jsx";
import CallsRequested from "../modules/PropacityPlus/CallsRequested.jsx";
import Plans from "../modules/Settings/Plus App/Plans.jsx";
import BorkerCompanyUsers from "../modules/Settings/Broker-CP/BorkerCompanyUsers.jsx";
import BrokerCompany from "../modules/Settings/Broker-CP/BrokerCompany.jsx";
import BrokerCompanyLeads from "../modules/Settings/Broker-CP/BrokerCompanyLeads.jsx";
import RevisitTagging from "../modules/Settings/Automation/RevisitTagging/index.jsx";
import CampaignList from "../modules/Settings/Marketing/WhatsappCampaigns/CampaignList.jsx";
import CreateCampaign from "../modules/Settings/Marketing/WhatsappCampaigns/CreateCampaign.jsx";
import Template from "../modules/Settings/Marketing/WhatsappCampaigns/Template.jsx";
import CreateTamplate from "../modules/Settings/Marketing/WhatsappCampaigns/CreateTamplate.jsx";
import Chats from "../modules/Settings/Marketing/WhatsappCampaigns/Chats.jsx";
import CampaignDetails from "../modules/Settings/Marketing/WhatsappCampaigns/CampaignDetails.jsx";
import NewsList from "../modules/Settings/CustomerApp/NewsList.jsx";
import AddNew from "../modules/Settings/CustomerApp/AddNew.jsx";
import ReferralIncentive from "../modules/Settings/CustomerApp/ReferralIncentive.jsx";
import FAQs from "../modules/Settings/CustomerApp/FAQs.jsx";
// import CreatePlan from "../modules/Settings/Plus App/CreatePlan.jsx";


export const settingsBaseUrl = "/settings";

export const settingsRoutes = [
  {
    name: "My Profile",
    url: "my-profile",
    childRoutes: [
      {
        name: "Personal Info",
        url: "personal-info",
        component: PersonalInfo,
      },
      {
        name: "Change Password",
        url: "password",
        component: PasswordChange,
      },
    ],
  }
]

export const companyDetail = {
  name: "Company Details",
  url: "company-details",
  childRoutes: [
    {
      name: "Company Info",
      url: "company-info",
      component: CompanyInfo,
    },
    {
      name: "Marketing Agency",
      url: "agency",
      component: Agency,
    },
  ],
}

export const learningCentreURL =
{
  name: "Learning Centre",
  url: "learning-centre",
  childRoutes: [
    {
      name: "All Workshops",
      url: "all-workshops",
      component: Workshops,
    },
    {
      url: "workshops/enrolled-users/:workshop_uuid",
      component: EnrolledUsers,
    },
    {
      url: "workshops/edit-workshop/:uuid",
      component: EditWorkshop,
    },
    {
      url: "workshops/new-workshop",
      component: CreateWorkshop,
    },
    {
      url: "all-workshop/:uuid",
      component: Workshop,
    },
    {
      url: "courses/create-courses",
      component: CreateCourses
    },
    {
      url: "courses/course-edit/:id",
      component: CreateCourses,
    },
    {
      name: "Courses",
      url: "courses",
      component: Courses,
    },
    {
      name: "Guides",
      url: "guide",
      component: Guides,
    },
    {
      name: "Category",
      url: "category",
      component: Category,
    },
  ],
}

export const MentorURL = {
  name: "Mentors",
  url: "mentors",
  childRoutes: [
    {
      name: "All Mentors",
      url: "all-mentors",
      component: Mentors,
    },
    {
      url: "new-mentor",
      component: CreateMentor,
    },
    {
      url: "edit-mentor/:uuid",
      component: EditMentor,
    },
  ],
}


export const leadURL = {
  name: "Leads",
  url: "leads",
  childRoutes: [
    {
      name: "Lead Assignment",
      url: "lead-assignment",
      component: LeadIntegrations,
    },
    {
      name: "Lead Duplication",
      url: "lead-duplication",
      component: LeadDuplication,
    },
    {
      name: "Lead Source",
      url: "lead-source",
      component: LeadSource,
    }
  ],
}

export const automationUrl = {
  name: "Automation",
  url: "automation",
  childRoutes: [
    {
      name: "Lead Automation",
      url: "lead-automation",
      component: LeadAutomation,
    },
    {
      // name: "Status",
      url: "status-permissions",
      component: StatusPermissions,
    },
    {
      // name: "Status",
      url: "unassigned-leads",
      component: UnAssignedLeads,
    },
    {
      url: 'followups',
      component: FollowupUsers
    },
    {
      url: 'reassign-eoi',
      component: ReassignEoiCancelled
    },
    {
      url: 'final-negotiation',
      component: FinalNegotiation
    },
    {
      url: 'project-wise-distribution',
      component: ProjectWiseLeads
    },
    {
      url: 'revisit-tagging-extended',
      component: RevisitTagging
    }
  ],
}

export const usersRolesURL = {
  name: "User, Roles & Teams",
  url: "user-roles",
  childRoutes: [
    {
      name: "Users",
      url: "users",
      component: SettingsUser,
    },
    {
      name: "Roles",
      url: "roles",
      component: Roles,
      oldComponent: RolesPrevious
    },
    {
      name: "Teams",
      url: "team",
      component: Teams,
      oldComponent: RolesPrevious
    },

    // {
    //   name: "Roles",
    //   url: "roles",
    //   component: RolesPrevious,
    // },
    {
      url: 'roles/new',
      component: NewRole
    },
    {
      url: 'roles/new/:uuid',
      component: NewRole
    },
    {
      url: 'roles/detail/:uuid',
      component: RolesDetail
    },
    {
      url: 'roles/new/invite-user/:uuid',
      component: InviteUsers
    },
    {
      url: 'roles/permissions',
      exact: true,
      component: Permissions
    },
    {
      url: "teamDetails/:uuid",
      component: TeamDetails,

    }

  ],
}

export const dataImportURL = {
  name: "Import/Export Leads",
  url: "import-export",
  childRoutes: [
    {
      name: "Import",
      url: "import",
      component: DataImport,
    },
    {
      name: "Export",
      url: "export",
      component: Export,
    },
  ],
}

export const brokerURL = {
  name: "Broker/CP",
  url: "broker-cp",
  childRoutes: [
    {
      name: "Broker Companies",
      url: "company",
      component: BrokerCompany,
    },
    {
      // name: "Export",
      url: "company/users/:uuid",
      component: BorkerCompanyUsers,
    },
    {
      // name: "Export",
      url: "company/leads/:uuid",
      component: BrokerCompanyLeads,
    },
  ],
}

export const listingUrls = {
  name: "Listing",
  url: "listing",
  childRoutes: [
    {
      name: "Events",
      url: "events",
      component: Events,
    },
    {
      name: "Projects",
      url: "projects",
      component: ListingProjects,
    },
    {
      name: "Builder Info",
      url: "builder-info",
      component: BuilderInfo,
    },
    {
      url: "events/create-event",
      component: CreateEvent,
    },
    {
      url: 'projects/create-project/basic',
      component: CreateProject
    },
    {
      url: 'projects/create-project/basic/:uuid',
      component: CreateProject
    },
    {
      url: 'projects/create-project/listing-media-upload/:uuid',
      component: ListingMediaUpload
    },
    {
      url: 'projects/create-project/seo-info/:uuid',
      component: SeoInfo
    }
  ],
}

export const projectURL = {
  name: "Projects",
  url: "projects",
  childRoutes: [
    {
      name: "Project Manager",
      url: "project-manager",
      component: Projects,
    },
    // {
    //   name: "Create Project",
    //   url: "create-projects",
    //   component: CreateProjects,
    // },
    {
      // name: "Basic Details",
      url: "create-projects/basic-details",
      component: BasicDetails,
    },
    {
      // name: "Basic Details",
      url: "create-projects/basic-details/:uuid",
      component: BasicDetails,
    },

    {
      // name: "Media Upload",
      url: "create-projects/mediaupload/:uuid",
      component: MediaUpload,
    },
    {
      // name: "Additional Info",
      url: "create-projects/additional-info/:uuid",
      component: AdditionalInfo,
    },
    {
      // name: "Additional Info",
      url: "create-projects/team/:uuid",
      component: Team,
    },
    {
      // name: "Team Info",
      url: "create-projects/team-info/:uuid",
      component: TeamInfo,
    },
    {
      // name: "Payment Plan",
      url: "create-projects/payment-plan/:uuid",
      component: PaymentPlan,
    },
    {
      // name: "Payment Plan",
      url: "create-projects/pricing/:uuid",
      component: PricingModule,
    },
  ],
}

export const integrationURL = {
  name: "Integrations",
  url: "integrations",
  childRoutes: [
    {
      name: "All Integrations",
      url: "all-integrations",
      component: CompanyIntegrations,
    },
    {
      url: "all-integrations/facebook-lead-ads",
      component: FacebookLeadAds,
    },
    {
      url: "all-integrations/landing-page",
      component: GoogleAds,
    },
    {
      url: "all-integrations/housing",
      component: HousingAds,
    },
    {
      url: "all-integrations/housing/manage-account",
      component: ManageHousingAccounts,
    },
    {
      url: "all-integrations/99-acres",
      component: AcresAds,
    },
    {
      url: "all-integrations/99-acres/manage-account",
      component: Manage99AcresAccounts,
    },
    {
      url: "all-integrations/magicbricks",
      component: MagicbricksAds,
    },
    {
      url: "all-integrations/magicbricks/manage-account",
      component: ManageMagicbricksAccounts,
    },
    {
      url: 'all-integrations/facebook-forms',
      component: Forms
    },
    {
      url: 'all-integrations/facebook-forms/new-integration',
      component: Integration
    },
    {
      url: 'all-integrations/manage-accounts',
      component: ManageAccounts
    },
    {
      url: 'all-integrations/user-account',
      component: UserAccount
    },
    {
      url: 'all-integrations/facebook-forms/page-link-form/:id',
      component: PageLinkForms
    },
    {
      url: 'all-integrations/facebook-forms/page-link-form/:id/import-previous',
      component: ImportPreviousLeads
    },
    {
      url: 'all-integrations/ivr/myoperator',
      component: MyOperator
    },

    {
      url: 'all-integrations/ivr/knowlarity',
      component: Knowlarity
    }
    // {
    //   url: 'all-integrations/ivr',
    //   component: IVR
    // }

  ],
}

// export const plusAppURL = {
//   name: "Propacity Plus Customers",
//   url: "plus-app",
//   childRoutes: [
//     {
//       name: "Companies",
//       url: "companies",
//       component: CompanyAnalytics,
//     },
//   ],
// }

export const customerNewsURL = {
  name: "Customer App",
  url: "customer-news",
  childRoutes: [
    {
      name: "News",
      url: "news",
      component: NewsList,
    },
    {
      name: "Referral Incentive",
      url: "refer-incentive",
      component: ReferralIncentive,
    },
    {
      name: "FAQs",
      url: "faqs",
      component: FAQs,
    },
    {
      // name: "News",
      url: "news/create",
      component: AddNew,
    },
    {
      // name: "News",
      url: "news/create/:uuid",
      component: AddNew,
    },

  ],
}

export const plusAppURL = {
  name: "Learning Center/Plus App",
  url: "plus-app",
  childRoutes: [
    {
      name: "Plans",
      url: "plans",
      component: Plans,
    },
    // {
    //   // name: "Plans cre",
    //   url: "plans/create-plans",
    //   component: CreatePlan,
    // },
  ],
}

export const marketingURL = {
  name: "Whatsapp Campaigns",
  url: "whatsapp",
  childRoutes: [
    {
      name: "All Campaigns",
      url: "campaign",
      component: CampaignList,
    },
    {
      // name: "Campaign",
      url: "campaign/create-campaign",
      component: CreateCampaign,
    },
    {
      // name: "Campaign",
      url: "campaign/edit-campaign/:uuid",
      component: CreateCampaign,
    },
    {
      // name: "Campaign",
      url: "campaign/detail/:uuid",
      component: CampaignDetails,
    },
    {
      name: "Template",
      url: "template",
      component: Template,
      // component: CreateTamplate,
    },
    {
      // name: "Template",
      url: "template/create-template",
      component: CreateTamplate,
    },
    {
      // name: "Template",
      url: "template/duplicate-template/:uuid",
      component: CreateTamplate,
    },
    {
      name: "Chat",
      url: "chat",
      component: Chats,
    }
  ]
}

