import React, { useContext, useEffect, useState } from 'react';
import BasicModal from './BasicModal';
import { ReactComponent as NavRight } from '../../assets/icons/NavRight.svg';
import { ReactComponent as Owlleft } from '../../assets/icons/owlleft.svg';
import { ReactComponent as OwlRight } from '../../assets/icons/owlright.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

import { getDetailsByLeadId, getEOIDetails } from '../../services/private/inventory.service';
import { getPricingBasePrice, getPricingEOI, getProjectPaymentPlans } from '../../services/public/public.service';
import AreYouSureModal from './AreYouSureModal';
import Consumer from '../../helpers/context';
import { getLeadByUuid } from '../../services/private/leads.service';
import BasicDetail from '../New_EOI/BasicDetails';
import UnitDetail from '../New_EOI/UnitDetail';
import Pricing from '../New_EOI/Pricing';
import PaymentProof from '../New_EOI/PaymentProof';
import Other from '../New_EOI/Others';
import Paymentplan from '../New_EOI/Paymentplan';
import AdditionalInfo from '../New_EOI/AdditionalInfo';

function NewEOIModal({ show, closeModal, lead, onSuccess, data, isEdit, showTab, editToken }) {
    const [steps, setSteps] = useState('EOIBasicDetail');
    const [selectedProject, setSelectedProject] = useState({});
    const [eoiId, setEOIId] = useState('');
    const [totalPrice, setTotalPrice] = useState('');
    const [unit, setUnit] = useState('');
    const [unitPreference, setUnitPreference] = useState('');
    const [selectedBooking, setSelectedBooking] = useState();
    const [basePriceConfigMap, setBasePriceConfigMap] = useState(null);
    const [basePriceData, setBasePriceData] = useState([]);
    const [showCloseConfirmationModal, setShowCloseConfirmationModal] = useState(false);
    const [adjustedPrice, setAdjustedPrice] = useState();
    const [selectedPaymentPlan, setSelectedPaymentPlan] = useState({});
    const [selectedPaymentPricing, setSelectedPaymentPricing] = useState({});
    const [userEmail, setUserEmail] = useState('');
    const [initialProject, setInitialProject] = useState('');
    const [isPrefferedUnit, setIsPrefferedUnit] = useState('no');
    const [projectName, setProjectName] = useState('');
    const [initialSource, setInitialSource] = useState({});

    const { allowedPermissions, userProfile } = useContext(Consumer);

    console.log(lead, data)
    const getLeadDetails = async (id) => {
        await getLeadByUuid(id).then(res => {
            if (res.status === 200) {
                console.log(res.data.data, 'response')
                setUserEmail(res.data.data.customer.data.email);
                setInitialProject(res.data.data?.projects.length > 0 ? res.data.data?.projects[0] : '')
                setInitialSource(res.data.data?.lead?.source);
            }

        })
            .catch((err) => {
                if (err.response?.data)
                    // setUserNotFound(true)
                    console.log(err.response?.data)
            });
    }

    const getEOILeadDetails = async (uuid) => {
        const res = await getEOIDetails(uuid);
        if (res.data.status === 200) {
            if (res.data.data?.customer?.uuid) {
                const { bookingUnit, booking } = res.data.data;
                setUnit(bookingUnit);
                if (bookingUnit?.uuid) {
                    setIsPrefferedUnit('yes');
                } else {
                    setIsPrefferedUnit('no');
                }
                setTotalPrice(res.data.data?.bookingPricing?.adjusted_price || res.data.data?.bookingPricing?.final_price || 0)
                setSelectedBooking(res.data.data);
                setProjectName(res.data.data?.project.name)
                const { paymentPlan } = res.data.data
                if (paymentPlan) {
                    let plans = { ...paymentPlan, uuid: paymentPlan?.project_payment_plan }
                    setSelectedPaymentPlan(plans);
                }
            }
        }
    }

    const getBasePriceData = async (uuid) => {
        const res = await getPricingBasePrice(uuid);
        if (res.data.status === 200) {
            setBasePriceData(res.data.data);
            if (res.data.data && res.data.data.length) {
                let temp = {};
                for (let index = 0; index < res.data.data.length; index++) {
                    const element = res.data.data[index];
                    if (element.config && element.config.length) {
                        temp[element.config[0].id] = element.rate
                    }
                }
                if (Object.keys(temp).length) {
                    setBasePriceConfigMap(temp);
                }
            }
        }
    }

    const getEoiTokenData = async (uuid) => {
        const res = await getPricingEOI(uuid);
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                setSelectedPaymentPricing(res.data.data[0])
            }
        }
    }

    const getEOIDetailsByLeadId = async (uuid) => {
        const res = await getDetailsByLeadId(uuid);
        if (res.data.status === 200) {
            // setSelectedEoiDetails({ ...res.data.data, eoiData: true });
            if (res.data.status === 200) {
                if (res.data.data?.customer?.uuid) {
                    const { bookingUnit, booking } = res.data.data;
                    setUnit(bookingUnit);
                    if (bookingUnit?.uuid) {
                        setIsPrefferedUnit('yes');
                    } else {
                        setIsPrefferedUnit('no');
                    }
                    setTotalPrice(res.data.data?.bookingPricing?.adjusted_price || res.data.data?.bookingPricing?.final_price || 0)
                    setSelectedBooking({
                        ...res.data.data,
                        agreement: {},
                        booking: {},
                        bookingUnit: {},
                        referred_by: {},
                        paymentPlan: {},
                        loan: {},
                        developer: {},
                        customerBankDetails: {},
                        coapplicant: [],
                        bookingAmeniites: [],
                        bookingDiscounts: [],
                        followup: {},
                        transactions: []
                    });
                    setProjectName(res.data.data?.project.name)
                    const { paymentPlan } = res.data.data
                    if (paymentPlan) {
                        let plans = { ...paymentPlan, uuid: paymentPlan?.project_payment_plan }
                        setSelectedPaymentPlan(plans);
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (showTab) {
            setSteps(showTab)
        }
    }, [showTab]);

    useEffect(() => {
        if (selectedProject?.uuid) {
            getBasePriceData(selectedProject?.uuid);
            getEoiTokenData(selectedProject?.uuid)
        }
    }, [selectedProject])


    useEffect(() => {
        if (data.eoi_uuid) {
            setEOIId(data.eoi_uuid)
            getEOILeadDetails(data.eoi_uuid);
        }
        if (data.id) {
            getLeadDetails(data.id);
            getEOIDetailsByLeadId(data.id)
        }

    }, [data]);

    useEffect(() => {
        if (eoiId) {
            getEOILeadDetails(eoiId);
        }

    }, [eoiId, steps]);

    return (
        <BasicModal show={show} closeModal={closeModal} modalClass='lg:!max-w-[810px] !max-w-[95%] md:!mx-auto !mx-4'>
            <div className='h-[80vh] flex flex-fill flex-col'>
                <div className='pt-6 relative'>
                    <h1 className='sm:mx-6 mx-3 text-textblack sm:text-xl text-lg leading-7 font-semibold !mb-4 inter flex justify-between'>
                        <span>
                            {isEdit ? 'Edit' : ''} {userProfile?.company_id === 581 ? 'Token' : 'EOI'} Done For
                            <span className='ml-1 inline-block  text-capitalize'>{data.name}</span>
                        </span>
                        <button onClick={() => setShowCloseConfirmationModal(true)}>
                            <CloseIcon />
                        </button>
                    </h1>
                    <div className='flex items-center no-scrollbar border-b border-[#E2E2EA]'>
                        <button className='absolute left-1 lg:hidden'><Owlleft /></button>
                        <ul className='flex gap-x-3 items-center mb-0 pb-2  md:!px-0 lg:w-[100%] sm:w-[90%] w-[80%] md:!mx-5  !mx-auto no-scrollbar lg:overflow-hidden overflow-auto'>
                            <li
                                className={`flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3 ${isEdit ? 'cursor-pointer' : ''}`}
                                onClick={() => {
                                    if (isEdit) {
                                        setSteps('EOIBasicDetail');
                                    }
                                }}
                            >
                                <span className={`${steps === 'EOIBasicDetail' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Basic Details</span> <NavRight />
                            </li>
                            {/* <li className='flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3'>
                                <span className={`${steps === 'EOIQuotes' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>
                                    Select Quote
                                </span> <NavRight />
                            </li> */}
                            <li
                                className={`flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3 ${isEdit ? 'cursor-pointer' : ''}`}
                                onClick={() => {
                                    if (isEdit) {
                                        setSteps('EOIUnitDetail');
                                    }
                                }}
                            >
                                <span className={`${steps === 'EOIUnitDetail' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Unit Details</span> <NavRight />
                            </li>
                            {isPrefferedUnit && isPrefferedUnit === 'yes' && <li
                                className={`flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3 ${isEdit ? 'cursor-pointer' : ''}`}
                                onClick={() => {
                                    if (isEdit) {
                                        setSteps('EOIPricing');
                                    }
                                }}
                            >
                                <span className={`${steps === 'EOIPricing' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Pricing </span><NavRight />
                            </li>}
                            <li
                                className={`flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3 ${isEdit ? 'cursor-pointer' : ''}`}
                                onClick={() => {
                                    if (isEdit) {
                                        setSteps('Additional');
                                    }
                                }}
                            >
                                <span className={`${steps === 'Additional' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Additional details  </span><NavRight />
                            </li>
                            {isPrefferedUnit && isPrefferedUnit === 'yes' && <li
                                className={`flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3 ${isEdit ? 'cursor-pointer' : ''}`}
                                onClick={() => {
                                    if (isEdit) {
                                        setSteps('EOIPaymentplan');
                                    }
                                }}
                            >
                                <span className={`${steps === 'EOIPaymentplan' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Payment Plan  </span><NavRight />
                            </li>}
                            {/* <li
                                className={`flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3 ${isEdit ? 'cursor-pointer' : ''}`}
                                onClick={() => {
                                    if (isEdit) {
                                        setSteps('EOIPaymentProof');
                                    }
                                }}
                            >
                                <span className={`${steps === 'EOIPaymentProof' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Payment Proof  </span><NavRight />
                            </li> */}
                            {/* <li className='flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3'>
                                <span className={`${steps === 'EOIBrokerDetails' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Broker Details</span> <NavRight /></li> */}
                            <li
                                className={`flex items-center text-textblack text-sm leading-6 inter whitespace-nowrap  gap-3 ${isEdit ? 'cursor-pointer' : ''}`}
                                onClick={() => {
                                    if (isEdit) {
                                        setSteps('EOIOther');
                                    }
                                }}
                            >
                                <span className={`${steps === 'EOIOther' ? 'before:h-px font-semibold' : ' '}  relative  before:w-full before:content-[''] before:bg-black700 before:absolute before:left-0 before:-bottom-2`}>Others</span>
                            </li>
                        </ul>
                        <button className='absolute right-1 lg:hidden'><OwlRight /></button>
                    </div>
                </div>
                {steps === 'EOIBasicDetail' &&
                    <BasicDetail
                        data={data}
                        lead={lead}
                        setSteps={setSteps}
                        setEOIId={setEOIId}
                        setSelectedProject={setSelectedProject}
                        closeModal={closeModal}
                        selectedBooking={selectedBooking}
                        selectedPaymentPricing={selectedPaymentPricing}
                        userEmail={userEmail}
                        userProfile={userProfile}
                        initialProject={initialProject}
                        steps={steps}
                        selectedProject={selectedProject}
                        editToken={editToken}
                        initialSource={initialSource}
                    />}
                {/* {steps === 'EOIQuotes' &&
                    <EOIQuotes
                        setSteps={setSteps}
                        lead={lead}
                        eoiId={eoiId}
                        selectedProject={selectedProject}
                    />} */}
                {steps === 'EOIUnitDetail' &&
                    <UnitDetail
                        setSteps={setSteps}
                        data={data}
                        lead={lead}
                        eoiId={eoiId}
                        selectedProject={selectedProject}
                        setUnit={setUnit}
                        setUnitPreference={setUnitPreference}
                        selectedBooking={selectedBooking}
                        basePriceData={basePriceData}
                        basePriceConfigMap={basePriceConfigMap}
                        setIsPrefferedUnit={setIsPrefferedUnit}
                    />}
                {steps === 'EOIPricing' &&
                    <Pricing
                        setSteps={setSteps}
                        lead={lead}
                        eoiId={eoiId}
                        unit={unit}
                        data={data}
                        setTotalPrice={setTotalPrice}
                        selectedProject={selectedProject}
                        selectedBooking={selectedBooking}
                        updateAdjustedPrice={setAdjustedPrice}
                        allowedPermissions={allowedPermissions}
                    />}
                {
                    steps === 'Additional' && <AdditionalInfo
                        setSteps={setSteps}
                        eoiId={eoiId}
                        selectedBooking={selectedBooking}
                        isPrefferedUnit={isPrefferedUnit}
                    />
                }
                {steps === 'EOIPaymentplan' &&
                    <Paymentplan
                        setSteps={setSteps}
                        lead={lead}
                        eoiId={eoiId}
                        unit={unit}
                        data={data}
                        totalPrice={totalPrice}
                        selectedProject={selectedProject}
                        selectedBooking={selectedBooking}
                        setPaymentPlanForRaiseDemand={setSelectedPaymentPlan}
                    />}
                {/* {steps === 'EOIBrokerDetails' &&
                    <EOIBrokerDetails
                        data={data}
                        setSteps={setSteps}
                        lead={lead}
                        eoiId={eoiId}
                        selectedProject={selectedProject}
                        unitPreference={unitPreference}
                    />} */}
                {/* {
                    steps === 'EOIPaymentProof' && <PaymentProof
                        setSteps={setSteps}
                        selectedBooking={selectedBooking}
                        eoiId={eoiId}
                        selectedProject={selectedProject}
                    />
                } */}
                {steps === 'EOIOther' &&
                    <Other
                        data={data}
                        setSteps={setSteps}
                        eoiId={eoiId}
                        lead={lead}
                        selectedProject={selectedProject}
                        onSuccess={onSuccess}
                        unitPreference={unitPreference}
                        selectedBooking={selectedBooking}
                        selectedPaymentPlan={selectedPaymentPlan}
                        adjustedPrice={adjustedPrice}
                        closeModal={closeModal}
                        isPrefferedUnit={isPrefferedUnit}
                        projectName={projectName}
                        setEOIId={setEOIId}
                        setSelectedProject={setSelectedProject}
                        setSelectedBooking={setSelectedBooking}
                    />}
            </div>
            {showCloseConfirmationModal && <AreYouSureModal
                show={showCloseConfirmationModal}
                closeModal={() => setShowCloseConfirmationModal(false)}
                subText={'Do you want to cancel the Token Done process? Any unsaved changes you made may be lost.'}
                confirmClose={closeModal}
            />}
        </BasicModal>


    )
}

export default NewEOIModal
