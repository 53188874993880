import React, { useEffect, useState, useRef } from 'react'

import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { useCallback } from 'react';

// Set your Google Maps API key here
const apiKey = 'AIzaSyDRuni-c1oJBFaF8ahTxlxPmYyCmRRtclw';

const LocationPicker = ({ google,
    handleUpdateLocation,
    googleAddress,
    latitude,
    longitude,
}) => {
    const [selectedLocation, setSelectedLocation] = useState(null);
    const mapRef = useRef(null);
    const searchInputRef = useRef(null);
    const autocompleteService = useRef(null);
    const autocomplete = useRef(null);
    const markerRef = useRef(null);

    const initAutocomplete = useCallback(() => {
        autocompleteService.current = new google.maps.places.AutocompleteService();
        autocomplete.current = new google.maps.places.Autocomplete(searchInputRef.current);
        autocomplete.current.addListener('place_changed', handlePlaceChanged);
    }, []);

    useEffect(() => {
        initAutocomplete();
    }, []);


    useEffect(() => {
        let lat = latitude ? parseFloat(latitude) : null;
        let lng = longitude ? parseFloat(longitude) : null;

        setSelectedLocation({
            lat: lat,
            lng: lng,
            address: googleAddress,
            // city: previousCity,
            // state: previousState,
        })
        searchInputRef.current.value = googleAddress;
        if (mapRef.current && lat && lng) {
            mapRef.current.map.setCenter({ lat, lng });
            mapRef.current.map.panTo({ lat, lng });
        }

        if (markerRef.current && lat && lng) {
            markerRef.current.marker.setPosition({ lat, lng });
        }

    }, [googleAddress, latitude, longitude])

    const handlePlaceChanged = useCallback(() => {
        const place = autocomplete.current.getPlace();
        if (place.geometry) {
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            const address = place.formatted_address;

            let city = '';
            let state = '';
            let country = '';

            place.address_components.forEach(component => {
                if (component.types.includes('locality')) {
                    city = component.long_name;
                } else if (component.types.includes('administrative_area_level_1')) {
                    state = component.long_name;
                } else if (component.types.includes('country')) {
                    country = component.long_name;
                }
            });

            setSelectedLocation({
                lat,
                lng,
                address,
                // city, 
                // state, 
                // country 
            });
            handleUpdateLocation(address, lat, lng);

            if (mapRef.current) {
                mapRef.current.map.setCenter({ lat, lng });
                mapRef.current.map.panTo({ lat, lng });
            }

            if (markerRef.current) {
                markerRef.current.marker.setPosition({ lat, lng });
            }
        }
    }, []);

    const handleMapClick = useCallback((mapProps, map, clickEvent) => {
        const lat = clickEvent.latLng.lat();
        const lng = clickEvent.latLng.lng();

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const address = results[0].formatted_address;
                    setSelectedLocation({ lat, lng, address });
                }
            }
        });

        if (markerRef.current) {
            markerRef.current.marker.setPosition({ lat, lng });
        }
    }, []);

    const handleMarkerDrag = (mapProps, map, event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();

        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    const address = results[0].formatted_address;

                    setSelectedLocation({ lat, lng, address });
                }
            }
        });
    };

    const handleSearchChange = useCallback(() => {
        const query = searchInputRef.current.value;

        if (query && autocompleteService.current) {
            const request = {
                input: query,
            };

            autocompleteService.current.getPlacePredictions(request, (predictions) => {
                if (predictions && predictions.length > 0) {
                    autocomplete.current.set('predictions', predictions);
                }
            });
        }
    }, []);

    const handleGetDirections = () => {
        const { lat, lng } = selectedLocation;
        const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
        window.open(url, '_blank');
    };

    return (


        <div className={`form-group position-relative`}>
            <label>Full Address </label>
            <input
                className={'form-control text-input'}
                type={"text"}
                placeholder='e.g. Orchid Island'
                ref={searchInputRef}
                // value={googleAddress}
                onChange={handleSearchChange}
            // {...register('projectLocation')}
            />

            {/* {errors?.google_address?.message && (
                                <div className="input-feedback mt-8">
                                    {errors?.google_address?.message}
                                </div>
                            )} */}
            {/* <div className='form-group'>
                        <label htmlFor="">Google Maps</label>
                        <div className={`${style.gmap} border border-color radius-8`}>                           
                            <Map
                                google={google}
                                zoom={14}
                                onClick={handleMapClick}
                                initialCenter={{ lat: 37.7749, lng: -122.4194 }}
                                style={{ width: '100%', height: '100%', marginBottom: '0' }}
                                ref={mapRef}
                            >
                                {selectedLocation && (
                                    <Marker position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }} />
                                )}
                            </Map>
                        </div>

                    </div> */}
        </div>
    )
}

export default GoogleApiWrapper({
    apiKey: apiKey,
    libraries: ['places'],
})(LocationPicker);

