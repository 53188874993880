import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form';
import InputTextarea from '../InputGroup/InputTextarea'
import InputSelect from '../InputGroup/InputSelect'
import DateTimePicker from '../InputGroup/DateTime'
import InputText from '../InputGroup/InputText'
import { updateBookingApprovalStatus, updateBookingOtherDetails, updateEOIApprovalStatus } from '../../services/private/inventory.service';
import { toast } from 'react-toastify';
import { BankList, validityUnit } from '../../helpers/enums';
import { getLeadAccess, getLeadStatusAssignedToConfigByStatusId, getLeadStatusShareConfigByStatusId, getUsersForSettings, saveLeadAccess } from '../../services/private/company.service';
import MobileInput from '../InputGroup/MobileInput';
import InputRupees from '../InputGroup/InputRupees';
import { ReactComponent as PlusPrimaryIcon } from '../../assets/icons/PlusPrimary.svg';
import moment from 'moment';
import Consumer from '../../helpers/context';
import { reassignLeads } from '../../services/private/leads.service';
import { checkIsArrayEmpty, checkIsSomeItemAvailableInList } from '../../helpers/helpers';
import { findAllBrokerageForProject, uploadFile } from '../../services/public/public.service';
import { ReactComponent as PluswhiteIcon } from '../../assets/icons/Pluswhite.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg';
import { ReactComponent as PlusPrimary } from '../../assets/icons/PlusPrimary.svg';
import { ReactComponent as Pluswhite } from '../../assets/icons/Pluswhite.svg';
import pdfimg from '../../assets/icons/pdfview.jpg';
import style from '../../modules/Settings/Projects/style.module.css'
import '../../modules/Settings/Projects/style.css';
import { setBookingOtherDetails } from '../../modules/New Dashboard/services/dashboard.service';

const customStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: "none",
        borderColor: '#ced4da',
        // backgroundColor: '#FAFAFB',
        minHeight: '44px',
        borderRadius: '8px',
        fontSize: '14px',
        fontWeight: '400',
        cursor: 'pointer',
        '&:focus': { borderColor: '#0062ff' },
        // '&:hover': { borderColor: '#ced4da' },



        // You can also use state.isFocused to conditionally style based on the focus state
    }),
    option: (styles, state) => ({
        ...styles,
        fontSize: '14px',
        cursor: 'pointer',
        backgroundColor: state.isSelected ? "#f1f1f5" : styles.color,
        "&:hover": {
            color: "#44444f",
            backgroundColor: "#f1f1f5"
        }
    }),
};

const loanStatus = [
    {
        label: 'Pending',
        value: "Pending"
    },
    {
        label: 'Approved',
        value: "Approved"
    },
]

const IncentiveStatus = [
    {
        label: 'Paid',
        value: 'Paid'
    },
    {
        label: 'Pending',
        value: 'Pending'
    }
]

const Other = ({
    setSteps,
    data,
    onSuccess,
    bookingId,
    selectedBooking,
    closeModal,
    selectedEoiDetails,
    selectedProject,
    selectedCP,
    selectedCPUser,
    isPrefferedUnit,
    projectName,
    setBookingId,
    setSelectedProject,
    setSelectedBooking
}) => {

    const [allUsers, setAllUsers] = useState([]);
    const [users, setUsers] = useState([{
        access: 'edit',
        validity: 30,
        validity_unit: 'days'
    }]);
    const [loading, setLoading] = useState(false);
    const [statusShareUsers, setStatusShareUsers] = useState([]);
    const [leadsShareUsers, setLeadsShareUsers] = useState([]);
    const [assignedTo, setAssignedTo] = useState();
    const [closingManager, setClosingManager] = useState('')
    const [usersForAssigned, setUsersForAssigned] = useState([]);
    const [pdcs, setPdcs] = useState([{}]);
    const [brokerageList, setBrokerageList] = useState([]);
    const [showReferralDetail, setShowReferralDetail] = useState(false);
    const [addAnother, setAddAnother] = useState(false);
    const { userProfile, allowedPermissions } = useContext(Consumer);
    const ROLE_NAME = localStorage.getItem('role');

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        resetField,
        reset,
        setError,
        formState: { errors, isSubmitting, isDirty }
    } = useForm({
        defaultValues: {
            followupScheduleDate: '',
            remarks: '',
            status: '',
            amount: '',
            bank: '',
            sanction_amount: '',
            interest_rate: '',
            agreement_status: '',
            date: '',
            propose_date: '',
            agreeement: '',
            closing_manager: '',
            sourcing_manager: '',
            poc: '',
            name: '',
            mobile: '',
            account_no: '',
            bank_name: '',
            ifsc_code: '',
            branch: '',
            swift_code: '',
            nre_account_no: '',
            nro_account_no: '',
            fcnr_account_no: '',
            brokerage_id: '',
            brokerage_due: '',
            incentive_type: '',
            incentive_amount: '',
            incentive_status: '',
            society: '',
            flat_no: ''

        }
    })

    const getAllUsers = () => {
        getUsersForSettings().then(res => {
            if (res.status === 200) {
                const userData = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        value: data.id
                    }
                })
                const usersForAssign = res.data.data.map((data) => {
                    return {
                        label: data.name + " - " + data.email,
                        // label: data.name,
                        value: data.id,
                        uuid: data.uuid
                    }
                })
                setUsersForAssigned(usersForAssign);
                setAllUsers(userData)

                const user = usersForAssign.find(i => i.uuid === data?.assigned_user_uuid)
                setAssignedTo(user?.value)
            }
        })
    }

    useEffect(() => {
        if (selectedBooking && Object.keys(selectedBooking).length > 0) {
            const { agreement, loan, developer, referred_by, customerBankDetails, brokerageAndIncentive } = selectedBooking;
            if (agreement || loan || developer || referred_by || brokerageAndIncentive) {
                reset({
                    status: loan?.status ? loanStatus.find(item => item.value === loan?.status) : '',
                    amount: loan?.amount,
                    bank: loan?.bank ? BankList.find(item => item.value === loan.bank) : '',
                    sanction_amount: loan?.sanction_amount,
                    interest_rate: loan?.interest_rate,

                    agreement_status: agreement?.status || '',
                    date: agreement?.date,
                    propose_date: agreement?.propose_date,
                    agreeement: agreement?.agreeement,

                    closing_manager: developer?.closing_manager ? allUsers.find(item => item.value === developer?.closing_manager) : '',
                    sourcing_manager: developer?.sourcing_manager ? allUsers.find(item => item.value === developer?.sourcing_manager) : '',
                    poc: developer?.poc ? allUsers.find(item => item.value === developer?.poc) : '',

                    name: referred_by?.name,
                    mobile: referred_by?.mobile,
                    society: referred_by?.society,
                    flat_no: referred_by?.flat_no,

                    brokerage_id: brokerageAndIncentive?.brokerage_id || '',
                    brokerage_due: brokerageAndIncentive?.brokerage_due || '',
                    incentive_type: brokerageAndIncentive?.incentive_type || '',
                    incentive_amount: brokerageAndIncentive?.incentive_amount || '',
                    incentive_status: brokerageAndIncentive?.incentive_status || '',

                    account_no: customerBankDetails?.account_no || null,
                    bank_name: customerBankDetails?.bank_name || null,
                    ifsc_code: customerBankDetails?.ifsc_code || null,
                    branch: customerBankDetails?.branch || null,
                    swift_code: customerBankDetails?.swift_code || null,
                    nre_account_no: customerBankDetails?.nre_account_no || null,
                    nro_account_no: customerBankDetails?.nro_account_no || null,
                    fcnr_account_no: customerBankDetails?.fcnr_account_no || null,
                },
                    { keepStateOptions: true }
                )
                if (referred_by?.name || referred_by?.mobile) {
                    setShowReferralDetail(true)
                }
            } else if (selectedEoiDetails) {
                const { agreement, loan, developer, referred_by, customerBankDetails } = selectedEoiDetails;
                reset({
                    status: loan?.status ? loanStatus.find(item => item.value === loan?.status) : '',
                    amount: loan?.amount,
                    bank: loan?.bank ? BankList.find(item => item.value === loan.bank) : '',
                    sanction_amount: loan?.sanction_amount,
                    interest_rate: loan?.interest_rate,

                    agreement_status: agreement?.status || '',
                    date: agreement?.date,
                    propose_date: agreement?.propose_date,
                    agreeement: agreement?.agreeement,

                    closing_manager: developer?.closing_manager ? allUsers.find(item => item.value === developer?.closing_manager) : '',
                    sourcing_manager: developer?.sourcing_manager ? allUsers.find(item => item.value === developer?.sourcing_manager) : '',
                    poc: developer?.poc ? allUsers.find(item => item.value === developer?.poc) : '',

                    name: referred_by?.name,
                    mobile: referred_by?.mobile,
                    society: referred_by?.society,
                    flat_no: referred_by?.flat_no,

                    account_no: customerBankDetails?.account_no || null,
                    bank_name: customerBankDetails?.bank_name || null,
                    ifsc_code: customerBankDetails?.ifsc_code || null,
                    branch: customerBankDetails?.branch || null,
                    swift_code: customerBankDetails?.swift_code || null,
                    nre_account_no: customerBankDetails?.nre_account_no || null,
                    nro_account_no: customerBankDetails?.nro_account_no || null,
                    fcnr_account_no: customerBankDetails?.fcnr_account_no || null,
                },
                    { keepStateOptions: true }
                )
                if (referred_by?.name || referred_by?.mobile) {
                    setShowReferralDetail(true)
                }
            }


        }
    }, [selectedBooking, allUsers, loanStatus]);

    const getShareLeadAccess = async () => {
        const res = await getLeadAccess(data.id);
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                const mapped = res.data.data.map(item => ({
                    user_id: item.user_id,
                    validity: item.validity,
                    validity_unit: item?.validity_unit,
                    access: item.access
                }))
                // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
                // setAllUsers(results)
                setLeadsShareUsers(mapped);
            }
        }
    }

    const getShareLeadAccessAssignedToByStatus = async () => {
        const res = await getLeadStatusAssignedToConfigByStatusId({ status_id: data?.status });
        if (res.data.status === 200) {
            if (res.data.data?.user_uuid) {
                const user = usersForAssigned.find(i => i.uuid === res.data.data?.user_uuid)
                setAssignedTo(user?.value)
            }
            // else {
            //     setAssignedTo(data?.assigned_user_uuid)
            // }
        }
    }

    const getShareLeadAccessUsingStatusId = async () => {
        const res = await getLeadStatusShareConfigByStatusId({ status_id: data.status });
        if (res.data.status === 200) {
            if (res.data.data.length > 0) {
                const mapped = res.data.data.map(item => ({
                    user_id: item.user_id,
                    validity: item.validity,
                    validity_unit: item?.validity_unit,
                    access: item.access
                }))
                // const results = allUsers.filter((item) => !mapped.some((mapVal) => item.value === mapVal.user_id));
                // setAllUsers(results)
                setStatusShareUsers(mapped);
            }
        }
    }

    useEffect(() => {
        if (statusShareUsers.length > 0 && (leadsShareUsers.length === 0 || !userProfile?.maintain_lead_access)) {
            setUsers(statusShareUsers);
        } else if (leadsShareUsers.length > 0 && statusShareUsers.length === 0) {
            setUsers(leadsShareUsers);
        } else if (statusShareUsers.length > 0 && leadsShareUsers.length > 0 && userProfile?.maintain_lead_access) {
            // merge two array uniquely
            var ids = new Set(statusShareUsers.map(d => d.user_id));
            var merged = [...statusShareUsers, ...leadsShareUsers.filter(d => !ids.has(d.user_id))];
            setUsers(merged);
        }
    }, [statusShareUsers, leadsShareUsers])


    useEffect(() => {
        getAllUsers()
        if (data && userProfile?.allowed_modules?.includes('lead_sharing')) {
            getShareLeadAccess();
            getShareLeadAccessUsingStatusId()
            if (!data?.assigned_user_uuid) {
                getShareLeadAccessAssignedToByStatus();
            }
        }
    }, [data]);

    const getBrokrageDetails = async () => {
        const res = await findAllBrokerageForProject(selectedProject.uuid);
        if (res.data.status === 200) {
            const list = res.data.data.map(i => ({ ...i, label: `${i.min_value}-${i.max_value}`, value: i.id }))
            setBrokerageList(list);
            // setBrokrageDetails(res.data.data)
        }
    }

    useEffect(() => {
        if (selectedProject.uuid)
            getBrokrageDetails()
    }, [selectedProject])

    const handleReassignModalSubmit = () => {
        reassignLeads([data.id], assignedTo, '').then(res => {
            if (res.status === 200) {
                toast.success("Lead Reassigned")
            }
        }).catch(e => {
            toast.error(e.response.data.message)
        })
    }

    const handleSubmitSaveLeadAccess = async () => {
        let payload = users.filter(item => item.user_id);
        // let count = 0
        // payload.forEach(item => {
        //     if (!item.user_id || !item.validity || !item.access || !item.validity_unit) {
        //         count++
        //     }
        // })
        // if (count > 0) {
        //     toast.error('Please fill all values');
        //     return;
        // }

        let res = {}

        payload = payload.map(item => ({
            ...item,
            validity: item.validity || '30',
            access: item.access || 'edit',
            validity_unit: item.validity_unit || 'days',
            expires_at: new Date(moment().add(parseInt(item.validity), item.validity_unit)).toISOString()
        }))
        res = await saveLeadAccess(data.id, { status_id: payload?.length ? data.status : null, users: payload || [] })
        if (res.data.status === 200) {
            // toast.success(res.data.message);
            setLoading(false);
            // onSuccess();
            // document.getElementById('new-bookings-tab').click();
            // closeModal();
        }
    }

    const handleUpdateStatus = async () => {

        const res = await updateBookingApprovalStatus(bookingId, { status: 'pending' });
        if (res.data.status === 200) {
            if (assignedTo) {
                handleReassignModalSubmit();
            }
            // if (!checkIsArrayEmpty(users)) {
            handleSubmitSaveLeadAccess();
            // } else {
            setLoading(false);
            // onSuccess();

            /**
             * Commenting this code for testing
             */
            // document.getElementById('new-bookings-tab').click();
            // closeModal();
            setBookingId('')
            setSelectedProject({})
            setSelectedBooking()
            setSteps('basic')

            // }

        }
    }

    const handleChangePDC = async (e, fileIndex) => {
        const file = e.target.files[0]
        const formData = new FormData();
        formData.append("file", file)
        const response = await uploadFile(formData);
        if (response.status === 200) {
            const newFile = {
                name: file.name,
                fileName: file.name,
                type: file.type,
                url: response.data.data.s3_url,
                size: file.size,
                is_private: false,
                extension: file.name.split('.').pop(),
                file_type: file.type,
                category: ''
            }
            let newDocs = [...pdcs];
            newDocs[fileIndex].documents = [newFile];
            setPdcs(newDocs)

        }
    }

    const isDisable = useMemo(() => {
        if (ROLE_NAME === 'Super Admin') {
            return false;
        }

        return !allowedPermissions.includes('edit_lead_sharing')

    }, [allowedPermissions, ROLE_NAME])

    const onFormSubmit = async (values) => {
        let pdcsData = pdcs.filter(item => Object.keys(item).length > 0);

        let usersPayload = users.filter(item => item.user_id)
        usersPayload = usersPayload.map(item => ({
            ...item,
            validity: item.validity || '30',
            access: item.access || 'edit',
            validity_unit: item.validity_unit || 'days',
            expires_at: new Date(moment().add(parseInt(item.validity), item.validity_unit)).toISOString()
        }))

        const payload = {
            // followupScheduleDate: values.followupScheduleDate || null,
            // remarks: values.remarks || null,
            customerPDC: pdcsData,
            loan: {
                status: values.status.value || null,
                amount: values.amount ? parseInt(values.amount) : null,
                bank: values.bank.value || null,
                sanction_amount: values.sanction_amount ? parseInt(values.sanction_amount) : null,
                interest_rate: values.interest_rate ? parseInt(values.interest_rate) : null,
                // sanction_letter: values.sanction_letter || null
            },
            agreement: {
                status: values.agreement_status || null,
                date: values.date || null,
                propose_date: values.propose_date || null,
                agreeement: values.agreeement || null
            },
            developer: {
                closing_manager: values.closing_manager.value || null,
                sourcing_manager: values.sourcing_manager.value || null,
                poc: values.poc.value || null
            },
            referred_by: {
                name: values?.name || null,
                mobile: values?.mobile || null,
                society: values?.society || null,
                flat_no: values?.flat_no || null
            },
            customerBankDetails: {
                account_no: values.account_no || null,
                bank_name: values.bank_name || null,
                ifsc_code: values.ifsc_code || null,
                branch: values.branch || null,
                swift_code: values.swift_code || null,
                nre_account_no: values.nre_account_no || null,
                nro_account_no: values.nro_account_no || null,
                fcnr_account_no: values.fcnr_account_no || null,
            },
            brokerage_and_incentive: {
                brokerage_id: values.brokerage_id || null,
                brokerage_due: values.brokerage_due || null,
                incentive_amount: values.incentive_amount || null,
                incentive_type: values.incentive_type,
                broker_id: selectedCP || null,
                cp_firm_id: selectedCPUser || null,
                // lead_id: 101112,
                // booking_id: 131415,
                incentive_status: values.incentive_status // pending, Paid
            },
            // lead_access_users: usersPayload,
            reassign_users: {
                preSaleUserId: assignedTo || null,
                sourcingUserId: null,
                closingUserId: closingManager || null
            }
        }
        setLoading(true);
        try {
            // const res = await updateBookingOtherDetails(bookingId, payload);
            const res = await setBookingOtherDetails(bookingId, payload);
            if (res.data.status === 200) {
                toast.success(res.data.message);
                // if (!allowedPermissions.includes('booking_without_approval')) {
                //     handleUpdateStatus();
                // }
                // setBookingId('')
                // setSelectedProject({})
                // setSteps('basic')
                // closeModal();
                setLoading(false);
                setAddAnother(true);
            }
        } catch (err) {
            setLoading(false);
        }
    }

    const handleAnotherToken = () => {
        // setSelectedBooking({});
        setBookingId('');
        setSelectedProject({});
        setSteps('basic');
    }

    return (
        <>
            {!addAnother && <>
                <form onSubmit={handleSubmit(onFormSubmit)} id='other-detail' className='!pt-5 !px-6 pb-8 h-full overflow-auto'>
                    <div className='mb-3'>
                        <h2 className='m-0 text-textblack text-lg font-semibold inter'>Project ({selectedProject?.name || projectName})</h2>
                    </div>
                    {/* <div>
                    <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px]" >
                        Followup
                    </h5>
                    <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                        <div>
                            <Controller
                                control={control}
                                name='followupScheduleDate'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <DateTimePicker
                                        label='Next Payment Followup (optional)'
                                        inputclass='mt-0'
                                        placeholder='Select Followup Date & Time'
                                        value={value}
                                        onValueChange={onChange}
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <Controller
                                control={control}
                                name='remarks'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <InputTextarea
                                        type="text"
                                        label="Comments"
                                        placeholder="Enter your comments..."
                                        name="name"
                                        inputclass='m-0 inter'
                                        row='2'
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                        </div>

                    </div>
                </div> */}
                    <div>
                        {/* Brokerage */}
                        <div className='!mt-4'>
                            <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                {/* <div>
                                <Controller
                                    control={control}
                                    name='brokerage_id'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <InputSelect
                                                label="Brokerage Slab"
                                                placeholder="Select Brokerage Slab"
                                                options={brokerageList}
                                                inputclass='m-0 inter'
                                                value={value ? brokerageList.find(i => i.value === value) : ''}
                                                onChange={(value) => {
                                                    onChange(value.value)
                                                    const calcutedVal = ((value.brokerage_percentage * selectedBooking?.booking?.agreement_price) / 100)
                                                    setValue('brokerage_due', calcutedVal)
                                                }}
                                            />
                                        </>
                                    )}
                                />
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name='brokerage_due'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <InputRupees
                                                label="Total Brokerage Due"
                                                placeholder="Enter Total Brokerage Due"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                                value={value}
                                                onChange={onChange}
                                                handleBlur={() => { }}
                                            />
                                        </>
                                    )}
                                />
                            </div>
                            
                            <div>
                                <Controller
                                    control={control}
                                    name='incentive_amount'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <InputRupees
                                                label="Incentive Amt."
                                                placeholder="Enter Incentive Amt."
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                                value={value}
                                                onChange={onChange}
                                                handleBlur={() => { }}
                                            />
                                        </>
                                    )}
                                />
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name='incentive_type'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <InputText
                                                label="Incentive Type - (Offers)"
                                                placeholder="Enter Incentive Type"
                                                type="text"
                                                name="name"
                                                inputclass='m-0 inter'
                                                value={value}
                                                onChange={onChange}
                                            />
                                        </>
                                    )}
                                />
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name='incentive_status'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <>
                                            <InputSelect
                                                label="Status"
                                                placeholder="Select Status"
                                                type="text"
                                                name="name"
                                                options={IncentiveStatus}
                                                inputclass='m-0 inter'
                                                value={value ? IncentiveStatus.find(i => i.value === value) : ''}
                                                onChange={(value) => { onChange(value.value) }}
                                            />
                                        </>
                                    )}
                                />
                            </div> */}

                                {/* Customer Bank Details */}
                                <h5 className="!mb-3 text-textblack col-span-2 text-lg leading-6 font-semibold tracking-[-0.2px] inter" >
                                    Customer Bank Details
                                </h5>
                                <div>
                                    <Controller
                                        control={control}
                                        name='account_no'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    label="A/C No."
                                                    placeholder="Enter A/C No."
                                                    type="text"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='bank_name'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputSelect
                                                    label="Bank Name"
                                                    placeholder="Enter Bank Name"
                                                    type="text"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value ? BankList.find(item => item.value === value) : ''}
                                                    onChange={(value) => { onChange(value.value) }}
                                                    options={BankList}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='ifsc_code'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    label="IFSC Code"
                                                    placeholder="Enter IFSC Code"
                                                    type="text"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='branch'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    label="Branch Name"
                                                    placeholder="Enter Branch Name"
                                                    type="text"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='swift_code'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    label="Swift Code"
                                                    placeholder="Enter Swift Code"
                                                    type="text"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>

                                <div>
                                    <Controller
                                        control={control}
                                        name='nre_account_no'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    label="NRE A/C No."
                                                    placeholder="Enter NRE A/C No."
                                                    type="text"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='nro_account_no'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    label="NRO A/C No."
                                                    placeholder="Enter NRO A/C No."
                                                    type="text"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Controller
                                        control={control}
                                        name='fcnr_account_no'
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                        }) => (
                                            <>
                                                <InputText
                                                    label="FCNR A/C No."
                                                    placeholder="Enter FCNR A/C No."
                                                    type="text"
                                                    name="name"
                                                    inputclass='m-0 inter'
                                                    value={value}
                                                    onChange={onChange}
                                                />
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Loan Status */}
                    <div className='!mt-4'>
                        <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px]" >
                            Loan Status
                        </h5>
                        <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                            <div>
                                <Controller
                                    control={control}
                                    name='bank'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <InputSelect
                                            label='Bank (Preferred)'
                                            placeholder='Select Bank (Preferred)'
                                            inputclass='m-0 inter'
                                            isMulti={false}
                                            styles={customStyles}
                                            onChange={onChange}
                                            value={value}
                                            options={BankList}
                                        />
                                    )}
                                />
                            </div>

                            <div>
                                <Controller
                                    control={control}
                                    name='amount'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <InputRupees
                                            type="text"
                                            label="Loan Amount (Preferred)"
                                            placeholder="Enter Preferred Loan Amount"
                                            name="name"
                                            inputclass='m-0 inter'
                                            onChange={onChange}
                                            value={value}
                                            handleBlur={() => { }}
                                        />
                                    )}
                                />
                            </div>

                            {/* <div>
                            <Controller
                                control={control}
                                name='cp_id'
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <InputText
                                        type="text"
                                        label="Loan Amount (Preferred)"
                                        placeholder="Enter Preferred Loan Amount"
                                        name="name"
                                        inputclass='m-0 inter'
                                        onChange={onChange}
                                        value={value}
                                    />
                                )}
                            />
                        </div> */}
                            <div>
                                <Controller
                                    control={control}
                                    name='status'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <InputSelect
                                            label='Loan Status'
                                            placeholder='Select Loan Status'
                                            inputclass='m-0 inter'
                                            isMulti={false}
                                            styles={customStyles}
                                            onChange={onChange}
                                            value={value}
                                            options={loanStatus}
                                        />
                                    )}
                                />
                            </div>

                            <div>
                                <Controller
                                    control={control}
                                    name='sanction_amount'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <InputRupees
                                            label='Sanction Amount'
                                            placeholder='Select Sanction Amount'
                                            inputclass='m-0 inter'
                                            styles={customStyles}
                                            onChange={onChange}
                                            value={value}
                                            handleBlur={() => { }}
                                        />
                                    )}
                                />
                            </div>

                            <div>
                                <Controller
                                    control={control}
                                    name='interest_rate'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <InputText
                                            label='Interest Rate %'
                                            placeholder='Select Interest Rate'
                                            inputclass='m-0 inter'
                                            isMulti={false}
                                            type={'number'}
                                            styles={customStyles}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                            </div>

                        </div>
                    </div>
                    {/* Post Date cheques */}
                    {/* {
                    pdcs.map((pdc, index) => (
                        <div className='!mt-4'>
                            <div className='!mb-3 flex justify-between'>
                                <h5 className=" text-textblack text-lg leading-6 font-semibold tracking-[-0.2px] inter" >
                                    Add Post Date Cheque {index + 1}
                                </h5>
                                {pdcs.length > 1 &&
                                    <button className='border-0 bg-tr ' onClick={() => {
                                        const post = [...pdcs];
                                        post.splice(index, 1)
                                        setPdcs(post);
                                    }}
                                    >
                                        <CloseIcon />
                                    </button>}
                            </div>
                            <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                                <InputText
                                    label="Cheque No."
                                    placeholder="Enter Cheque No."
                                    type="text"
                                    name="cheque_no"
                                    inputclass='m-0 inter'
                                    value={pdc?.cheque_no}
                                    onChange={(e) => {
                                        let pdcsVal = [...pdcs];
                                        pdcsVal[index]['cheque_no'] = e.target.value;
                                        setPdcs(pdcsVal)
                                    }}
                                />

                                <DateTimePicker
                                    label="Cheque Date"
                                    placeholder="Enter Cheque Date"
                                    inputclass={'m-0'}
                                    value={pdc?.cheque_date}
                                    onValueChange={(date) => {
                                        let pdcsVal = [...pdcs];
                                        pdcsVal[index]['cheque_date'] = date;
                                        setPdcs(pdcsVal)
                                    }}
                                    showTime={false}
                                />

                                <InputRupees
                                    label="Cheque Amount"
                                    placeholder="Enter Cheque Amount"
                                    type="text"
                                    name="cheque_amount"
                                    inputclass='m-0 inter'
                                    value={pdc?.cheque_amount}
                                    showRupees={true}
                                    onChange={(value) => {
                                        let pdcsVal = [...pdcs];
                                        pdcsVal[index]['cheque_amount'] = value
                                        setPdcs(pdcsVal)
                                    }}
                                    handleBlur={() => { }}
                                />
                                {(!pdc.documents) && <div>
                                    <label className='text-sm text-black700 !mb-2 inter'>Cheque Photo</label>
                                    <div className='relative group border border-grayLight h-[154px] rounded-lg flex items-center justify-center overflow-hidden cursor-pointer'>
                                        <input
                                            type="file"
                                            className='d-none'
                                            id={`upload-pdc-${index}`}
                                            accept="application/pdf, image/*"
                                            onChange={(e) => handleChangePDC(e, index)} />
                                        <button
                                            type='button'
                                            className='!bg-primary flex items-center justify-center py-1 px-2 text-white gap-1 rounded-lg text-sm font-medium'
                                            onClick={() => {
                                                document.getElementById(`upload-pdc-${index}`).click();
                                            }}
                                        >
                                            <Pluswhite /> Upload
                                        </button>

                                        {/* <div className="group-hover:flex absolute left-0 right-0 top-0 bottom-0 w-100 h-100 bg-black bg-opacity-30 hidden items-center justify-center">
                                                    <div className='flex items-end gap-x-1 relative '>
                                                        <button type='button' className='py-1 px-2.5 text-white text-xs font-medium  !bg-primary rounded-md  tracking-[0.5px] inline-flex items-center inter'><Pluswhite /> Replace</button>
                                                        <button type='button' className='trashbtn w-8 h-8 bg-white rounded-md inline-flex items-center justify-center'><TrashIcon /></button>
                                                    </div>
                                                </div>
                                    </div>
                                </div>}
                                {pdc?.documents && pdc?.documents[0].url && <div>
                                    <label className='text-sm text-black700 !mb-2 inter'>Cheque Photo</label>
                                    <div className='!border !border-grayLight primary bg-[#FAFAFB] !p-4 rounded-lg h-[204px] relative'>
                                        <figure className='mb-0 !table !mx-auto relative group'>
                                            {['jpg', 'jpeg', 'png', 'webp'].includes(pdc.documents[0].extension) ?
                                                <img src={pdc.documents[0].url} alt="" className='h-[140px]' />
                                                : <img src={pdfimg} className='h-[140px]' />}

                                            <div className={`${style.hoverbox} group-hover:!opacity-100 rounded-lg`}>
                                                <button
                                                    className={`${style.replacebtn} pr-btn inter flex items-center`}
                                                    onClick={() => {
                                                        document.getElementById(`upload-pdc-${index}`).click();
                                                    }}
                                                ><PluswhiteIcon />Replace</button>

                                                <button
                                                    className={`${style.delbtn} inter border-0 bg-white`}
                                                    onClick={() => {
                                                        let newFiles = [...pdcs];
                                                        delete newFiles[index].documents;
                                                        setPdcs(newFiles);
                                                    }}
                                                ><TrashIcon /></button>
                                            </div>
                                        </figure>
                                        <p className='!text-primary !mb-2 mt-2 leading-6 text-xs font-medium text-center flex items-center gap-2 justify-center inter'> {pdc.documents[0].fileName}</p>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    ))
                } */}
                    {/* <div className="!mt-3">
                    <button
                        type='button'
                        className='flex items-center justify-center w-100 !text-primary p-4 text-sm font-medium inter !border !border-primary rounded-lg gap-2 inter'
                        onClick={() => setPdcs([...pdcs, {}])}
                    >
                        <PlusPrimary /> Post Date cheques
                    </button>
                </div> */}
                    <div className='!mt-5 !border-t !border-grayLight !pt-5'>
                        <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px]" >
                            Developer RM
                        </h5>
                        <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                            <div>
                                <Controller
                                    control={control}
                                    name='closing_manager'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <InputSelect
                                            label="Developer Closing Manager"
                                            placeholder="Enter Developer Closing Manager"
                                            name="name"
                                            inputclass='m-0 inter'
                                            value={value}
                                            onChange={onChange}
                                            options={allUsers}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name='sourcing_manager'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <InputSelect
                                            label="Developer Sourcing Manager"
                                            placeholder="Enter Developer Sourcing Manager"
                                            name="name"
                                            inputclass='m-0 inter'
                                            value={value}
                                            onChange={onChange}
                                            options={allUsers}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name='poc'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (

                                        <InputSelect
                                            type="text"
                                            label="Developer Collection POC"
                                            placeholder="Enter Developer Collection POC"
                                            name="name"
                                            inputclass='m-0 inter'
                                            value={value}
                                            onChange={onChange}
                                            options={allUsers}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    {/* Agreement */}
                    <div className='!mt-5 !border-t !border-grayLight !pt-5'>
                        <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px]" >
                            Agreement
                        </h5>
                        <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                            <div>
                                <Controller
                                    control={control}
                                    name='agreement_status'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (

                                        <InputSelect
                                            label='Agreement status'
                                            placeholder='Select status'
                                            inputclass='m-0 inter'
                                            isMulti={false}
                                            styles={customStyles}
                                            onChange={(value) => onChange(value.value)}
                                            value={value ? loanStatus.find(l => l.value === value) : ''}
                                            options={loanStatus}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name='propose_date'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (

                                        <DateTimePicker
                                            label='Proposed Agreement Date'
                                            inputclass='mt-0'
                                            onValueChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name='date'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (

                                        <DateTimePicker
                                            label='Agreement Date'
                                            inputclass='mt-0'
                                            onValueChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='!border-t !border-grayLight !pt-5 !mt-5'></div>
                    <div className='flex items-center col-span-2'>
                        <h2 className='text-textblack text-lg font-semibold inter mr-4'>Do you want to add Referral Detail ?</h2>
                        <div className='flex'>
                            <div className='mr-4 cursor-pointer'>
                                <input type="radio" id='unit_yes' name='unit_select' className='mr-2' value={showReferralDetail} checked={showReferralDetail} onChange={() => setShowReferralDetail(true)} />
                                <label className='cursor-pointer font-semibold' htmlFor="unit_yes">Yes</label>
                            </div>
                            <div >
                                <input type="radio" id='unit_no' name='unit_select' className='mr-2' value={!showReferralDetail} checked={!showReferralDetail} onChange={() => {
                                    setValue('name', '');
                                    setValue('mobile', '');
                                    setValue('society', '');
                                    setValue('flat_no', '');
                                    setShowReferralDetail(false)
                                }} />
                                <label className='cursor-pointer font-semibold' htmlFor="unit_no">No</label>
                            </div>
                        </div>
                    </div>
                    {showReferralDetail && <div className='!mt-5'>
                        <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px]" >
                            Refer By
                        </h5>
                        <div className='grid sm:grid-cols-2 grid-cols-1 gap-x-9	gap-y-4'>
                            <div>
                                <Controller
                                    control={control}
                                    name='name'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <InputText
                                            type="text"
                                            label="Customer Name"
                                            placeholder="Enter Customer Name"
                                            name="name"
                                            inputclass='m-0 inter'
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name='mobile'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <MobileInput
                                            type="text"
                                            label="Customer Mobile"
                                            placeholder="Enter Customer Mobile"
                                            name="name"
                                            inputclass='m-0 inter'
                                            value={value}
                                            onChange={(e) => onChange(e.phone)}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name='society'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <InputText
                                            type="text"
                                            label="Customer Society"
                                            placeholder="Enter Customer Society"
                                            name="society"
                                            inputclass='m-0 inter'
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </div>
                            <div>
                                <Controller
                                    control={control}
                                    name='flat_no'
                                    render={({
                                        field: { onChange, onBlur, value, ref },
                                    }) => (
                                        <InputText
                                            type="text"
                                            label="Customer Flat no"
                                            placeholder="Enter Customer Flat no"
                                            name="flat_no"
                                            inputclass='m-0 inter'
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </div>}
                    <div className='!mt-5 !border-t !border-grayLight !pt-5'>
                        <h5 className="!mb-3 text-textblack text-lg leading-6 font-semibold tracking-[-0.2px]" >
                            Lead Sharing
                        </h5>
                        {userProfile?.allowed_modules?.includes('lead_sharing') && checkIsSomeItemAvailableInList(allowedPermissions, ['view_lead_share_modal', 'edit_lead_sharing']) && <>
                            <div className='mb-3'>
                                <InputSelect
                                    label={'Pre-sale'}
                                    placeholder={'Select User'}
                                    options={usersForAssigned}
                                    value={assignedTo ? usersForAssigned.find(user => user.value === assignedTo) : ''}
                                    onChange={(value) => {
                                        setAssignedTo(value.value);
                                    }}
                                    disable={isDisable}
                                />
                            </div>
                            <div className='mb-3'>
                                <InputSelect
                                    label={'Closing'}
                                    placeholder={'Select User'}
                                    options={usersForAssigned}
                                    value={closingManager ? usersForAssigned.find(user => user.value === closingManager) : ''}
                                    onChange={(value) => {
                                        setClosingManager(value.value);
                                    }}
                                    disable={isDisable}
                                />
                            </div>
                            {
                                users.map((item, index) => (
                                    <div className={`${index > 0 ? 'mt-3' : ''}`} key={`${index}`}>
                                        <div className='flex justify-between items-center'>
                                            <h3 className='text-base font-semibold'>User {index + 1}</h3>
                                            {allowedPermissions.includes('edit_lead_sharing') && <button
                                                className='text-primary text-base font-medium'
                                                type='button'
                                                onClick={() => {
                                                    let newUsers = [...users];
                                                    newUsers.splice(index, 1);
                                                    if (users.length === 1) {
                                                        setUsers([{}])
                                                    } else {
                                                        setUsers([...newUsers])
                                                    }

                                                }}
                                            >Remove</button>}
                                        </div>
                                        <div>
                                            <InputSelect
                                                placeholder={'Select User'}
                                                options={allUsers}
                                                value={item.user_id ? allUsers.find(user => user.value === item.user_id) : ''}
                                                onChange={(value) => {
                                                    let newUsers = [...users];
                                                    newUsers[index].user_id = value.value;
                                                    // let filteredUser = allUsers.filter(aluser => aluser.value !== value.value)
                                                    // setAllUsers(filteredUser)
                                                    setUsers([...newUsers]);
                                                }}
                                                disable={isDisable}
                                            />
                                            <div className='mt-3'>
                                                <label htmlFor={`edit_${index}`} className='mr-4'>
                                                    <input
                                                        type="radio"
                                                        className='mr-2'
                                                        name={`permission_${index}`}
                                                        id={`edit_${index}`}
                                                        value='edit'
                                                        checked={item.access === 'edit'}
                                                        onChange={() => {
                                                            let newUsers = [...users];
                                                            newUsers[index].access = 'edit'
                                                            setUsers(newUsers);
                                                        }}
                                                        disable={isDisable}
                                                    />
                                                    <span>Edit/View Access</span>
                                                </label>
                                                <label htmlFor={`view_${index}`}>
                                                    <input
                                                        type="radio"
                                                        className='mr-2'
                                                        name={`permission_${index}`}
                                                        id={`view_${index}`}
                                                        value='view'
                                                        checked={item.access === 'view'}
                                                        onChange={() => {
                                                            let newUsers = [...users];
                                                            newUsers[index].access = 'view'
                                                            setUsers(newUsers);
                                                        }}
                                                        disable={isDisable}
                                                    />
                                                    <span>View Only</span>
                                                </label>
                                            </div>
                                            <div>
                                                <label htmlFor="">Validity</label>
                                                <div className='grid grid-cols-2 gap-2'>
                                                    <InputText
                                                        placeholder={'e.g. 30'}
                                                        value={item.validity}
                                                        onChange={(e) => {
                                                            let newUsers = [...users];
                                                            newUsers[index].validity = e.target.value
                                                            setUsers(newUsers);
                                                        }}
                                                        disable={isDisable}
                                                    />
                                                    <InputSelect
                                                        inputclass={'m-0'}
                                                        styles={customStyles}
                                                        placeholder={'Select Time'}
                                                        value={item.validity_unit ? validityUnit.find(validity => validity.value === item.validity_unit) : ''}
                                                        options={validityUnit}
                                                        onChange={(value) => {
                                                            let newUsers = [...users];
                                                            newUsers[index].validity_unit = value.value;
                                                            setUsers(newUsers);
                                                        }}
                                                        disable={isDisable}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                            <button
                                className='inline-flex !mt-3 items-center gap-x-2 !text-primary inter text-sm font-semibold disabled:bg-transparent disabled:hover:bg-transparent'
                                type='button'
                                onClick={() => setUsers([...users, {
                                    access: 'edit',
                                    validity: 30,
                                    validity_unit: 'days'
                                }])}
                                disable={isDisable}
                            >
                                <PlusPrimaryIcon />Add another
                            </button>
                        </>}
                    </div>
                </form>
                <div className='!px-6 !py-4 flex gap-3 items-center justify-end shadow-topshadow'>
                    <button
                        type='button'
                        className='text-base font-medium inter leading-6 rounded-lg !border !border-grayLight !text-black w-[128px] h-[44px] text-center'
                        onClick={() => {
                            if (isPrefferedUnit && isPrefferedUnit === 'no') {
                                setSteps('Additional')
                            } else {
                                setSteps('Paymentplan')
                            }

                        }}
                    >Previous</button>
                    <button
                        type='submit'
                        form='other-detail'
                        disabled={loading}
                        className='text-base font-medium inter leading-6 rounded-lg !text-white !bg-primary w-max h-[44px] px-2 text-center'
                    >Send For Approval</button>
                </div>
            </>}
            {addAnother && <>
                <div className='!pt-5 !px-6 pb-8 h-full overflow-auto flex justify-center'>

                    <div className='flex items-center flex-col justify-center w-[400px]'>
                        <p className='text-xl font-semibold !mb-3 text-center'>Do you want to add another booking?</p>
                        {/* <p className='text-sm text-grey-500'>You can edit the individual details later on.</p> */}
                        <div className='flex w-full items-center !mt-4'>

                            <button className='text-base font-medium w-1/2 !py-2.5 !rounded-lg text-primary !border !border-primary !mr-4' onClick={() => {
                                closeModal();
                                onSuccess();
                            }}>No</button>
                            <button className='!bg-primary !rounded-lg w-1/2 !py-2.5 text-white text-base font-medium' onClick={handleAnotherToken}>
                                Yes
                            </button>
                        </div>
                    </div>
                </div>
            </>}
        </>
    )
}

export default Other
