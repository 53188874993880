export const enums = {
    'Status': 'Status',
    'Intent': 'Intent',
    'Project': 'Project',
    'Source': 'Source',
    'Dead Reasons': 'Dead Reasons',
    'followup_date': 'Followup Date',
    'sv_schedule_date': 'SV Schedule Date',
    'Assigned User': 'Assigned User',
    'Created Date': 'Created Date',
    'Created Time': 'Created Time',
    'Updated Date': 'Assigned Date',
    'Name': 'Name',
    'City': 'City',
    'Marketing Agency': 'Marketing Agency',
    'Mobile No.': 'Mobile No',
    'employment_type': 'Employment Type',
    'facing': 'Facing',
    'customer_address': 'Customer Address',
    'preferred_location': 'Preferred Location',
    'possession': 'Possession Preference',
    'purpose': 'Purpose',
    'property_category': 'Property Type',
    'configuration': 'Configuration Type',
    'size': 'Size',
    'property_type': 'Requirement Type',
    'funding_source': 'Funding Source',
    'budget': 'Budget',
    'email': 'Email',
    'alternate_mobile': 'Alt. Mobile',
    'industry': 'Industry',
    'referred_by': 'Referred By',
    'booking_date': 'Booking Date',
    'eoi_date': 'Booking Date',
    'sv_complete_date': 'SV Complete Date',
    'import_job_id': 'CSV Imports',
    'shared_with': 'Shared With',
    'is_re_engagement': 'Re-Engaged',
    'sv_type': 'SV Type',
    'not_connected_reason': 'Not Connected Reason',
    'in_progress_reason': 'In Progress Reason',
    'sv_cancellation_reason': 'SV Cancellation Reason',
    're_assign_date': 'Re assign Date',
    're_engage_date': 'Re engage Date',
    'Form Id': 'Facebook Forms',
    'hide_team_leads': 'Hide Team Leads',
    'status_changed_on': 'Marked Date',
    'status_changed_by': 'Marked By',
    'eoi_cancellation_reason': 'Token Cancellation',
    'booking_cancellation_reason': 'Booking Cancellation',
    're_engage_sources': 'Re-Engaged Source',
    'broker_company_id': 'Broker Company',
    'broker_user_id': 'Broker User',
    'broker_user_code': 'Broker Code',
    'teams': 'Team',
    'RMs': 'Sourcing Manager',
    'is_site_visit_done': 'Site Visit Completed Leads',
    'is_confirmed': 'Sitevisit Confirmed',
    'revisit': 'Revisit',
    'no_projects': 'No Project Opportunity'
}

export const SV_ENUMS = {
    projects: 'Project',
    markedBy: 'Marked By',
    markedDate: 'Marked Date',
    cpFirm: 'CP Firm',
    lead_created_by: 'Lead Created By',
    completed_by: 'Completed By',
    lead_existed: 'Lead Exist',
    tagged: 'Lead Tagged',
    is_cp_registered: 'CP Registered',
    lead_created_time: 'Lead Created Date',
    source: 'Source',
    sv_source: 'Channel Partner/Direct'
}

export const filtersEnum = {
    property_type: 'Property Type',
    possession_type: 'Possession Type',
    unit_type: 'Unit Type',
    transaction_type: 'Transaction Type',
    furnished: 'Furnished',
    configurations: 'Configurations',
    facing: 'Facing',
    usages_status: 'Usages Status',
    build_up_area: 'Build up Area',
    super_area: 'Super Area',
    plot_area: 'Plot Area'
}

export const propertyTypeEnum = {
    residential: 'Residential',
    commercial: 'Commercial',
}

export const transactionTypeEnum = {
    sale: 'Sale',
    rent: 'Rent',
    retail_lease: 'Retail-lease',
    pre_lease: 'Pre-lease'
}