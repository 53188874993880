import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export default function Projects({
    projectsArr
}) {

    return (
        <>
            <span className='text-capitalize'>{projectsArr?.length > 0 ? <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id="button-tooltip" >
                        <div className='info-msg'>
                            {/* <h4>Total Leads</h4>*/}
                            <p className='text-capitalize'>{projectsArr[0]}</p>
                        </div>
                    </Tooltip >
                }
            >
                <span className='three-dots cursor-pointer'>{projectsArr[0]}</span>
            </OverlayTrigger > : '-'}
            </span>
            {
                projectsArr?.length > 1 ? <div className="tag-dropdown" >
                    <button className="border-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        +{projectsArr?.length - 1}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {projectsArr.map((item, index) => {
                            return <a key={index} className="dropdown-item text-capitalize"  >{item}
                                {/* {index == 0 ? <span className='drop-tag'>Primary</span> : ''} */}
                            </a>

                        })}
                    </div>
                </div> :
                    " "}
        </>
    )
}