import React from 'react';
import history from '../config/history';
import AmplifySdk from '../helpers/amplify';
import { useContext } from 'react';
import request from '../helpers/requests';
import AppContext from './context';
import URLS from '../helpers/urls'
import moment from 'moment';
import { UAParser } from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';

const Amplify = AmplifySdk();


export const DisplayFormikState = props =>
  <div style={{ margin: '1rem 0' }}>
    <h3 style={{ fontFamily: 'monospace' }} >&nbsp;</h3>
    <pre
      style={{
        background: '#f6f8fa',
        fontSize: '.65rem',
        padding: '.5rem',
      }}
    >
      <strong>props</strong> ={' '}
      {JSON.stringify(props, null, 2)}
    </pre>
  </div>;

export const browserRedirect = location => {
  history.push(location);
}

export const parseJwt = token => {
  if (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }
  return null;
};

export const checkAuthorization = async () => {
  try {
    // as discussed with ankit
    const data = localStorage.getItem("authToken") ? true : false
    // await Amplify.Auth.currentUserPoolUser()

    return data
  } catch (err) {
    console.log('error: ', err);
    return false
  }
};

export const getState = (state, modulename) => state[modulename];


export const convertToTitleCase = (input) => {
  const words = input.split('_');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
};


export const getCompanyConfig = async () => {

  try {
    const data = await request("GET", `${process.env.REACT_APP_DEV_API_URL}/${URLS.COMPANY_URL}/config`)

    return { result: data, err: null }
  } catch (err) {
    console.log('error: ', err);
    return { result: null, err: err }
  }

}



export const convertColumnsToSelectData = (allColumns) => {

  let selectData = [];
  for (let col of allColumns) {

    if (col.name != "S.No" && ["OnHoverText", "Text", "Date", "RefrenceText"].includes(col.type)) selectData.push({ label: col.name, value: col.name })
  }
  return selectData
}

export const getDisplayDateTime = (inputDate) => {
  return `${moment(inputDate).add('5:30').format('LT')} ${moment(inputDate).add('5:30').format('LL')}`
}

export const isDue = (inputDate) => {
  return moment().isAfter(moment(inputDate).add('5:30'));
}

export const isFutureDate = (selected) => {
  let current = moment();
  return moment(selected).isAfter(current);
}

export const toINRFormat = (value) => {
  let n1;
  const num = `${Math.round(value)}` || '';
  // works for integer and floating as well
  n1 = num.split('.');
  const n2 = n1[1] || null;
  n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, '$1,');
  const number = n2 ? `${n1}.${n2}` : n1;
  return number;
};

export const getUnion = (arr1, arr2) => {
  if (arr1.length === 0) {
    return [...arr2]
  }
  var result = arr2.concat(arr1).filter(function (o) {
    return this[o.key] ? false : this[o.key] = true;
  }, {});

  return [...result];
}

export const differenceOfArray = (arr1, arr2) => {
  return arr1.filter(el => {
    return !arr2.find(element => {
      return element.value === el.value
    })
  })
}

export const dateRange = {
  tomorrow: {
    from: moment().add(1, 'day').endOf('day').format("YYYY-MM-DD"),
    to: moment().add(1, 'day').endOf('day').format("YYYY-MM-DD")
  },
  this_Week: {
    from: moment(Date.now()).startOf('week').format("YYYY-MM-DD"),
    to: moment(Date.now()).endOf('week').format("YYYY-MM-DD")
  },
  next_week: {
    from: moment(Date.now()).add(1, 'weeks').startOf('week').format("YYYY-MM-DD"),
    to: moment(Date.now()).add(1, 'weeks').endOf('week').format("YYYY-MM-DD")
  },
  this_month: {
    from: moment(Date.now()).startOf('month').format("YYYY-MM-DD"),
    to: moment(Date.now()).endOf('month').format("YYYY-MM-DD")
  },
  next_month: {
    from: moment(Date.now()).add(1, 'month').startOf('week').format("YYYY-MM-DD"),
    to: moment(Date.now()).add(1, 'month').endOf('week').format("YYYY-MM-DD")
  },
}

export const removeDecimalPlaces = (number) => {
  const parsedNumber = parseFloat(number);
  return parsedNumber % 1 === 0 ? parsedNumber.toFixed(0) : parsedNumber.toFixed(2);
}


export const getYoutubeVideoId = (url) => {
  const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})/);
  return match ? `https://www.youtube.com/embed/${match[1]}` : null;
};

export function numDifferentiation(value) {
  const val = Math.abs(value)
  if (val >= 10000000) return `${value % 10000000 === 0 ? (value / 10000000) : (value / 10000000).toFixed(2)} Cr`
  if (val >= 100000) return `${value % 100000 === 0 ? (value / 100000) : (value / 100000).toFixed(2)} Lac`
  if (val >= 1000) return `${value % 1000 === 0 ? (value / 1000) : (value / 1000).toFixed(2)} K`
  return value;
}

export const getInitials = (string) => {
  var names = string?.split(' '),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

export const checkIsSomeItemAvailableInList = (list, items) => {
  if (list?.length === 0) {
    return false;
  }
  return list?.some(lItem => items.includes(lItem));
}

export const checkExpiryDate = (date) => {
  const expiryTime = moment(date);
  const currentTime = moment();
  return expiryTime.isBefore(currentTime)
}

const getSum = (toBeSum) => {
  let sum = 0;
  toBeSum.forEach(item => sum += parseInt(item));
  return sum;
}

export const addPercentage = (baseAmount, percentageAmount) => {
  // Check if the inputs are valid numbers
  if (typeof baseAmount !== 'number' || typeof percentageAmount !== 'number') {
    return "Both inputs must be numbers";
  }

  // Calculate the addition based on the percentage
  const addition = baseAmount + (baseAmount * (percentageAmount / 100));

  return addition;
}

export const subtractPercentage = (baseAmount, percentageAmount) => {
  // Check if the inputs are valid numbers
  if (typeof baseAmount !== 'number' || typeof percentageAmount !== 'number') {
    return "Both inputs must be numbers";
  }

  // Calculate the addition based on the percentage
  const addition = baseAmount - (baseAmount * (percentageAmount / 100));

  return addition;
}

export const getMobileNumber = (mobile) => {
  if (mobile?.length == 12) {
    return `+${mobile}`;
  } else {
    return mobile;
  }
}

const getCommon = (arr1, arr2) => {
  return arr1.filter(item => arr2.includes(item))
}

export const getPriceFromConfig = (config, rate) => {
  // type cast to numbers
  rate = Number(rate);

  let price = 0;

  if (config?.plot_area !== null) {
    price = rate * Number(config?.plot_area);
  } else if (config?.built_up_area !== null) {
    price = rate * Number(config?.built_up_area);
  } else if (config?.super_area !== null) {
    price = rate * Number(config?.super_area);
  } else if (config?.carpet_area !== null) {
    price = rate * Number(config?.carpet_area);
  } else {
    price = 0; // Return 0 if no valid area field is found
  }
  return formatPrice(price);
}

export const formatPrice = (price) => {
  // Ensure price is a number
  price = parseFloat(price);
  // Round the number to the nearest whole number
  return Math.round(price);
}

export const priceTwoDecimalPlaces = (price) => {
  // Ensure price is a number
  price = parseFloat(price);

  // Check if there are decimal places
  if (price % 1 !== 0) {
    // If decimal places are present, round to 2 decimal places
    return Number(price.toFixed(2));
  } else {
    // If no decimal places, return as is
    return price;
  }
}

export const getUnitTotalPrice = (unit) => {
  let amount = getPriceFromConfig(unit?.configuration, unit?.base_price);
  let priceToBeAdded = [];

  if (amount) {
    priceToBeAdded.push(amount);
  }

  if (unit?.plc_charge1) {
    priceToBeAdded.push(unit?.plc_charge1)
  }
  if (unit?.plc_charge2) {
    priceToBeAdded.push(unit?.plc_charge2)
  }
  if (unit?.plc_charge3) {
    priceToBeAdded.push(unit?.plc_charge3)
  }
  if (unit?.plc_charge4) {
    priceToBeAdded.push(unit?.plc_charge4)
  }
  return priceToBeAdded.length > 0 ? `₹ ${toINRFormat(getSum(priceToBeAdded))}` : '-'
}

export const getUnitArea = (config, rate) => {
  if (config?.plot_area !== null) {
    return config?.plot_area_unit;
  } else if (config?.built_up_area !== null) {
    return config?.built_up_area_unit;
  } else if (config?.super_area !== null) {
    return config?.super_area_unit;
  } else if (config?.carpet_area !== null) {
    return config?.carpet_area_unit;
  } else {
    return 0; // Return 0 if no valid area field is found
  }
}

const mappedUnit = [
  'Sq.feet'
]

export const getConfigString = (config) => {
  let result = [];
  if (!Array.isArray(config)) {
    config = [config]; // Convert to an array if it's not already
  }
  for (const item of config) {
    const unitType = item?.unit_type.charAt(0).toUpperCase() + item?.unit_type.slice(1); // Capitalize the unit_type
    let areaKey = null;
    let areaKeyUnit = null;

    if (item?.carpet_area !== null) {
      areaKey = item?.carpet_area;
      areaKeyUnit = item?.carpet_area_unit;
    } else if (item?.super_area !== null) {
      areaKey = item?.super_area;
      areaKeyUnit = item?.super_area_unit;
    } else if (item?.built_up_area !== null) {
      areaKey = item?.built_up_area;
      areaKeyUnit = item?.built_up_area_unit;
    } else if (item?.plot_area !== null) {
      areaKey = item?.plot_area;
      areaKeyUnit = item?.plot_area_unit;
    }

    if (unitType && areaKey !== null && areaKeyUnit) {
      result.push(`${unitType} - ${item?.unit_configuration ? item?.unit_configuration + ' ' : ''}${areaKey} ${mappedUnit.includes(areaKeyUnit) ? 'sq.ft' : areaKeyUnit}`);
    }
  }

  return result.join(', ');
}

export const getAreaConfig = (config) => {
  let result = [];
  if (!Array.isArray(config)) {
    config = [config]; // Convert to an array if it's not already
  }
  for (const item of config) {
    // const unitType = item?.unit_type.charAt(0).toUpperCase() + item?.unit_type.slice(1); // Capitalize the unit_type
    let areaKey = null;
    let areaKeyUnit = null;

    if (item?.carpet_area !== null) {
      areaKey = item?.carpet_area;
      areaKeyUnit = item?.carpet_area_unit;
    } else if (item?.super_area !== null) {
      areaKey = item?.super_area;
      areaKeyUnit = item?.super_area_unit;
    } else if (item?.built_up_area !== null) {
      areaKey = item?.built_up_area;
      areaKeyUnit = item?.built_up_area_unit;
    } else if (item?.plot_area !== null) {
      areaKey = item?.plot_area;
      areaKeyUnit = item?.plot_area_unit;
    }

    if (areaKey !== null && areaKeyUnit) {
      result.push(`${areaKey} ${areaKeyUnit}`);
    }
  }

  return result.join(', ');
}

export const toNumber = (input) => {
  if (input === null || input === undefined) {
    return 0;
  }

  if (typeof input === 'string' || typeof input === 'number') {
    return parseFloat(input);
  }

  return 0;
}

export const checkIsArrayEmpty = (arr) => {
  // Check if the array is technically empty
  if (arr.length === 0) {
    return true;
  }

  // Check if all elements in the array are empty objects
  return arr.every(item =>
    Object.keys(item).length === 0 && item.constructor === Object
  );
}

export const isNumber = (n) => {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

export const bytesToMegaBytes = (bytes) => {
  if (bytes > (1024 * 1024)) {
    return `${(bytes / (1024 * 1024)).toFixed(2)} mb`;
  } else {
    return `${(bytes / (1024)).toFixed(2)} kb`;
  }
}

// Function to merge two arrays of objects uniquely based on a key
export const mergeUniqueByKey = (arr1, arr2, key) => {
  // Combine the arrays
  let combinedArray = arr1.concat(arr2);

  // Use an object or a Set to store unique items based on the key
  let uniqueItems = {};
  combinedArray.forEach(item => {
    uniqueItems[item[key]] = item;
  });

  // Convert the object's values back to an array
  let mergedArray = Object.values(uniqueItems);

  return mergedArray;
}

export const numberFormat = (num) => {
  if (num >= 100000) {
    return (num / 100000).toFixed(1).replace(/\.0$/, '') + 'L';
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return num
}

export const getDeviceInfo = async () => {
  const parser = UAParser(navigator.userAgent);
  const deviceInfo = parser.device;
  const osInfo = parser.os;
  const browserInfo = parser.browser;

  const deviceId = localStorage.getItem('device_id') || uuidv4();
  localStorage.setItem('device_id', deviceId);

  let location = {};
  if ('geolocation' in navigator) {
    await new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          resolve();
        },
        () => resolve()
      );
    });
  }

  return {
    device_name: `${deviceInfo.vendor} ${deviceInfo.model}`,
    device_type: deviceInfo.type,
    os: `${osInfo.name} ${osInfo.version}`,
    location,
    device_brand: deviceInfo.vendor,
    device_model_id: deviceInfo.model,
    browser: `${browserInfo.name} ${browserInfo.version}`,
    device_id: deviceId,
  };
};
