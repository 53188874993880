import { API_METHOD } from '../../../config/apiUrl';
import requests from '../../../helpers/requests';
import { API_URLS_DASHBOARD } from './newDashboardAPIUrls';

export async function customerDashboardStats(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.dashboard.customerDashboardStats}`, null, payload, null);
}

export async function customerDashboard(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.dashboard.customerDashboard}`, null, payload, null);
}

export async function getTimeline(uuid, payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.dashboard.getTimeline}/${uuid}`, null, payload, null);
}

export async function getCustomerOpportunities(uuid) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.dashboard.getCustomerOpportunities}/${uuid}`, null, null, null);
}

export async function getCustomerDetails(uuid) {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.dashboard.getCustomerDetails}/${uuid}`, null, null, null);
}

export async function getCustomerEventCount(uuid) {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.dashboard.getCustomerEventCount}/${uuid}`, null, null, null);
}

export async function updateSingleKeyValue(uuid, payload) {
    return await requests(API_METHOD.PUT, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.dashboard.updateSingleKeyValue}/${uuid}`, null, payload, null);
}

export async function updateDoubleKeyValue(uuid, payload) {
    return await requests(API_METHOD.PUT, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.dashboard.updateDoubleKeyValue}/${uuid}`, null, payload, null);
}

export async function findCustomerWithMobile(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.dashboard.findCustomerWithMobile}`, null, payload, null);
}

// Column Customisation
export async function saveColumnPreferenceForcustomerDashbaord(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.company.saveColumnPreferenceForcustomerDashbaord}`, null, payload, null);
}

export async function getColumnPreferenceForCustomerDashboard() {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.company.getColumnPreferenceForCustomerDashboard}`, null, null, null);
}

export async function saveColumnPreferenceForOpportunityDashbaord(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.company.saveColumnPreferenceForOpportunityDashbaord}`, null, payload, null);
}

export async function getColumnPreferenceForOpportunityDashboard() {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.company.getColumnPreferenceForOpportunityDashboard}`, null, null, null);
}

export async function saveColumnPreferenceForBookingDashbaord(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.company.saveColumnPreferenceForBookingDashbaord}`, null, payload, null);
}

export async function getColumnPreferenceForBookingDashboard() {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.company.getColumnPreferenceForBookingDashboard}`, null, null, null);
}

// Opportunity
export async function insertOpportunity(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.insertOpportunity}`, null, payload, null);
}

export async function opportunityDashboard(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.opportunityDashboard}`, null, payload, null);
}

export async function opportunityStats(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.opportunityStats}`, null, payload, null);
}

export async function changeStatus(uuid, payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.changeStatus}/${uuid}`, null, payload, null);
}

export async function getOpportunityTimeline(uuid, payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.getTimeline}/${uuid}`, null, payload, null);
}

export async function getOpportunityBasicDetails(uuid) {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.getOpportunityBasicDetails}/${uuid}`, null, null, null);
}

export async function findOpportunityByMobileAndProject(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.findOpportunityByMobileAndProject}`, null, payload, null);
}

export async function getOpportunityDetails(uuid) {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.getOpportunityDetails}/${uuid}`, null, null, null);
}
export async function getBookingAndEOIUnitsByOpportunityUUID(uuid) {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.getBookingAndEOIUnitsByOpportunityUUID}/${uuid}`, null, null, null);
}

export async function getOpportunityEventCount(uuid) {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.getOpportunityEventCount}/${uuid}`, null, null, null);
}

export async function opportunityUpdateSingleKeyValue(uuid, payload) {
    return await requests(API_METHOD.PUT, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.opportunityUpdateSingleKeyValue}/${uuid}`, null, payload, null);
}

export async function opportunityUpdateDoubleKeyValue2(uuid, payload) {
    return await requests(API_METHOD.PUT, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.opportunityUpdateDoubleKeyValue2}/${uuid}`, null, payload, null);
}

export async function reassignSingleOpportunity(uuid, payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.reassignSingleOpportunity}/${uuid}`, null, payload, null);
}

export async function getAllEventOpportunityDashboard(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.getAllEventOpportunityDashboard}`, null, payload, null);
}

export async function bulkReassignOpportunity(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.bulkReassignOpportunity}`, null, payload, null);
}

// Site visit dashboard

export async function getSvForSVTracker(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.sitevisit.getSvForSVTracker}`, null, payload, null);
}

export async function getStatsForSvTracker(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.sitevisit.getStatsForSvTracker}`, null, payload, null);
}

export async function getClosingManagerByProjectId(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.sitevisit.getClosingManagerByProjectId}`, null, payload, null);
}

export async function scheduleSiteVisit(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.sitevisit.scheduleSiteVisit}`, null, payload, null);
}

export async function getDeadEventsAfterSv(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.sitevisit.getDeadEventsAfterSv}`, null, payload, null);
}

export async function rescheduleSiteVisit(uuid, payload) {
    return await requests(API_METHOD.PUT, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.sitevisit.rescheduleSiteVisit}/${uuid}`, null, payload, null);
}

export async function rescheduleFollowup(uuid, payload) {
    return await requests(API_METHOD.PUT, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.followup.rescheduleFollowup}/${uuid}`, null, payload, null);
}

export async function getTaggingConflictByUUID(uuid) {
    return await requests(API_METHOD.GET, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.getTaggingConflictByUUID}/${uuid}`, null, null, null);
}

export async function assignClosingAndTagging(uuid, payload) {
    return await requests(API_METHOD.PUT, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.opportunity.assignClosingAndTagging}/${uuid}`, null, payload, null);
}

// Booking/Token

export async function bookingDashboard(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.booking.bookingDashboard}`, null, payload, null);
}

export async function bookingDashboardStats(payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.booking.bookingDashboardStats}`, null, payload, null);
}

export async function setEOIOtherDetails(uuid, payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.booking.setEOIOtherDetails}/${uuid}`, null, payload, null);
}

export async function setBookingOtherDetails(uuid, payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.booking.setBookingOtherDetails}/${uuid}`, null, payload, null);
}


export async function updateBookingBasicDetails(uuid, payload) {
    return await requests(API_METHOD.PUT, `${API_URLS_DASHBOARD.baseurlV1}${API_URLS_DASHBOARD.booking.updateBookingBasicDetails}/${uuid}`, null, payload, null);
}

export async function updateEOIBasicDetails(uuid, payload) {
    return await requests(API_METHOD.PUT, `${API_URLS_DASHBOARD.baseurlV1}${API_URLS_DASHBOARD.booking.updateEOIBasicDetails}/${uuid}`, null, payload, null);
}

export async function getAllByLeadUUID(uuid, payload) {
    return await requests(API_METHOD.POST, `${API_URLS_DASHBOARD.baseurl}${API_URLS_DASHBOARD.note.getAllByLeadUUID}/${uuid}`, null, payload, null);
}