import React from 'react';
import TextInput from '../../modules/Lead Details/components/Info Components/Text Input/TextInput';
import InputModal from '../Modals/InputModal';


export default function DynamicEditinput({
    editActive,
    setEditActive,
    heading,
    name,
    placeholder,
    isEditable,
    value,
    type,
    onValueChange,
    onSave,
    leadAccess,
    userProfile,
    isNewVersion
}) {
    return (
        <>
            {
                !editActive ?
                    <TextInput
                        inputKey={name}
                        heading={heading}
                        placeholder={placeholder}
                        isEditable={isEditable}
                        enableEditing={editActive}
                        value={value ? value : "-"}
                        //  onClickHandler={() => { }}
                        editActive={editActive}
                        onEditClick={() => setEditActive(true)}
                        editDeactive={() => setEditActive(false)}
                        leadAccess={leadAccess}
                        userProfile={userProfile}
                        isNewVersion={isNewVersion}
                    /> :
                    <InputModal
                        show={editActive}
                        setShow={() => setEditActive(!editActive)}
                        name={heading}
                        type={type || "text"}
                        placeholder={placeholder}
                        value={value}
                        onChange={(e) => onValueChange(e.target.value)}
                        editSave={onSave}
                    />
            }
        </>
    )
}