import React, { useState, useCallback, useEffect, useRef, useContext } from 'react'
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SearchIcon from '../../../../assets/icons/search.svg'
import { getProjectsForFilter } from '../../../../services/private/leads.service';
import { ReactComponent as DownIcon } from '../../../../assets/new/down.svg';

const sortList = (list) => {
    return list.sort((item1, item2) => item2?.is_selected - item1?.is_selected);
}

const ProjectFilters = ({ handleFilter, leadTopFilters, cornerRadious }) => {
    const [selectedTeamName, setSelectedTeamName] = useState([]);
    const [selectedProjectName, setSelectedProjectName] = useState([]);
    const [showProject, setShowProject] = useState(false);
    const [selectedProjectList, setSelectedProjectList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [allProject, setAllProject] = useState([]);
    const [selectedProject, setSelectedProject] = useState([])
    const [searchProject, setSearchProject] = useState('');
    const [filters, setFilters] = useState([])
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [appliedFilter, setAppliedFilter] = useState(false);
    const [appliedFilterLength, setAppliedFilterLength] = useState(0);

    const projectRef = useRef();

    // Listen to screen resizing to update isMobile
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);

        // Cleanup listener on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleOutsideClick = (e) => {
        if (projectRef.current && !projectRef.current.contains(e.target)) {
            setShowProject(false)
        }
    }

    const getProjects = async () => {
        const res = await getProjectsForFilter();
        if (res.data.status === 200) {
            const mapped = res.data.data.map((item) => ({ ...item, is_selected: false }))
            setProjectList(mapped);
            setAllProject(mapped)
        }
    }

    useEffect(() => {
        getProjects();
        document.addEventListener('mousedown', handleOutsideClick)
        return (() => document.removeEventListener('mousedown', handleOutsideClick))
    }, []);

    useEffect(() => {
        if (leadTopFilters.length > 0 && allProject.length) {
            let filtersArr = {};
            let newFilters = []
            for (const filterData of leadTopFilters) {
                filtersArr = { ...filtersArr, ...filterData }
                if (!['Project'].includes(Object.keys(filterData)[0])) {
                    newFilters.push(filterData);
                }
            }
            let filterKeys = Object.keys(filtersArr);
            if (filterKeys.includes("Project") && filtersArr.Project?.length) {
                let projList = [...allProject];
                filtersArr.Project.forEach(int => {
                    projList?.forEach(proj => {
                        if (proj.id === int) {
                            proj.is_selected = true
                        }
                    })
                })
                // setSelectedProjectList(filteredProject || [])
                setSelectedProject(filtersArr.Project)

                // const notFilteredProject = allProject?.filter(conf => !filtersArr.Project.includes(conf.id))
                projList = sortList(projList)
                setProjectList(projList);
                setAppliedFilter(true)
            }
            setFilters(newFilters)
        }
    }, [leadTopFilters, allProject])

    const handleChangeProject = (e, data, index) => {
        // e.preventDefault();        
        let projList = [...projectList];
        if (selectedProject.includes(data.id)) {
            projList[index].is_selected = false;
            const project = selectedProject.filter(item => item != data.id);
            setSelectedProject(project);

        } else {
            projList[index].is_selected = true;
            setSelectedProject([...selectedProject, data.id]);
        }
        projList = sortList(projList)
        setProjectList([...projList])
        // setAllProject([...projList]);
        // if (selectedProject.includes(data.id)) {
        //     const project = selectedProject.filter(item => item != data.id);
        //     const filteredName = selectedProjectList.filter(item => item.name != data.name);
        //     setSelectedProject(project);
        //     // setSelectedProjectName(filteredName);
        //     setSelectedProjectList(filteredName)
        //     setProjectList([...projectList, data])
        // } else {
        //     setSelectedProject([...selectedProject, data.id]);
        //     // setSelectedProjectName([...selectedProjectName, data]);
        //     setSelectedProjectList([...selectedProjectList, data])
        //     const index = projectList.findIndex(list => list.id === data.id);
        //     projectList.splice(index, 1)
        // }
    }

    const handleProjectSearch = (e) => {
        setSearchProject(e.target.value)
        const projects = allProject.filter(item => item.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1);
        setProjectList([...projects])
    }

    const handleSelectAllProject = () => {
        if (projectList.length > 0) {
            const projectSelection = projectList.map(item => ({ ...item, is_selected: true }));
            const projectIds = projectList.map(item => item.id)
            // setSelectedProjectName([...projectList])            
            setSelectedProject([...projectIds]);
            // setSelectedProjectList([...projectList])
            setProjectList([...projectSelection]);
            setAllProject([...projectSelection])
        }
    }

    const handleClearProjectSearch = () => {
        setSearchProject('');
        setProjectList([...allProject])
        // getAllRecords();
    };

    const handleCloseProject = useCallback(() => {
        const newItems = []
        selectedProject.forEach(item => {
            const index = projectList.findIndex(list => list.id === item);
            newItems.push(projectList[index])
            projectList.splice(index, 1)
        })
        setSelectedProjectList(newItems);
    }, [selectedProject, projectList]);

    const handleClearProjectSelection = () => {
        if (selectedProject.length > 0) {
            // setProjectList([...projectList, ...selectedProjectList])
            const projectSelection = projectList.map(item => ({ ...item, is_selected: false }));
            setProjectList([...projectSelection]);
            setAllProject([...projectSelection]);
            // setAllProject([...projectSelection])
            // setSelectedProjectList([])
            setSelectedProject([]);
        }
        setSelectedProjectName([]);
    };

    return (
        <div className="assigned-drop" ref={projectRef}>
            <Dropdown align="start" className={"short-btn"} show={showProject}>
                <Dropdown.Toggle variant="short" className={`border inter !py-1.5 !text-sm !font-semibold  border-[#CFD5DE] !text-[#344054] hover:!bg-white !flex items-center ${cornerRadious ? '!rounded-lg' : '!rounded-3xl'} ${selectedProject.length > 0 ? 'active' : ' '}`} id="dropdown-autoclose-true" onClick={() => { setShowProject(!showProject) }}>
                    Projects

                    {appliedFilterLength > 0 && <span className='!ml-2 bg-[#1570EF] text-white !text-[10px] rounded-full h-4 w-4 flex items-center justify-center'>{appliedFilterLength} </span>}
                    {/* // : <span>{selectedProjectName[0]?.label || ''}</span> */}

                    {/* <svg className="ml-16" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M12 13.5858L16.2929 9.29289C16.6834 8.90237 17.3166 8.90237 17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L12 13.5858Z" fill="#969698"></path></svg> */}
                    <DownIcon className='stroke-[#344054] !ml-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu className='!w-[297px] !px-0 !py-3 inter !fixed'
                    flip={isMobile ? false : undefined}
                    popperConfig={
                        isMobile
                            ? { strategy: 'fixed', modifiers: [{ name: 'preventOverflow', options: { boundary: 'viewport' } }] }
                            : undefined
                    }>
                    <div className="d-flex justify-content-between align-itmes-center flex-nowrap all-clear !px-3">
                        <a className='fz12 black-dark-700 fw-po-medium pr-hover' onClick={handleSelectAllProject}>Select all</a>
                        <a className='fz12 black-dark-700 fw-po-medium del-hover m-0' onClick={() => {
                            let oldFilters = leadTopFilters;
                            let newFilters = []
                            for (const filterData of oldFilters) {
                                // Check filter values and select element at pos 0 to compare with selected key
                                if (!['Project'].includes(Object.keys(filterData)[0])) {
                                    newFilters.push(filterData);
                                }
                            }
                            if (newFilters.length > 0) {
                                localStorage.setItem('new_filters', JSON.stringify(newFilters))
                            } else {
                                localStorage.removeItem('new_filters')
                            }
                            handleFilter(newFilters);
                            setAppliedFilter(false)
                            setAppliedFilterLength(0)
                            handleClearProjectSelection()
                        }}>Clear all</a>
                    </div>
                    <div className='drop-search !w-full !my-4 !px-3 '>
                        <div className={`form-group position-relative search-input ${searchProject ? 'input-active' : ''} `}>
                            <div className="serch-icon">
                                <img src={SearchIcon} className="" />
                            </div>
                            <input
                                type="text"
                                placeholder="Search"
                                className={`searchinput !w-full !rounded-3xl`}
                                value={searchProject}
                                onChange={(e) => handleProjectSearch(e)}
                            />
                            {searchProject &&
                                <div className="close-icon" onClick={handleClearProjectSearch}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#696974" />
                                    </svg>
                                </div>}
                        </div>
                    </div>
                    <div className='max-h-[200px] overflow-auto !px-3'>
                        <div className='input-list space-y-2 !px-2'>
                            {/* {selectedProjectList.length > 0 && selectedProjectList.map((data, index) => {
                                return (
                                    <div className='dropdown-item rounded-lg !px-0' key={`project_${data.name}_${data.id}`}>
                                        <div className="!px-2 !flex justify-between" onClick={(e) => {
                                            handleChangeProject(e, data, index)
                                        }}>
                                            <label className="form-check-label cursor-pointer w-[200px] truncate" htmlFor={`project_${data.name}_${data.id}`}>
                                                {data.name}
                                            </label>
                                            <input
                                                className=""
                                                id={`project_${data.name}_${data.id}`}
                                                type="checkbox"
                                                name={data.name}
                                                checked={selectedProject.includes(data.id)}
                                            />

                                        </div>
                                    </div>
                                )
                            })} */}
                            {projectList.length > 0 ? projectList.map((data, index) => {
                                return (
                                    <div className='dropdown-item rounded-lg !px-0' key={`project_${data.name}_${data.id}`}
                                    // onClick={(e) => {
                                    //     handleChangeProject(e, data, index)
                                    // }}
                                    >
                                        <div className="!px-2 !flex justify-between" >
                                            <label className="form-check-label cursor-pointer w-[200px] truncate" htmlFor={`project_${data.name}_${data.id}`}>
                                                {data.name}
                                            </label>
                                            <input
                                                id={`project_${data.name}_${data.id}`}
                                                type="checkbox"
                                                name={data.name}
                                                checked={data?.is_selected}
                                                onChange={(e) => {
                                                    handleChangeProject(e, data, index)
                                                }}
                                                onClick={(e) => e.stopPropagation()}
                                            // readOnly
                                            />
                                        </div>
                                    </div>
                                )
                            }) : selectedProjectList.length === 0 && <p>No Records Found</p>}
                        </div>
                    </div>
                    <div className="aply-btn w-full !px-3">
                        <button className='pr-btn d-block w-full' onClick={() => {
                            // getAllRecords();
                            if (selectedProject.length > 0) {
                                handleFilter([
                                    ...filters,
                                    {
                                        Project: selectedProject
                                    }
                                ]);

                                localStorage.setItem('new_filters', JSON.stringify([
                                    ...filters,
                                    {
                                        Project: selectedProject
                                    }
                                ]))

                                // handleCloseProject();
                                // setShowProject(false);
                                setAppliedFilter(true)
                                setAppliedFilterLength(selectedProject.length)
                            }
                            setShowProject(false);
                        }}>Apply filter</button>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default ProjectFilters