import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import Tabs from '../../../components/HOC/Tabs'
import SearchBox from '../../../components/SearchBox';
import { ReactComponent as RefreshIcon } from '../../../assets/icons/dashboard_refresh.svg'
import { ReactComponent as ExportIcon } from '../../../assets/icons/ExportIcon.svg'
import ProjectFilters from '../../LeadsManager/LeadsTopBar/component/ProjectFilters';
import InternalTabs from '../Tabs/InternalTabs';
import Booking from './Booking';
import BookingSV from './BookingSV';
import DeadLeads from './DeadLeads';
import Token from './Token';
import TokenSV from './TokenSV';
import { bookingDashboard, bookingDashboardStats, getColumnPreferenceForBookingDashboard, opportunityDashboard, reassignSingleOpportunity } from '../services/dashboard.service';
import Consumer from '../../../helpers/context';
import { useLocation } from 'react-router-dom';
import { ReactComponent as PluswhiteIcon } from "../../../assets/icons/Pluswhite.svg";
import LeadsFooterBar from '../../LeadsManager/LeadsFooterBar';
import PresaleDropdown from '../Components/PresaleDropdown';
import NewSitevisitModal from '../Components/Modals/NewSitevisitModal';
import { getDeadReasons } from '../../../services/public/public.service';
import { getCompanyAgency, getKnowlarityIvrCallerIdByCompanyId, getUniqueFacebookForms, getUsersForSettings } from '../../../services/private/company.service';
import { getAllJobs } from '../../../services/private/csv.service';
import { bulkSourceChange, reassignLeads } from '../../../services/private/leads.service';
import { toast } from 'react-toastify';
import BulkLeadShareModal from '../../../components/Modals/BulkLeadShare';
import ReassignLeadToTeamAndUserModal from '../../../components/Modals/ReassignLeadToTeamAndUserModal';
import BulkSourceChange from '../../../components/Modals/BulkSourceChange';
import ReassignLeadModal from '../../../components/Modals/ReassignLeadModal';
import FiltersTag from '../Components/FiltersTag';
import FilterModal from '../../../components/Modals/FilterModal/FilterModal';
import { getCustomerEnumsCall } from '../../../services/private/customer.service';
import StatusDropDownDynamic from '../../../components/Status Dropdown/StatusDropdownDynamic';
import { Dropdown } from 'react-bootstrap';
import useDebounce from '../Components/CustomHooks/useDebounce';
import ReassignedOpportunity from '../Components/Modals/ReassignOpportunityModal';
import { filter } from 'jszip';
import AreYouSureModal from '../../../components/Modals/AreYouSureModal';
import { getEOIDetails } from '../../../services/private/inventory.service';
import { useHistory } from 'react-router-dom';
import BulkReassignModal from '../Components/Modals/BulkReassignModal';

const tabList = [
  {
    name: 'Token',
    value: 'token'
  },
  {
    name: 'Booking',
    value: 'booking'
  },
  {
    name: 'Token SV',
    value: 'site_visits_token'
  },
  {
    name: 'Booking SV',
    value: 'site_visits_booking'
  },
  {
    name: 'Dead',
    value: 'dead'
  },

]

const InterTabList = [
  {
    name: 'All',
    value: 'all',
    count: 0
  },
  {
    name: ' Pending',
    value: 'pending',
    count: 0
  },
  {
    name: 'Approved',
    value: 'approved',
    count: 0
  },
  {
    name: 'Cancelled',
    value: 'cancel',
    count: 0
  },

]

const TokenSVTabList = [
  {
    name: 'All',
    value: 'all',
    count: 0
  },
  {
    name: 'Token SV Scheduled',
    value: 'sv_scheduled_token',
    count: 0
  },
  {
    name: 'Token SV Completed',
    value: 'sv_completed_token',
    count: 0
  }
]

const BookingSVTabList = [
  {
    name: 'All',
    value: 'all',
    count: 0
  },
  {
    name: 'Booking SV Scheduled',
    value: 'sv_scheduled_booking',
    count: 0
  },
  {
    name: 'Booking SV Completed',
    value: 'sv_completed_booking',
    count: 0
  }
]

const DeadTabList = [
  {
    name: 'All',
    value: 'all',
    count: 0
  },
  {
    name: 'Post Token Dead',
    value: 'post_token_dead',
    count: 0
  },
  {
    name: 'Post Booking Dead',
    value: 'post_booking_dead',
    count: 0
  }
]

const tableColumn = [
  {
    label: 'Opportunities'
  },
  {
    label: 'Name'
  },
  {
    label: 'Mobile No.'
  },
  {
    label: 'Email'
  },
  {
    label: 'Status'
  },
  {
    label: 'Intent'
  },
  {
    label: 'Created Date'
  }
]

const keyMapping = {
  sv_completed_booking: 'booking_sv_completed',
  sv_scheduled_booking: 'booking_sv_scheduled',
  sv_completed_token: 'token_sv_completed',
  sv_scheduled_token: 'token_sv_scheduled',
  all: 'all',
  approved: 'approved',
  pending: 'pending',
  cancel: 'cancelled'
}
const OPTIONS = [
  { value: "Date", label: "Created Date" },
  { value: "Mobile No.", label: "Mobile No." },
  { value: "Name", label: "Name" },
  { value: "Project", label: "Project" },
  { value: "Source", label: "Source" },
  { value: "Status", label: "Status" },
  { value: "Intent", label: "Intent" }
]

const columnEnum = {
  'Assigned To': 'Assigned User',
  'dead_lead_date': 'Marked Date'
}

const BookingTokenDashboard = () => {
  const [selectedTab, setSelectedTab] = useState(localStorage.getItem('tab') ? tabList.find(i => i.name === localStorage.getItem('tab')) : tabList[0])
  const [tabRowCount, setTabRowCount] = useState(
    {
      Token: 0,
      Booking: 0,
      'Token SV': 0,
      'Booking SV': 0,
      Dead: 0
    }
  );
  const [active, setActive] = useState("By Current Active");
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState([]);
  const [showFilters, setShowFilter] = useState(false);
  const [opportunityData, setOpportunityData] = useState([]);
  const [intentStatuses, setIntentStatuses] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [sourceStatuses, setSourceStatuses] = useState([]);
  const [dashboardColumns, setDashboardColumns] = useState([]);
  const [showingResults, setShowingResults] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [leadsCount, setLeadsCount] = useState();
  const [eventStatus, setEventStatus] = useState('current');
  const [scheduledBy, setScheduleBy] = useState({
    label: 'Direct/CP',
    value: 'all'
  });
  const [showNewSiteVisitModal, setShowNewSiteVisitModal] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [agencyList, setAgencyList] = useState([]);
  const [csvList, setCSVList] = useState([]);
  const [project, setProject] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [showTeamReassign, setShowTeamReassign] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [showSourceChangeModal, setShowSourceChangeModal] = useState(false)
  const [showReassignToTeam, setShowReassignToTeam] = useState(false);
  const [showBulkLeadShare, setShowBulkLeadShare] = useState(false);
  const [showBulkLeadSourceChange, setShowBulkLeadSourceChange] = useState(false);
  const [showBulkLeadShareModal, setShowBulkLeadShareModal] = useState(false);
  const [selectedTableData, setSelectedTableData] = useState([]);
  const [showReassign, setShowReassign] = useState(false)
  const [sourceLoading, setSourceLoading] = useState(false);
  const [selectedLeadName, setSelectedLeadName] = useState('');
  const [showTripleDot, setShowTripleDot] = useState(true);
  const [selectedTableValues, setSelectedTableValues] = useState([]);
  const [sort, setSort] = useState(localStorage.getItem('sort') || "");
  const [showSort, setShowSort] = useState(false);
  const [btnAsc, setBtnAsc] = useState(sort === 'asc' ? true : false);
  const [btnDes, setBtnDes] = useState(sort === 'desc' ? true : false);
  const [sortingOptions, setSortingOptions] = useState(OPTIONS);
  const [col, setCol] = useState('');
  const [fbFormList, setFbFormList] = useState([]);
  const [callerList, setCallerList] = useState([]);
  const [propertyEnums, setPropertyEnums] = useState([]);
  const [leadTopFilters, setLeadTopFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [leadStatus, setLeadStatus] = useState();
  const [showExportLeadModal, setShowExportLeadModal] = useState(false);
  const [exportLoading, setExportLoading] = useState(false)
  const [selectedInternalTab, setSelectedInternalTab] = useState({
    name: 'All',
    value: 'all',
    count: 0
  })
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLead, setSelectedLead] = useState({})
  const [showNewOppReassigned, setShowNewOppReassigned] = useState(false);

  let { companyConfig, allowedPermissions, isRolesModule, userProfile } = useContext(Consumer);

  const debouncedSearch = useDebounce(searchText, 1000);

  const sortRef = useRef();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const history = useHistory();

  const tab = localStorage.getItem('tab');
  const subTab = localStorage.getItem('subTab');

  const handleMouseClick = (e) => {
    if (sortRef.current && !sortRef.current.contains(e.target)) {
      setShowSort(false)
    }
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const exportOpportunities = async (value) => {
    const combineFilters = [...filters, ...leadTopFilters]
    const filterResult = combineFilters.reduce((acc, obj) => {
      // const value = Object.values(obj)[0]; // Extract the nested object
      return { ...acc, ...obj }; // Merge it into the accumulator
    }, {});
    const payload = {
      limit: showingResults,
      pageNumber: currentPage,
      filters: filterResult,
      sort: sort,
      col: col,
      text: debouncedSearch,
      filterType: selectedTab?.value,
      site_visits_filter: '',
      export_opportunity: value,
      followup_filter: (selectedTab.name === 'Dead' || selectedTab.name === 'New' || selectedInternalTab?.name === 'All') ? '' : selectedInternalTab?.value
    }
    try {
      setExportLoading(true);
      const res = await opportunityDashboard(payload);
      setExportLoading(false);
      if (res.data.status === 200) {
        const link = document.createElement("a");
        link.href = res.data.data.url;
        link.download = `dashboard_exported`;
        link.click();
        setShowExportLeadModal(false);
      }
    } catch (err) {
      // setIsLoading(false);
      console.log({ err })
      setExportLoading(false);
    }
  }

  const getOpportunities = async () => {
    const combineFilters = [...filters, ...leadTopFilters]
    const filterResult = combineFilters.reduce((acc, obj) => {
      // const value = Object.values(obj)[0]; // Extract the nested object
      return { ...acc, ...obj }; // Merge it into the accumulator
    }, {})
    const payload = {
      perPage: showingResults,
      pageNumber: currentPage,
      filters: filterResult,
      sortDirection: sort,
      sortColumn: col,
      eventStatus: eventStatus,
      scheduledBy: scheduledBy.value,
      tab: selectedTab?.value,
      subTab: selectedInternalTab?.value,
      searchText: debouncedSearch,
    }
    try {
      setIsLoading(true)
      const res = await bookingDashboard(payload);
      setIsLoading(false)
      if (res.data.status === 200) {
        const ids = res.data.data.response.map(l => l.id)
        localStorage.setItem('leadIds', JSON.stringify(ids))
        setOpportunityData(res.data.data?.response)
        // if (selectedTab.name === 'Token') {
        // } else if (selectedTab.name === 'Booking') {
        //   setOpportunityData(res.data.data?.bookingOnly)
        // } else if (selectedTab?.name === 'Dead') {
        //   setOpportunityData(res.data.data?.deadLeads)
        // }
      }
    } catch (err) {
      setIsLoading(false)
    }
  }

  const getStats = async () => {
    const combineFilters = [...filters, ...leadTopFilters]
    const filterResult = combineFilters.reduce((acc, obj) => {
      // const value = Object.values(obj)[0]; // Extract the nested object
      return { ...acc, ...obj }; // Merge it into the accumulator
    }, {})
    const payload = {
      perPage: showingResults,
      pageNumber: currentPage,
      filters: filterResult,
      sortDirection: sort,
      sortColumn: col,
      eventStatus: eventStatus,
      scheduledBy: scheduledBy.value,
      tab: selectedTab?.value,
      subTab: selectedInternalTab?.value,
      searchText: debouncedSearch,
    }
    const res = await bookingDashboardStats(payload);
    if (res.data.status === 200) {
      const stats = res.data.data.stats;
      setTabRowCount({
        Token: stats?.token,
        Booking: stats?.booking,
        'Token SV': stats?.tokenSv,
        'Booking SV': stats?.bookingSv,
        Dead: stats?.dead
      })
      if (selectedTab.name === 'Token') {
        const totalPages = Math.ceil(
          Number(stats?.token) / showingResults
        );
        setMaxPage(totalPages);
        setLeadsCount(stats?.token);
        localStorage.setItem('leadCount', stats?.token)
        InterTabList.forEach(item => {
          if (item.name === 'All') {
            item.count = stats?.token
          } else {
            item.count = stats?.subTabs[keyMapping[item.value]]
          }
        })
      }
      if (selectedTab.name === 'Booking') {
        const totalPages = Math.ceil(
          Number(stats?.booking) / showingResults
        );
        setMaxPage(totalPages);
        setLeadsCount(stats?.booking);
        localStorage.setItem('leadCount', stats?.booking);
        InterTabList.forEach(item => {
          if (item.name === 'All') {
            item.count = stats?.booking
          } else {
            item.count = stats?.subTabs[keyMapping[item.value]]
          }
          // item.count = stats?.subTabs[keyMapping[item.value]]
        })
      }
      if (selectedTab?.name === 'Token SV') {
        const totalPages = Math.ceil(
          Number(stats?.tokenSv) / showingResults
        );
        setMaxPage(totalPages);
        setLeadsCount(stats?.tokenSv);
        localStorage.setItem('leadCount', stats?.tokenSv)
        TokenSVTabList.forEach(item => {
          if (item.name === 'All') {
            item.count = stats?.tokenSv
          } else {
            item.count = stats?.subTabs[keyMapping[item.value]]
          }
          // item.count = stats?.subTabs[keyMapping[item.value]]
        })
      }
      if (selectedTab?.name === 'Booking SV') {
        const totalPages = Math.ceil(
          Number(stats?.bookingSv) / showingResults
        );
        setMaxPage(totalPages);
        setLeadsCount(stats?.bookingSv);
        localStorage.setItem('leadCount', stats?.bookingSv)
        BookingSVTabList.forEach(item => {
          if (item.name === 'All') {
            item.count = stats?.bookingSv
          } else {
            item.count = stats?.subTabs[keyMapping[item.value]]
          }
          // item.count = stats?.subTabs[keyMapping[item.value]]
        })
      }
      if (selectedTab?.name === 'Dead') {
        const totalPages = Math.ceil(
          Number(stats?.dead) / showingResults
        );
        setMaxPage(totalPages);
        setLeadsCount(stats?.dead);
        localStorage.setItem('leadCount', stats?.dead)
        BookingSVTabList.forEach(item => {
          if (item.name === 'All') {
            item.count = stats?.dead
          } else {
            item.count = stats?.subTabs[keyMapping[item.value]]
          }
          // item.count = stats?.subTabs[keyMapping[item.value]]
        })
      }
    }
  }

  const getColumns = async () => {
    const res = await getColumnPreferenceForBookingDashboard();
    if (res.data.status === 200) {
      let dashboardColumns = [];
      res.data.data.forEach(items => {
        let columnsList = [];
        let selectedColumn = [];

        items.columns.forEach(column => {
          if (column.is_selected) {
            selectedColumn.push(column);
          }

          if (!column.is_selected) {
            columnsList.push(column);
          }
        })

        // adding column according to tab with sorting
        dashboardColumns.push({
          tab: items.tab,
          columns: {
            selected: selectedColumn.sort((a, b) => a.placement - b.placement),
            notSelected: columnsList
          }
        })

        // data for table columns
        // if (items.tab === 'New') {
        //     setNewLeadsColumn(selectedColumn)
        // }
        // if (items.tab === 'Site Visits') {
        //     setSiteVisitColumn(selectedColumn)
        // }
        // if (items.tab === 'All') {
        //     setAllLeadsColumn(selectedColumn)
        // }
        // if (items.tab === 'Followups') {
        //     setNotConnectedColumn(selectedColumn)
        // }
      })
      setDashboardColumns(dashboardColumns)
    }
  }

  /**
 * Updating re-arranged list items
 */
  const updateColumnList = (tab, list) => {
    let columnClone = [...dashboardColumns];
    const index = columnClone.findIndex(item => item.tab === tab)
    const itemColumn = columnClone[index].columns
    columnClone[index].columns = { ...itemColumn, selected: list }
    setDashboardColumns([...columnClone]);
  };

  const handleChangeSelection = (tab, selected, notSelected) => {
    let columnClone = [...dashboardColumns];
    const index = columnClone.findIndex(item => item.tab === tab)
    columnClone[index].columns = { selected, notSelected }
    setDashboardColumns([...columnClone]);
  };

  useEffect(() => {
    if (Object.keys(companyConfig).length > 0) {
      setIntentStatuses(companyConfig.Intent);
      setStatuses(companyConfig.Status);
      setSourceStatuses(companyConfig?.Source)
      setProject(companyConfig.Project);
    }
  }, [companyConfig]);

  useEffect(() => {
    if (tab) {
      const seleted = tabList.find(i => i.name === tab)
      setSelectedTab(seleted);
    }
  }, []);

  // useEffect(() => {
  //   if (searchParams.get('tab')) {
  //     const seleted = tabList.find(i => i.name === searchParams.get('tab'))
  //     setSelectedTab(seleted);
  //   }
  // }, [searchParams]);

  const getFbFormData = async () => {
    const res = await getUniqueFacebookForms();
    if (res.data.status === 200) {
      const list = res.data.data.map((item) => ({
        ...item,
        label: item.form_name,
        value: item.form_id,
      }));
      setFbFormList(list);
    }
  };

  const getAgencyData = async () => {
    await getCompanyAgency().then(res => {
      if (res.status === 200) {
        setAgencyList(res.data.data)
      }
    }).catch((err) => {
      console.log("Something went wrong", err);
    });
  }

  const getAllDataJobs = () => {
    getAllJobs()
      .then((res) => {
        if (res.data.status === 200) {
          // setMappedLeads(res.data.data)
          const mappedData = res.data.data.map(item => ({ ...item, label: item.filename, value: item.job_id }))
          setCSVList(mappedData)
        }
      })
      .catch((err) => {
        console.log({ err })
      })
  }

  const getAllUsers = () => {
    getUsersForSettings().then(res => {
      if (res.status === 200) {
        const userData = res.data.data.map((data) => {
          return {
            label: data.name,
            value: data.id
          }
        })
        // setUsersForAssigned(usersForAssign);
        setAllUsers(userData)
      }
    })
  }

  const getDeadReasonsStatus = () => {
    getDeadReasons().then(res => {
      if (res.status === 200) {
        const reasonsData = res.data.data.map((data) => {
          return {
            label: data.reason,
            value: data.uuid
          }
        })
        setReasons(reasonsData);
      }
    })
  }

  const getCallerIds = async () => {
    const res = await getKnowlarityIvrCallerIdByCompanyId();
    if (res.data.status === 200) {
      const formatedData = res.data.data.map(item => ({ label: item.caller_id, value: item.uuid }))
      setCallerList(formatedData)
    }
  }

  useEffect(() => {
    getCallerIds()
    getFbFormData();
    getDeadReasonsStatus();
    getAllDataJobs();
    getAgencyData();
    getAllUsers();
    getColumns();
    const load = async () => {
      const res = await getCustomerEnumsCall();
      setPropertyEnums(res.data.data);
    };
    load();
    document.addEventListener('mousedown', handleMouseClick)
    return (() => document.removeEventListener('mousedown', handleMouseClick))
  }, [])

  useEffect(() => {
    getOpportunities();
    getStats();
  }, [selectedTab, debouncedSearch, showingResults, currentPage, eventStatus, scheduledBy, col, sort, filters, leadTopFilters, selectedInternalTab]);

  useEffect(() => {
    setShowingResults(100);
    setCurrentPage(1);
    if (selectedTab?.name != tab) {
      localStorage.removeItem('tab');
      localStorage.removeItem('subTab');
    }
    if (selectedTab?.name === tab && subTab) {
      if (tab == 'Token SV') {
        const seleted = TokenSVTabList.find(i => i.name == subTab);
        setSelectedInternalTab(seleted);
      }
      if (tab == 'Booking SV') {
        const seleted = BookingSVTabList.find(i => i.name == subTab)
        setSelectedInternalTab(seleted);
      }
      if (tab == 'Booking' || tab === 'Token') {
        const seleted = InterTabList.find(i => i.name == subTab)
        setSelectedInternalTab(seleted);
      }
      if (tab == 'Dead') {
        const seleted = DeadTabList.find(i => i.name == subTab)
        setSelectedInternalTab(seleted);
      }

    } else {
      if (selectedTab?.name == 'Token SV') {
        setSelectedInternalTab(TokenSVTabList[0]);
      }
      if (selectedTab?.name == 'Booking SV') {
        setSelectedInternalTab(BookingSVTabList[0]);
      }
      if (selectedTab?.name == 'Booking' || selectedTab?.name === 'Token') {
        setSelectedInternalTab(InterTabList[0]);
      }
      if (selectedTab?.name == 'Dead') {
        setSelectedInternalTab(DeadTabList[0]);
      }
    }

  }, [selectedTab])

  useEffect(() => {
    if (selectedInternalTab?.name === subTab) {
      localStorage.removeItem('tab');
      localStorage.removeItem('subTab');
    }
  }, [selectedInternalTab])

  const removeFilterValue = (keyValue) => {
    let oldFilters = filters;
    let newFilters = []
    for (const filterData of oldFilters) {
      // Check filter values and select element at pos 0 to compare with selected key
      if (!keyValue.includes(Object.keys(filterData)[0])) {
        newFilters.push(filterData);
      }
    }
    if (newFilters.length > 0) {
      localStorage.setItem('filter', JSON.stringify(newFilters))
    } else {
      localStorage.removeItem('filter')
    }
    setFilters(newFilters);
  }

  useEffect(() => {
    if (showReassign || showBulkLeadShare || showTeamReassign || showTeamReassign || showBulkLeadSourceChange) {
      setShowTripleDot(false);
    } else {
      setShowTripleDot(true);
    }
  }, [showReassign, showTeamReassign, showBulkLeadShare, showTeamReassign, showBulkLeadSourceChange])

  const resetDataMods = () => {
    setShowCheck(false);
    setSelectedTableValues([]);
    setSelectedTableData([]);
    setShowReassignModal(false);
    setShowReassign(false);
    setShowBulkLeadShare(false);
    setShowReassignToTeam(false);
    setShowTeamReassign(false);
    setShowSourceChangeModal(false);
    setShowBulkLeadSourceChange(false);
  }

  const reAssignTeamClick = () => {
    setShowTeamReassign(true);
    setShowCheck(true);
  }

  const handleBulkLeadShare = () => {
    setShowBulkLeadShare(true);
    setShowCheck(true)
  }

  const handleBulkSourceChange = () => {
    setShowBulkLeadSourceChange(true)
    setShowCheck(true)
  }

  const handleReassign = () => {
    if (selectedTableValues.length > 0) {
      setShowReassignModal(true)
    } else {
      toast.error(" Please select leads to reassign.");
    }
  }

  const handleTeamReassign = () => {
    if (selectedTableValues.length > 0) {
      setShowReassignToTeam(true)
    } else {
      toast.error(" Please select leads to reassign.");
    }
  }

  const handleBulkLead = () => {
    if (selectedTableValues.length > 0) {
      setShowBulkLeadShareModal(true)
    } else {
      toast.error(" Please select leads to reassign.");
    }
  }

  const handleBulkLeadSourceChange = () => {
    if (selectedTableValues.length > 0) {
      setShowSourceChangeModal(true)
    } else {
      toast.error(" Please select leads to change source.");
    }
  }
  // const handleDelete = () => {
  //     if (selectedTableValues.length > 0) {
  //         setShowDeleteModal(true)
  //     } else {
  //         toast.error(" Please select leads for deletion.");
  //     }
  // }
  const setCancelClicked = () => {
    setShowReassign(false)
    // setShowDelete(false)
    // setShowMerge(false)
    setShowCheck(false)
    setShowTeamReassign(false)
    setShowBulkLeadShare(false)
    setSelectedTableValues([]);
    setSelectedTableData([]);
    setShowBulkLeadSourceChange(false);
  }

  const handleReassignModalSubmit = (userDetails, remarks) => {
    let user_array = [];
    if (userDetails?.length > 0) {
      user_array = userDetails.map(u => u.value)
    }
    reassignLeads(selectedTableValues, userDetails?.length ? user_array : userDetails.value, remarks).then(res => {
      if (res.status === 200) {
        toast.success("Lead Reassigned")
        getOpportunities();
        getStats();
      }
    }).catch(e => {
      toast.error(e.response.data.message)
    })
    resetDataMods()
  }

  const handleSourceChange = (source) => {
    const payload = {
      uuid: selectedTableValues,
      source_id: source.value
    }
    setSourceLoading(true)
    bulkSourceChange(payload).then(res => {
      if (res.status === 200) {
        toast.success(res.data.message)
        getOpportunities();
        getStats();
        setSourceLoading(false)
      }
    }).catch(e => {
      toast.error(e.response.data.message)
    })
    resetDataMods()
  }

  const handleTeamReassignModalSubmit = (userDetails, remarks) => {
    let user_array = [];
    if (userDetails?.length > 0) {
      user_array = userDetails.map(u => u.value)
    }
    reassignLeads(selectedTableValues, userDetails?.length ? user_array : userDetails.value, remarks).then(res => {
      if (res.status === 200) {
        toast.success("Lead Reassigned")
        getOpportunities();
        getStats();

      }
    }).catch(e => {
      toast.error(e.response.data.message)
    })
    resetDataMods()
  }

  const setLeadName = useCallback((name) => {
    setSelectedLeadName(name)
  }, []);

  const handleSortChange = e => {
    if (col) {
      if (e == "asc") {
        setBtnAsc(true);
        setBtnDes(false);
      } else {
        setBtnAsc(false);
        setBtnDes(true);
      }
      // localStorage.setItem('sort', e);
      setSort(e);
      // sortToaster();
      // setCurrentPage(1)
    }
  }

  const handleSortColChange = (e) => {
    const value = e.value === 'Marked Date' ? 'dead_lead_date' : e.value
    setCol(value)
    // localStorage.setItem('col', value)
    // localStorage.removeItem('currentPage')
    // sortToaster();
    // setCurrentPage(1);
    if (!sort) {
      // localStorage.setItem('sort', 'asc')
      setSort("asc");
      setBtnAsc(true);
    }
  }

  const handleSingleReassignModalSubmit = (presaleManager, closingManager, sourcingManager) => {
    const payload = {
      preSaleUserId: presaleManager?.value || null,
      sourcingUserId: sourcingManager?.value || null,
      closingUserId: closingManager || null
    }
    reassignSingleOpportunity(selectedLead?.id, payload).then(res => {
      if (res.status === 200) {
        toast.success("Lead Reassigned");
        setShowNewOppReassigned(false);
        // if (ROLE_NAME === 'ADMIN') {
        //     window.location.reload();
        // } else {
        //     history.push('/')
        // }
        // handle();
        ;
      }
    }).catch(e => {
      toast.error(e.response.data.message)
    })
  }

  return (
    <div className="main-section show-new-leads flex flex-col !pl-0 !pb-0 !pr-0">
      <div className="!px-5 !pt-5 !border-b !border-grayLight">
        <div className="w-100 d-flex justify-between flex-md-fill flex-wrap !mb-4">
          <div className="flex items-center">
            <div className='flex items-center flex-col md:flex-row'>
              <h1 className="text-2xl mb-0 black font-semibold !mr-1"> Booking/Token Dashboard </h1>
              <button className="!border !p-1 rounded-lg hover:bg-grey-100" onClick={() => {
                getOpportunities();
                getStats();
              }}>
                <RefreshIcon />
              </button>
            </div>
          </div>
          <div className="d-flex justify-end align-items-md-end aling-items-center flex-md-fill !space-x-4">


            <ProjectFilters
              handleFilter={setLeadTopFilters}
              leadTopFilters={leadTopFilters}
              cornerRadious={true}
            />

            {/* Presale */}
            <PresaleDropdown scheduledBy={scheduledBy} setScheduleBy={setScheduleBy} />

            <SearchBox searchText={searchText} setSearchText={setSearchText} placeholder={'Search'} classname='mr-0' />
            {/* <button
              className="bg-primary text-white !pl-2 !pr-3 !py-1 !rounded-lg flex items-center font-medium text-sm"
              onClick={() => setShowNewSiteVisitModal(true)} >
              <PluswhiteIcon /> New Site Visit
            </button> */}
            <div className="flex items-center">

              <div className="d-flex flex-row align-items-center">
                <div className="" ref={sortRef}>
                  <Dropdown align="end" className={"short-btn"} show={showSort}>
                    <Dropdown.Toggle variant="short" id="dropdown-autoclose-true" className={`${col ? "active" : " "} d-flex`} onClick={() => { setShowSort(true) }}>
                      <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M4 6.00012H13" stroke="#44444F" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path><path d="M4 12.0001H11" stroke="#44444F" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path><path d="M4 18.0001H11" stroke="#44444F" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path><path d="M15 15.0001L18 18.0001L21 15.0001" stroke="#44444F" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path><path d="M18 6.00012V18.0001" stroke="#696974" strokeWidth="1.73082" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                      <span className="text-capitalize">Sort</span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="filter-heading d-flex align-items-center justify-content-between mb-12">
                        <h3 className="fz14 black mb-0"> Sort by</h3>
                        <button type="button" onClick={() => setShowSort(!showSort)} className="close float-right" aria-label="Close">
                          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#000">
                            </path>
                          </svg>
                        </button>
                      </div>
                      <div className="form-group">
                        <StatusDropDownDynamic
                          value={{ value: col, label: (col == 'Assigned To' || col === 'dead_lead_date') ? columnEnum[col] : col }}
                          onChange={handleSortColChange}
                          options={sortingOptions}
                          placeholder='Select Column'
                        />
                      </div>
                      {
                        col ?
                          <>
                            <div className="mt-12 a-z-short">
                              <button className={`${btnAsc ? "active" : ""}`} onClick={() => handleSortChange("asc")} >
                                Ascending
                              </button>
                              <button className={`${btnDes ? "active" : ""}`} onClick={() => handleSortChange("desc")} >
                                Descending
                              </button>
                            </div>
                            <div className="remove mt-22">
                              <a onClick={() => {
                                setCol("")
                                setSort("")
                                setBtnAsc(false);
                                setBtnDes(false);
                                // localStorage.removeItem('sort');
                                // localStorage.removeItem('col');
                                toast.success('Sort removed')
                              }} className="d-flex align-items-center fz14  ml-auto float-right justify-content-end black-dark-700">
                                <svg className="mr-8" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                  <path fillRule="evenodd" clipRule="evenodd" d="M5.10904 3.13331L5.55709 1.78916C5.64783 1.51693 5.90259 1.33331 6.18954 1.33331H9.78954C10.0765 1.33331 10.3313 1.51693 10.422 1.78916L10.87 3.13331H13.9895C14.3577 3.13331 14.6562 3.43179 14.6562 3.79998C14.6562 4.16817 14.3577 4.46665 13.9895 4.46665H13.4181L12.9289 12.7841C12.8667 13.8412 11.9913 14.6666 10.9323 14.6666H5.04675C3.9878 14.6666 3.11238 13.8412 3.0502 12.7841L2.56094 4.46665H2.00016C1.63197 4.46665 1.3335 4.16817 1.3335 3.79998C1.3335 3.43179 1.63197 3.13331 2.00016 3.13331H5.10904ZM6.51449 3.13331H9.46459L9.30904 2.66665H6.67005L6.51449 3.13331ZM12.0825 4.46665H3.89658L4.38123 12.7058C4.40196 13.0582 4.69377 13.3333 5.04675 13.3333H10.9323C11.2853 13.3333 11.5771 13.0582 11.5979 12.7058L12.0825 4.46665ZM9.12417 6.15839C9.14714 5.79092 9.46366 5.51164 9.83113 5.53461C10.1986 5.55758 10.4779 5.87409 10.4549 6.24157L10.1549 11.0416C10.1319 11.409 9.81543 11.6883 9.44796 11.6653C9.08048 11.6424 8.80121 11.3259 8.82417 10.9584L9.12417 6.15839ZM7.15491 10.9584C7.17788 11.3259 6.8986 11.6424 6.53113 11.6653C6.16366 11.6883 5.84714 11.409 5.82417 11.0416L5.52417 6.24157C5.50121 5.87409 5.78048 5.55758 6.14796 5.53461C6.51543 5.51164 6.83194 5.79092 6.85491 6.15839L7.15491 10.9584Z" fill="#696974" />
                                </svg> Clear Sort
                              </a>
                            </div>
                          </>
                          : " "
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="filter-btn ">
                <button
                  className={`d-flex align-items-center ${filters?.length > 0
                    ? " border-0 pr-hover  active"
                    : "border-0 btn"
                    }`}
                  type="button"
                  onClick={() => setShowFilter(true)}
                >
                  <svg
                    className="mr-8"
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.981 11.1747V15.5775C12.981 15.9053 12.7958 16.2049 12.5026 16.3515L9.04095 18.0823C8.46554 18.37 7.78852 17.9516 7.78852 17.3083V11.1747L1.92626 4.00973C1.46395 3.44468 1.86597 2.59631 2.59606 2.59631H18.1734C18.9035 2.59631 19.3056 3.44468 18.8432 4.00973L12.981 11.1747ZM11.2502 15.0426V10.8658C11.2502 10.666 11.3193 10.4724 11.4458 10.3178L16.3472 4.32713H4.42228L9.32372 10.3178C9.45022 10.4724 9.51934 10.666 9.51934 10.8658V15.908L11.2502 15.0426Z"
                      fill="#696974"
                    ></path>
                  </svg>
                  <span className="text-capitalize">
                    Filter {filters?.length > 0 ? ` (${filters?.length})` : " "}
                  </span>
                </button>
              </div>
            </div>
            {
              showTripleDot && <div className="lead-manager dropdown view-more d-flex !pb-1 !ml-0">
                <button className="bg-tr border-0 px-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17Z" fill="#696974" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z" fill="#696974" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5C14 3.89543 13.1046 3 12 3Z" fill="#696974" />
                  </svg>
                </button>
                <div className="dropdown-menu w-auto dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                  {/* {ROLE_NAME === 'ADMIN' && */}
                  <a className="dropdown-item mb-8" onClick={() => setShowExportLeadModal(true)}> Export </a>
                  <>
                    <div className="head-itme">Bulk Actions <span style={{ width: '80px' }}></span></div>
                    <a className="dropdown-item" onClick={reAssignTeamClick}>Re-assign Leads to User</a>
                    {/* <a className="dropdown-item mb-0" onClick={deleteClick}>Delete Leads</a> */}
                  </>
                  <a className="dropdown-item" onClick={handleBulkLeadShare}>Bulk Lead Share</a>
                  <a className="dropdown-item" onClick={handleBulkSourceChange}>Bulk Leads Source Change</a>

                </div>
              </div>
            }
          </div>
        </div>
        <div className='flex w-100 justify-between'>
          <Tabs
            tabList={tabList}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            tabName={selectedTab}
            tabRowCount={tabRowCount}
          />
          <>
            {
              showReassign ?
                <div className="merge-del-btn">
                  <div className="mr-12 d-flex flex-row">
                    <button className="border-0 merge-btn !rounded-tr-none !rounded-br-none" onClick={handleReassign}>Re-assign to User ({selectedTableValues.length})</button>
                    <button className="cancel-btn ml-0 flex items-center !rounded-bl-none !rounded-tl-none !px-2.5" onClick={setCancelClicked}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#171725"></path></svg></button>
                  </div></div> : " "
            }

            {
              showTeamReassign ? <div className="merge-del-btn">
                <div className="mr-12 d-flex flex-row">
                  <button className="border-0 merge-btn !rounded-tr-none !rounded-br-none" onClick={handleTeamReassign}>Re-assign to Team ({selectedTableData.length})</button>
                  <button className="cancel-btn ml-0 flex items-center !rounded-bl-none !rounded-tl-none !px-2.5" onClick={setCancelClicked}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#171725"></path></svg></button>
                </div></div> : " "
            }
            {
              showBulkLeadShare ? <div className="merge-del-btn">
                <div className="mr-12 d-flex flex-row">
                  <button className="border-0 merge-btn !rounded-tr-none !rounded-br-none" onClick={handleBulkLead}>Leads Share Access ({selectedTableValues.length})</button>
                  <button className="cancel-btn ml-0 flex items-center !rounded-bl-none !rounded-tl-none !px-2.5" onClick={setCancelClicked}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#171725"></path></svg></button>
                </div></div> : " "
            }
            {
              showBulkLeadSourceChange ? <div className="merge-del-btn">
                <div className="mr-12 d-flex flex-row">
                  <button className="border-0 merge-btn !rounded-tr-none !rounded-br-none" onClick={handleBulkLeadSourceChange}>Leads Source Change ({selectedTableValues.length})</button>
                  <button className="cancel-btn ml-0 flex items-center !rounded-bl-none !rounded-tl-none !px-2.5" onClick={setCancelClicked}><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.94131 5.00019L9.45935 8.52142C9.71958 8.78188 9.71939 9.20399 9.45892 9.46423C9.19845 9.72446 8.77634 9.72427 8.51611 9.4638L4.9978 5.9423L1.47122 9.46365C1.21067 9.7238 0.788564 9.72349 0.528408 9.46295C0.268252 9.2024 0.268566 8.78029 0.529109 8.52014L4.05542 4.99907L0.53071 1.47117C0.270478 1.2107 0.270669 0.788594 0.531136 0.528362C0.791603 0.26813 1.21371 0.268321 1.47395 0.528788L4.99893 4.05696L8.51612 0.544989C8.77666 0.284833 9.19877 0.285147 9.45893 0.54569C9.71909 0.806233 9.71877 1.22834 9.45823 1.4885L5.94131 5.00019Z" fill="#171725"></path></svg></button>
                </div></div> : " "
            }
          </>
        </div>
      </div>
      <div className='!pt-5 !px-5 h-100'>
        <div className='flex w-full justify-between items-center'>
          {selectedTab.name === 'Token' &&
            <InternalTabs
              tabList={InterTabList}
              selectedTab={selectedInternalTab}
              setSelectedTab={setSelectedInternalTab}
            />
          }
          {selectedTab.name === 'Booking' &&
            <InternalTabs
              tabList={InterTabList}
              selectedTab={selectedInternalTab}
              setSelectedTab={setSelectedInternalTab}
            />
          }
          {selectedTab.name === 'Dead' &&
            <InternalTabs
              tabList={DeadTabList}
              selectedTab={selectedInternalTab}
              setSelectedTab={setSelectedInternalTab}
            />
          }
          {selectedTab.name === 'Booking SV' &&
            <InternalTabs
              tabList={BookingSVTabList}
              selectedTab={selectedInternalTab}
              setSelectedTab={setSelectedInternalTab}
            />
          }
          {selectedTab.name === 'Token SV' &&
            <InternalTabs
              tabList={TokenSVTabList}
              selectedTab={selectedInternalTab}
              setSelectedTab={setSelectedInternalTab}
            />
          }
          <div className="flex items-center !gap-4 !mb-5">
            {/* Button Group */}
            <div className="flex border border-gray-300 rounded-lg w-max">
              <button
                onClick={() => setEventStatus("current")}
                className={`flex gap-2 px-4 py-2 text-sm ${eventStatus === "current"
                  ? "text-blue-600 bg-blue-50 font-semibold"
                  : "text-gray-700 hover:bg-gray-100"
                  }`}
              >
                By Current Active
              </button>
              <button
                onClick={() => setEventStatus("all")}
                className={`px-4 py-2 text-sm  ${eventStatus === "all"
                  ? "text-blue-600 bg-blue-50 font-semibold"
                  : "text-gray-700 hover:bg-gray-100"
                  }`}
              >
                All Events
              </button>
            </div>

            {/* Export Button */}
            <button
              className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-100"
            >
              <ExportIcon className='mr-2' />
              Export
            </button>
          </div>
        </div>
        {(filters.length > 0 || showExportLeadModal) &&
          <div className="!mb-5">
            <FiltersTag
              filters={filters}
              removeFilterValue={removeFilterValue}
              reasons={reasons}
              agencyList={agencyList}
              propertyEnums={propertyEnums}
              csvList={csvList}
              project={project}
              intentStatuses={intentStatuses}
              fbFormList={fbFormList}
              sourceStatuses={sourceStatuses}
              statuses={statuses}
              allUsers={allUsers}
              showExportLeadModal={showExportLeadModal}
              setShowExportLeadModal={setShowExportLeadModal}
              exportOpportunities={exportOpportunities}
              exportLoading={exportLoading}
              leadsCount={leadsCount}
              selectedTab={selectedTab}
            />
          </div>
        }
        {
          selectedTab.name === 'Token' && <Token
            opportunityData={opportunityData}
            tableColumn={tableColumn}
            sourceStatuses={sourceStatuses}
            statuses={statuses}
            intentStatuses={intentStatuses}
            dashboardColumns={dashboardColumns}
            selectedTab={selectedTab}
            updateColumnList={updateColumnList}
            handleChangeSelection={handleChangeSelection}
            getColumns={getColumns}
            getOpportunities={getOpportunities}
            selectedTableValues={selectedTableValues}
            setSelectedTableValues={setSelectedTableValues}
            setSelectedTableData={setSelectedTableData}
            selectedTableData={selectedTableData}
            setLeadName={setLeadName}
            showCheck={showCheck}
            callerList={callerList}
            col={col}
            sort={sort}
            setSelectedLead={setSelectedLead}
            setShowNewOppReassigned={setShowNewOppReassigned}
            filters={filters}
            searchText={searchText}
            isLoading={isLoading}
            selectedInternalTab={selectedInternalTab}
          />
        }
        {
          selectedTab.name === 'Booking' && <Booking
            opportunityData={opportunityData}
            tableColumn={tableColumn}
            sourceStatuses={sourceStatuses}
            statuses={statuses}
            intentStatuses={intentStatuses}
            dashboardColumns={dashboardColumns}
            selectedTab={selectedTab}
            updateColumnList={updateColumnList}
            getOpportunities={getOpportunities}
            handleChangeSelection={handleChangeSelection}
            selectedTableValues={selectedTableValues}
            setSelectedTableValues={setSelectedTableValues}
            setSelectedTableData={setSelectedTableData}
            selectedTableData={selectedTableData}
            setLeadName={setLeadName}
            showCheck={showCheck}
            callerList={callerList}
            col={col}
            sort={sort}
            getColumns={getColumns}
            setSelectedLead={setSelectedLead}
            setShowNewOppReassigned={setShowNewOppReassigned}
            filters={filters}
            searchText={searchText}
            isLoading={isLoading}
            selectedInternalTab={selectedInternalTab}
          />
        }
        {
          selectedTab.name === 'Token SV' && <TokenSV
            opportunityData={opportunityData}
            tableColumn={tableColumn}
            sourceStatuses={sourceStatuses}
            statuses={statuses}
            intentStatuses={intentStatuses}
            dashboardColumns={dashboardColumns}
            selectedTab={selectedTab}
            updateColumnList={updateColumnList}
            getOpportunities={getOpportunities}
            handleChangeSelection={handleChangeSelection}
            selectedTableValues={selectedTableValues}
            setSelectedTableValues={setSelectedTableValues}
            setSelectedTableData={setSelectedTableData}
            selectedTableData={selectedTableData}
            setLeadName={setLeadName}
            showCheck={showCheck}
            callerList={callerList}
            col={col}
            sort={sort}
            getColumns={getColumns}
            setSelectedLead={setSelectedLead}
            setShowNewOppReassigned={setShowNewOppReassigned}
            filters={filters}
            searchText={searchText}
            isLoading={isLoading}
            selectedInternalTab={selectedInternalTab}
          />
        }
        {
          selectedTab.name === 'Booking SV' && <BookingSV
            opportunityData={opportunityData}
            tableColumn={tableColumn}
            sourceStatuses={sourceStatuses}
            statuses={statuses}
            intentStatuses={intentStatuses}
            dashboardColumns={dashboardColumns}
            selectedTab={selectedTab}
            updateColumnList={updateColumnList}
            getOpportunities={getOpportunities}
            handleChangeSelection={handleChangeSelection}
            selectedTableValues={selectedTableValues}
            setSelectedTableValues={setSelectedTableValues}
            setSelectedTableData={setSelectedTableData}
            selectedTableData={selectedTableData}
            setLeadName={setLeadName}
            showCheck={showCheck}
            callerList={callerList}
            col={col}
            sort={sort}
            getColumns={getColumns}
            setSelectedLead={setSelectedLead}
            setShowNewOppReassigned={setShowNewOppReassigned}
            filters={filters}
            searchText={searchText}
            isLoading={isLoading}
            selectedInternalTab={selectedInternalTab}
          />
        }
        {
          selectedTab.name === 'Dead' && <DeadLeads
            opportunityData={opportunityData}
            tableColumn={tableColumn}
            sourceStatuses={sourceStatuses}
            statuses={statuses}
            intentStatuses={intentStatuses}
            dashboardColumns={dashboardColumns}
            selectedTab={selectedTab}
            updateColumnList={updateColumnList}
            getOpportunities={getOpportunities}
            handleChangeSelection={handleChangeSelection}
            selectedTableValues={selectedTableValues}
            setSelectedTableValues={setSelectedTableValues}
            setSelectedTableData={setSelectedTableData}
            selectedTableData={selectedTableData}
            setLeadName={setLeadName}
            showCheck={showCheck}
            callerList={callerList}
            col={col}
            sort={sort}
            getColumns={getColumns}
            setSelectedLead={setSelectedLead}
            setShowNewOppReassigned={setShowNewOppReassigned}
            filters={filters}
            searchText={searchText}
            isLoading={isLoading}
            selectedInternalTab={selectedInternalTab}
          />
        }
      </div>
      <LeadsFooterBar
        showingResults={showingResults}
        setShowingResults={setShowingResults}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        maxPage={maxPage}
        leadsCount={leadsCount}
        classname='!basis-0'
      />
      {
        showNewSiteVisitModal && <NewSitevisitModal
          show={showNewSiteVisitModal}
          setShow={setShowNewSiteVisitModal}
        />
      }
      {showReassignModal && <ReassignLeadModal
        setShow={setShowReassignModal}
        show={showReassignModal}
        handleSubmit={(userId, remarks) => handleReassignModalSubmit(userId, remarks)}
        count={selectedTableValues?.length}
        selectedLeadName={selectedLeadName}
      />}
      {/* Source change */}
      {showSourceChangeModal && <BulkSourceChange
        setShow={setShowSourceChangeModal}
        show={showSourceChangeModal}
        handleSubmit={(source) => handleSourceChange(source)}
        count={selectedTableValues?.length}
        selectedLeadName={selectedLeadName}
        sourceList={companyConfig?.Source}
        loading={sourceLoading}
      />}

      {/* ReassignTeamLeadModal */}
      {
        showReassignToTeam &&
        <BulkReassignModal
          setShow={setShowReassignToTeam}
          show={showReassignToTeam}
          handleSubmit={(userId, remarks) => handleTeamReassignModalSubmit(userId, remarks)}
          count={selectedTableData?.length}
          selectedLeadName={selectedLeadName}
          selectedTableData={selectedTableData}
          setSelectedTableData={setSelectedTableData}
          setSelectedTableValues={setSelectedTableValues}
          resetDataMods={resetDataMods}
          selectedTableValues={selectedTableValues}
          getLeadsData={() => {
            getOpportunities();
            getStats();
          }}
          getAllLeadsCount={() => { }}
        />
      }
      {showBulkLeadShareModal &&
        <BulkLeadShareModal
          show={showBulkLeadShareModal}

          closeModal={() => {
            setShowBulkLeadShareModal(false)
            // setSelectedItem({})
          }}
          count={selectedTableValues?.length}
          selectedLeadName={selectedLeadName}
          resetDataMods={resetDataMods}
          selectedTableValues={selectedTableValues}
          getLeadsData={() => {
            getOpportunities();
            getStats();
          }}
          getAllLeadsCount={() => { }}
        />
      }
      {showFilters && (
        <FilterModal
          show={showFilters}
          setShow={setShowFilter}
          handleFilter={setFilters}
          setFilterCount={() => { }}
          filtersData={filters}
          leadType={""}
          reAssignRole={""}
          setCurrentPage={() => { }}
          filterToaster={() => { }}
          propertyEnums={propertyEnums}
          isRolesModule={isRolesModule}
          allowedPermissions={allowedPermissions}
          fbFormList={fbFormList}
        />
      )}
      {showNewOppReassigned && <ReassignedOpportunity
        setShow={setShowNewOppReassigned}
        show={showNewOppReassigned}
        selectedLeadName={selectedLead?.customer_name || selectedLead?.name}
        handleSubmit={handleSingleReassignModalSubmit}
      />}

    </div>
  )
}

export default BookingTokenDashboard